//@flow
import { isString, isInteger, isBoolean, isArray } from 'lodash'

export function parseArray<T>(f: any => T): any => Array<T> {
  return (obj: any) => {
    if (!isArray(obj)) throw new Error(`Expected an array, got ${obj}`)
    return obj.map(f)
  }
}

export function parseOrNull<T>(input: any, parse: void => T): null | T {
  try {
    return parse(input)
  } catch (e) {
    return null
  }
}

export const parseString = (s: any): string => {
  if (!isString(s)) throw new Error(`Expected a string, got ${s}`)
  return s
}

export const parseInt = (i: any): number => {
  if (!isInteger(i)) throw new Error(`Expected an integer, got ${i}`)
  return i
}

export const parseBoolean = (i: any): boolean => {
  if (!isBoolean(i)) throw new Error(`Expected a boolean, got ${i}`)
  return i
}

export function parseField<T>(object: Object, field: string, parse: any => T): T {
  if (typeof object !== 'object') {
    throw new Error(`Expected an object, got ${object}`)
  }
  if (!(field in object)) {
    throw new Error(`Couldn't find field '${field}' in ${JSON.stringify(object)}`)
  }
  return parse(object[field])
}

//@flow
import { unionWith } from 'lodash'
import { Fetcher, Fetching, makeGetReducer } from 'util/fetcher'
import { parseField, parseString, parseInt, parseArray } from 'util/parseUtils'

// A single entry for a whitelisted email domain.
export type DomainWhitelistEntryT = {
  email_domain: string,
  id: number,
}

// Validate an object as a domain whitelist entry
const parseDomainWhitelistEntry = (obj: Object): DomainWhitelistEntryT => ({
  email_domain: parseField(obj, 'email_domain', parseString),
  id: parseField(obj, 'id', parseInt),
})

// Convenient type alias for an array of entries
export type Entries = Array<DomainWhitelistEntryT>

// The format of a response to a request to add whitelisted domains.
export type DomainWhitelistPostResponseT = {
  successes: Entries,
  failures: Array<string>,
}

// Parse successes and failures from a
const parseDomainWhitelistResponse = (obj: Object): DomainWhitelistPostResponseT => {
  return {
    successes: parseField(obj, 'successes', parseArray(parseDomainWhitelistEntry)),
    failures: parseField(obj, 'failures', parseArray(parseString)),
  }
}

// Fetches whitelisted domains from rottweiler
export const domainWhitelistFetcher: Fetcher<void, DomainWhitelistPostResponseT> = new Fetcher({
  prefix: 'FETCH_DOMAIN_WHITELIST',
  decoder: obj => obj.results.map(parseDomainWhitelistEntry),
  buildUrl: () => '/proxy/rottweiler/whitelists/domain/',
})

// Fetching whitelisted domains occurs in the standard way.
const domainWhitelistFetchReducer = makeGetReducer(domainWhitelistFetcher.prefix)

// Adds new whitelisted domains to rottweiler
export const domainWhitelistUpdater: Fetcher<
  Array<string>,
  DomainWhitelistPostResponseT
> = new Fetcher({
  prefix: 'UPDATE_DOMAIN_WHITELIST',
  decoder: obj => parseDomainWhitelistResponse(obj),
  buildUrl: () => '/proxy/rottweiler/whitelists/domain/',
  buildOptions: (domains: Array<string>) => {
    return Fetcher.defaultOptions({
      method: 'POST',
      body: JSON.stringify({ values: domains }),
    })
  },
})

// Create a reducer which updates the full list of whitelist entries
// with any successfully created entries.
const domainWhitelistUpdateReducer = domainWhitelistUpdater.makeReducer({
  onSuccess: (
    state: Fetching<Entries>,
    payload: DomainWhitelistPostResponseT
  ): Fetching<Entries> => {
    const update = (items: Entries): Entries => {
      return unionWith(items, payload.successes, (a, b) => a.id === b.id)
    }
    return state.handle({
      whenFetching: (items: null | Entries) => Fetching.fetched(update(items || [])),
      whenErrored: _ => Fetching.fetched([payload]),
      whenFetched: (items: Entries) => Fetching.fetched(update(items)),
    })
  },
})

// Deletes whitelisted domains from rottweiler
export const domainWhitelistDeleter: Fetcher<number, number> = new Fetcher({
  prefix: 'DELETE_DOMAIN_WHITELIST',
  // "Decode" the response by ignoring it and just returning the ID used to construct the request.
  decoder: (_, id: number) => id,
  buildUrl: (id: number) => `/proxy/rottweiler/whitelists/domain/${id}/`,
  buildOptions: (id: number) => Fetcher.defaultOptions({ method: 'DELETE' }),
})

// Deletes the removed entry from the list of whitelist entries.
const domainWhitelistDeleteReducer = domainWhitelistDeleter.makeReducer({
  onSuccess: (state: Fetching<Entries>, payload: number): Fetching<Entries> => {
    const update = (entries: Entries): Entries => entries.filter(e => e.id !== payload)
    return state.handle({
      whenFetching: (entries: null | Entries) => Fetching.fetched(update(entries || [])),
      whenErrored: _ => Fetching.fetched([payload]),
      whenFetched: (entries: Entries) => Fetching.fetched(update(entries)),
    })
  },
})

// $FlowFixMe
export const domainWhitelistReducer = (state: Fetching<Entries>, action: any) => {
  state = domainWhitelistFetchReducer(state, action)
  state = domainWhitelistUpdateReducer(state, action)
  state = domainWhitelistDeleteReducer(state, action)
  return state
}

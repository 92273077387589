// @flow
import type { ActionT } from '@knowledgehound/data/Action.type'
import type { ErrorT } from '@knowledgehound/data/Error.type'
import type { MetadataValueT } from './MetadataValuesT'
import { handleResponse, handleError, handleData } from '@knowledgehound/data/fetchUtils'

// CONSTANTS
export const METADATAVALUES_LOADING = 'METADATAVALUES_LOADING'
export const METADATAVALUES_SUCCESS = 'METADATAVALUES_SUCCESS'
export const METADATAVALUES_ERROR = 'METADATAVALUES_ERROR'

export function metadataValuesLoading(): ActionT {
  return {
    type: METADATAVALUES_LOADING,
  }
}

export function metadataValuesSuccess(metadataValues: Array<MetadataValueT> | string): ActionT {
  return {
    type: METADATAVALUES_SUCCESS,
    payload: metadataValues,
  }
}

export function metadataValuesError(err: ErrorT): ActionT {
  return {
    type: METADATAVALUES_ERROR,
    error: true,
    payload: err,
  }
}

export function metadataValues(id: number): Function {
  return (dispatch: Function, getState, { fetch }) => {
    dispatch(metadataValuesLoading())

    return new Promise((resolve: Function, reject: Function) => {
      fetch(`/proxy/greyhound/fields/${id}/values/`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'same-origin',
      })
        .then(handleResponse)
        .then(handleData(resolve, dispatch, metadataValuesSuccess))
        .catch(handleError(reject, dispatch, metadataValuesError))
    })
  }
}

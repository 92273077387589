import type { MetadataValueT } from 'data/metadata/metadataValues/MetadataValuesT'

export default function listToTree(list: Array<MetadataValueT>): Array<MetadataValueT> {
  const tree = []
  const lookup = list.reduce(function (map, node) {
    map[node.id] = { ...node }
    return map
  }, {})
  list.forEach(function (node) {
    const nodeCopy = lookup[node.id]
    const parent = lookup[nodeCopy.parent]
    if (parent) {
      // create child array if it doesn't exist
      ;(parent.children || (parent.children = []))
        // add nodeCopy to parent's child array
        .push(nodeCopy)
    } else {
      // parent is null or missing
      tree.push(nodeCopy)
    }
  })
  return tree
}

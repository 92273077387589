// @flow
import { createSelector } from 'reselect'

const currentSession = state => state.session.currentSession

export const getCurrentSession = createSelector([currentSession], currentSession => currentSession)

export const getCurrentSessionData = createSelector([getCurrentSession], s => s && s.data)

export const currentUserIsManager = createSelector([getCurrentSessionData], s => s && s.manager)

export const currentUserIsAdmin = createSelector([getCurrentSessionData], s => s && s.admin)

export const currentUserIsDIYAdmin = createSelector([getCurrentSessionData], s =>
  Boolean(s && (s.qualtrics_enabled || s.survey_monkey_enabled || s.decipher_enabled))
)

export const getCurrentUserRole = createSelector(
  [getCurrentSessionData],
  s => s && (s.admin ? 'Admin' : s.manager ? 'Manager' : 'User')
)

export const getUserEmail = createSelector([getCurrentSessionData], s => s && s.email)

export const getHomepageUrl = createSelector(
  [getCurrentSessionData],
  session => session.homepage_url
)

/**
 * Determines if ACLs-restricted user data should be hidden from users without
 * access to that data.
 */
export const hasSilentRestrictions = createSelector(getCurrentSessionData, session =>
  Boolean(session.silent_restrictions_enabled)
)

/**
 * Determines if user can access bulk analysis reports.
 */
export const getBulkAnalysisEnabled = createSelector(getCurrentSessionData, session =>
  Boolean(session.bulk_analysis_enabled)
)

/** Determines if user can access Stories. */
export const getStoriesEnabled = createSelector(getCurrentSessionData, session =>
  Boolean(session.stories_enabled)
)

/** Determines if Study Home should be hidden. */
export const getHideStudyLibrary = createSelector(getCurrentSessionData, session =>
  Boolean(session.hide_study_library_enabled)
)

export const getRottweilerUserId = createSelector(
  getCurrentSessionData,
  session => session.rottweiler_user_id
)

import React, { memo } from 'react'
import PropTypes from 'prop-types'

import * as styles from './HighlightedText.module.scss'

const propTypes = {
  match: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
}

const HighlightedText = ({ match, text }) => {
  if (!match) return text

  // sanitize match text before using as regex
  const regex = new RegExp(`${match.replace(/[#-.]|[[-^]|[?|{}]/g, '\\$&')}`, 'gi')

  // preserve capitalization as-authored
  const matches = text.match(regex)
  let matchIdx = 0

  return (
    <React.Fragment>
      {text.split(regex).reduce((prev, curr, i) => {
        if (!i) return [curr]

        return prev.concat(
          <em key={i} className={styles.highlight}>
            {matches[matchIdx++]}
          </em>,
          curr
        )
      }, [])}
    </React.Fragment>
  )
}

HighlightedText.propTypes = propTypes

export default memo(HighlightedText)

// @flow
import { createSelector } from 'reselect'

const currentClientInfo = state => state.clientInfo.currentClientInfo

export const getCurrentClientInfo = createSelector(
  [currentClientInfo],
  currentClientInfo => currentClientInfo
)

const updateClientInfo = state => state.clientInfo.updateClientInfo

export const getUpdateClientInfo = createSelector(
  [updateClientInfo],
  updateClientInfo => updateClientInfo
)

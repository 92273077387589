//@flow
import { uniq, unionWith } from 'lodash'
import { Fetcher, Fetching, makeGetReducer } from 'util/fetcher'
import { parseField, parseString, parseInt, parseArray } from 'util/parseUtils'

// A single entry for a whitelisted email address.
export type EmailWhitelistEntryT = {
  email: string,
  id: number,
  emailSent?: boolean,
}

// Convenient type alias for an array of entries
export type Entries = Array<EmailWhitelistEntryT>

// Validate an object as an email whitelist entry
const parseEmailWhitelistEntry = (obj: Object): EmailWhitelistEntryT => ({
  email: parseField(obj, 'email', parseString),
  id: parseField(obj, 'id', parseInt),
})

// The format of a response to a request to add whitelisted emails.
export type EmailWhitelistPostResponseT = {
  successes: Entries,
  failures: Array<string>,
}

// Parse successes and failures from a
const parseEmailWhitelistResponse = (obj: Object): EmailWhitelistPostResponseT => {
  return {
    successes: parseField(obj, 'successes', parseArray(parseEmailWhitelistEntry)),
    failures: parseField(obj, 'failures', parseArray(parseString)),
  }
}

// Fetches whitelisted emails from rottweiler
export const emailWhitelistFetcher: Fetcher<void, EmailWhitelistPostResponseT> = new Fetcher({
  prefix: 'FETCH_EMAIL_WHITELIST',
  decoder: obj => obj.results.map(parseEmailWhitelistEntry),
  buildUrl: () => '/proxy/rottweiler/whitelists/email/',
})

// Fetching whitelisted emails occurs in the standard way.
const emailWhitelistFetchReducer = makeGetReducer(emailWhitelistFetcher.prefix)

// Adds new whitelisted emails to rottweiler
export const emailWhitelistUpdater: Fetcher<
  Array<string>,
  EmailWhitelistPostResponseT
> = new Fetcher({
  prefix: 'UPDATE_EMAIL_WHITELIST',
  decoder: obj => parseEmailWhitelistResponse(obj),
  buildUrl: () => '/proxy/rottweiler/whitelists/email/',
  buildOptions: (emails: Array<string>) => {
    return Fetcher.defaultOptions({
      method: 'POST',
      body: JSON.stringify({ values: uniq(emails.map(email => email.toLocaleLowerCase())) }),
    })
  },
})

// Create a reducer which updates the full list of whitelist entries
// with any successfully created entries.
const emailWhitelistUpdateReducer = emailWhitelistUpdater.makeReducer({
  onSuccess: (
    state: Fetching<Entries>,
    payload: EmailWhitelistPostResponseT
  ): Fetching<Entries> => {
    const update = (items: Entries): Entries => {
      return unionWith(items, payload.successes, (a, b) => a.id === b.id)
    }
    return state.handle({
      whenFetching: (items: null | Entries) => Fetching.fetched(update(items || [])),
      whenErrored: _ => Fetching.fetched([payload]),
      whenFetched: (items: Entries) => Fetching.fetched(update(items)),
    })
  },
})

// Deletes whitelisted emails from rottweiler
export const emailWhitelistDeleter: Fetcher<number, number> = new Fetcher({
  prefix: 'DELETE_EMAIL_WHITELIST',
  // "Decode" the response by ignoring it and just returning the ID used to construct the request.
  decoder: (_, id: number) => id,
  buildUrl: (id: number) => `/proxy/rottweiler/whitelists/email/${id}`,
  buildOptions: (id: number) =>
    Fetcher.defaultOptions({
      method: 'DELETE',
    }),
})

// Deletes the removed entry from the list of whitelist entries.
const emailWhitelistDeleteReducer = emailWhitelistDeleter.makeReducer({
  onSuccess: (state: Fetching<Entries>, payload: number): Fetching<Entries> => {
    const update = (entries: Entries): Entries => entries.filter(e => e.id !== payload)
    return state.handle({
      whenFetching: (entries: null | Entries) => Fetching.fetched(update(entries || [])),
      whenErrored: _ => Fetching.fetched([payload]),
      whenFetched: (entries: Entries) => Fetching.fetched(update(entries)),
    })
  },
})

// Blargh, this works but flow is obtuse af
// $FlowFixMe
export const emailWhitelistReducer = (state: Fetching<Entries>, action: any) => {
  state = emailWhitelistFetchReducer(state, action)
  state = emailWhitelistUpdateReducer(state, action)
  state = emailWhitelistDeleteReducer(state, action)
  return state
}

import React, { useEffect, useRef, useState, useMemo } from 'react'
import PropTypes from 'prop-types'
import cs from 'classnames'

import { Button, Icon } from '@knowledgehound/laika'

import HighlightedText from 'components/HighlightedText'

import PreferenceTag from '../PreferenceTag'

import * as styles from './UserPreferenceTags.module.scss'

const propTypes = {
  field: PropTypes.string.isRequired,
  values: PropTypes.object.isRequired,
  inUserSettings: PropTypes.bool,
  searchValue: PropTypes.string,
  userHasNotViewedModal: PropTypes.bool,
  fetchAction: PropTypes.func,
}

const fieldLabel = (field, inUserSettings, selectedCount) => {
  if (inUserSettings && selectedCount > 0) {
    return `${field} (${selectedCount})`
  }
  return field
}

function UserPreferenceTags({
  inUserSettings,
  field,
  values,
  searchValue,
  userHasNotViewedModal,
  fetchAction,
}) {
  const flexContainerRef = useRef(null)
  const [hideAdditionalRows, setHideAdditionalRows] = useState(false)

  useEffect(() => {
    const flexContainer = flexContainerRef.current

    function checkRows() {
      if (flexContainer.children.length === 0) return
      const containerHeight = flexContainer.scrollHeight
      const itemHeight = flexContainer.children[0].clientHeight
      const numberOfRows = Math.floor(containerHeight / itemHeight)
      if (numberOfRows > 2) {
        setHideAdditionalRows(true)
      }
    }
    checkRows()
    window.addEventListener('resize', checkRows)

    return () => {
      window.removeEventListener('resize', checkRows)
    }
  }, [])

  const selectedCount = useMemo(
    () =>
      Object.values(values).reduce((total, value) => {
        if (value.selected) total += 1
        return total
      }, 0),
    [values]
  )

  return (
    <div>
      <details className={styles.accordion} open>
        <summary className={styles.content}>
          <HighlightedText
            text={fieldLabel(field, inUserSettings, selectedCount)}
            match={searchValue}
          />
          <div className={styles.headerArrow} aria-hidden="true">
            <Icon icon="arrowUp" size="small" />
          </div>
        </summary>
        <div
          className={cs(styles.preferenceTagListContainer, {
            [styles.hideAdditionalRows]: hideAdditionalRows,
          })}
          ref={flexContainerRef}
        >
          {Object.values(values).map(value => (
            <PreferenceTag
              key={`${field}-${value.label}`}
              value={value}
              field={field}
              searchValue={searchValue}
              userHasNotViewedModal={userHasNotViewedModal}
              fetchAction={fetchAction}
            />
          ))}
        </div>
        {hideAdditionalRows && (
          <Button
            variant="text"
            onClick={() => {
              setHideAdditionalRows(!hideAdditionalRows)
            }}
          >
            See more
          </Button>
        )}
      </details>
    </div>
  )
}

UserPreferenceTags.propTypes = propTypes
export default UserPreferenceTags

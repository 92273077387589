import { uniq, uniqBy, isEqual } from 'lodash'
import { batch } from 'react-redux'
import * as Sentry from '@sentry/react'

import {
  Analysis2Store,
  generateFilterText,
  Analysis2Utils,
  updatedAxisVarsForFilters,
} from '@knowledgehound/analysis'

import { getDatasetListResult, getDatasetQuestions } from 'data/studyDatasets/selectors'
import { gridPlaceholderText } from './constants'
import {
  isReportAutoName,
  getSegmentationVariables,
  getMainVariables,
  getSelectedVariables,
  getVariableLists,
  getLastSelectedIdx,
  getPreviewVariable,
  getAxisVariables,
  getReportName,
  getReportOptions,
  getHandleTrack,
  getShowStatsConfidence,
  getVisibleSegVars,
  getMaxSegCount,
  getOldReportVarSelectionMap,
  getIsPreviewingOldReport,
} from './BulkAnalysisSelectors'

const DALMATIAN_URL = process.env.REACT_APP_DALMATIAN_API2_URL || ''

const generateReportName = segmentationList => {
  const qNameList = segmentationList
    .filter(v => {
      if (!v.isGrid) return true
      return segmentationList.filter(v2 => v2.variableNk === v.variableNk).length > 1
    })
    .map(v => v.label)
  if (!qNameList || !qNameList.length) return `New Report ${new Date(Date.now()).toLocaleString()}`
  return `Report by ${qNameList.join(', ')}`
}

// Bulk Analysis Report List Actions
export const fetchBulkAnalysisListAction = Analysis2Store.generateReduxAction(
  'FETCH_BULK_ANALYSIS_LIST'
)
export const fetchBulkAnalysisList =
  () =>
  async (dispatch, getState, { fetch }) => {
    const dataset = getDatasetListResult(getState())
    if (!dataset || !dataset.study_id) return

    dispatch(fetchBulkAnalysisListAction.loading())

    const response = await fetch(`${DALMATIAN_URL}/reports/${dataset.study_id}/`)

    if (!response.ok) {
      dispatch(fetchBulkAnalysisListAction.error(response.statusText))
      return
    }

    const resultList = await response.json()
    dispatch(fetchBulkAnalysisListAction.success(resultList))
  }

export const CLEAR_REPORT_LIST = 'CLEAR_REPORT_LIST'
export const clearBulkAnalysisReportList = () => ({
  type: CLEAR_REPORT_LIST,
})

export const SET_BULK_ANALYSIS_FILTER = 'SET_BULK_ANALYSIS_FILTER'
export const setBulkAnalysisFilter = filterText => ({
  type: SET_BULK_ANALYSIS_FILTER,
  payload: { filterText },
})

export const EXPORT_REPORT_LOADING = 'EXPORT_REPORT_LOADING'
export const setExportReportLoading = isExporting => ({
  type: EXPORT_REPORT_LOADING,
  payload: { isExporting },
})

export const UPDATE_REPORT = 'UPDATE_REPORT'
const __updateReport = report => ({
  type: UPDATE_REPORT,
  payload: { report },
})

export const getBulkAnalysisStatusById =
  (studyId, reportId) =>
  async (dispatch, getState, { fetch }) => {
    const response = await fetch(`${DALMATIAN_URL}/reports/${studyId}/${reportId}/`)

    if (!response.ok) {
      Sentry.captureException(
        `Failed to fetch bulk analysis report ${reportId} in study ${studyId}`
      )
      return null
    }

    const report = await response.json()
    dispatch(__updateReport(report))
    return report
  }

export const DELETE_REPORT = 'DELETE_REPORT'
const __deleteReport = reportId => ({
  type: DELETE_REPORT,
  payload: { reportId },
})

export const deleteBulkAnalysis =
  (studyId, reportId) =>
  async (dispatch, getState, { fetch }) => {
    const response = await fetch(`${DALMATIAN_URL}/reports/${studyId}/${reportId}/`, {
      method: 'DELETE',
    })

    if (!response.ok) {
      Sentry.captureException(
        `Failed to delete bulk analysis report ${reportId} in study ${studyId}`
      )
      return
    }

    dispatch(__deleteReport(reportId))
  }

// Create Bulk Analysis Report Actions
export const SET_REPORT_BUILDER_ACTIVE = 'SET_REPORT_BUILDER_ACTIVE'
export const setReportBuilderActive = isActive => ({
  type: SET_REPORT_BUILDER_ACTIVE,
  payload: { isActive },
})

export const CLEAR_REPORT_BUILDER = 'CLEAR_REPORT_BUILDER'
export const clearReportBuilder = () => ({
  type: CLEAR_REPORT_BUILDER,
})

export const SET_BA_FILTER_MODAL = 'SET_BA_FILTER_MODAL'
export const setFilterModal = isOpen => ({
  type: SET_BA_FILTER_MODAL,
  payload: isOpen,
})

export const SET_BA_AXIS_VARIABLES = 'SET_BA_AXIS_VARIABLES'
export const setAxisVars = axisVars => ({
  type: SET_BA_AXIS_VARIABLES,
  payload: { axisVars },
})

export const addBAFilterThunk = filterData => (dispatch, getState) => {
  const autoAdjustBaseEnabled = Analysis2Store.selectors.isAutoAdjustBaseEnabled(getState())
  const axisVariables = getAxisVariables(getState())
  const variables = Analysis2Store.selectors.getVariables(getState())

  const { updatedX: updatedAxisVars } = updatedAxisVarsForFilters(
    autoAdjustBaseEnabled,
    axisVariables,
    [],
    filterData,
    variables
  )
  batch(() => {
    dispatch(setAxisVars(updatedAxisVars))
    dispatch(setFilterModal(false))
  })
}

export const SET_BA_NETS_MODAL = 'SET_BA_NETS_MODAL'
export const setNetsModal = isOpen => ({
  type: SET_BA_NETS_MODAL,
  payload: isOpen,
})

export const SET_CREATE_REPORT_NAME = 'SET_CREATE_REPORT_NAME'
export const setCreateReportName = fullName => {
  // report name limited to 100 characters per BE
  const reportName = fullName.slice(0, 100)
  return {
    type: SET_CREATE_REPORT_NAME,
    payload: { reportName },
  }
}

export const SET_AUTO_NAME = 'SET_AUTO_NAME'
export const setAutoName = autoName => ({
  type: SET_AUTO_NAME,
  payload: { autoName },
})

export const SET_BA_VARIABLE_FILTER = 'SET_BA_VARIABLE_FILTER'
export const setBAVariableFilter = filterText => ({
  type: SET_BA_VARIABLE_FILTER,
  payload: { filterText },
})

export const SET_DRAG_COUNT = 'SET_DRAG_COUNT'
export const setDragCount = dragCount => ({
  type: SET_DRAG_COUNT,
  payload: { dragCount },
})

export const SET_DRAG_AXIS = 'SET_DRAG_AXIS'
export const setDragAxis = dragAxis => ({
  type: SET_DRAG_AXIS,
  payload: { dragAxis },
})

export const SET_DRAG_VAR = 'SET_DRAG_VAR'
export const setDragVariable = dragVar => ({
  type: SET_DRAG_VAR,
  payload: { dragVar },
})

export const setDragThunk = (dragAxis, dragCount, dragVar) => dispatch => {
  batch(() => {
    dispatch(setDragCount(dragCount))
    dispatch(setDragAxis(dragAxis))
    dispatch(setDragVariable(dragVar))
  })
}

/** Set which variables are staged to be added to the report layout */
export const SET_SELECTED_VARIABLES = 'SET_BA_SELECTED_VARIABLES'
export const setSelectedVariables = (selectedVariables = []) => ({
  type: SET_SELECTED_VARIABLES,
  payload: { selectedVariables },
})
export const SET_LAST_IDX = 'SET_LAST_IDX'
export const setLastIdx = (lastIdx = null) => ({
  type: SET_LAST_IDX,
  payload: { lastIdx },
})

export const manuallySetNameThunk = reportName => dispatch => {
  batch(() => {
    dispatch(setAutoName(false))
    dispatch(setCreateReportName(reportName))
  })
}

export const toggleVariableThunk =
  (selectedVar = null, shiftKey = false) =>
  (dispatch, getState) => {
    const { suggestedVariables, mainVariables } = getVariableLists(getState())
    const currSelected = getSelectedVariables(getState())
    const buildVariable = v => ({
      questionName: v.questionName,
      label: v.label,
      variableNk: v.variableNk,
      idx: v.idx,
      suggested: v.suggested,
      isGrid: v.isGrid,
      represents: v.represents,
      funQuestionType: v.funQuestionType,
      resourceType: 'options',
    })
    let updatedList = []
    if (!selectedVar) {
      // aka toggle all main vars
      if (currSelected.find(v => !v.suggested)) {
        // remove all main from selected
        updatedList = currSelected.filter(v => v.suggested)
      } else {
        // add all main to selected
        updatedList = [...currSelected, ...mainVariables.map(buildVariable)]
      }
    } else if (!shiftKey) {
      if (selectedVar.isSelected) {
        updatedList = currSelected.filter(v => v.questionName !== selectedVar.questionName)
      } else {
        updatedList = [...currSelected, buildVariable(selectedVar)]
      }
    } else {
      // handle that shift click situation
      const lastIdx = getLastSelectedIdx(getState())
      const limits = [lastIdx, selectedVar.idx]
      limits.sort((a, b) => a - b)
      updatedList = currSelected.filter(v => v.idx < limits[0] || v.idx > limits[1])
      if (!selectedVar.isSelected) {
        for (let i = limits[0]; i <= limits[1]; i++) {
          const currentVar = [...suggestedVariables, ...mainVariables][i]
          updatedList.push(buildVariable(currentVar))
        }
      }
    }
    batch(() => {
      dispatch(setSelectedVariables(updatedList))
      selectedVar && dispatch(setLastIdx(selectedVar.idx))
    })
  }

export const SET_CREATE_BA_LOADING = 'SET_CREATE_BA_LOADING'
export const setCreateBALoading = isFetching => ({
  type: SET_CREATE_BA_LOADING,
  payload: { isFetching },
})

export const SET_BA_VARIABLES = 'SET_BA_VARIABLES'
export const setBulkAnalysisVariables = ({ mainList = [], segmentationList = [] }) => ({
  type: SET_BA_VARIABLES,
  payload: { mainList, segmentationList },
})

export const SET_PREVIEW_VARIABLE = 'SET_PREVIEW_VARIABLE'
export const setPreviewVariable = variableNk => ({
  type: SET_PREVIEW_VARIABLE,
  payload: { variableNk },
})

export const SET_PREVIEW_MODAL = 'SET_PREVIEW_MODAL'
export const setPreviewModal = isModalOpen => ({
  type: SET_PREVIEW_MODAL,
  payload: isModalOpen,
})

export const setPreviewAndUpdateChart = variableNk => async (dispatch, getState) => {
  await dispatch(setPreviewVariable(variableNk))
  dispatch(updateAnalysisChartAxisVars())
}

export const addAxisVars =
  (fetchedVarList, axis = 'ROW') =>
  async (dispatch, getState) => {
    const axisVariables = getAxisVariables(getState())
    const updatedAxisVars = [...axisVariables]
    fetchedVarList.forEach(([responsesVar, breakoutsVar]) => {
      const responsesVarIdx = axisVariables.findIndex(
        axisVar =>
          axisVar.variableNk === responsesVar.variableNk && axisVar.resourceType === 'options'
      )

      if (responsesVarIdx >= 0 && axisVariables[responsesVarIdx].fromOldReport) {
        const variableOptionTable = getOldReportVarSelectionMap(getState())
        const [responsesAxisVar, breakoutsAxisVar] = buildAxisVarsFromOriginalRequest(
          variableOptionTable,
          [breakoutsVar ? [responsesVar, breakoutsVar] : [responsesVar]],
          axis
        )

        updatedAxisVars.splice(responsesVarIdx, 1, responsesAxisVar)

        if (breakoutsVar) {
          const breakoutsVarIdx = axisVariables.findIndex(
            axisVar =>
              axisVar.variableNk === breakoutsVar.variableNk && axisVar.resourceType === 'breakouts'
          )
          updatedAxisVars.splice(breakoutsVarIdx, 1, breakoutsAxisVar)
        }
      } else if (responsesVarIdx === -1) {
        const defaultToMean =
          axis === 'ROW' && responsesVar.fundamentalQuestionType.includes('Numeric')
        const optionsNets = defaultToMean ? [] : responsesVar.nets.map(o => o.id)
        const nets = breakoutsVar
          ? [...optionsNets, ...breakoutsVar.nets.map(o => o.id)]
          : optionsNets
        const options = defaultToMean ? [] : responsesVar.options.map(o => o.id)
        const breakouts = breakoutsVar ? breakoutsVar.options.map(o => o.id) : []
        const axisVars = Analysis2Store.requests.generateAxisVars(
          breakoutsVar ? [responsesVar, breakoutsVar] : [responsesVar],
          axis,
          nets,
          options,
          breakouts,
          defaultToMean ? '__mean__' : []
        )
        updatedAxisVars.push(...axisVars)
      }
    })

    await dispatch(setAxisVars(updatedAxisVars))
  }

/**
 * @param {Array<String>} varsToFetch - List of variable natural keys
 * @param {String} [axis='ROW'] - Axis for the variables to be inserted, i.e. ROW or COLUMN
 * @returns {Array<Object>} - List of variables
 */
export const fetchAndAddAxisVariables =
  (varsToFetch, axis = 'ROW') =>
  async (dispatch, getState) => {
    const fetchedVarList = await Promise.all(
      varsToFetch.map(v => dispatch(Analysis2Store.actions.fetchVariableThunk(v, true)))
    )
    await dispatch(addAxisVars(fetchedVarList, axis))
    return fetchedVarList
  }

export const removeBulkAnalysisVariables = variableList => async (dispatch, getState) => {
  const autoName = isReportAutoName(getState())
  const existingMainVars = getMainVariables(getState())
  const existingSegVars = getSegmentationVariables(getState())
  const previewVariable = getPreviewVariable(getState())
  const chartType = Analysis2Store.selectors.getChartType(getState())

  let mainList = existingMainVars.filter(v => !variableList.includes(v.variableNk))
  let segmentationList = existingSegVars.filter(v => !variableList.includes(v.variableNk))

  const notLastGrid = [...mainList, ...segmentationList].find(
    v => v.isGrid && v.variableNk !== gridPlaceholderText
  )
  if (!notLastGrid) {
    mainList = mainList.filter(v => v.variableNk !== gridPlaceholderText)
    segmentationList = segmentationList.filter(v => v.variableNk !== gridPlaceholderText)
  }
  if (chartType === 'line' && !segmentationList.length) {
    await dispatch(Analysis2Store.actions.setChartTypeAction('column'))
  }

  await dispatch(setBulkAnalysisVariables({ mainList, segmentationList }))
  if (autoName && existingSegVars.length !== segmentationList.length) {
    dispatch(setCreateReportName(generateReportName(segmentationList)))
  }
  if (variableList.includes(previewVariable)) {
    const firstMainVar = mainList.find(v => v.variableNk !== gridPlaceholderText)
    firstMainVar
      ? dispatch(setAndFetchPreviewThunk(firstMainVar.variableNk))
      : dispatch(setAndFetchPreviewThunk(''))
  } else {
    dispatch(updateAnalysisChartAxisVars())
  }
}

export const addVariablesToList = (currentVars, variablesToAdd, position) => {
  if (position === -1) return [...variablesToAdd, ...currentVars]
  currentVars.splice(position, 0, ...variablesToAdd)
  return currentVars
}

export const addGridVariablesToList = (currentVars, gridVars, isFirstGrid = false) => {
  // Make sure to add with the default placeholder
  const breakoutPlaceholderIdx = currentVars.findIndex(
    v => v.variableNk === gridPlaceholderText && v.resourceType === 'breakouts'
  )
  if (breakoutPlaceholderIdx > -1) {
    const updatedVars = [...currentVars]
    updatedVars.splice(breakoutPlaceholderIdx, 0, ...gridVars)
    return updatedVars
  } else {
    return [...currentVars, ...gridVars.map(v => ({ ...v, positionOverride: !isFirstGrid }))]
  }
}

const placeBreakoutVar = (varList, gridVars) => {
  const mergedVarList = [...varList]
  gridVars.forEach(gv => {
    const idx = mergedVarList.findIndex(v => v.variableNk === gv.variableNk)
    mergedVarList.splice(idx, 0, gv)
  })
  return mergedVarList
}

/**
 * Stage selected variables to be added to the report layout. Also handles the
 * grid question placeholder logic.
 *
 * @param {Array<Object>} variableList - List of available dataset questions
 * @param {boolean} segmentation - Are target variables being added to Segmentation or Main/Report variables?
 * @param {String} nk - Natural key of the variable to be added (i.e. FRE1234/Dataset/QID123)
 * @param {String} resourceType - Type of question being added (i.e. responses, breakouts, etc.)
 */
export const addBAVariablesThunk =
  (variableList, segmentation, nk = null, resourceType = null) =>
  async (dispatch, getState) => {
    const autoName = isReportAutoName(getState())
    const maxSegCount = getMaxSegCount(getState())
    const existingMainVars = getMainVariables(getState())
    const existingSegVars = getSegmentationVariables(getState())
    const { visibleSegVars } = getVisibleSegVars(getState())
    const previewVariable = getPreviewVariable(getState())
    let position = 0
    if (segmentation && nk) {
      position = getDropPosition(existingSegVars, nk, resourceType)
    } else if (nk) {
      position = getDropPosition(existingMainVars, nk, resourceType)
    }

    const isFirstGrid = !existingMainVars.some(v => v.isGrid)
    const blockGridToSeg =
      visibleSegVars.filter(
        v => v.label !== gridPlaceholderText && v.variableNk !== previewVariable
      ).length >= maxSegCount

    const gridVars =
      variableList
        .filter(v => v.isGrid)
        .map(v => ({
          ...v,
          resourceType: 'breakouts',
          funQuestionType: 'grid_breakout',
          positionOverride: segmentation,
        })) || []

    let updatedMainList
    let updatedSegList
    if (blockGridToSeg) {
      updatedMainList = addVariablesToList(
        [...existingMainVars],
        placeBreakoutVar(variableList, gridVars),
        position
      )
      updatedSegList = [...existingSegVars]
    } else {
      updatedMainList = segmentation
        ? addGridVariablesToList(existingMainVars, gridVars, isFirstGrid)
        : addVariablesToList([...existingMainVars], variableList, position)
      updatedSegList = segmentation
        ? addVariablesToList(
            [...existingSegVars],
            variableList.map(v => ({ ...v, positionOverride: !isFirstGrid })),
            position
          )
        : addGridVariablesToList(existingSegVars, gridVars, isFirstGrid)
    }

    // If we are adding a grid variable here and it is the first to be added
    // to the report, we need to also add the default placeholder varCards
    if (gridVars.length && isFirstGrid) {
      const buildMockVariable = type => ({
        questionName: gridPlaceholderText,
        label: `Default Grid ${type === 'breakouts' ? 'Options' : 'Responses'} Position`,
        variableNk: gridPlaceholderText,
        isGrid: true,
        resourceType: type,
      })
      if (blockGridToSeg) {
        updatedMainList.unshift(buildMockVariable('options'))
        updatedMainList.unshift(buildMockVariable('breakouts'))
      } else {
        if (segmentation) {
          updatedMainList.unshift(buildMockVariable('breakouts'))
          updatedSegList.push(buildMockVariable('options'))
        } else {
          updatedMainList.unshift(buildMockVariable('options'))
          updatedSegList.push(buildMockVariable('breakouts'))
        }
      }
    }

    await dispatch(
      setBulkAnalysisVariables({ mainList: updatedMainList, segmentationList: updatedSegList })
    )
    if (segmentation) {
      const varsToFetch = variableList
        .filter(v => !v.isGrid && v.variableNk !== gridPlaceholderText)
        .map(v => v.variableNk)
      await dispatch(fetchAndAddAxisVariables(varsToFetch, 'COLUMN'))
      if (autoName) {
        dispatch(setCreateReportName(generateReportName(updatedSegList)))
      }
    }
    if (!previewVariable && updatedMainList.length) {
      const firstMainVar = updatedMainList.find(v => v.variableNk !== gridPlaceholderText)
      if (firstMainVar) {
        await dispatch(setAndFetchPreviewThunk(firstMainVar.variableNk))
      }
    } else {
      dispatch(updateAnalysisChartAxisVars())
    }
  }

export const setAndFetchPreviewThunk =
  (variableNk, axis = 'ROW') =>
  async (dispatch, getState) => {
    if (variableNk === gridPlaceholderText) return
    variableNk && (await dispatch(fetchAndAddAxisVariables([variableNk], axis)))
    dispatch(setPreviewAndUpdateChart(variableNk))
  }

const getDropPosition = (varList, dropNk, dropType) => {
  if (!dropNk) return 0
  const foundIdx = varList.findIndex(v => v.variableNk === dropNk && v.resourceType === dropType)
  if (dropNk !== gridPlaceholderText) return foundIdx + 1
  // if you are moving a variable after the grid placeholder, make sure to
  // keep the placeholder with the grids
  for (let i = foundIdx; i < varList.length; i++) {
    if (!varList[i].isGrid || varList[i].positionOverride) {
      return i
    }
  }
}

const moveVariableWithinAxis = (variableList, dragVar, dropNk, dropType, main = false) => {
  let updatedVarList = []
  if (dragVar.variableNk === gridPlaceholderText) {
    // if it is a placeholder we need to move all the grids that
    // have not been manually overriden
    const gridsToMove = []
    variableList.forEach(v => {
      if (v.isGrid && !v.positionOverride && v.resourceType === dragVar.resourceType) {
        gridsToMove.push(v)
      } else updatedVarList.push(v)
    })
    updatedVarList.splice(getDropPosition(updatedVarList, dropNk, dropType), 0, ...gridsToMove)
  } else {
    const varsToMove = []
    variableList.forEach(v => {
      // if it is the main list, move grids so they stay together within the list
      if (
        (main || v.resourceType === dragVar.resourceType) &&
        v.variableNk === dragVar.variableNk
      ) {
        varsToMove.push({ ...v, positionOverride: true })
      } else updatedVarList.push(v)
    })
    updatedVarList.splice(getDropPosition(updatedVarList, dropNk, dropType), 0, ...varsToMove)
  }
  return updatedVarList
}

const moveToNewAxis = (fromList, toList, dragVariable, dropNk, dropResourceType, dropMain) => {
  let updatedToList = [...toList]
  let updatedFromList = []
  const dropIdx = getDropPosition(toList, dropNk, dropResourceType)
  if (dragVariable.variableNk === gridPlaceholderText) {
    let swapIdx = 0
    const gridsToMove = []
    fromList.forEach(v => {
      if (v.isGrid && v.resourceType === dragVariable.resourceType && !v.positionOverride) {
        gridsToMove.push(v)
        if (v.variableNk === gridPlaceholderText) swapIdx = updatedFromList.length
      } else updatedFromList.push(v)
    })
    updatedToList.splice(dropIdx, 0, ...gridsToMove)
    if (
      !dropMain &&
      updatedToList.find(
        v => v.variableNk === gridPlaceholderText && v.resourceType !== dragVariable.resourceType
      )
    ) {
      // If you are moving a placeholder to the segmentation section and the other grid
      // placeholder is there, swap them and all the grids that have moved with it
      // We don't want all the grids crosstabbing every question
      const partnersToMove = []
      const filteredToList = []
      const movedNks = gridsToMove.map(v => v.variableNk)
      toList.forEach(v => {
        if (movedNks.includes(v.variableNk)) {
          partnersToMove.push(v)
        } else filteredToList.push(v)
      })
      updatedToList = filteredToList
      updatedFromList.splice(swapIdx, 0, ...partnersToMove)
    }
  } else {
    updatedFromList = fromList.filter(
      v => v.resourceType !== dragVariable.resourceType || v.variableNk !== dragVariable.variableNk
    )
    const varsToMove = [{ ...dragVariable, positionOverride: true }]
    // if moving a grid to main list and the partner variable is already there,
    // we want to keep them together
    if (dropMain && dragVariable.isGrid) {
      const partnerGridIdx = toList.findIndex(
        toVar =>
          toVar.variableNk === dragVariable.variableNk &&
          toVar.resourceType !== dragVariable.resourceType
      )
      if (partnerGridIdx > -1) {
        varsToMove.push({ ...toList[partnerGridIdx] })
        updatedToList.splice(partnerGridIdx, 1)
      }
    }
    updatedToList.splice(dropIdx, 0, ...varsToMove)
  }
  return { updatedToList, updatedFromList }
}

export const moveBAVariablesThunk =
  (dragVariable, dragAxis, dropNk, dropResourceType, dropAxis) => async (dispatch, getState) => {
    const autoName = isReportAutoName(getState())
    const existingMainVars = getMainVariables(getState())
    const existingSegVars = getSegmentationVariables(getState())
    const previewVariable = getPreviewVariable(getState())
    const axisVariables = getAxisVariables(getState())
    let updatedSegList = [...existingSegVars]
    let updatedMainList = [...existingMainVars]

    if (dragAxis === 'COLUMN' && dropAxis === 'COLUMN') {
      // column reorder
      updatedSegList = moveVariableWithinAxis(
        [...existingSegVars],
        dragVariable,
        dropNk,
        dropResourceType
      )
      updatedMainList = [...existingMainVars]
    } else if (dragAxis === 'ROW' && dropAxis === 'ROW') {
      // row reorder
      updatedMainList = moveVariableWithinAxis(
        [...existingMainVars],
        dragVariable,
        dropNk,
        dropResourceType,
        true
      )
      updatedSegList = [...existingSegVars]
    } else if (dragAxis === 'COLUMN' && dropAxis === 'ROW') {
      // move from col to row
      const { updatedFromList, updatedToList } = moveToNewAxis(
        [...existingSegVars],
        [...existingMainVars],
        dragVariable,
        dropNk,
        dropResourceType,
        true
      )
      updatedSegList = updatedFromList
      updatedMainList = updatedToList
      // set new main var as preview var
      if (dragVariable.variableNk !== gridPlaceholderText)
        await dispatch(setAndFetchPreviewThunk(dragVariable.variableNk, 'ROW'))
    } else if (dragAxis === 'ROW' && dropAxis === 'COLUMN') {
      // move from row to col
      const { updatedFromList, updatedToList } = moveToNewAxis(
        [...existingMainVars],
        [...existingSegVars],
        dragVariable,
        dropNk,
        dropResourceType
      )
      updatedSegList = updatedToList
      updatedMainList = updatedFromList
      if (
        !axisVariables.find(av => av.variableNk === dragVariable.variableNk) &&
        dragVariable.variableNk !== gridPlaceholderText
      ) {
        await dispatch(fetchAndAddAxisVariables([dragVariable.variableNk], 'COLUMN'))
      }
    }

    if (autoName) {
      // update the report name if it hasnt been manually set
      dispatch(setCreateReportName(generateReportName(updatedSegList)))
    }
    await dispatch(
      setBulkAnalysisVariables({ mainList: updatedMainList, segmentationList: updatedSegList })
    )
    // unset as preview variable and set new one (unless it's partner grid var is still in main list)
    if (
      previewVariable === dragVariable.variableNk &&
      !updatedMainList.find(v => v.variableNk === previewVariable)
    ) {
      const firstMainVar = updatedMainList.find(v => v.variableNk !== gridPlaceholderText)
      await dispatch(setAndFetchPreviewThunk(firstMainVar ? firstMainVar.variableNk : ''))
    } else {
      dispatch(updateAnalysisChartAxisVars())
    }
  }

export const updateAnalysisChartAxisVars = () => async (dispatch, getState) => {
  const axisVars = getAxisVariables(getState())
  const mainVars = getMainVariables(getState())
  const isInverted = Analysis2Store.selectors.getIsInverted(getState())
  const xAxisVariables = Analysis2Store.selectors.getXAxisVariables(getState())
  const yAxisVariables = Analysis2Store.selectors.getYAxisVariables(getState())
  const { visibleSegVars } = getVisibleSegVars(getState())
  const segVars = visibleSegVars.filter(v => v.variableNk !== gridPlaceholderText)
  const previewVariable = getPreviewVariable(getState())

  const visibleMainVars = mainVars.filter(v => v.variableNk === previewVariable)
  const visibleMainAxis = visibleMainVars.map(mv =>
    axisVars.find(av => av.variableNk === mv.variableNk && av.resourceType === mv.resourceType)
  )
  const segAxis = segVars.map(sv =>
    axisVars.find(av => av.variableNk === sv.variableNk && av.resourceType === sv.resourceType)
  )
  const mainAxis = isInverted ? segAxis : visibleMainAxis
  const xtabAxis = isInverted ? visibleMainAxis : segAxis

  if (isEqual(mainAxis, xAxisVariables) && isEqual(xtabAxis, yAxisVariables)) return
  await dispatch(Analysis2Store.actions.setAxisVariables(mainAxis, xtabAxis))
  dispatch(fetchBAAnalysisData())
}

export const toggleBAOptionThunk =
  (id, axis, optionId, optionType) => async (dispatch, getState) => {
    const axisVars = getAxisVariables(getState())
    const inverted = Analysis2Store.selectors.getIsInverted(getState())
    const analysisAxis = inverted ? (axis === 'ROW' ? 'COLUMN' : 'ROW') : axis
    await dispatch(Analysis2Store.actions.toggleOptionThunk(id, analysisAxis, optionId, optionType))
    const { updatedVars, isSelected } = Analysis2Store.actions.toggleSelectionOption(
      id,
      axisVars,
      optionId,
      optionType
    )
    dispatch(setAxisVars(updatedVars))
    return isSelected
  }

export const toggleAllBAOptionThunk = (id, axis) => async (dispatch, getState) => {
  const axisVars = getAxisVariables(getState())
  const inverted = Analysis2Store.selectors.getIsInverted(getState())
  const analysisAxis = inverted ? (axis === 'ROW' ? 'COLUMN' : 'ROW') : axis
  await dispatch(Analysis2Store.actions.toggleAllOptionsThunk(id, analysisAxis))
  const { updatedVars, someSelected } = Analysis2Store.actions.toggleAllOptions(id, axisVars)
  dispatch(setAxisVars(updatedVars))
  return someSelected
}

export const openFilterModalThunk =
  (variable = null) =>
  async (dispatch, getState) => {
    dispatch(setFilterModal(true))
    if (variable) {
      const variables = Analysis2Store.selectors.getVariables(getState())
      const filters = Analysis2Store.selectors.getFilters(getState())
      const nk = variable.variableNk
      const resourceType =
        variable.isGrid && variable.fundamentalQuestionType.includes('Numeric')
          ? 'breakouts'
          : variable.resourceType

      const found = Analysis2Utils.getVariable(variables, nk, resourceType)
      let optionVar = null
      let breakoutVar = null

      if (!found) {
        const fetchedVars = await dispatch(Analysis2Store.actions.fetchVariableThunk(nk))
        optionVar = fetchedVars[0]
        breakoutVar = fetchedVars.length > 1 ? fetchedVars[1] : null
      } else {
        optionVar =
          resourceType === 'options' ? found : Analysis2Utils.getVariable(variables, nk, 'options')
        breakoutVar =
          resourceType === 'breakouts'
            ? found
            : Analysis2Utils.getVariable(variables, nk, 'breakouts')
      }
      const defaultFilterVariable = resourceType === 'options' ? optionVar : breakoutVar
      const defaultFilterGrid = optionVar && breakoutVar ? [optionVar, breakoutVar] : null
      dispatch(
        Analysis2Store.filterNet.setSelectedVariableThunk(
          defaultFilterVariable,
          filters,
          defaultFilterGrid
        )
      )
    }
  }

export const openCreateNetThunk = (nk, resourceType) => async (dispatch, getState) => {
  const variables = Analysis2Store.selectors.getVariables(getState())
  let variable = Analysis2Utils.getVariable(variables, nk, resourceType)

  if (!variable) {
    const fetchedVars = await dispatch(Analysis2Store.actions.fetchVariableThunk(nk))
    variable =
      resourceType === 'options' || fetchedVars.length < 2 ? fetchedVars[0] : fetchedVars[1]
  }
  dispatch(setAndFetchPreviewThunk(nk))
  if (variable.isGrid) {
    const selectedGrid = variables.filter(v => v.questionName === variable.questionName)
    await dispatch(
      Analysis2Store.filterNet.setSelectedVariableThunk(variable, [], selectedGrid, {
        id: '__total__',
        type: 'calculated',
        label: 'Total',
      })
    )
  } else {
    await dispatch(Analysis2Store.filterNet.setSelectedVariableThunk(variable, []))
  }
  dispatch(setNetsModal(true))
}

export const addOptToAxisVariable =
  (newOpt, variableNk, resourceType) => async (dispatch, getState) => {
    const axisVars = getAxisVariables(getState())
    const updatedAxisVars = axisVars.map(av => {
      if (av.variableNk !== variableNk || av.resourceType !== resourceType) return av
      const currentCalc = []
      const currentNets = []
      const currentOpts = []
      const allOpts = [...av.selectedOptions, newOpt]
      allOpts.forEach(o => {
        if (o.type === 'calculated') {
          currentCalc.push(o)
        } else if (o.type === 'net') {
          currentNets.push(o)
        } else {
          currentOpts.push(o)
        }
      })
      return {
        ...av,
        selectedOptions: [...currentCalc, ...currentNets, ...currentOpts],
      }
    })
    await dispatch(setAxisVars(updatedAxisVars))
    if (newOpt.selected) dispatch(fetchBAAnalysisData())
  }

export const removeOptionFromAxisVariable =
  (id, type, variableNk, resourceType) => (dispatch, getState) => {
    const axisVars = getAxisVariables(getState())
    const updatedAxisVars = axisVars.map(av => {
      if (av.variableNk !== variableNk || av.resourceType !== resourceType) return av
      return {
        ...av,
        selectedOptions: av.selectedOptions.filter(op => op.id !== id || op.type !== type),
      }
    })
    dispatch(setAxisVars(updatedAxisVars))
  }

const lookupTableToList = table =>
  Object.entries(table).reduce((acc, [key, value]) => {
    if (value) {
      return acc.concat(key)
    }
    return acc
  }, [])

// ** OPTIONS / BREAKOUTS **
// for options and breakouts include all DESELECTED options
// example: deselected_options: [1, 2, 3] OR deselected_breakouts: [4, 5, 6]
// if this field is NOT present it means NO options selected
// &&
// ** NETS / CALCULATED **
// for nets and calc an empty list means NO NETS/CALCS selected. Not including
// the nets field means ALL NETS selected.
// A list with some values represents the SELECTED VALUES
export const buildVariableList = (axisVars, listVars, variableOptionTable, baseVar = false) => {
  if (!listVars.length) return []
  return listVars.reduce((acc, lv) => {
    if (lv.variableNk === gridPlaceholderText) return acc
    const deselectedField = `deselected_${lv.resourceType}`
    const axisVar = axisVars.find(
      av => av.variableNk === lv.variableNk && av.resourceType === lv.resourceType
    )
    let deselectedList = []
    let netList = []
    let calcList = []
    let optionsSelected = false
    let netsPresent = false

    if (axisVar && axisVar.fromOldReport) {
      netList = lookupTableToList(variableOptionTable[axisVar.questionName].nets)
      calcList = lookupTableToList(variableOptionTable[axisVar.questionName].calculated)
      deselectedList =
        axisVar.resourceType === 'breakouts'
          ? variableOptionTable[axisVar.questionName].deselectedBreakouts
          : variableOptionTable[axisVar.questionName].deselectedOptions
      netsPresent = Boolean(netList.length)
      optionsSelected = deselectedList !== 'ALL_DESELECTED'
      deselectedList = optionsSelected ? lookupTableToList(deselectedList) : []
    } else if (axisVar) {
      axisVar.selectedOptions.forEach(so => {
        if (so.type === 'net') {
          netsPresent = true
          if (so.selected) netList.push(so.id)
        } else if (so.type === 'calculated') {
          if (so.selected) calcList.push(so.id)
        } else if (!so.selected) {
          deselectedList.push(so.id)
        } else {
          optionsSelected = true
        }
      })
    }

    // if there are no nets or calcs selected, and all options are deselected,
    // default to mean for numeric base vars or to having all options selected for others
    let includeDeselectedList =
      !optionsSelected && (calcList.length || netList.length) ? false : true
    if (!optionsSelected && !calcList.length && (!netsPresent || !netList.length)) {
      if (baseVar && lv.funQuestionType.includes('Numeric')) {
        calcList = ['__mean__']
        includeDeselectedList = false
      } else {
        deselectedList = []
      }
    }
    const serializedVar = includeDeselectedList
      ? { [deselectedField]: deselectedList, calculated: calcList }
      : { calculated: calcList }
    if (netList.length || netsPresent) serializedVar.nets = netList
    if (lv.resourceType === 'breakouts') serializedVar.type = lv.resourceType
    return [...acc, { [lv.questionName]: serializedVar }]
  }, [])
}

export const SET_BA_STAT_TESTING_CONFIDENCE = 'SET_BA_STAT_TESTING_CONFIDENCE'
function setStatTestingConfidence(showStatTesting, confidence) {
  return {
    type: SET_BA_STAT_TESTING_CONFIDENCE,
    payload: {
      showStatTesting,
      confidence,
    },
  }
}

export const SET_CHART_LOADING = 'SET_CHART_LOADING'
export const setChartLoading = isLoading => ({
  type: SET_CHART_LOADING,
  payload: isLoading,
})

export function fetchBAAnalysisData(keepBase) {
  return async (dispatch, getState) => {
    dispatch(setChartLoading(true))
    const dataset = getDatasetListResult(getState())
    const xAxisVariables = Analysis2Store.selectors.getXAxisVariables(getState())
    const yAxisVariables = Analysis2Store.selectors.getYAxisVariables(getState())
    const allAxisVars = [...xAxisVariables, ...yAxisVariables]
    const hasEmptyVar = allAxisVars.some(axisVar => Analysis2Utils.hasNoSelectedOpts(axisVar))
    if (dataset && xAxisVariables.length && !hasEmptyVar) {
      await dispatch(Analysis2Store.actions.clearAnalysisData(keepBase))
      try {
        await dispatch(Analysis2Store.actions.fetchAnalysisData(true))
      } catch (error) {
        Sentry.captureException(
          new Error('Bulk Analysis: Error when fetching analysis Data', { cause: error })
        )
      }
    }
    dispatch(setChartLoading(false))
  }
}

export function setBAChartType(chartType) {
  return async (dispatch, getState) => {
    dispatch(Analysis2Store.actions.updateChartTypeThunk(chartType))
  }
}

export function setStatsThunk(confidence) {
  return async (dispatch, getState) => {
    const prevConfidence = getShowStatsConfidence(getState())
    if (!confidence) {
      dispatch(setStatTestingConfidence(false, prevConfidence))
    } else {
      await dispatch(setStatTestingConfidence(true, [confidence]))
    }
  }
}

export function toggleAutoAdjustBase(autoAdjustBaseEnabled) {
  return async (dispatch, getState) => {
    await dispatch(Analysis2Store.actions.toggleAutoAdjustBaseThunk(autoAdjustBaseEnabled))
    dispatch(fetchBAAnalysisData(true))
  }
}

const postReport =
  (studyId, body) =>
  async (dispatch, getState, { fetch }) => {
    const response = await fetch(`${DALMATIAN_URL}/reports/${studyId}/`, {
      method: 'POST',
      jsonBody: body,
    })

    if (!response.ok) {
      const error = await response.json()
      dispatch(generateBAReportAction.error(error))
      return null
    }

    return response.json()
  }

export const generateBAReportAction = Analysis2Store.generateReduxAction('GENERATE_BA_REPORT')
export const generateBAReport =
  () =>
  async (dispatch, getState, { fetch }) => {
    dispatch(generateBAReportAction.loading())
    const dataset = getDatasetListResult(getState())
    const title = getReportName(getState())
    const mainVars = getMainVariables(getState())
    const segVars = getSegmentationVariables(getState())
    const axisVars = getAxisVariables(getState())
    const variables = Analysis2Store.selectors.getVariables(getState())
    const filters = Analysis2Store.selectors.getFilters(getState())
    const autoAdjustBaseEnabled = Analysis2Store.selectors.isAutoAdjustBaseEnabled(getState())
    const weighting = Analysis2Store.selectors.getValidWeightShape(getState())
    const weightingLabel = Analysis2Store.selectors.getWeightingLabel(getState())
    const viewSettings = Analysis2Store.selectors.getViewSettings(getState())
    const inverted = Analysis2Store.selectors.getIsInverted(getState())
    const options = getReportOptions(getState())
    const handleTrack = getHandleTrack(getState())

    try {
      const crossTabs = new Set()
      segVars.forEach(v => {
        if (!v.isGrid) {
          crossTabs.add(v.label)
        } else if (v.variableNk !== gridPlaceholderText) {
          const foundPair = segVars.find(
            sv => sv.variableNk === v.variableNk && sv.resourceType !== v.resourceType
          )
          if (foundPair) crossTabs.add(v.label)
        }
      })
      const crossTabText = crossTabs.size ? `Segmented by ${[...crossTabs].join(', ')}.  ` : ''
      const filterText = filters.length
        ? `Filters: ${filters
            .map(filter => {
              const { methodText, responseTextList, questionTextFull } = generateFilterText(
                variables,
                filter
              )
              return [questionTextFull, methodText.toLowerCase(), responseTextList].join(' ')
            })
            .join(' AND ')}`
        : ''

      const variableOptionTable = getOldReportVarSelectionMap(getState())
      const body = {
        dataset_name: dataset.dataset_name,
        title: title || '',
        description:
          `Generated on ${new Date(Date.now()).toLocaleString()} ` + crossTabText + filterText,
        main: buildVariableList(axisVars, mainVars, variableOptionTable, true),
        segmentation: buildVariableList(axisVars, segVars, variableOptionTable),
        options: {
          ...options,
          filters,
          autoAdjustBaseEnabled,
          weighting,
          weightingLabel: !weighting ? undefined : weightingLabel,
          viewSettings: {
            ...options.viewSettings,
            numberType: viewSettings.numberType,
            chartType: viewSettings.chartType || 'column',
            showLabels: viewSettings.showLabels,
            hideLowBase: viewSettings.hideLowBase,
            sortState: viewSettings.sortState,
            suppressNullValues: viewSettings.suppressNullValues,
            showUnweightedBase: viewSettings.showUnweightedBase,
            sortable: false,
          },
          inverted,
        },
      }
      handleTrack('Bulk analysis report generated', body)

      const created = await dispatch(postReport(dataset.study_id, body))
      if (created === null) {
        return { created: false }
      }

      const totalCount = new Set(body.main.map(varObj => Object.keys(varObj)[0])).size
      dispatch(generateBAReportAction.success({ ...created, total: totalCount }))
      return { created: true }
    } catch (error) {
      Sentry.captureException(new Error('Error generating rapid report', { cause: error }))
      dispatch(generateBAReportAction.error(error.message))
      return { created: false }
    }
  }

export const openReportBuilderThunk = () => async (dispatch, getState) => {
  const dataset = Analysis2Store.selectors.getDataset(getState())
  const { suggestedVariables, mainVariables } = getVariableLists(getState())
  const maxSegCount = getMaxSegCount(getState())

  await dispatch(clearReportBuilder())
  await dispatch(Analysis2Store.actions.resetAnalysisChart())
  await dispatch(Analysis2Store.actions.fetchWeights())

  dispatch(Analysis2Store.actions.setUnweightedBaseFromPerm())

  if (dataset.defaultFilters && dataset.defaultFilters.length) {
    await dispatch(
      Analysis2Store.actions.setDefaultFilters(
        dataset.defaultFilters,
        dataset.studyId,
        dataset.datasetNk
      )
    )
  }

  let defaultChartType = 'column'
  if (dataset.defaultXtab && dataset.defaultXtab.length) {
    const segVars = dataset.defaultXtab.slice(0, maxSegCount).reduce((allVars, xt) => {
      const found = [...suggestedVariables, ...mainVariables].find(v => v.questionName === xt)
      if (found) return [...allVars, found]
      return allVars
    }, [])
    if (segVars.length) await dispatch(addBAVariablesThunk(segVars, true))
    if (segVars.length === 1 && segVars[0].represents === 'interval') {
      defaultChartType = 'line'
    }
  }
  await dispatch(Analysis2Store.actions.setChartTypeAction(defaultChartType))
  dispatch(setReportBuilderActive(true))

  const handleTrack = getHandleTrack(getState())
  const isPreviewingOldReport = getIsPreviewingOldReport(getState())
  handleTrack('Open Report Builder Clicked', { isPreviewingOldReport })

  if (window.hj) {
    window.hj('event', 'rapid_reports_builder_opened')
  }
}

export const closeReportBuilderThunk = () => async dispatch => {
  await dispatch(Analysis2Store.actions.resetAnalysisChart())
  await dispatch(clearReportBuilder())
  dispatch(setReportBuilderActive(false))
}

export const BA_SET_EXISTING_REPORT_FETCHING = 'BA_SET_EXISTING_REPORT_FETCHING'
const __setExistingReportFetching = (reportId, isFetching = true) => ({
  type: BA_SET_EXISTING_REPORT_FETCHING,
  reportId,
  isFetching,
})

export const BA_SET_EXISTING_REPORT_ERROR = 'BA_SET_EXISTING_REPORT_ERROR'
const __setExistingReportError = reportId => ({
  type: BA_SET_EXISTING_REPORT_ERROR,
  reportId,
})

export const BA_SET_EXISTING_REPORT_SUCCESS = 'BA_SET_EXISTING_REPORT_SUCCESS'
const __setExistingReportSuccess = (reportId, reportVars) => ({
  type: BA_SET_EXISTING_REPORT_SUCCESS,
  reportId,
  reportVars,
})

export const BA_START_REPORT_FROM_OLD_REPORT = 'BA_START_REPORT_FROM_OLD_REPORT'
const __startReportFromOldReport = oldReportName => ({
  type: BA_START_REPORT_FROM_OLD_REPORT,
  oldReportName,
})

export const BA_REHYDRATED_REPORT_MISSING_DATA = 'BA_REHYDRATED_REPORT_MISSING_DATA'
const __baRehydratedReportMissingData = () => ({
  type: BA_REHYDRATED_REPORT_MISSING_DATA,
})

export const startReportFromOldReport = () => (dispatch, getState) => {
  const reportName = getReportName(getState())
  const handleTrack = getHandleTrack(getState())
  handleTrack('Start Copy of Rapid Report', {
    oldReportName: reportName,
  })

  dispatch(__startReportFromOldReport(reportName))
  dispatch(manuallySetNameThunk(`Copy of ${reportName}`))
}

/**
 * From dehydrated old report state, create axis variables.
 *
 * The original request to generate a Rapid Report has only the selected
 * calculated and net options, and which response options are deselected.
 * The axis variable state tracks which options are selected, and we need
 * the complete option list to feed the variable card React components.
 *
 * @param {Object} variableOptionTable - Variable option selection lookup table
 * @param {Array<Object>} collieVars - Complete variable state from Collie
 * @param {String} axis - COLUMN (segmentation) or ROW (main)
 * @returns {Array<Object>} - Axis variables
 */
const buildAxisVarsFromOriginalRequest = (variableOptionTable, collieVars, axis) => {
  return collieVars.reduce((acc, [responsesVar, breakoutsVar]) => {
    const varOpts = variableOptionTable[responsesVar.questionName]

    if (varOpts.deselectedOptions !== undefined) {
      acc.push({
        id: responsesVar.id,
        resourceType: 'options',
        variableNk: responsesVar.variableNk,
        selectedOptions: [
          ...responsesVar.calculated.map(c => ({
            id: c.id,
            type: c.type,
            selected: varOpts.calculated[c.id] ?? false,
          })),
          ...responsesVar.nets.map(c => ({
            id: c.id,
            type: c.type,
            selected: varOpts.nets[c.id] ?? false,
          })),
          ...responsesVar.options.map(c => ({
            id: c.id,
            type: c.type,
            selected:
              varOpts.deselectedOptions === 'ALL_DESELECTED'
                ? false
                : !varOpts.deselectedOptions[c.id],
          })),
        ],
        axis,
      })
    }

    if (breakoutsVar && varOpts?.deselectedBreakouts !== undefined) {
      acc.push({
        id: breakoutsVar.id,
        resourceType: 'breakouts',
        variableNk: breakoutsVar.variableNk,
        selectedOptions: [
          ...breakoutsVar.calculated.map(c => ({
            id: c.id,
            type: c.type,
            selected: varOpts.calculatedBreakouts[c.id] ?? false,
          })),
          ...breakoutsVar.nets.map(c => ({
            id: c.id,
            type: c.type,
            selected: varOpts.nets[c.id] ?? false,
          })),
          ...breakoutsVar.options.map(c => ({
            id: c.id,
            type: c.type,
            selected:
              varOpts.deselectedBreakouts[c.id] === 'ALL_DESELECTED'
                ? false
                : !varOpts.deselectedBreakouts[c.id],
          })),
        ],
        axis,
      })
    }

    return acc
  }, [])
}

/**
 * Start a new report from an existing report.
 *
 * @param {String} studyId - Study number/ID
 * @param {String} reportId - UUID of the report
 */
export const rehydrateExistingReport =
  reportId =>
  async (dispatch, getState, { fetch }) => {
    const { study_id: studyId } = getDatasetListResult(getState())
    dispatch(__setExistingReportFetching(reportId))

    const response = await fetch(
      `${DALMATIAN_URL}/reports/${studyId}/${reportId}/?serialized_request=true`
    )

    if (!response.ok) {
      dispatch(__setExistingReportError(reportId))
      return
    }

    const { serialized_request: oldReport } = await response.json()
    dispatch(
      __setExistingReportSuccess(reportId, {
        main: oldReport.main,
        segmentation: oldReport.segmentation,
      })
    )

    await dispatch(Analysis2Store.actions.fetchWeights())

    const questionMap = getDatasetQuestions(getState()).reduce((acc, q, idx) => {
      acc[q.question_name] = {
        questionName: q.question_name,
        label: q.question_text,
        variableNk: q.nk,
        idx,
        suggested: q.suggested_xtab,
        isGrid: q.is_grid,
        represents: q.represents,
        funQuestionType: q.fundamental_question_type,
      }
      return acc
    }, {})

    const mainList = oldReport.main.reduce((mainArr, v) => {
      const id = Object.keys(v)[0]
      if (!questionMap[id]) {
        dispatch(__baRehydratedReportMissingData())
      } else {
        let mainVar = {
          ...questionMap[id],
          resourceType: v[id].type ?? 'options',
          funQuestionType:
            v[id].type === 'breakouts' ? 'grid_breakout' : questionMap[id].funQuestionType,
          positionOverride: v[id].type === 'breakouts',
        }
        mainArr.push(mainVar)
      }
      return mainArr
    }, [])

    const segmentationList = oldReport.segmentation.reduce((segArr, v) => {
      const id = Object.keys(v)[0]
      if (!questionMap[id]) {
        dispatch(__baRehydratedReportMissingData())
      } else {
        let segVar = {
          ...questionMap[id],
          resourceType: v[id].type ?? 'options',
          funQuestionType:
            v[id].type === 'breakouts' ? 'grid_breakout' : questionMap[id].funQuestionType,
          positionOverride: v[id].type === 'breakouts',
        }
        segArr.push(segVar)
      }
      return segArr
    }, [])

    dispatch(setBulkAnalysisVariables({ mainList, segmentationList }))

    const previewVar = mainList[0]
    let segVarsToFetch = []

    if (
      segmentationList.length === 2 &&
      segmentationList.every(v => v.isGrid && v.variableNk === segmentationList[0].variableNk)
    ) {
      // Both parts of a grid variable are in segmentation, so we just need to fetch this one var
      segVarsToFetch = [segmentationList[0]]
    } else {
      segVarsToFetch = uniqBy(
        segmentationList.reduce((acc, v) => {
          if (!v.isGrid || (v.isGrid && v.variableNk === previewVar.variableNk)) {
            return acc.concat(v)
          }
          return acc
        }, []),
        v => v.variableNk
      )
    }

    const fetchedMainVars = await dispatch(
      Analysis2Store.actions.fetchVariableThunk(previewVar.variableNk, true)
    )
    const fetchedSegVars = await Promise.all(
      segVarsToFetch.map(v =>
        dispatch(Analysis2Store.actions.fetchVariableThunk(v.variableNk, true))
      )
    )

    const variableOptionTable = getOldReportVarSelectionMap(getState())
    const alreadyLoadedVars = uniq([previewVar, ...segVarsToFetch])
    const deferredMainVars = uniqBy(
      mainList.filter(v => !alreadyLoadedVars.some(av => av.variableNk === v.variableNk)),
      v => v.variableNk
    )

    const rowVars = buildAxisVarsFromOriginalRequest(variableOptionTable, [fetchedMainVars], 'ROW')
    const placeholderVars = deferredMainVars.reduce((acc, variable) => {
      acc.push({
        variableNk: variable.variableNk,
        questionName: variable.questionName,
        resourceType: 'options',
        fromOldReport: true,
      })
      if (variable.isGrid) {
        acc.push({
          variableNk: variable.variableNk,
          questionName: variable.questionName,
          resourceType: 'breakouts',
          fromOldReport: true,
        })
      }
      return acc
    }, [])
    const columnVars = buildAxisVarsFromOriginalRequest(
      variableOptionTable,
      fetchedSegVars,
      'COLUMN'
    )

    const axisVars = [...rowVars, ...placeholderVars, ...columnVars]

    dispatch(setAxisVars(axisVars))

    oldReport.options.filters.forEach(async filter => {
      const { variableNk } = questionMap[filter.questionName]
      const variable = await dispatch(Analysis2Store.actions.fetchVariableThunk(variableNk, true))

      dispatch(
        Analysis2Store.actions.addFilterAction({
          selectedVariable: filter.variableResourceType === 'options' ? variable[0] : variable[1],
          selectedGrid: variable[0].isGrid ? variable : null,
          selectedFilterMethod: filter.method,
          selectedOptions: filter.filteredOptions,
          selectedGridBreakout: variable[0].isGrid
            ? { id: filter.breakoutId, type: filter.variableResourceType }
            : null,
        })
      )
    })

    let weighting = oldReport.options.weighting ?? 'useDefault'
    weighting = Array.isArray(weighting) ? weighting[0] : weighting
    //check if weight still exists

    const availableStandardWeights = Analysis2Store.selectors.getStandardWeights(getState())
    const availableCustomWeights = Analysis2Store.selectors.getCustomWeights(getState())
    let availableWeights = [...availableCustomWeights, ...availableStandardWeights]
    availableWeights = availableWeights.map(weight => weight.id)
    let weightingExist = availableWeights.includes(weighting)
    if (!weightingExist && weighting !== 'useDefault') {
      weighting = 'useDefault'
      dispatch(__baRehydratedReportMissingData())
    }

    dispatch(Analysis2Store.actions.toggleAutoAdjustBase(oldReport.options.autoAdjustBaseEnabled))
    dispatch(Analysis2Store.actions.setChartTypeAction(oldReport.options.viewSettings.chartType))
    dispatch(Analysis2Store.actions.toggleHideLowBase(oldReport.options.viewSettings.hideLowBase))
    dispatch(Analysis2Store.actions.toggleShowLabels(oldReport.options.viewSettings.showLabels))
    dispatch(Analysis2Store.actions.setWeight(weighting))
    dispatch(
      Analysis2Store.actions.toggleNullSuppression(
        oldReport.options.viewSettings.suppressNullValues ?? true
      )
    )
    dispatch(
      setStatTestingConfidence(
        oldReport.options.viewSettings.showStatTesting,
        oldReport.options.viewSettings.statsConfidence
      )
    )
    dispatch(
      Analysis2Store.actions.setSortAction('ROW', oldReport.options.viewSettings.sortState.ROW)
    )
    dispatch(
      Analysis2Store.actions.setSortAction(
        'COLUMN',
        oldReport.options.viewSettings.sortState.COLUMN
      )
    )
    dispatch(Analysis2Store.actions.setDisplayTypeAction(oldReport.options.viewSettings.numberType))

    dispatch(setPreviewAndUpdateChart(mainList[0].variableNk))
    dispatch(setReportBuilderActive(true))

    dispatch(manuallySetNameThunk(oldReport.title))

    const handleTrack = getHandleTrack(getState())
    handleTrack('Preview Rapid Report', { reportName: oldReport.title })
  }

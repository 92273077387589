import { handleResponse, handleError, handleData, isJson } from '@knowledgehound/data/fetchUtils'
import queryString from 'query-string'

const COLLIE_URL = process.env.REACT_APP_COLLIE_URL ?? ''

export const CREATE_QUALTRICS_KEY_LOADING = 'CREATE_QUALTRICS_KEY_LOADING'
const createQualtricsKeyLoading = () => ({
  type: CREATE_QUALTRICS_KEY_LOADING,
})

export const CREATE_QUALTRICS_KEY_SUCCESS = 'CREATE_QUALTRICS_KEY_SUCCESS'
const createQualtricsKeySuccess = (response: IntegrationTokenT | string) => ({
  type: CREATE_QUALTRICS_KEY_SUCCESS,
  payload: response,
})

export const CREATE_QUALTRICS_KEY_ERROR = 'CREATE_QUALTRICS_KEY_ERROR'
const createQualtricsKeyError = (err: ErrorT) => ({
  type: CREATE_QUALTRICS_KEY_ERROR,
  error: true,
  payload: err,
})

export const createQualtricsKey =
  (newToken: string) =>
  (dispatch, getState, { fetch }) => {
    dispatch(createQualtricsKeyLoading())
    const formData = new FormData()
    formData.append('api_key', newToken)

    return new Promise((resolve: Function, reject: Function) => {
      fetch(`${COLLIE_URL}/survey_integrations/qualtrics/token/create/`, {
        method: 'POST',
        body: formData,
      })
        .then(handleResponse)
        .then(handleData(resolve, dispatch, createQualtricsKeySuccess))
        .catch(handleError(reject, dispatch, createQualtricsKeyError))
    })
  }

export const FETCH_QUALTRICS_SURVEYS_LOADING = 'FETCH_QUALTRICS_SURVEYS_LOADING'
const fetchQualtricsSurveysLoading = () => ({
  type: FETCH_QUALTRICS_SURVEYS_LOADING,
})

export const FETCH_QUALTRICS_SURVEYS_SUCCESS = 'FETCH_QUALTRICS_SURVEYS_SUCCESS'
const fetchQualtricsSurveysSuccess = (response: IntegrationPageT | string) => ({
  type: FETCH_QUALTRICS_SURVEYS_SUCCESS,
  payload: response,
})

export const FETCH_QUALTRICS_SURVEYS_ERROR = 'FETCH_QUALTRICS_SURVEYS_ERROR'
const fetchQualtricsSurveysError = (err: ErrorT) => ({
  type: FETCH_QUALTRICS_SURVEYS_ERROR,
  error: true,
  payload: err,
})

export const fetchQualtricsSurveys =
  () =>
  (dispatch, getState, { fetch }) => {
    dispatch(fetchQualtricsSurveysLoading())

    return new Promise((resolve: Function, reject: Function) => {
      fetch(`${COLLIE_URL}/qualtrics/surveys/`, { skipLoginRedirect: true })
        .then(handleResponse)
        .then(handleData(resolve, dispatch, fetchQualtricsSurveysSuccess))
        .catch(handleError(reject, dispatch, fetchQualtricsSurveysError))
    })
  }

export const FETCH_QUALTRICS_SURVEY_STATUS_LOADING = 'FETCH_QUALTRICS_SURVEY_STATUS_LOADING'
const fetchQualtricsSurveyStatusLoading = () => ({
  type: FETCH_QUALTRICS_SURVEY_STATUS_LOADING,
})

export const FETCH_QUALTRICS_SURVEY_STATUS_SUCCESS = 'FETCH_QUALTRICS_SURVEY_STATUS_SUCCESS'
const fetchQualtricsSurveyStatusSuccess = (
  response: IntegrationPageT | string,
  body: Array<string>
) => ({
  type: FETCH_QUALTRICS_SURVEY_STATUS_SUCCESS,
  payload: response,
  body,
})

export const FETCH_QUALTRICS_SURVEY_STATUS_ERROR = 'FETCH_QUALTRICS_SURVEY_STATUS_ERROR'
const fetchQualtricsSurveyStatusError = (err: ErrorT, body: Array<string>) => ({
  type: FETCH_QUALTRICS_SURVEY_STATUS_ERROR,
  error: true,
  payload: err,
  body,
})

export const fetchQualtricsSurveyStatus =
  (body: Array<string>) =>
  (dispatch, getState, { fetch }) => {
    const query = queryString.stringify({ survey_id: body })

    dispatch(fetchQualtricsSurveyStatusLoading())
    return new Promise((resolve: Function, reject: Function) => {
      fetch(`${COLLIE_URL}/qualtrics/surveys/?${query}`)
        .then(handleResponse)
        .then(res => {
          dispatch(fetchQualtricsSurveyStatusSuccess(res, body))
          return resolve(res)
        })
        .catch(err => {
          if (err.response && isJson(err.response)) {
            err.response.json().then(function (errBody) {
              err.body = errBody
              dispatch(fetchQualtricsSurveyStatusError(err, body))
              return reject(err)
            })
          } else {
            dispatch(fetchQualtricsSurveyStatusError(err, body))
            return reject(err)
          }
        })
    })
  }

export const IMPORT_QUALTRICS_SURVEY_LOADING = 'IMPORT_QUALTRICS_SURVEY_LOADING'
export function importQualtricsSurveyLoading(survey_id: string): ActionT {
  return {
    type: IMPORT_QUALTRICS_SURVEY_LOADING,
    survey_id,
  }
}

export const IMPORT_QUALTRICS_SURVEY_SUCCESS = 'IMPORT_QUALTRICS_SURVEY_SUCCESS'
export function importQualtricsSurveySuccess(response: Object, survey_id: string): ActionT {
  return {
    type: IMPORT_QUALTRICS_SURVEY_SUCCESS,
    payload: response,
    survey_id,
  }
}

export const IMPORT_QUALTRICS_SURVEY_ERROR = 'IMPORT_QUALTRICS_SURVEY_ERROR'
export function importQualtricsSurveyError(err: ErrorT, survey_id: string): ActionT {
  return {
    type: IMPORT_QUALTRICS_SURVEY_ERROR,
    error: true,
    payload: err,
    survey_id,
  }
}

export function importQualtricsSurvey(survey: IntegrationSurveyT): Function {
  return (dispatch: Function, getState: Function, { fetch }) => {
    dispatch(importQualtricsSurveyLoading(survey.survey_id))
    const formData = new FormData()
    formData.append('_method', 'POST')
    formData.append('study_name', survey.name)
    formData.append('created_date', survey.created_date)

    return new Promise((resolve: Function, reject: Function) => {
      fetch(
        `${COLLIE_URL}/qualtrics/surveys/${survey.survey_id}/`,
        { method: 'POST', body: formData },
        false
      )
        .then(handleResponse)
        .then(response => {
          dispatch(importQualtricsSurveySuccess(response, survey.survey_id))
          return resolve(response)
        })
        .catch(err => {
          if (err.response && isJson(err.response)) {
            err.response.json().then(function (errBody) {
              err.body = errBody
              dispatch(importQualtricsSurveyError(err, survey.survey_id))
              return reject(err)
            })
          } else {
            dispatch(importQualtricsSurveyError(err, survey.survey_id))
            return reject(err)
          }
        })
    })
  }
}

export const PUBLISH_QUALTRICS_SURVEY_LOADING = 'PUBLISH_QUALTRICS_SURVEY_LOADING'
export function publishQualtricsSurveyLoading(survey_id: string): ActionT {
  return {
    type: PUBLISH_QUALTRICS_SURVEY_LOADING,
    survey_id,
  }
}

export const PUBLISH_QUALTRICS_SURVEY_SUCCESS = 'PUBLISH_QUALTRICS_SURVEY_SUCCESS'
export function publishQualtricsSurveySuccess(survey_id: string): ActionT {
  return {
    type: PUBLISH_QUALTRICS_SURVEY_SUCCESS,
    survey_id,
  }
}

export const PUBLISH_QUALTRICS_SURVEY_ERROR = 'PUBLISH_QUALTRICS_SURVEY_ERROR'
export function publishQualtricsSurveyError(err: ErrorT, survey_id: string): ActionT {
  return {
    type: PUBLISH_QUALTRICS_SURVEY_ERROR,
    error: true,
    payload: err,
    survey_id,
  }
}

export function publishQualtricsSurvey(survey: IntegrationSurveyT): Function {
  return (dispatch: Function, getState, { fetch }) => {
    dispatch(publishQualtricsSurveyLoading(survey.survey_id))

    return new Promise((resolve: Function, reject: Function) => {
      fetch(`${COLLIE_URL}/qualtrics/surveys/${survey.survey_id}/publish/`, { method: 'POST' })
        .then(handleResponse)
        .then(res => {
          dispatch(publishQualtricsSurveySuccess(survey.survey_id))
          return resolve(res)
        })
        .catch(err => {
          if (err.response && isJson(err.response)) {
            err.response.json().then(function (errBody) {
              err.body = errBody
              dispatch(publishQualtricsSurveyError(err, survey.survey_id))
              return reject(err)
            })
          } else {
            dispatch(publishQualtricsSurveyError(err, survey.survey_id))
            return reject(err)
          }
        })
    })
  }
}

export const DELETE_QUALTRICS_TOKEN_SUCCESS = 'DELETE_QUALTRICS_TOKEN_SUCCESS'
const deleteQualtricsTokenSuccess = (response: IntegrationTokenT) => ({
  type: DELETE_QUALTRICS_TOKEN_SUCCESS,
  payload: response,
})

export const DELETE_QUALTRICS_TOKEN_ERROR = 'DELETE_QUALTRICS_TOKEN_ERROR'
const deleteQualtricsTokenError = (err: ErrorT) => ({
  type: DELETE_QUALTRICS_TOKEN_ERROR,
  error: true,
  payload: err,
})

export const deleteQualtricsToken =
  (newToken: string) =>
  (dispatch, getState, { fetch }) => {
    const formData = new FormData()
    formData.append('api_key', newToken)

    return new Promise((resolve: Function, reject: Function) => {
      fetch(
        `${COLLIE_URL}/survey_integrations/qualtrics/token/delete/`,
        {
          method: 'POST',
          body: formData,
        },
        false
      )
        .then(handleResponse)
        .then(handleData(resolve, dispatch, deleteQualtricsTokenSuccess))
        .catch(handleError(reject, dispatch, deleteQualtricsTokenError))
    })
  }

import * as actions from './actions'

const defaultState = {
  error: null,
  reportId: null,
  studyId: null,
  pollCancelCallback: null,
  items: {},
  globalFilters: [],
  exports: {
    pptxUrl: '',
    xslxUrl: '',
  },
  searchValue: '',
}

export default function summaryReportReducer(state = defaultState, action = {}) {
  switch (action.type) {
    case actions.CREATE_REPORT_SUCCESS:
      return {
        ...state,
        studyId: action.studyId,
        reportId: action.reportId,
        items: action.items.reduce(
          (acc, item, index) => ({
            ...acc,
            [item.id]: {
              ...item,
              position: index,
            },
          }),
          {}
        ),
        exports: {
          pptxUrl: '',
          xslxUrl: '',
        },
      }
    case actions.CREATE_REPORT_ERROR:
      return {
        ...state,
        studyId: null,
        reportId: null,
        error: action.error,
      }
    case actions.CLEAR_REPORT:
      return {
        ...defaultState,
      }
    case actions.SET_POLL_CANCEL_CALLBACK:
      return {
        ...state,
        pollCancelCallback: action.pollCancelCallback,
      }
    case actions.UPDATE_ITEMS_STATUS:
      return {
        ...state,
        items: action.items.reduce(
          (acc, item, index) => ({
            ...acc,
            [item.id]: {
              ...state.items[item.id],
              ...item,
              position: index,
            },
          }),
          {}
        ),
        exports: {
          pptxUrl: action.pptxUrl,
          xslxUrl: action.xslxUrl,
        },
      }
    case actions.UPDATE_ITEM:
      return {
        ...state,
        items: {
          ...state.items,
          [action.itemId]: {
            ...state.items[action.itemId],
            ...action.content,
          },
        },
      }
    case actions.CLEAR_POLL_CANCEL_CALLBACK:
      return {
        ...state,
        pollCancelCallback: null,
      }
    case actions.SET_GLOBAL_FILTERS:
      return {
        ...state,
        globalFilters: action.filters,
      }
    case actions.CLEAR_GLOBAL_FILTERS:
      return {
        ...state,
        globalFilters: [],
      }
    case actions.SET_SEARCH_VALUE:
      return {
        ...state,
        searchValue: action.searchValue,
      }

    default: {
      return state
    }
  }
}

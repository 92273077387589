const DALMATIAN_URL = process.env.REACT_APP_DALMATIAN_API2_URL ?? ''

async function imageFetchHelper(
  fetch,
  dispatch: Function,
  keyInsight: Object,
  studyId: string,
  isNew: boolean
) {
  const url = isNew
    ? `${DALMATIAN_URL}/study_items/${studyId}/key_metrics/`
    : `${DALMATIAN_URL}/study_items/${studyId}/key_metrics/${keyInsight.id}/`
  const success = isNew ? __createKeyInsightSuccess : __updateKeyInsightSuccess
  const error = isNew ? __createKeyInsightError : __updateKeyInsightError
  const method = isNew ? 'POST' : 'PATCH'

  let response

  if (keyInsight.insight_image) {
    // formData needed to send file, similar to cover photo
    const formData = new FormData()

    for (const field in keyInsight) {
      if (field === 'content_object') {
        formData.append(field, JSON.stringify(keyInsight[field]))
      } else {
        formData.append(field, keyInsight[field])
      }
    }

    response = await fetch(url, {
      method,
      body: formData,
    })
  } else {
    response = await fetch(url, {
      method,
      jsonBody: keyInsight,
    })
  }

  if (!response.ok) {
    dispatch(error(response.statusText))
    return
  }

  const data = await response.json()
  dispatch(success(data))
}

export const FETCH_KEY_INSIGHTS_LOADING = 'FETCH_KEY_INSIGHTS_LOADING'
const __fetchKeyInsightsLoading = () => ({
  type: FETCH_KEY_INSIGHTS_LOADING,
})

export const FETCH_KEY_INSIGHTS_SUCCESS = 'FETCH_KEY_INSIGHTS_SUCCESS'
const __fetchKeyInsightsSuccess = response => ({
  type: FETCH_KEY_INSIGHTS_SUCCESS,
  payload: response,
})

export const FETCH_KEY_INSIGHTS_ERROR = 'FETCH_KEY_INSIGHTS_ERROR'
const __fetchKeyInsightsError = err => ({
  type: FETCH_KEY_INSIGHTS_ERROR,
  error: true,
  payload: err,
})

export const fetchKeyInsights =
  studyId =>
  async (dispatch, getState, { fetch }) => {
    dispatch(__fetchKeyInsightsLoading())

    const response = await fetch(`${DALMATIAN_URL}/study_items/${studyId}/key_metrics/`)

    if (!response.ok) {
      dispatch(__fetchKeyInsightsError(response.statusText))
      return
    }

    const data = await response.json()
    dispatch(__fetchKeyInsightsSuccess(data))
  }

export const CREATE_KEY_INSIGHT_LOADING = 'CREATE_KEY_INSIGHT_LOADING'
const __createKeyInsightLoading = () => ({
  type: CREATE_KEY_INSIGHT_LOADING,
})

export const CREATE_KEY_INSIGHT_SUCCESS = 'CREATE_KEY_INSIGHT_SUCCESS'
const __createKeyInsightSuccess = keyInsight => ({
  type: CREATE_KEY_INSIGHT_SUCCESS,
  payload: keyInsight,
})

export const CREATE_KEY_INSIGHT_ERROR = 'CREATE_KEY_INSIGHT_ERROR'
const __createKeyInsightError = err => ({
  type: CREATE_KEY_INSIGHT_ERROR,
  error: true,
  payload: err,
})

export const createKeyInsight =
  (keyInsight, studyId) =>
  async (dispatch, getState, { fetch }) => {
    dispatch(__createKeyInsightLoading())
    return imageFetchHelper(fetch, dispatch, keyInsight, studyId, true)
  }

export const UPDATE_KEY_INSIGHT_LOADING = 'UPDATE_KEY_INSIGHT_LOADING'
const __updateKeyInsightLoading = () => ({
  type: UPDATE_KEY_INSIGHT_LOADING,
})

export const UPDATE_KEY_INSIGHT_SUCCESS = 'UPDATE_KEY_INSIGHT_SUCCESS'
const __updateKeyInsightSuccess = keyInsight => ({
  type: UPDATE_KEY_INSIGHT_SUCCESS,
  payload: keyInsight,
})

export const UPDATE_KEY_INSIGHT_ERROR = 'UPDATE_KEY_INSIGHT_ERROR'
const __updateKeyInsightError = err => ({
  type: UPDATE_KEY_INSIGHT_ERROR,
  error: true,
  payload: err,
})

export const updateKeyInsight =
  (keyInsight, studyId) =>
  async (dispatch, getState, { fetch }) => {
    dispatch(__updateKeyInsightLoading())
    return imageFetchHelper(fetch, dispatch, keyInsight, studyId, false)
  }

export const DELETE_KEY_INSIGHT_LOADING = 'DELETE_KEY_INSIGHT_LOADING'
const __deleteKeyInsightLoading = keyInsightId => ({
  type: DELETE_KEY_INSIGHT_LOADING,
  payload: {
    id: keyInsightId,
  },
})

export const DELETE_KEY_INSIGHT_SUCCESS = 'DELETE_KEY_INSIGHT_SUCCESS'
const __deleteKeyInsightSuccess = () => ({
  type: DELETE_KEY_INSIGHT_SUCCESS,
})

export const DELETE_KEY_INSIGHT_ERROR = 'DELETE_KEY_INSIGHT_ERROR'
const __deleteKeyInsightError = err => ({
  type: DELETE_KEY_INSIGHT_ERROR,
  error: true,
  payload: err,
})

/**
 * @param {String} keyInsightId - ID of the key insight to delete
 * @param {String} studyId - ID of the study (i.e. FRE0123)
 */
export const deleteKeyInsight =
  (keyInsightId, studyId) =>
  async (dispatch, getState, { fetch }) => {
    dispatch(__deleteKeyInsightLoading(keyInsightId))

    const response = await fetch(
      `${DALMATIAN_URL}/study_items/${studyId}/key_metrics/${keyInsightId}/`,
      { method: 'DELETE' }
    )

    if (!response.ok) {
      dispatch(__deleteKeyInsightError(response.statusText))
      return
    }

    dispatch(__deleteKeyInsightSuccess())
  }

export const UPDATE_KEY_INSIGHT_CARD_POSITION = 'UPDATE_KEY_INSIGHT_CARD_POSITION'
export const updateKeyInsightCardPosition = (
  newPosition: number,
  atPosition: number,
  card: KeyInsightT
) => ({
  type: UPDATE_KEY_INSIGHT_CARD_POSITION,
  payload: {
    newPosition,
    atPosition,
    card,
  },
})

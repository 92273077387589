import filterReducerModule, { initialState as initFilterState } from 'data/filters/shared/reducer'
import * as actions from './actions'

export const initialState = {
  ...['studies', 'questions', 'stories', 'documents'].reduce((acc, cur) => {
    acc[cur] = {
      isFetching: false,
      hasError: false,
      count: 0,
      results: [],
      pagination: {
        currentPage: 1,
        totalPages: 1,
        pageSize: 25,
      },
    }
    return acc
  }, {}),
  query: '',
  initialState: true,
  isLoadingFilters: false,
  storyFilters: initFilterState,
  storyFilterSettings: {
    isFetching: false,
    hasError: false,
    initialState: true,
    initialStoryFilterAggregations: [],
  },
  vectorResults: {
    vendors: ['openai', 'sentence_transformer'],
  },
}

export default function SearchResultsReducer(state = initialState, action) {
  const storyFilterReducerResult = filterReducerModule(
    {
      initAction: actions.RESULTS_INITIALIZE_STORY_FILTERS,
      updateAction: actions.RESULTS_UPDATE_AVAILABLE_STORY_FILTERS,
      setSelectionAction: actions.RESULTS_SET_STORY_FILTER_SELECTION,
      clearAction: actions.RESULTS_CLEAR_STORY_FILTERS,
    },
    state,
    action
  )
  if (storyFilterReducerResult) return storyFilterReducerResult

  switch (action.type) {
    case actions.SET_QUERY_STRING:
      if (action.query === state.query) return state
      return {
        ...state,
        query: action.query,
      }
    case actions.INIT_STORY_FILTERS_LOADING:
      return {
        ...state,
        storyFilterSettings: {
          ...state.storyFilterSettings,
          isFetching: true,
          hasError: false,
        },
      }
    case actions.INIT_STORY_FILTERS_ERROR:
      return {
        ...state,
        storyFilterSettings: {
          ...state.storyFilterSettings,
          isFetching: false,
          hasError: true,
        },
      }
    case actions.INIT_STORY_FILTERS_SUCCESS:
      return {
        ...state,
        storyFilterSettings: {
          ...state.storyFilterSettings,
          hasError: false,
          isFetching: false,
          initialState: false,
          initialStoryFilterAggregations: action.initialStoryFilterAggregations,
        },
      }
    case actions.SET_ITEM_FETCH_LOADING:
      return {
        ...state,
        initialState: false,
        [action.itemType]: {
          ...state[action.itemType],
          hasError: false,
          isFetching: action.isFetching,
        },
      }
    case actions.SET_ITEM_FETCH_ERROR:
      return {
        ...state,
        initialState: false,
        [action.itemType]: {
          ...state[action.itemType],
          isFetching: false,
          hasError: action.hasError,
        },
      }
    case actions.SET_ITEM_FETCH_SUCCESS:
      return {
        ...state,
        initialState: false,
        [action.itemType]: {
          ...state[action.itemType],
          ...action.data,
          isFetching: false,
          hasError: false,
        },
      }
    case actions.SET_ITEM_PAGE_SIZE:
      return {
        ...state,
        initialState: false,
        [action.itemType]: {
          ...state[action.itemType],
          pagination: {
            ...state[action.itemType].pagination,
            pageSize: action.pageSize,
          },
        },
      }
    case actions.VECTOR_RESULT_SUCCESS:
      return {
        ...state,
        vectorResults: {
          ...state.vectorResults,
          [action.vendor]: {
            topMatches: action.results?.top_matches,
            questions: action.results?.questions,
            suggested: action.results?.suggested,
            reasons: action.results?.reasons,
          },
        },
      }
    default:
      return state
  }
}

// @flow
import * as actions from './SessionActions'
import type { SessionStateT } from './SessionStateT'

type ActionT =
  | {
      type: 'FETCH_SESSION_SUCCESS',
      payload: Object, // this is not sessionT anymore because of a bug in flow
    }
  | {
      type: 'FETCH_SESSION_ERROR',
      payload: Error,
    }
  | {
      type: 'FETCH_SESSION_LOADING',
    }

export const sessionReducer = (
  state: SessionStateT = {
    currentSession: {
      isFetching: true,
      error: null,
      data: null,
      lastFetched: null,
    },
  },
  action: ActionT
): SessionStateT => {
  switch (action.type) {
    case actions.FETCH_SESSION_LOADING: {
      return {
        ...state,
        currentSession: {
          ...state.currentSession,
          isFetching: true,
          error: null,
        },
      }
    }
    case actions.FETCH_SESSION_SUCCESS: {
      const lastFetched = Date.now()
      // currently session_check returns false if not logged in :(
      if (typeof action.payload === 'boolean' && !action.payload) {
        return {
          ...state,
          currentSession: {
            ...state.currentSession,
            isFetching: false,
            lastFetched,
            data: {
              valid_login: false,
            },
            error: null,
          },
        }
      } else {
        return {
          ...state,
          currentSession: {
            ...state.currentSession,
            isFetching: false,
            lastFetched,
            data: {
              ...action.payload,
              valid_login: true,
            },
            error: null,
          },
        }
      }
    }
    case actions.FETCH_SESSION_ERROR: {
      const lastFetched = Date.now()
      return {
        ...state,
        currentSession: {
          ...state.currentSession,
          isFetching: false,
          lastFetched,
          error: action.payload,
        },
      }
    }
    default:
      return state
  }
}

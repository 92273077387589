import { handleResponse } from '@knowledgehound/data/fetchUtils'
import { encodeNaturalKey } from '@knowledgehound/analysis'
import queryString from 'query-string'

export class RequestTimeoutError extends Error {
  constructor(message: string) {
    super(message)
    this.name = 'RequestTimeoutError'
  }
}

export function getOpenEndedResponses(naturalKey, params) {
  return fetch(
    `/proxy/husky/questions/${encodeNaturalKey(naturalKey)}/responses/${
      params ? '?' + queryString.stringify(params) : ''
    }`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'same-origin',
    }
  ).then(handleResponse)
}

export function getSentimentRanges() {
  return fetch('/proxy/husky/sentiment/', {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
    credentials: 'same-origin',
  }).then(handleResponse)
}

export function getClientInfo() {
  return fetch('/proxy/greyhound/', {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
    credentials: 'same-origin',
  }).then(handleResponse)
}

export function getParentStudy(datasetPath, params) {
  return fetch(`/proxy/spss${datasetPath}${params ? '?' + queryString.stringify(params) : ''}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
    credentials: 'same-origin',
  }).then(handleResponse)
}

import { handleResponse, handleError, handleData, isJson } from '@knowledgehound/data/fetchUtils'
import queryString from 'query-string'

const COLLIE_URL = process.env.REACT_APP_COLLIE_URL || ''

export const FETCH_SM_SURVEYS_LOADING = 'FETCH_SM_SURVEYS_LOADING'
const fetchSmSurveysLoading = () => ({
  type: FETCH_SM_SURVEYS_LOADING,
})

export const FETCH_SM_SURVEYS_SUCCESS = 'FETCH_SM_SURVEYS_SUCCESS'
const fetchSmSurveysSuccess = response => ({
  type: FETCH_SM_SURVEYS_SUCCESS,
  payload: response,
})

export const FETCH_SM_SURVEYS_ERROR = 'FETCH_SM_SURVEYS_ERROR'
const fetchSmSurveysError = err => ({
  type: FETCH_SM_SURVEYS_ERROR,
  error: true,
  payload: err,
})

export const fetchSmSurveys =
  () =>
  (dispatch, getState, { fetch }) => {
    dispatch(fetchSmSurveysLoading())

    return new Promise((resolve: Function, reject: Function) => {
      fetch(`${COLLIE_URL}/survey_monkey/surveys/`, { skipLoginRedirect: true })
        .then(handleResponse)
        .then(handleData(resolve, dispatch, fetchSmSurveysSuccess))
        .catch(handleError(reject, dispatch, fetchSmSurveysError))
    })
  }

export const FETCH_SM_SURVEY_STATUS_LOADING = 'FETCH_SM_SURVEY_STATUS_LOADING'
const fetchSmSurveyStatusLoading = () => ({
  type: FETCH_SM_SURVEY_STATUS_LOADING,
})

export const FETCH_SM_SURVEY_STATUS_SUCCESS = 'FETCH_SM_SURVEY_STATUS_SUCCESS'
const fetchSmSurveyStatusSuccess = (response: IntegrationPageT | string, body: Array<string>) => ({
  type: FETCH_SM_SURVEY_STATUS_SUCCESS,
  payload: response,
  body,
})

export const FETCH_SM_SURVEY_STATUS_ERROR = 'FETCH_SM_SURVEY_STATUS_ERROR'
const fetchSmSurveyStatusError = (err: ErrorT, body: Array<string>) => ({
  type: FETCH_SM_SURVEY_STATUS_ERROR,
  error: true,
  payload: err,
  body,
})

export const fetchSmSurveyStatus =
  (body: Array<string>) =>
  (dispatch, getState, { fetch }) => {
    const query = queryString.stringify({ survey_id: body })

    dispatch(fetchSmSurveyStatusLoading())
    return new Promise((resolve: Function, reject: Function) => {
      fetch(`${COLLIE_URL}/survey_monkey/surveys/?${query}`)
        .then(handleResponse)
        .then(res => {
          dispatch(fetchSmSurveyStatusSuccess(res, body))
          return resolve(res)
        })
        .catch(err => {
          if (err.response && isJson(err.response)) {
            err.response.json().then(function (errBody) {
              err.body = errBody
              dispatch(fetchSmSurveyStatusError(err, body))
              return reject(err)
            })
          } else {
            dispatch(fetchSmSurveyStatusError(err, body))
            return reject(err)
          }
        })
    })
  }

export const IMPORT_SM_SURVEY_LOADING = 'IMPORT_SM_SURVEY_LOADING'
export function importSmSurveyLoading(survey_id: string): ActionT {
  return {
    type: IMPORT_SM_SURVEY_LOADING,
    survey_id,
  }
}

export const IMPORT_SM_SURVEY_SUCCESS = 'IMPORT_SM_SURVEY_SUCCESS'
export function importSmSurveySuccess(response: Object, survey_id: string): ActionT {
  return {
    type: IMPORT_SM_SURVEY_SUCCESS,
    payload: response,
    survey_id,
  }
}

export const IMPORT_SM_SURVEY_ERROR = 'IMPORT_SM_SURVEY_ERROR'
export function importSmSurveyError(err: ErrorT, survey_id: string): ActionT {
  return {
    type: IMPORT_SM_SURVEY_ERROR,
    error: true,
    payload: err,
    survey_id,
  }
}

export function importSmSurvey(survey: IntegrationSurveyT, date: string): Function {
  return (dispatch: Function, getState: Function, { fetch }) => {
    dispatch(importSmSurveyLoading(survey.survey_id))
    const formData = new FormData()
    formData.append('_method', 'POST')
    formData.append('study_name', survey.name)

    return new Promise((resolve: Function, reject: Function) => {
      fetch(
        `${COLLIE_URL}/survey_monkey/surveys/${survey.survey_id}/`,
        { method: 'POST', body: formData },
        false
      )
        .then(handleResponse)
        .then(response => {
          dispatch(importSmSurveySuccess(response, survey.survey_id))
          return resolve(response)
        })
        .catch(err => {
          if (err.response && isJson(err.response)) {
            err.response.json().then(function (errBody) {
              err.body = errBody
              dispatch(importSmSurveyError(err, survey.survey_id))
              return reject(err)
            })
          } else {
            dispatch(importSmSurveyError(err, survey.survey_id))
            return reject(err)
          }
        })
    })
  }
}

export const PUBLISH_SM_SURVEY_LOADING = 'PUBLISH_SM_SURVEY_LOADING'
export function publishSmSurveyLoading(survey_id: string): ActionT {
  return {
    type: PUBLISH_SM_SURVEY_LOADING,
    survey_id,
  }
}

export const PUBLISH_SM_SURVEY_SUCCESS = 'PUBLISH_SM_SURVEY_SUCCESS'
export function publishSmSurveySuccess(survey_id: string): ActionT {
  return {
    type: PUBLISH_SM_SURVEY_SUCCESS,
    survey_id,
  }
}

export const PUBLISH_SM_SURVEY_ERROR = 'PUBLISH_SM_SURVEY_ERROR'
export function publishSmSurveyError(err: ErrorT, survey_id: string): ActionT {
  return {
    type: PUBLISH_SM_SURVEY_ERROR,
    error: true,
    payload: err,
    survey_id,
  }
}

export function publishSmSurvey(survey: IntegrationSurveyT): Function {
  return (dispatch: Function, getState, { fetch }) => {
    dispatch(publishSmSurveyLoading(survey.survey_id))

    return new Promise((resolve: Function, reject: Function) => {
      fetch(`${COLLIE_URL}/survey_monkey/surveys/${survey.survey_id}/publish/`, { method: 'POST' })
        .then(handleResponse)
        .then(res => {
          dispatch(publishSmSurveySuccess(survey.survey_id))
          return resolve(res)
        })
        .catch(err => {
          if (err.response && isJson(err.response)) {
            err.response.json().then(function (errBody) {
              err.body = errBody
              dispatch(publishSmSurveyError(err, survey.survey_id))
              return reject(err)
            })
          } else {
            dispatch(publishSmSurveyError(err, survey.survey_id))
            return reject(err)
          }
        })
    })
  }
}
export const DELETE_SM_INTEGRATION_LOADING = 'DELETE_SM_INTEGRATION_LOADING'
const deleteSMIntegrationLoading = () => ({
  type: DELETE_SM_INTEGRATION_LOADING,
})

export const DELETE_SM_INTEGRATION_SUCCESS = 'DELETE_SM_INTEGRATION_SUCCESS'
const deleteSMIntegrationSuccess = () => ({
  type: DELETE_SM_INTEGRATION_SUCCESS,
})

export const DELETE_SM_INTEGRATION_ERROR = 'DELETE_SM_INTEGRATION_ERROR'
const deleteSMIntegrationError = error => ({
  type: DELETE_SM_INTEGRATION_ERROR,
  error: true,
  payload: error,
})

export const deleteSMIntegration =
  (user: string) =>
  (dispatch, getState, { fetch }) => {
    dispatch(deleteSMIntegrationLoading())

    return new Promise((resolve: Function, reject: Function) => {
      fetch(`/proxy/spss/silverback/${encodeURIComponent(user)}/`, {
        method: 'DELETE',
        credentials: 'same-origin',
      })
        .then(handleResponse)
        .then(handleData(resolve, dispatch, deleteSMIntegrationSuccess))
        .catch(handleError(reject, dispatch, deleteSMIntegrationError))
    })
  }

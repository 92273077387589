import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { Link } from 'react-router-dom'
import { Icon } from '@knowledgehound/laika'

import * as styles from './Card.module.scss'

const propTypes = {
  /** content, if any */
  body: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  /** gray text at bottom of card */
  footer: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  /** main bold header */
  header: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  /** Adds locked icon to card */
  isLocked: PropTypes.bool,
  /** Optional URL to direct the user to on click. */
  linkTo: PropTypes.string,
  /** Optional callback to trigger when user clicks card. */
  onClick: PropTypes.func,
  /** gray text above the main bold header */
  preheader: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  /** normal, small - small used in /results, normal everywhere else */
  type: PropTypes.oneOf(['normal', 'small', 'locked']),
}

function Card({
  body = '',
  footer = '',
  header,
  isLocked = false,
  linkTo = null,
  onClick = () => {},
  preheader,
  type = 'normal',
}) {
  return (
    <li className={classnames(styles.card, styles[type], { [styles.locked]: isLocked })}>
      <Link to={linkTo || ''} onClick={onClick}>
        <div className={styles.preHeader}>
          {preheader && <span className={styles.meta}>{preheader}</span>}
        </div>
        {typeof header === 'string' ? <span className={styles.name}>{header}</span> : header}
        {typeof body === 'string' ? <span>{body}</span> : body}
        {typeof footer === 'string' || isLocked ? (
          <span className={isLocked ? styles.restrictedStatus : styles.meta}>
            {isLocked && <Icon icon="locked" size="medium" />}{' '}
            {isLocked ? 'Restricted Study' : footer}
          </span>
        ) : (
          footer
        )}
      </Link>
    </li>
  )
}

Card.propTypes = propTypes

export default Card

// @flow
import type { ActionT } from 'data/ActionT'
import type { ErrorT } from 'data/ErrorT'
import type { ClientInfoT } from './ClientInfoT'
import type { UpdateT } from './ClientInfoStateT'
import { handleResponse, handleError } from '@knowledgehound/data/fetchUtils'

// CONSTANTS
export const FETCH_CLIENT_INFO_LOADING = 'FETCH_CLIENT_INFO_LOADING'
export const FETCH_CLIENT_INFO_SUCCESS = 'FETCH_CLIENT_INFO_SUCCESS'
export const FETCH_CLIENT_INFO_ERROR = 'FETCH_CLIENT_INFO_ERROR'
export const UPDATE_CLIENT_INFO_LOADING = 'UPDATE_CLIENT_INFO_LOADING'
export const UPDATE_CLIENT_INFO_SUCCESS = 'UPDATE_CLIENT_INFO_SUCCESS'
export const UPDATE_CLIENT_INFO_ERROR = 'UPDATE_CLIENT_INFO_ERROR'

export function fetchClientInfoLoading(): ActionT {
  return {
    type: FETCH_CLIENT_INFO_LOADING,
  }
}

export function fetchClientInfoSuccess(clientInfo: ClientInfoT | string): ActionT {
  return {
    type: FETCH_CLIENT_INFO_SUCCESS,
    payload: clientInfo,
  }
}

export function fetchClientInfoError(err: ErrorT): ActionT {
  return {
    type: FETCH_CLIENT_INFO_ERROR,
    error: true,
    payload: err,
  }
}

export function fetchClientInfo(): Function {
  return (dispatch: Function, getState: Function, { fetch }): Promise<any> => {
    dispatch(fetchClientInfoLoading())

    return new Promise((resolve: Function, reject: Function) => {
      fetch('/proxy/greyhound/', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'same-origin',
      })
        .then(handleResponse)
        .then(function (clientInfo: ClientInfoT) {
          resolve(clientInfo)
          dispatch(fetchClientInfoSuccess(clientInfo))
        })
        .catch(handleError(reject, dispatch, fetchClientInfoError))
    })
  }
}

export function updateClientInfoLoading(): ActionT {
  return {
    type: UPDATE_CLIENT_INFO_LOADING,
  }
}

export function updateClientInfoSuccess(update: UpdateT | string): ActionT {
  return {
    type: UPDATE_CLIENT_INFO_SUCCESS,
    payload: update,
  }
}

export function updateClientInfoError(err: ErrorT): ActionT {
  return {
    type: UPDATE_CLIENT_INFO_ERROR,
    error: true,
    payload: err,
  }
}

export function updateClientInfo(clientinfo: Object): Function {
  return (dispatch: Function, getState: Function, { fetch }) => {
    dispatch(updateClientInfoLoading())

    return new Promise((resolve: Function, reject: Function) => {
      fetch('/proxy/greyhound/', {
        method: 'PATCH',
        body: JSON.stringify(clientinfo),
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'same-origin',
      })
        .then(handleResponse)
        .then(function (update: UpdateT | string) {
          resolve(update)
          dispatch(updateClientInfoSuccess(update))
        })
        .catch(handleError(reject, dispatch, updateClientInfoError))
    })
  }
}

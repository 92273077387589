// @flow
import React from 'react'
import ErrorPage from 'components/errorPage/ErrorPage'

const NotFound = () => {
  return (
    <ErrorPage
      leadText="Oops! This page doesn't exist."
      text="If you believe this is an error let us know in the chat below."
      actionItem={
        <a href="/home" title="Go to homepage" className="btn secondary white">
          Head safely home
        </a>
      }
    />
  )
}

export default NotFound

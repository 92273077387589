// @flow
import * as actions from './ClientInfoActions'
import type { ClientInfoStateT } from './ClientInfoStateT'
import type { ClientInfoT } from './ClientInfoT'

type ActionT =
  | {
      type: 'FETCH_CLIENT_INFO_SUCCESS',
      payload: ClientInfoT,
    }
  | {
      type: 'FETCH_CLIENT_INFO_ERROR',
      payload: Error,
    }
  | {
      type: 'FETCH_CLIENT_INFO_LOADING',
    }

export const clientinfoReducer = (
  state: ClientInfoStateT = {
    currentClientInfo: {
      isFetching: true,
      error: null,
      data: null,
      lastFetched: null,
    },
    updateClientInfo: {
      isFetching: true,
      error: null,
      data: null,
      lastFetched: null,
    },
  },
  action: ActionT
): ClientInfoStateT => {
  switch (action.type) {
    case actions.FETCH_CLIENT_INFO_LOADING: {
      return {
        ...state,
        currentClientInfo: {
          ...state.currentClientInfo,
          isFetching: true,
          error: null,
        },
      }
    }
    case actions.FETCH_CLIENT_INFO_SUCCESS: {
      const lastFetched = Date.now()
      return {
        ...state,
        currentClientInfo: {
          ...state.currentClientInfo,
          isFetching: false,
          lastFetched,
          data: action.payload,
          error: null,
        },
      }
    }
    case actions.FETCH_CLIENT_INFO_ERROR: {
      const lastFetched = Date.now()
      return {
        ...state,
        currentClientInfo: {
          ...state.currentClientInfo,
          isFetching: false,
          lastFetched,
          error: action.payload,
        },
      }
    }
    case actions.UPDATE_CLIENT_INFO_LOADING: {
      return {
        ...state,
        updateClientInfo: {
          ...state.updateClientInfo,
          isFetching: true,
          error: null,
        },
      }
    }
    case actions.UPDATE_CLIENT_INFO_SUCCESS: {
      const lastFetched = Date.now()
      return {
        ...state,
        updateClientInfo: {
          ...state.updateClientInfo,
          isFetching: false,
          lastFetched,
          data: action.payload,
          error: null,
        },
      }
    }
    case actions.UPDATE_CLIENT_INFO_ERROR: {
      const lastFetched = Date.now()
      return {
        ...state,
        updateClientInfo: {
          ...state.updateClientInfo,
          isFetching: false,
          lastFetched,
          error: action.payload,
        },
      }
    }
    default:
      return state
  }
}

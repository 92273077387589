import { combineReducers } from 'redux'
import { reducer as formReducer } from 'redux-form'
import * as study from '@knowledgehound/studies'
import { reducer as filesReducer } from '@knowledgehound/files'
import storiesReducer from '@knowledgehound/stories'
import featuredItemsReducer from '@knowledgehound/stories/featuredItems'
import * as cards from '@knowledgehound/cards'
import { Analysis2Store } from '@knowledgehound/analysis'

import home from 'data/home'
import * as analysis from 'data/analysis'
import keyInsights from 'data/keyInsight'
import * as metadataFields from 'data/metadata/metadataField'
import * as metadataValues from 'data/metadata/metadataValues'
import * as clientInfo from 'data/clientinfo'
import studyHome from 'data/studyHome/reducer'
import results from 'data/results/reducer'
import summaryReport from 'data/summaryReport'
import * as share from 'data/share'
import * as favorite from 'data/favorite'
import domainsReducer from 'data/domains'
import studyDatasets from 'data/studyDatasets'
import * as session from 'data/session'
import integrations from 'data/integrations'
import * as userManagement from 'data/userManagement'
import clientBranding from 'data/clientBranding'
import { bulkAnalysisReducer } from 'data/bulkAnalysis/BulkAnalysisReducers'
import personalization from 'data/personalization'
import unifiedFilters from 'data/filters/unified'
import positionalStoryReducer from './positionalStoriesDuck'

const app = combineReducers({
  analysis: analysis.reducers.analysisReducer,
  bulkAnalysis: bulkAnalysisReducer,
  cards: cards.reducers.cardsReducer,
  clientInfo: clientInfo.reducers.clientinfoReducer,
  clientBranding,
  config: Analysis2Store.config.configReducer,
  domains: domainsReducer,
  dataset: Analysis2Store.reducers.datasetReducer,
  favorite: favorite.reducers.favoriteReducer,
  featuredItems: featuredItemsReducer,
  files: filesReducer,
  filterNet: Analysis2Store.filterNet.filterNetReducer,
  form: formReducer,
  home,
  integrations,
  keyInsights,
  metadataField: metadataFields.reducers.metadataFieldReducer,
  metadataValues: metadataValues.reducers.metadataValuesReducer,
  personalization,
  results,
  session: session.reducers.sessionReducer,
  share: share.reducers.shareReducer,
  stories: positionalStoryReducer(storiesReducer),
  studyHome,
  study: study.reducers.studyReducer,
  studyDatasets,
  summaryReport,
  unifiedFilters,
  userManagement: userManagement.reducer,
})

export default app

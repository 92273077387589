import { createSelector } from 'reselect'

const integrationStatus = state => state.integrations.integrationStatus

export const getIntegrationStatus = createSelector(
  [integrationStatus],
  integrationStatus => integrationStatus
)

export const getIntegrationData = createSelector(
  [integrationStatus],
  integrationStatus => integrationStatus.data
)

const deleteSmIntegration = state => state.integrations.deleteSmIntegration

export const getDeleteSmIntegration = createSelector(
  [deleteSmIntegration],
  deleteSmIntegration => deleteSmIntegration
)

const integrationTokens = state => state.integrations.integrationTokens

export const getIntegrationTokens = createSelector(
  [integrationTokens],
  integrationTokens => integrationTokens
)

const qualtricsSurveys = state => state.integrations.qualtricsSurveys

export const getQualtricsSurveys = createSelector(
  [qualtricsSurveys],
  qualtricsSurveys => qualtricsSurveys
)

export const getSurveySearchQuery = state => state.integrations.searchQuery.toLowerCase()

export const getSearchedQualtricsSurveys = createSelector(
  getQualtricsSurveys,
  getSurveySearchQuery,
  (qualtricsSurveys, searchQuery) => {
    const surveys = qualtricsSurveys?.data?.results ?? []
    if (!searchQuery) return surveys
    return surveys.filter(survey => survey.name.toLowerCase().includes(searchQuery))
  }
)

const surveyMonkeySurveys = state => state.integrations.surveyMonkeySurveys

export const getSurveyMonkeySurveys = createSelector(
  [surveyMonkeySurveys],
  surveyMonkeySurveys => surveyMonkeySurveys
)

export const getSearchedSurveyMonkeySurveys = createSelector(
  getSurveyMonkeySurveys,
  getSurveySearchQuery,
  (surveyMonkeySurveys, searchQuery) => {
    const surveys = surveyMonkeySurveys?.data?.results ?? []
    if (!searchQuery) return surveys
    return surveys.filter(survey => survey.name.toLowerCase().includes(searchQuery))
  }
)

const decipherState = state => state.integrations.decipher
export const getIsFetchingDecipherSurveys = state => decipherState(state).isFetchingSurveys
export const getErrorFetchingDecipherSurveys = state => decipherState(state).errorFetchingSurveys
const getPlainDecipherSurveys = state => decipherState(state).surveys ?? []
export const getDecipherSurveys = createSelector(
  getPlainDecipherSurveys,
  getSurveySearchQuery,
  (surveys, searchQuery) => {
    if (!searchQuery) return surveys
    return surveys.filter(survey => survey.name.toLowerCase().includes(searchQuery))
  }
)

export const getIsFetchingDecipherToken = state => getIntegrationTokens(state)?.decipher?.isFetching
export const getDecipherToken = state => getIntegrationTokens(state)?.decipher?.data?.token
export const getAreDecipherSurveysAutoImported = state =>
  getIntegrationTokens(state)?.decipher?.data?.auto_import ?? false
export const getDecipherSurveysAutoImportDate = state =>
  getIntegrationTokens(state)?.decipher?.data?.auto_import_enabled ?? false

export const getDecipherPublicKey = state => decipherState(state).apiKey.publicKey
export const getDecipherPrivateKey = state => decipherState(state).apiKey.privateKey
export const getIsStagedDecipherKeyValid = createSelector(
  getDecipherPublicKey,
  getDecipherPrivateKey,
  (publicKey, privateKey) => {
    if (!publicKey || !privateKey) return false
    if (publicKey.length !== 32 || privateKey.length !== 32) return false
    return true
  }
)
export const getIsCreatingDecipherKey = state => decipherState(state).apiKey.isCreating

export const getIsUpdatingDecipherKey = state => decipherState(state).apiKey.isUpdating
export const getHasErrorUpdatingDecipherKey = state =>
  Boolean(decipherState(state).apiKey.errorUpdating)
export const getHasErrorCreatingDecipherKey = state =>
  Boolean(decipherState(state).apiKey.errorCreating)
export const getIsDeletingDecipherKey = state => decipherState(state).apiKey.isDeleting
export const getHasErrorDeletingDecipherKey = state =>
  Boolean(decipherState(state).apiKey.errorDeleting)

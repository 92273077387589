import { Analysis2Store } from '@knowledgehound/analysis'

export const FETCH_DOMAIN_LOADING = 'FETCH_DOMAIN_LOADING'
const __fetchDomainLoading = () => ({
  type: FETCH_DOMAIN_LOADING,
})

export const FETCH_DOMAIN_SUCCESS = 'FETCH_DOMAIN_SUCCESS'
const __fetchDomainSuccess = domain => ({
  type: FETCH_DOMAIN_SUCCESS,
  payload: domain,
})

export const FETCH_DOMAIN_ERROR = 'FETCH_DOMAIN_ERROR'
const __fetchDomainError = error => ({
  type: FETCH_DOMAIN_ERROR,
  payload: error,
})

export const fetchDomain =
  () =>
  async (dispatch, getState, { fetch }) => {
    if (!Analysis2Store.config.getIsUserManager(getState())) return

    dispatch(__fetchDomainLoading())
    const response = await fetch('/proxy/rottweiler/')

    if (!response.ok) {
      dispatch(__fetchDomainError(response.statusText))
      return
    }

    const domainData = await response.json()
    dispatch(__fetchDomainSuccess(domainData))
  }

import metadataReducerModule from 'data/filters/shared/reducer'
import * as actions from './actions'

export const defaultReducerState = {
  searchQuery: '',
  userPreferences: {
    isFetching: false,
    fetchingError: null,
    selectionError: false,
    results: [],
    selectedFields: [],
  },
  showToolTip: false,
  localFilters: [],
  availablePreferences: null,
}

export default function personalizationReducer(state = defaultReducerState, action) {
  const availablePreferencesResult = metadataReducerModule(
    {
      initAction: actions.INITIALIZE_AVAILABLE_PREFERENCES,
      updateAction: actions.UPDATE_AVAILABLE_PREFERENCES,
      setSelectionAction: actions.SET_AVAILABLE_PREFERENCES,
    },
    state,
    action
  )
  if (availablePreferencesResult) return availablePreferencesResult

  switch (action.type) {
    case actions.SET_SEARCH_QUERY: {
      return {
        ...state,
        searchQuery: action.searchQuery,
      }
    }
    case actions.FETCH_USER_PREFERENCES_LOADING: {
      return {
        ...state,
        userPreferences: {
          ...state.userPreferences,
          isFetching: true,
          fetchingError: null,
        },
      }
    }
    case actions.FETCH_USER_PREFERENCES_ERROR: {
      return {
        ...state,
        userPreferences: {
          ...state.userPreferences,
          isFetching: false,
          fetchingError: action.error,
        },
      }
    }
    case actions.FETCH_USER_PREFERENCES_SUCCESS: {
      return {
        ...state,
        userPreferences: {
          ...state.userPreferences,
          isFetching: false,
          results: action.results,
        },
      }
    }
    case actions.UPDATE_USER_PREFERENCES_ERROR: {
      return {
        ...state,
        userPreferences: {
          ...state.userPreferences,
          selectionError: action.error,
        },
      }
    }
    case actions.REMOVED_USER_PREFERENCES_SUCCESS: {
      const removedPreferences = action.updatedPreferences
      const updatedUserPreferenceResults = state.userPreferences.results.reduce(
        (reducedResults, field) => {
          const newField = {
            ...field,
            values: field.values.filter(
              value =>
                (field.name === removedPreferences.name &&
                  !removedPreferences.values.includes(value)) ||
                field.name !== removedPreferences.name
            ),
          }
          if (newField.values.length > 0) reducedResults.push(newField)
          return reducedResults
        },
        []
      )
      return {
        ...state,
        userPreferences: {
          ...state.userPreferences,
          results: updatedUserPreferenceResults,
        },
      }
    }
    case actions.ADDED_USER_PREFERENCES_SUCCESS: {
      const newlyAddedPreferences = action.updatedPreferences
      const updatedUserPreferenceResults = [...state.userPreferences.results]
      if (updatedUserPreferenceResults.some(field => field.name === newlyAddedPreferences.name)) {
        updatedUserPreferenceResults.forEach(preference => {
          if (newlyAddedPreferences.name === preference.name) {
            preference.values = [...preference.values, ...newlyAddedPreferences.values]
          }
        })
      } else {
        updatedUserPreferenceResults.push(newlyAddedPreferences)
      }
      return {
        ...state,
        userPreferences: {
          ...state.userPreferences,
          results: updatedUserPreferenceResults,
        },
      }
    }
    case actions.FETCH_LOCAL_FILTERS: {
      return {
        ...state,
        localFilters: action.localFilters,
      }
    }
    case actions.SET_SHOW_TOOLTIP: {
      return {
        ...state,
        showToolTip: action.show,
      }
    }
    default:
      return state
  }
}

import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { fetchStudies, fetchStories, fetchRecentFavorites } from 'data/home/actions'
import HomeSearchHeader from './HomeSearch/HomeSearchHeader'
import ExploreCallout from './ExploreCallout'
import HomeStudiesCards from './HomepageCards/HomeStudiesCards'
import HomeStoriesCards from './HomepageCards/HomeStoriesCards'
import HomepageFavoritesCards from './HomepageCards/HomeFavoritesCards'

import * as styles from './Home.module.scss'

export default function Home() {
  const dispatch = useDispatch()

  useEffect(() => {
    window.analytics.track('Home')
    window.analytics.page('Home')

    dispatch(fetchStudies())
    dispatch(fetchStories())
    dispatch(fetchRecentFavorites())
  }, [dispatch])

  return (
    <main className={styles.homepage}>
      <HomeSearchHeader />
      <div className="main-content-wrapper">
        <ExploreCallout />
        <HomeStudiesCards />
        <HomeStoriesCards />
        <HomepageFavoritesCards />
      </div>
    </main>
  )
}

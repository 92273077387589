import { isFetching, isUpdating } from './selectors'

const parseClientBranding = data => ({
  defaultCoverPhoto: {
    contentUrl: data.default_cover_photo || null,
    modifiedBy: data.default_cover_photo_modified_by || null,
    modifiedAt: data.default_cover_photo_modified_at || null,
  },
  logo: {
    contentUrl: data.logo || null,
    modifiedBy: data.logo_modified_by || null,
    modifiedAt: data.logo_modified_at || null,
  },
  homepageBackground: {
    contentUrl: data.background || null,
    modifiedBy: data.background_modified_by || null,
    modifiedAt: data.background_modified_at || null,
  },
  loginBackground: {
    contentUrl: data.login_background || null,
    modifiedBy: data.login_background_modified_by || null,
    modifiedAt: data.login_background_modified_at || null,
  },
  pptxTemplate: {
    contentUrl: data.pptx_template || null,
    modifiedBy: data.pptx_template_modified_by || null,
    modifiedAt: data.pptx_template_modified_at || null,
  },
})

export const SET_FETCH_CLIENT_BRANDING_FETCHING = 'SET_FETCH_CLIENT_BRANDING_FETCHING'
const __setFetchClientBrandingFetching = () => ({
  type: SET_FETCH_CLIENT_BRANDING_FETCHING,
})

export const SET_FETCH_CLIENT_BRANDING_ERROR = 'SET_FETCH_CLIENT_BRANDING_ERROR'
const __setFetchClientBrandingError = error => ({
  type: SET_FETCH_CLIENT_BRANDING_FETCHING,
  error,
})

export const SET_FETCH_CLIENT_BRANDING_SUCCESS = 'SET_FETCH_CLIENT_BRANDING_SUCCESS'
const __setFetchClientBrandingSuccess = data => ({
  type: SET_FETCH_CLIENT_BRANDING_SUCCESS,
  data,
})

export const fetchClientBranding =
  () =>
  async (dispatch, getState, { fetch }) => {
    if (isFetching(getState())) return

    dispatch(__setFetchClientBrandingFetching())

    const response = await fetch('/proxy/greyhound/branding/')
    if (!response.ok) {
      dispatch(__setFetchClientBrandingError(true))
      return
    }

    const data = await response.json()
    dispatch(__setFetchClientBrandingSuccess(parseClientBranding(data)))
  }

export const UPDATE_CLIENT_BRANDING_FETCHING = 'UPDATE_CLIENT_BRANDING_FETCHING'
const __updateClientBrandingFetching = () => ({
  type: UPDATE_CLIENT_BRANDING_FETCHING,
})

export const UPDATE_CLIENT_BRANDING_ERROR = 'UPDATE_CLIENT_BRANDING_ERROR'
const __updateClientBrandingError = () => ({
  type: UPDATE_CLIENT_BRANDING_ERROR,
})

export const UPDATE_CLIENT_BRANDING_SUCCESS = 'UPDATE_CLIENT_BRANDING_SUCCESS'
const __updateClientBrandingSuccess = data => ({
  type: UPDATE_CLIENT_BRANDING_SUCCESS,
  data,
})

export const updateClientBranding =
  updates =>
  async (dispatch, getState, { fetch }) => {
    if (isUpdating(getState())) return

    dispatch(__updateClientBrandingFetching())

    let response

    if (updates.formData) {
      response = await fetch('/proxy/greyhound/branding/', {
        method: 'POST',
        body: updates.formData,
      })
    } else {
      response = await fetch('/proxy/greyhound/branding/', {
        method: 'PATCH',
        jsonBody: updates.json,
      })
    }

    if (!response.ok) {
      dispatch(__updateClientBrandingError())
      return
    }

    const data = await response.json()
    dispatch(__updateClientBrandingSuccess(parseClientBranding(data)))
  }

import * as actions from './actions'

const initialState = {
  isFetching: false,
  isUpdating: false,
  error: null,
  data: {
    defaultCoverPhoto: {},
    logo: {},
    homepageBackground: {},
    loginBackground: {},
    pptxTemplate: {},
  },
}

/**
 * Responsible for fetching custom client branding such as default study banners, etc.
 */
export default function clientBrandingReducer(state = initialState, action = {}) {
  switch (action.type) {
    case actions.SET_FETCH_CLIENT_BRANDING_FETCHING: {
      return {
        ...state,
        isFetching: true,
        isUpdating: false,
        error: null,
      }
    }
    case actions.SET_FETCH_CLIENT_BRANDING_ERROR: {
      return {
        ...state,
        isFetching: false,
        error: action.error,
      }
    }
    case actions.SET_FETCH_CLIENT_BRANDING_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        error: null,
        data: action.data,
      }
    }
    case actions.UPDATE_CLIENT_BRANDING_FETCHING: {
      return {
        ...state,
        isFetching: false,
        isUpdating: true,
        error: null,
      }
    }
    case actions.UPDATE_CLIENT_BRANDING_ERROR: {
      return {
        ...state,
        isUpdating: false,
        error: true,
      }
    }
    case actions.UPDATE_CLIENT_BRANDING_SUCCESS: {
      return {
        ...state,
        isUpdating: false,
        data: action.data,
      }
    }
    default:
      return state
  }
}

import { Auth } from '@knowledgehound/analysis'

export default async function getSession() {
  const response = await fetch('/users/session_check/', {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
    credentials: 'same-origin',
  })

  if (!response.ok) {
    const error = new Error(response.statusText)
    error.response = response
    throw error
  }

  const session = await response.json()
  if (typeof session === 'boolean') {
    const error = new Error('Session check returned false')
    error.body = session.body
    throw error
  }

  // TODO: Remove all uses of fetchPublic so this can be removed, includes Analysis!
  // Also remove Analysis' direct JWT accession
  Auth.setDefaultAuthJWT(session.jwt)
  return session
}

// @flow
import { createSelector } from 'reselect'

import type { ErrorT } from './ErrorT'

export type ErrorSelectorT = {
  error: ErrorT,
}
//share.newShare.error
const appError = state => {
  let foundError = null
  //loop through state and see if any errors are present
  Object.keys(state).find(reducerKey => {
    const reducer = state[reducerKey]
    const error = Object.keys(reducer).find(reducerDataKey => {
      if (reducer[reducerDataKey]) {
        return reducer[reducerDataKey].error //see if there is an error
      }
      return false
    })
    if (error) {
      foundError = reducer[error]
    }
    return error
  })

  return foundError
}

export const getError = createSelector([appError], appError => appError)

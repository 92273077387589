import 'core-js/stable'
import React, { useState, useEffect } from 'react'
import { render } from 'react-dom'
import { Provider as ReduxProvider } from 'react-redux'
import { Helmet } from 'react-helmet'
import { Router } from 'react-router-dom'
import { createBrowserHistory } from 'history'
import { IntlProvider } from 'react-intl'
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import ErrorPage from '@knowledgehound/ui-theme/layout/error/ErrorPage'
import { AnalysisSegment, Analysis2Store } from '@knowledgehound/analysis'
import { LoadingSpinner } from '@knowledgehound/laika'
import '@knowledgehound/laika/scss/scaffolding.scss'
import '@progress/kendo-theme-default/dist/all.css'

import App from 'routes/App'
import configureStore from 'store/configure-store'
import getSession from 'data/session/getSession'
import { fetchSessionSuccess } from 'data/session/SessionActions'
import messages from './messages'
import { flattenMessages } from './messageUtils'

import './index.scss'

const brandingBucket = process.env.REACT_APP_KH_CLIENT_BRANDING_BUCKET
const [client] = window.location.hostname.split('.')

const history = createBrowserHistory()

Sentry.init({
  dsn: process.env.SENTRY_CUSTOMER_DSN,
  enabled: process.env.BUILD_TARGET !== 'local',
  integrations: [
    new Integrations.BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
    }),
  ],
  environment: process.env.BUILD_TARGET,
  release: process.env.REACT_APP_GIT_SHA,
  tracesSampleRate: 0.25,
  initialScope: {
    tags: { client },
  },
})

//extract into kh module
const localeDetected =
  (navigator.languages && navigator.languages[0]) ||
  navigator.language ||
  navigator.userLanguage ||
  'en-US'

const messagesData = messages[localeDetected] || messages['en-US']
const locale = messages[localeDetected] ? localeDetected : 'en-US'

window.analytics.ready(() =>
  AnalysisSegment.set({
    segment: window.analytics,
    debug: process.env.BUILD_TARGET === 'local' || process.env.NODE_ENV === 'test',
  })
)

const CustomerApp = () => {
  const [store, setStore] = useState()

  useEffect(() => {
    const checkSession = async () => {
      try {
        const session = await getSession()
        const store = configureStore(session.jwt, history)
        store.dispatch(fetchSessionSuccess(session))
        await store.dispatch(Analysis2Store.config.fetchUserConfig())
        setStore(store)
      } catch (error) {
        console.error(error)
        window.location.assign(
          `/login?next=${encodeURIComponent(window.location.pathname)}${encodeURIComponent(
            window.location.search
          )}${encodeURIComponent(window.location.hash)}`
        )
      }
    }

    if (!store) {
      checkSession()
    }
  }, [store, setStore])

  if (!store) return <LoadingSpinner centered text="One Moment..." />

  return (
    <ReduxProvider store={store}>
      <Sentry.ErrorBoundary
        fallback={
          <ErrorPage
            leadText="Oops!"
            text="There was a problem with this page."
            actionItem={
              <button className="button primary" onClick={() => window.analytics.track('Support')}>
                Contact us
              </button>
            }
          />
        }
      >
        <Helmet>
          <link
            rel="stylesheet"
            type="text/css"
            href={`${brandingBucket}/client-branding/${client}/client-branding.css`}
          />
        </Helmet>
        <IntlProvider locale={locale} messages={flattenMessages(messagesData)}>
          <Router history={history}>
            <App />
          </Router>
        </IntlProvider>
      </Sentry.ErrorBoundary>
    </ReduxProvider>
  )
}

render(<CustomerApp />, document.getElementById('root'))

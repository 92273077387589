import React from 'react'
import { useSelector, shallowEqual } from 'react-redux'

import Card from 'components/Card'
import {
  getFavorites,
  getHasFetchedFavorites,
  getIsFetchingFavorites,
  getFavoritesHasError,
} from 'data/home/selectors'
import { monthWithYear, formatReadableDate } from 'util/dateHelpers'
import imageUri from 'thumbnails/no_favorites.png'
import EmptyRow from './EmptyRow'
import CardRow from './CardRow'
import LoadingCardSkeleton from './LoadingCardSkeleton'

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

function generateCard(favorite) {
  let uriType, date
  if (favorite.itemType === 'story') {
    uriType = 'stories'
    date = favorite.modified ? `Last updated ${formatReadableDate(favorite.modified)}` : undefined
  } else if (favorite.itemType === 'study') {
    uriType = 'studies'
    date = favorite.modified ? monthWithYear(favorite.modified) : undefined
  } else {
    uriType = 'disabled'
    date = undefined
  }

  return (
    <Card
      key={`favorite-${favorite.itemId}`}
      preheader={
        typeof favorite.itemType === 'string'
          ? capitalizeFirstLetter(favorite.itemType)
          : favorite.itemType
      }
      header={favorite.title}
      footer={date}
      linkTo={`/${uriType}/${favorite.itemId}`}
    />
  )
}

export default function HomeFavoritesCards() {
  const favorites = useSelector(getFavorites, shallowEqual)
  const isFetching = useSelector(getIsFetchingFavorites, shallowEqual)
  const hasFetched = useSelector(getHasFetchedFavorites, shallowEqual)
  const hasError = useSelector(getFavoritesHasError, shallowEqual)
  const isEmpty = !favorites.length

  return (
    <CardRow
      showViewAll={!isEmpty}
      sectionMessageId="homepage.sections.favorites"
      viewAllMessageId="homepage.sections.viewAllFavorites"
      linkTo="/favorites"
    >
      {hasError && (
        <EmptyRow imgUri={imageUri} altText="Problem fetching favorites">
          There was a problem loading your favorites. Please refresh and try again.
        </EmptyRow>
      )}
      {isFetching && <LoadingCardSkeleton />}
      {hasFetched && isEmpty ? (
        <EmptyRow imgUri={imageUri} altText="no public favorites">
          No favorites yet.
        </EmptyRow>
      ) : (
        <React.Fragment>{favorites.map(f => generateCard(f))}</React.Fragment>
      )}
    </CardRow>
  )
}

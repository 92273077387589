// @flow
import React, { Component } from 'react'
import IframeComm from 'react-iframe-comm'
import { withRouter } from 'react-router'
import type { Match, RouterHistory, Location } from 'react-router-dom'
import queryString from 'query-string'

import './ViewerFrame.scss'

type PropsT = {
  viewUrl: null | string,
  viewPage: null | string,
  onPageChanged: Function,
  onClose: Function,
  match: Match,
  history: RouterHistory,
  location: Location,
}

export default withRouter(
  class ViewerFrame extends Component<PropsT> {
    handleViewerMessage = evt => {
      if (evt.origin === window.location.protocol + '//' + window.location.host) {
        if (evt.data && evt.data.type === 'viewer.close') {
          this.props.onClose()
          if (evt.data && evt.data.data && evt.data.data.action === 'support') {
            if (window.Intercom) window.Intercom('show')
          }
        }
        if (evt.data && evt.data.type === 'viewer.accessRestrictedHome') {
          window.location.assign('/home')
        }
        if (evt.data && evt.data.type === 'viewer.pageChanged') {
          this.props.onPageChanged(evt.data.data.page)
        }
        if (evt.data && evt.data.type === 'story.addDocumentToStory') {
          this.props.onClose()
          window.location.assign(`/stories/${evt.data.data.storyId}`)
        }
        if (evt.data && evt.data.type === 'study.addDocumentToStudy') {
          this.props.onClose()
          window.location.assign(`/studies/${evt.data.data.studyId}`)
        }
      }
    }

    componentDidUpdate(prevProps, prevState) {
      if (prevProps.viewUrl && !this.props.viewUrl) {
        document && document.body && document.body.classList.remove('viewerOpen')
      }

      if (!prevProps.viewUrl && this.props.viewUrl) {
        document && document.body && document.body.classList.add('viewerOpen')
      }
    }

    shouldComponentUpdate(nextProps) {
      return nextProps.viewUrl !== this.props.viewUrl
    }

    render() {
      const { viewUrl, viewPage, location } = this.props

      const search = queryString.parse(location.search)

      const { query } = search

      const origin = `${window.location.protocol}//${window.location.host}`

      let params = {
        view: viewUrl,
        page: viewPage,
      }

      if (query) {
        params = {
          ...params,
          query,
        }
      }

      if (viewUrl) {
        const attributes = {
          src: `${origin}/document-viewer/index.html?${queryString.stringify(params)}`,
          className: 'Viewer__iframe',
          width: '100%',
          height: '100%',
          frameBorder: 0,
        }

        return (
          <IframeComm
            attributes={attributes}
            handleReceiveMessage={this.handleViewerMessage}
            targetOrigin={window.location.protocol + '//' + window.location.host}
          />
        )
      }

      return null
    }
  }
)

import React from 'react'
import { Redirect } from 'react-router-dom'

import StudyHome from './studies/home'
import Home from './home/Home'
import NotFound from './NotFound'

const LazyStudyDash = React.lazy(() =>
  import(
    /* webpackChunkName: "studyDash" */
    './studies/studyDetail/Dashboard'
  )
)

const routes = [
  {
    path: '/home',
    component: Home,
  },
  {
    path: '/studies/:studyNumber',
    render: props => <LazyStudyDash {...props} key={props.match.params.studyNumber} />,
  },
  {
    path: '/studies',
    component: StudyHome,
  },
  {
    path: '/analysis/:studyNumber/:datasetName/:questionName',
    component: React.lazy(() =>
      import(
        /* webpackChunkName: "a1" */
        './analysis/A1ToA2Redirect'
      )
    ),
  },
  {
    path: '/open-analysis/:studyNumber/:datasetName/:questionName',
    component: React.lazy(() =>
      import(
        /* webpackChunkName: "openAnalysis" */
        './open-analysis/OpenAnalysis'
      )
    ),
  },
  {
    path: '/dataset/:pageType/:studyNumber/:datasetName/:questionName?',
    component: React.lazy(() =>
      import(
        /* webpackChunkName: "dataset" */
        './dataset/Dataset'
      )
    ),
  },
  {
    path: '/integrations',
    component: () => <Redirect to={{ pathname: '/settings/integrations' }} />,
  },
  {
    path: '/favorites',
    component: React.lazy(() =>
      import(
        /* webpackChunkName: "favorites" */
        './favorites/Favorites'
      )
    ),
  },
  {
    path: '/settings',
    component: React.lazy(() =>
      import(
        /* webpackChunkName: "settings" */
        './settings/Settings'
      )
    ),
  },
  {
    path: '/results',
    component: React.lazy(() =>
      import(
        /* webpackChunkName: "searchResults" */
        './results/SearchResults'
      )
    ),
  },
  {
    path: '/profile/:tab',
    component: args => {
      const { tab } = args.match.params
      return <Redirect to={{ pathname: `/settings/${tab}` }} />
    },
  },
  {
    path: '/profile',
    component: () => <Redirect to={{ pathname: '/settings/info' }} />,
  },
  {
    path: '/stories/new',
    component: React.lazy(() =>
      import(
        /* webpackChunkName: "storyCreate" */
        './stories/StoryCreate'
      )
    ),
  },
  {
    path: '/stories/:storyId',
    component: React.lazy(() =>
      import(
        /* webpackChunkName: "storyDetails" */
        './stories/StoryDetails'
      )
    ),
  },
  {
    path: '/stories',
    component: React.lazy(() =>
      import(
        /* webpackChunkName: "storyHome" */
        './stories/StoryHome'
      )
    ),
  },
  {
    path: '/download',
    component: React.lazy(() =>
      import(
        /* webpackChunkName: "download" */
        './download/Download'
      )
    ),
  },
  {
    component: NotFound,
  },
]

export default routes

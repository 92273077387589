import * as actions from './BulkAnalysisActions'

export const defaultReducerState = {
  bulkAnalysisList: {
    filterText: '',
    isFetching: false,
    error: null,
    data: [],
    lastFetched: null,
    isExporting: false,
  },
  createBulkAnalysis: {
    isFetching: false,
    error: null,
    reportBuilderActive: false,
    previewingOldReport: false,
    oldReportName: null, // used for tracking
    reportMissingData: false,
    isFilterModalOpen: false,
    isNetsModalOpen: false,
    reportName: '',
    autoName: true,
    variableFilterText: '',
    dragCount: 0,
    dragAxis: null,
    dragVariable: null,
    // [{questionName, label, variableNk, idx, suggested, isGrid, resourceType}, ...]
    selectedVariables: [],
    lastSelectedIdx: null,
    mainVariables: [],
    segmentationVariables: [],
    axisVariables: [],
    previewVariable: '', // variableNk
    previewModalOpen: false,
    chartLoadingOverride: false,
    options: {
      viewSettings: {
        showStatTesting: true,
        statsConfidence: [95],
      },
    },
  },
  fetchExistingReport: {
    reportVars: {
      main: [],
      segmentation: [],
    },
  },
}

export const bulkAnalysisReducer = (state = defaultReducerState, action) => {
  switch (action.type) {
    case actions.fetchBulkAnalysisListAction.loading.type: {
      return {
        ...state,
        bulkAnalysisList: {
          ...state.bulkAnalysisList,
          isFetching: true,
          error: defaultReducerState.bulkAnalysisList.error,
        },
      }
    }
    case actions.fetchBulkAnalysisListAction.success.type: {
      const lastFetched = Date.now()
      return {
        ...state,
        bulkAnalysisList: {
          ...state.bulkAnalysisList,
          isFetching: false,
          lastFetched,
          data: action.payload,
          error: defaultReducerState.bulkAnalysisList.error,
        },
      }
    }
    case actions.fetchBulkAnalysisListAction.error.type: {
      const lastFetched = Date.now()
      return {
        ...state,
        bulkAnalysisList: {
          ...state.bulkAnalysisList,
          isFetching: false,
          lastFetched,
          error: action.payload,
          data: defaultReducerState.bulkAnalysisList.data,
        },
      }
    }
    case actions.CLEAR_REPORT_LIST: {
      return {
        ...state,
        bulkAnalysisList: defaultReducerState.bulkAnalysisList,
      }
    }
    case actions.SET_BULK_ANALYSIS_FILTER: {
      return {
        ...state,
        bulkAnalysisList: {
          ...state.bulkAnalysisList,
          filterText: action.payload.filterText,
        },
      }
    }
    case actions.EXPORT_REPORT_LOADING: {
      return {
        ...state,
        bulkAnalysisList: {
          ...state.bulkAnalysisList,
          isExporting: action.payload.isExporting,
        },
      }
    }
    case actions.UPDATE_REPORT: {
      return {
        ...state,
        bulkAnalysisList: {
          ...state.bulkAnalysisList,
          data: state.bulkAnalysisList.data.map(report => {
            if (report.id === action.payload.report.id) {
              return action.payload.report
            }
            return report
          }),
        },
      }
    }
    case actions.DELETE_REPORT: {
      return {
        ...state,
        bulkAnalysisList: {
          ...state.bulkAnalysisList,
          data: state.bulkAnalysisList.data.filter(report => report.id !== action.payload.reportId),
        },
      }
    }

    case actions.SET_REPORT_BUILDER_ACTIVE: {
      return {
        ...state,
        createBulkAnalysis: {
          ...state.createBulkAnalysis,
          reportBuilderActive: action.payload.isActive,
          reportName: action.payload.isActive
            ? `New Report ${new Date(Date.now()).toLocaleString()}`
            : '',
        },
      }
    }
    case actions.CLEAR_REPORT_BUILDER: {
      return {
        ...state,
        createBulkAnalysis: {
          ...defaultReducerState.createBulkAnalysis,
          reportBuilderActive: state.createBulkAnalysis.reportBuilderActive,
        },
      }
    }
    case actions.SET_BA_FILTER_MODAL: {
      return {
        ...state,
        createBulkAnalysis: {
          ...state.createBulkAnalysis,
          isFilterModalOpen: action.payload,
        },
      }
    }
    case actions.SET_BA_NETS_MODAL: {
      return {
        ...state,
        createBulkAnalysis: {
          ...state.createBulkAnalysis,
          isNetsModalOpen: action.payload,
        },
      }
    }
    case actions.SET_CREATE_REPORT_NAME: {
      return {
        ...state,
        createBulkAnalysis: {
          ...state.createBulkAnalysis,
          reportName: action.payload.reportName,
        },
      }
    }
    case actions.SET_AUTO_NAME: {
      return {
        ...state,
        createBulkAnalysis: {
          ...state.createBulkAnalysis,
          autoName: action.payload.autoName,
        },
      }
    }
    case actions.SET_BA_VARIABLE_FILTER: {
      return {
        ...state,
        createBulkAnalysis: {
          ...state.createBulkAnalysis,
          variableFilterText: action.payload.filterText,
        },
      }
    }
    case actions.SET_DRAG_COUNT: {
      return {
        ...state,
        createBulkAnalysis: {
          ...state.createBulkAnalysis,
          dragCount: action.payload.dragCount,
        },
      }
    }
    case actions.SET_DRAG_AXIS: {
      return {
        ...state,
        createBulkAnalysis: {
          ...state.createBulkAnalysis,
          dragAxis: action.payload.dragAxis,
        },
      }
    }
    case actions.SET_DRAG_VAR: {
      return {
        ...state,
        createBulkAnalysis: {
          ...state.createBulkAnalysis,
          dragVariable: action.payload.dragVar,
        },
      }
    }
    case actions.SET_SELECTED_VARIABLES: {
      return {
        ...state,
        createBulkAnalysis: {
          ...state.createBulkAnalysis,
          selectedVariables: action.payload.selectedVariables,
        },
      }
    }
    case actions.SET_LAST_IDX: {
      return {
        ...state,
        createBulkAnalysis: {
          ...state.createBulkAnalysis,
          lastSelectedIdx: action.payload.lastIdx,
        },
      }
    }
    case actions.SET_CREATE_BA_LOADING: {
      return {
        ...state,
        createBulkAnalysis: {
          ...state.createBulkAnalysis,
          isFetching: action.payload.isFetching,
        },
      }
    }
    case actions.SET_BA_VARIABLES: {
      return {
        ...state,
        createBulkAnalysis: {
          ...state.createBulkAnalysis,
          mainVariables: action.payload.mainList,
          segmentationVariables: action.payload.segmentationList,
        },
      }
    }
    case actions.SET_BA_AXIS_VARIABLES: {
      return {
        ...state,
        createBulkAnalysis: {
          ...state.createBulkAnalysis,
          axisVariables: action.payload.axisVars,
        },
      }
    }
    case actions.SET_PREVIEW_VARIABLE: {
      return {
        ...state,
        createBulkAnalysis: {
          ...state.createBulkAnalysis,
          previewVariable: action.payload.variableNk,
        },
      }
    }
    case actions.SET_PREVIEW_MODAL: {
      return {
        ...state,
        createBulkAnalysis: {
          ...state.createBulkAnalysis,
          previewModalOpen: action.payload,
        },
      }
    }
    case actions.SET_CHART_LOADING: {
      return {
        ...state,
        createBulkAnalysis: {
          ...state.createBulkAnalysis,
          chartLoadingOverride: action.payload,
        },
      }
    }
    case actions.SET_BA_STAT_TESTING_CONFIDENCE: {
      return {
        ...state,
        createBulkAnalysis: {
          ...state.createBulkAnalysis,
          options: {
            ...state.createBulkAnalysis.options,
            viewSettings: {
              ...state.createBulkAnalysis.options.viewSettings,
              showStatTesting: action.payload.showStatTesting,
              statsConfidence: action.payload.confidence,
            },
          },
        },
      }
    }
    case actions.generateBAReportAction.loading.type: {
      return {
        ...state,
        createBulkAnalysis: {
          ...state.createBulkAnalysis,
          isFetching: true,
          error: defaultReducerState.createBulkAnalysis.error,
        },
      }
    }
    case actions.generateBAReportAction.success.type: {
      return {
        ...state,
        createBulkAnalysis: defaultReducerState.createBulkAnalysis,
        bulkAnalysisList: {
          ...state.bulkAnalysisList,
          data: [...state.bulkAnalysisList.data, action.payload],
        },
      }
    }
    case actions.generateBAReportAction.error.type: {
      return {
        ...state,
        createBulkAnalysis: {
          ...state.createBulkAnalysis,
          isFetching: false,
          error: action.payload,
        },
      }
    }
    case actions.BA_SET_EXISTING_REPORT_FETCHING: {
      return {
        ...state,
        createBulkAnalysis: {
          ...state.createBulkAnalysis,
          previewingOldReport: false,
          oldReportName: null,
        },
        fetchExistingReport: {
          ...state.fetchExistingReport,
          reportVars: null,
          [action.reportId]: {
            isFetching: true,
            hasError: false,
          },
        },
      }
    }
    case actions.BA_SET_EXISTING_REPORT_ERROR: {
      return {
        ...state,
        createBulkAnalysis: {
          ...state.createBulkAnalysis,
          previewingOldReport: false,
          oldReportName: null,
        },
        fetchExistingReport: {
          ...state.fetchExistingReport,
          reportVars: null,
          [action.reportId]: {
            isFetching: false,
            hasError: true,
          },
        },
      }
    }
    case actions.BA_SET_EXISTING_REPORT_SUCCESS: {
      return {
        ...state,
        createBulkAnalysis: {
          ...state.createBulkAnalysis,
          previewingOldReport: true,
        },
        fetchExistingReport: {
          ...state.fetchExistingReport,
          reportVars: action.reportVars,
          [action.reportId]: {
            isFetching: false,
            hasError: false,
          },
        },
      }
    }
    case actions.BA_START_REPORT_FROM_OLD_REPORT: {
      return {
        ...state,
        createBulkAnalysis: {
          ...state.createBulkAnalysis,
          previewingOldReport: false,
          oldReportName: action.oldReportName,
        },
      }
    }
    case actions.BA_REHYDRATED_REPORT_MISSING_DATA: {
      return {
        ...state,
        createBulkAnalysis: {
          ...state.createBulkAnalysis,
          reportMissingData: true,
        },
      }
    }
    default:
      return state
  }
}

import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'

import * as styles from './BodyLayout.module.scss'

const propTypes = {
  /** Header that appears above the main content */
  title: PropTypes.string.isRequired,
  /** Main body content */
  children: PropTypes.node.isRequired,
  /** Use Helmet to style HTML body background color. Must enter valid CSS color. */
  background: PropTypes.string,
  /** Optional, defaults true. Hides the header above content. title attr is still used for <title> */
  showHeader: PropTypes.bool,
}

/** High-level page layout for routes */
function BodyLayout({ title, children, background = null, showHeader = true }: PropsT) {
  return (
    <div className={styles.layout}>
      <Helmet>
        <title>{title}</title>
        {background && <style>{`body { background-color: ${background};}`}</style>}
      </Helmet>
      {showHeader && <header className={styles.header}>{title}</header>}
      <main>{children}</main>
    </div>
  )
}

BodyLayout.propTypes = propTypes

export default BodyLayout

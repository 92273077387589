import * as actions from './actions'

const initialState = {
  currentDomain: {
    isFetching: false,
    error: null,
    data: null,
    lastFetched: null,
  },
}

export default function domainsReducer(state = initialState, action = {}) {
  switch (action.type) {
    case actions.FETCH_DOMAIN_LOADING: {
      return {
        ...state,
        currentDomain: {
          ...state.currentDomain,
          isFetching: true,
          error: null,
        },
      }
    }
    case actions.FETCH_DOMAIN_SUCCESS: {
      const lastFetched = Date.now()
      return {
        ...state,
        currentDomain: {
          ...state.currentDomain,
          isFetching: false,
          lastFetched,
          data: action.payload,
          error: null,
        },
      }
    }
    case actions.FETCH_DOMAIN_ERROR: {
      const lastFetched = Date.now()
      return {
        ...state,
        currentDomain: {
          ...state.currentDomain,
          isFetching: false,
          lastFetched,
          error: action.payload,
        },
      }
    }
    default:
      return state
  }
}

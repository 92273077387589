import React, { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { throttle } from 'lodash'
import { SearchInput, Icon, DropdownButton, Tooltip, useCookie } from '@knowledgehound/laika'

import { setQueryString } from 'data/results/actions'
import { getClientLogo } from 'data/clientBranding/selectors'
import { setShowToolTip } from 'data/personalization/actions'
import {
  getShowPersonalizationToolTip,
  getUserHasPreferences,
} from 'data/personalization/selectors'
import StudyCreateModal from './StudyCreateModal'
import * as styles from './MainNavbar.module.scss'

const propTypes = {
  user: PropTypes.shape({
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    isAdmin: PropTypes.bool,
  }),
  homepageUrl: PropTypes.string,
  onClickLogout: PropTypes.func,
}

const MainNavbar = ({ user, homepageUrl, onClickLogout }) => {
  const session = useSelector(state => state.session.currentSession)
  const clientLogo = useSelector(getClientLogo, shallowEqual)
  const bannerMsg = session.data && session.data.client_banner ? session.data.client_banner : null
  const userName = user?.first_name.trim().charAt(0) + user?.last_name.trim().charAt(0) || 'you'
  const { pathname, search } = useLocation()
  const path = pathname.substring(1)
  const dispatch = useDispatch()
  const history = useHistory()
  const params = new URLSearchParams(search)
  const query = params.get('query')
  const [searchQuery, setSearchQuery] = useState('')
  const isAdmin = Boolean(user && user.isAdmin)
  const menuItemRef = useRef()
  const [excludeMenuText, setExcludeMenuText] = useState(false)
  const [wideScreen, setWideScreen] = useState(false)
  const showPersonalizationToolTip = useSelector(getShowPersonalizationToolTip, shallowEqual)
  const userHasPreferences = useSelector(getUserHasPreferences, shallowEqual)
  const [hasSeenPersonalizationTooltip, setHasSeenPersonalizationTooltip] =
    useCookie('prefs-tooltip-seen')

  useEffect(() => {
    if (pathname.includes('/results')) {
      setSearchQuery(query)
    }
  }, [pathname]) // eslint-disable-line react-hooks/exhaustive-deps -- Only apply on route transitions

  useEffect(() => {
    if (menuItemRef.current.clientWidth > 1750) {
      setWideScreen(true)
    }
  }, [menuItemRef, setWideScreen])

  useEffect(() => {
    const threshold = path === 'home' || !bannerMsg ? 1000 : 1200

    const observer = throttle(
      () => setExcludeMenuText(menuItemRef.current.clientWidth < threshold),
      8
    )
    observer()
    window.addEventListener('resize', observer)
    return () => window.removeEventListener('resize', observer)
  }, [bannerMsg, path, menuItemRef, setExcludeMenuText])

  const handleChangeSearch = event => {
    setSearchQuery(event.currentTarget.value)
  }

  const handleDismissTooltip = () => {
    dispatch(setShowToolTip(false))
    setHasSeenPersonalizationTooltip(true)
  }

  const handleSubmitSearch = event => {
    event.preventDefault()
    dispatch(setQueryString(searchQuery))
    history.push(`/results?query=${encodeURIComponent(searchQuery)}`)
  }

  const shouldRenderSearchBar =
    session.data && !session.data.hide_search_enabled
      ? pathname && !pathname.startsWith('/home')
      : false

  const userMenuItems = [
    <Link to="/settings/info">Personal Settings</Link>,
    <Link to="/settings/integrations">Integrations</Link>,
    <a href="http://support.knowledgehound.com/en/" target="_blank" rel="noopener noreferrer">
      Support
    </a>,
    <a
      href="https://www.knowledgehound.com/privacy-policy"
      target="_blank"
      rel="noopener noreferrer"
    >
      Privacy Policy
    </a>,
    <a onClick={onClickLogout} href="/logout">
      Log Out
    </a>,
  ]

  return (
    <nav ref={menuItemRef} className={styles.mainNavbar}>
      <div
        className={
          path === 'home' || !bannerMsg || wideScreen
            ? styles.rightCondensed
            : styles.logoSearchBanner
        }
      >
        <a href={homepageUrl} className={styles.logoSmall}>
          <img className={styles.navLogoImg} src={clientLogo} alt="company logo" />
        </a>
        {shouldRenderSearchBar && (
          <form onSubmit={handleSubmitSearch} name="search" action=".">
            <SearchInput
              size="navigation"
              value={searchQuery}
              onChange={handleChangeSearch}
              placeholder="Search and discover insights"
            />
          </form>
        )}
        {bannerMsg && <div className={styles.infoMsg}>{bannerMsg}</div>}
      </div>
      <div className={!excludeMenuText ? styles.menuItems : styles.menuItemsSmall}>
        {(isAdmin || !(session.data && session.data.hide_study_library_enabled)) && (
          <Link to="/studies" className={path === 'studies' ? styles.active : styles.hoverPath}>
            {!excludeMenuText ? (
              <div className={styles.menuItem}>
                <Icon icon="study" color="inherit" size="small" />
                Studies
              </div>
            ) : (
              <Tooltip placement="bottom" content="Studies">
                <div className={styles.menuIcon}>
                  <Icon icon="study" color="inherit" size="medium" />
                </div>
              </Tooltip>
            )}
          </Link>
        )}
        {session.data && session.data.stories_enabled && (
          <Link to="/stories" className={path === 'stories' ? styles.active : styles.hoverPath}>
            {!excludeMenuText ? (
              <div className={styles.menuItem}>
                <Icon icon="summary" color="inherit" size="small" />
                Stories
              </div>
            ) : (
              <Tooltip placement="bottom" content="Stories">
                <div className={styles.menuIcon}>
                  <Icon icon="summary" color="inherit" size="medium" />
                </div>
              </Tooltip>
            )}
          </Link>
        )}
        {session.data && (
          <Link to="/favorites" className={path === 'favorites' ? styles.active : styles.hoverPath}>
            {!excludeMenuText ? (
              <div className={styles.menuItem}>
                <Icon icon="favorite" color="inherit" size="small" />
                Favorites
              </div>
            ) : (
              <Tooltip placement="bottom" content="Favorites">
                <div className={styles.menuIcon}>
                  <Icon icon="favorite" color="inherit" size="medium" />
                </div>
              </Tooltip>
            )}
          </Link>
        )}
        <div>
          <StudyCreateModal />
        </div>
        <Tooltip
          placement="bottom"
          content={
            userHasPreferences
              ? 'You can always edit your preferences by visiting your personal settings page.'
              : 'You can always personalize your experience by visiting your personal settings page.'
          }
          isOpen={showPersonalizationToolTip && !hasSeenPersonalizationTooltip}
          variant="dismissible"
          onDismiss={handleDismissTooltip}
        >
          <DropdownButton
            icon="profile"
            text={userName}
            size="large"
            type="textualDark"
            leftAlignDropdown
          >
            {userMenuItems.map((item, index) => (
              <DropdownButton.MenuItem key={index}>{item}</DropdownButton.MenuItem>
            ))}
          </DropdownButton>
        </Tooltip>
      </div>
    </nav>
  )
}

MainNavbar.propTypes = propTypes

export default MainNavbar

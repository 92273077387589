import { createSelector } from 'reselect'
import { isEmpty } from 'lodash'

export const getStudyId = state => state.summaryReport.studyId

export const getReportId = state => state.summaryReport.reportId

export const getPollCancelCallback = state => state.summaryReport.pollCancelCallback

export const isPolling = state => Boolean(getPollCancelCallback(state))

export const getError = state => state.summaryReport.error

export const getItems = state => state.summaryReport.items

export const getGlobalFilters = state => state.summaryReport.globalFilters

export const getFlatSummaryItems = createSelector(getItems, items => {
  if (isEmpty(items)) return []

  return Object.values(items).reduce((acc, item) => {
    acc[item.position] = {
      ...item,
      state: (() => {
        if (item.state === 'complete' && !isEmpty(item.analysisData)) return 'fetched'
        if (item.state === 'complete' && isEmpty(item.analysisData) && isEmpty(item.errorData))
          return 'fetching'
        if (item.state === 'complete' && !isEmpty(item.errorData)) return 'error'
        return 'fetching'
      })(),
    }
    return acc
  }, new Array(items.length))
})

export const getExportPPTX = state =>
  isPolling(state) ? null : state.summaryReport.exports.pptxUrl

export const getSearchValue = state => state.summaryReport.searchValue

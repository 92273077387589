import { batch } from 'react-redux'
import * as Sentry from '@sentry/react'
import { Analysis2Store } from '@knowledgehound/analysis'

import * as unifiedFilterActions from 'data/filters/unified/actions'
import { getUnifiedFilters } from 'data/filters/unified/selectors'
import * as sharedFilterActions from 'data/filters/shared/actions'
import { getFilteringQueryParam } from 'data/filters/shared/selectors'
import { getStoryFilterQueryParams } from 'data/results/selectors'
import {
  getSearchQuery,
  getStudyPagination,
  getStoryPagination,
  getQuestionPagination,
  getDocumentPagination,
  isFetchingStudies,
  isFetchingStories,
  isFetchingDocuments,
  isFetchingQuestions,
  getAIVendors,
} from './selectors'

const PAGE_SIZE_INCREASE = 25

export const SET_QUERY_STRING = 'SET_QUERY_STRING'
export const setQueryString = query => ({
  type: SET_QUERY_STRING,
  query,
})

export const SET_ITEM_FETCH_LOADING = 'SET_ITEM_FETCH_LOADING'
const __setItemLoading = (itemType, isFetching) => ({
  type: SET_ITEM_FETCH_LOADING,
  itemType,
  isFetching,
})

export const SET_ITEM_FETCH_ERROR = 'SET_ITEM_FETCH_ERROR'
const __setItemFetchError = (itemType, hasError) => ({
  type: SET_ITEM_FETCH_ERROR,
  itemType,
  hasError,
})

export const SET_ITEM_FETCH_SUCCESS = 'SET_ITEM_FETCH_SUCCESS'
const __setItemFetchSuccess = (itemType, data, currentPage) => {
  const cleanedResults = data.results.map(result => ({
    ...result,
    explanation: Object.entries(result.explanation ?? {}).reduce((acc, [key, value]) => {
      // Husky's explanation field returns "null" keys for things matched based on
      // filters, i.e. user sets an FMD filter and it comes back in explanations
      if (value.null) {
        delete value.null
      }
      acc[key] = value
      return acc
    }, {}),
  }))

  return {
    type: SET_ITEM_FETCH_SUCCESS,
    itemType,
    data: {
      ...data,
      results: cleanedResults,
    },
    currentPage,
  }
}

export const SET_ITEM_PAGE_SIZE = 'SET_ITEM_PAGE_SIZE'
const __setItemPageSize = (itemType, pageSize) => ({
  type: SET_ITEM_PAGE_SIZE,
  itemType,
  pageSize,
})

// abort controllers must be defined as a let as it is overwritten
let studyAbortController, storyAbortController, documentAbortController, questionAbortController

export const setStudyFilterSelection = (fieldKey, key, query, value) => dispatch => {
  dispatch(unifiedFilterActions.setFilterSelection(fieldKey, key, value))
  dispatch(fetchStudies(false))
  dispatch(fetchQuestions())
  dispatch(fetchDocuments())
}

export const clearStudyFilters =
  (shouldFetch = true) =>
  dispatch => {
    if (shouldFetch) {
      dispatch(fetchStudies(true))
      dispatch(fetchQuestions())
      dispatch(fetchDocuments())
    }
  }

const hydrateStoryFilterParam =
  () =>
  (dispatch, getState, { history }) => {
    const params = new URLSearchParams(history.location.search)
    const storyFilters = params.get('storyFilters')

    if (!storyFilters) return

    try {
      const parsedFilters = JSON.parse(decodeURIComponent(storyFilters))

      const recursivelySetStoryFilter = (key, val) => {
        if (Array.isArray(val)) {
          val.forEach(e => recursivelySetStoryFilter(key, e))
        } else {
          dispatch(__setStoryFilterSelection(key, val, true))
        }
      }

      batch(() => {
        Object.keys(parsedFilters).forEach(filterKey => {
          const val = parsedFilters[filterKey]
          recursivelySetStoryFilter(filterKey, val)
        })
      })
    } catch (error) {
      // bad query param. Results page reads for the error and resets the filters.
      console.warn('Could not parse given story filter query', error)
    }
  }

export const INIT_STORY_FILTERS_LOADING = 'INIT_STORY_FILTERS_LOADING'
const initStoryFiltersLoading = () => ({ type: INIT_STORY_FILTERS_LOADING })

export const INIT_STORY_FILTERS_SUCCESS = 'INIT_STORY_FILTERS_SUCCESS'
const initStoryFiltersSuccess = aggregations => ({
  type: INIT_STORY_FILTERS_SUCCESS,
  initialStoryFilterAggregations: aggregations,
})
export const INIT_STORY_FILTERS_ERROR = 'INIT_STORY_FILTERS_ERROR'
const initStoryFiltersError = () => ({ type: INIT_STORY_FILTERS_ERROR })

/**
  In order to apply filters, we must first setup the aggregated list of available
  filters for the current query. If we consider that an filter may be applied via URL
  as we enter the page, we must have a setup step to initialize filters before making any fetches.

  Currently, only way we can do this is via making calls to husky with a query.
  In the future, we hope for an endpoint where we can get aggregations without the study/story results.
*/
export const setupStoryFilterState =
  () =>
  async (dispatch, getState, { fetch }) => {
    const searchQuery = getSearchQuery(getState())
    if (!searchQuery) return

    dispatch(initStoryFiltersLoading())

    try {
      const storiesRes = await fetch('/proxy/husky/stories/?page=1&page_size=1')

      if (storiesRes.ok) {
        const stories = await storiesRes.json()
        batch(() => {
          dispatch(initStoryFiltersSuccess(stories.aggregations))
          dispatch(__initializeStoryFilters(stories.aggregations))
          dispatch(hydrateStoryFilterParam())
        })
      } else {
        dispatch(initStoryFiltersError())
      }
    } catch (error) {
      console.error(error)
      Sentry.captureException(error)
      batch(() => {
        dispatch(initStoryFiltersError())
        dispatch(__initializeStoryFilters([]))
      })
    }
  }

const setupFetch = (dispatch, getState, type, shouldReset, shouldLoad, getPage) => {
  const searchQuery = getSearchQuery(getState())

  if (shouldLoad) dispatch(__setItemLoading(type, true))

  const { currentPage, pageSize } = getPage ? getPage(getState()) : { currentPage: 0, pageSize: 0 }

  const filterParams =
    type === 'stories'
      ? getStoryFilterQueryParams(getState())
      : getFilteringQueryParam(getUnifiedFilters(getState()))
  const filters = filterParams && filterParams.length ? `&filters=${filterParams}` : ''

  const header = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
    credentials: 'same-origin',
  }

  return {
    searchQuery: encodeURIComponent(searchQuery),
    filters,
    header,
    currentPage,
    pageSize,
  }
}

const handleResults = async (dispatch, res, currentPage = null, dataType, onSuccess = () => {}) => {
  if (res.ok) {
    const data = await res.json()

    currentPage
      ? dispatch(__setItemFetchSuccess(dataType, data, currentPage))
      : dispatch(__setItemFetchSuccess(dataType, data))
    onSuccess(data)
  } else {
    dispatch(__setItemFetchError(dataType, true))
  }
}

export const fetchStudies =
  (reset = false, noLoad = false) =>
  async (dispatch, getState, { fetch }) => {
    const { searchQuery, filters, header, currentPage, pageSize } = setupFetch(
      dispatch,
      getState,
      'studies',
      reset,
      !noLoad,
      getStudyPagination
    )
    if (!searchQuery) return

    try {
      studyAbortController =
        typeof studyAbortController !== undefined && new window.AbortController()

      header.signal = studyAbortController.signal

      const res = await fetch(
        `/proxy/husky/studies/?page=${currentPage}&page_size=${pageSize}&query=${searchQuery}${filters}`,
        header
      )

      const onSuccess = reset
        ? () => {}
        : data => dispatch(unifiedFilterActions.updateAvailableFilters(data.aggregations))

      handleResults(dispatch, res, currentPage, 'studies', onSuccess)
    } catch (error) {
      console.error(error)
      if (error.name !== 'AbortError') {
        dispatch(__setItemFetchError('studies', true))
        throw new Error(error)
      }
    }
  }

export const setStudyPageSize = query => async (dispatch, getState) => {
  const { pageSize } = getStudyPagination(getState())
  dispatch(__setItemPageSize('studies', pageSize + PAGE_SIZE_INCREASE))
  dispatch(fetchStudies(false, true))
}

export const RESULTS_INITIALIZE_STORY_FILTERS = 'RESULTS_INITIALIZE_STORY_FILTERS'
const __initializeStoryFilters = sharedFilterActions.createInitializeFiltersAction(
  RESULTS_INITIALIZE_STORY_FILTERS,
  'storyFilters'
)

export const RESULTS_UPDATE_AVAILABLE_STORY_FILTERS = 'RESULTS_UPDATE_AVAILABLE_STORY_FILTERS'
const __updateAvailableStoryFilters = sharedFilterActions.createUpdateAvailableFiltersAction(
  RESULTS_UPDATE_AVAILABLE_STORY_FILTERS,
  'storyFilters'
)

export const RESULTS_SET_STORY_FILTER_SELECTION = 'RESULTS_SET_STORY_FILTER_SELECTION'
const __setStoryFilterSelection = sharedFilterActions.createSetFilterSelectionAction(
  RESULTS_SET_STORY_FILTER_SELECTION,
  'storyFilters'
)

export const RESULTS_CLEAR_STORY_FILTERS = 'RESULTS_CLEAR_STORY_FILTERS'
const __clearStoryFilters = sharedFilterActions.createClearFiltersAction(
  RESULTS_CLEAR_STORY_FILTERS,
  'storyFilters'
)

export const fetchStories =
  (reset = false, noLoad = false) =>
  async (dispatch, getState, { fetch }) => {
    const { searchQuery, filters, header, currentPage, pageSize } = setupFetch(
      dispatch,
      getState,
      'stories',
      reset,
      !noLoad,
      getStoryPagination
    )
    if (!searchQuery) return

    try {
      storyAbortController =
        typeof storyAbortController !== undefined && new window.AbortController()

      header.signal = storyAbortController.signal

      const res = await fetch(
        `/proxy/husky/stories/?page=${currentPage}&page_size=${pageSize}&query=${searchQuery}${filters}`,
        header
      )

      const onSuccess = reset
        ? data => {
            batch(() => {
              dispatch(initStoryFiltersSuccess(data.aggregations))
              dispatch(__initializeStoryFilters(data.aggregations))
            })
          }
        : data => dispatch(__updateAvailableStoryFilters(data.aggregations))

      handleResults(dispatch, res, currentPage, 'stories', onSuccess)
    } catch (error) {
      console.error(error)
      if (error.name !== 'AbortError') {
        dispatch(__setItemFetchError('stories', true))
        throw new Error(error)
      }
    }
  }

export const setStoryPageSize = query => async (dispatch, getState) => {
  const { pageSize } = getStoryPagination(getState())
  await dispatch(__setItemPageSize('stories', pageSize + PAGE_SIZE_INCREASE))
  dispatch(fetchStories(false, true))
}

export const setStoryFilterSelection =
  (fieldKey, key, value) =>
  async (dispatch, getState, { history }) => {
    await dispatch(__setStoryFilterSelection(fieldKey, key, value))
    dispatch(fetchStories(false))

    const nextParams = new URLSearchParams(history.location.search)
    const filterParam = getStoryFilterQueryParams(getState())

    if (filterParam) {
      nextParams.set('storyFilters', filterParam)
    } else {
      nextParams.delete('storyFilters')
    }

    history.replace(`?${nextParams.toString()}`)
  }

export const clearStoryFilters =
  () =>
  async (dispatch, getState, { history }) => {
    dispatch(__clearStoryFilters())
    await dispatch(fetchStories())
    const nextParams = new URLSearchParams(history.location.search)
    nextParams.delete('storyFilters')
    history.replace(`?${nextParams.toString()}`)
  }

export const fetchQuestions =
  (noLoad = false) =>
  async (dispatch, getState, { fetch }) => {
    const { searchQuery, filters, header, currentPage, pageSize } = setupFetch(
      dispatch,
      getState,
      'questions',
      false,
      !noLoad,
      getQuestionPagination
    )

    try {
      questionAbortController =
        typeof questionAbortController !== undefined && new window.AbortController()

      header.signal = questionAbortController.signal

      const res = await fetch(
        `/proxy/husky/questions/?page=${currentPage}&page_size=${pageSize}&query=${searchQuery}${filters}`,
        header
      )

      handleResults(dispatch, res, currentPage, 'questions')
    } catch (error) {
      console.error(error)
      if (error.name !== 'AbortError') {
        dispatch(__setItemFetchError('questions', true))
        throw new Error(error)
      }
    }
  }

export const setQuestionsPageSize = query => async (dispatch, getState) => {
  const { pageSize } = getQuestionPagination(getState())
  dispatch(__setItemPageSize('questions', pageSize + PAGE_SIZE_INCREASE))
  dispatch(fetchQuestions(true))
}

const getFieldsParam = fields => {
  if (!fields || !fields.length) return ''
  const fieldParam = field => `&fields=${field}`
  return fields.map(f => fieldParam(f)).join('')
}

export const fetchDocuments =
  (noLoad = false) =>
  async (dispatch, getState, { fetch }) => {
    const { searchQuery, header, filters, currentPage, pageSize } = setupFetch(
      dispatch,
      getState,
      'documents',
      false,
      !noLoad,
      getDocumentPagination
    )

    try {
      documentAbortController =
        typeof documentAbortController !== undefined && new window.AbortController()

      // List copied from the old angular code
      const fields = getFieldsParam([
        'assets',
        'id',
        'group_restrictions',
        'file._name',
        'file._url',
        'study',
        'file_type',
        'file_name',
        'restricted_search',
        'preview_id',
        'preview_state',
      ])

      header.signal = documentAbortController.signal

      const res = await fetch(
        `/proxy/husky/documents/?page=${currentPage}&page_size=${pageSize}&query=${searchQuery}${filters}${fields}`,
        header
      )

      handleResults(dispatch, res, currentPage, 'documents')
    } catch (error) {
      console.error(error)
      if (error.name !== 'AbortError') {
        dispatch(__setItemFetchError('documents', true))
        throw new Error(error)
      }
    }
  }

export const setDocumentsPageSize = query => async (dispatch, getState) => {
  const { pageSize } = getDocumentPagination(getState())
  dispatch(__setItemPageSize('documents', pageSize + PAGE_SIZE_INCREASE))
  dispatch(fetchDocuments(true))
}

const controllers = [
  {
    abortController: studyAbortController,
    isFetching: state => isFetchingStudies(state),
  },
  {
    abortController: storyAbortController,
    isFetching: state => isFetchingStories(state),
  },
  {
    abortController: questionAbortController,
    isFetching: state => isFetchingQuestions(state),
  },
  {
    abortController: documentAbortController,
    isFetching: state => isFetchingDocuments(state),
  },
]

export const abortCurrentFetches = () => (dispatch, getState) => {
  controllers.forEach(controller => {
    try {
      if (controller.abortController && controller.isFetching(getState())) {
        controller.abortController.abort()
      }
    } catch (error) {
      console.debug(error)
      if (error.name !== 'AbortError') {
        throw new Error(error)
      }
    }
  })
}

export const VECTOR_RESULT_SUCCESS = 'VECTOR_RESULT_SUCCESS'
export const vectorResultSuccess = (vendor, results) => ({
  type: VECTOR_RESULT_SUCCESS,
  vendor,
  results,
})

export const fetchVectorResults =
  () =>
  async (dispatch, getState, { fetch }) => {
    const searchQuery = getSearchQuery(getState())
    const vectorSearch = Analysis2Store.config.hasCollieVectorSearch(getState())
    const aiVendors = getAIVendors(getState())
    if (!vectorSearch) return
    const COLLIE = process.env.REACT_APP_COLLIE_URL || ''

    const aiResponses = await Promise.all(
      aiVendors.map(async vendor => {
        try {
          const response = await fetch(
            `${COLLIE}/question_search/?vendor=${vendor}&query=${searchQuery}`
          )
          return {
            vendor: vendor,
            response: response,
          }
        } catch (error) {
          console.log(error)
        }
      })
    )

    await Promise.all(
      aiResponses.map(async aiResponse => {
        try {
          if (aiResponse.response.ok) {
            const result = await aiResponse.response.json()
            dispatch(vectorResultSuccess(aiResponse.vendor, result))
          }
        } catch (error) {
          console.log(error)
        }
      })
    )
  }

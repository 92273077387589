import * as actions from './actions'
import filterReducerModule from '../shared/reducer'

const initState = {
  categories: {},
}

export default function unifiedFiltersReducer(state = initState, action = {}) {
  const filterReducerResult = filterReducerModule(
    {
      initAction: actions.INITIALIZE_UNIFIED_FILTERS,
      updateAction: actions.UPDATE_AVAILABLE_UNIFIED_FILTERS,
      setSelectionAction: actions.SET_UNIFIED_FILTER_SELECTION,
    },
    state,
    action
  )

  if (filterReducerResult) return filterReducerResult

  switch (action.type) {
    case actions.CLEAR_UNIFIED_FILTERS: {
      return {
        ...state,
        categories: Object.entries(state.categories).reduce((acc, [categoryName, values]) => {
          acc[categoryName] = Object.entries(values).reduce((valuesAcc, [valueName, data]) => {
            valuesAcc[valueName] = {
              ...data,
              selected: false,
            }
            return valuesAcc
          }, {})
          return acc
        }, {}),
      }
    }
    default:
      return state
  }
}

import React, { useEffect, useState } from 'react'
import { useSelector, shallowEqual } from 'react-redux'
import { LoadingSpinner } from '@knowledgehound/laika'
import throttle from 'lodash/throttle'

import { currentUserIsManager, hasSilentRestrictions } from 'data/session/SessionSelectors'
import {
  hasErrorFetchingStudies,
  hasNoCreatedStudies,
  isFetchingStudies,
  getACLPartitionedStudies,
} from 'data/studyHome/selectors'
import StudyCreateModal from 'routes/MainNavbar/StudyCreateModal'
import noStudies from './assets/noStudies.svg'
import Card, { cardStyles } from 'components/Card'

import MetadataList from './MetadataList'
import * as styles from './StudyList.module.scss'

export default function StudyList() {
  const [showInaccessibleStudies, setShowInaccessibleStudies] = useState(false)
  const isFetching = useSelector(isFetchingStudies, shallowEqual)
  const hideRestrictedStudies = useSelector(hasSilentRestrictions, shallowEqual)
  const hasError = useSelector(hasErrorFetchingStudies, shallowEqual)
  const isManager = useSelector(currentUserIsManager, shallowEqual)
  const noCreatedStudies = useSelector(hasNoCreatedStudies, shallowEqual)
  const { preferredStudies, accessibleStudies, inaccessibleStudies } = useSelector(
    getACLPartitionedStudies,
    shallowEqual
  )

  const [firstCardEl, setFirstCardEl] = useState(null)
  const [interiorCardWidth, setInteriorCardWidth] = useState(0)

  useEffect(() => {
    const observer = throttle(() => setInteriorCardWidth(firstCardEl?.clientWidth), 40)
    observer()
    window.addEventListener('resize', observer)
    return () => window.removeEventListener('resize', observer)
  }, [firstCardEl])

  if (isFetching) {
    return <LoadingSpinner centered text="Loading..." />
  }

  if (hasError) {
    return (
      <div className={styles.messageContainer}>
        <div className={styles.message}>
          <span className={styles.header}>Something went wrong retrieving your studies</span>
          <p>Our engineers have been notified. In the mean time, try refreshing your browser.</p>
        </div>
      </div>
    )
  }

  if (noCreatedStudies) {
    return (
      <div className={styles.messageContainer}>
        <div className={styles.message}>
          <img
            src={noStudies}
            alt="An open manilla folder with data coming out, symbolizing potential and possibility."
          />
          <span className={styles.header}>No studies have been created yet</span>
          {isManager && (
            <React.Fragment>
              <p>You can create your first study by clicking the button below.</p>
              <StudyCreateModal text="Create your first study" />
            </React.Fragment>
          )}
        </div>
      </div>
    )
  }

  if (accessibleStudies.length === 0 && preferredStudies.length === 0) {
    return (
      <div className={styles.messageContainer}>
        <div className={styles.message}>
          <span className={styles.header}>No results found</span>
          <p>Your applied filters do not match any studies.</p>
        </div>
      </div>
    )
  }

  return (
    <React.Fragment>
      <ul className={cardStyles.cards}>
        {preferredStudies.map(study => (
          <Card
            key={study.studyNumber}
            linkTo={`/studies/${study.studyNumber}`}
            onClick={() =>
              window.analytics.track('Study Viewed', {
                study_id: study.studyNumber,
                study_name: study.name,
                source: 'Study Index',
              })
            }
            preheader={study.studyDate}
            header={study.name}
            body={<MetadataList metadata={study.metadata} interiorCardWidth={interiorCardWidth} />}
            footer={study.studyNumber}
          />
        ))}
        {accessibleStudies.map((study, index) => (
          <Card
            key={study.studyNumber}
            linkTo={`/studies/${study.studyNumber}`}
            onClick={() =>
              window.analytics.track('Study Viewed', {
                study_id: study.studyNumber,
                study_name: study.name,
                source: 'Study Index',
              })
            }
            preheader={study.studyDate}
            header={study.name}
            body={
              <MetadataList
                ref={index === 0 ? setFirstCardEl : null}
                interiorCardWidth={interiorCardWidth}
                metadata={study.metadata}
              />
            }
            footer={study.studyNumber}
          />
        ))}
      </ul>
      {!hideRestrictedStudies && Boolean(inaccessibleStudies.length) && (
        <React.Fragment>
          <h2 className={styles.aclsHeader}>
            <span>
              {inaccessibleStudies.length} restricted{' '}
              {inaccessibleStudies.length > 1 ? 'studies' : 'study'}
            </span>
            <button
              type="button"
              onClick={() => {
                window.analytics.track('Study Home Toggle ACL-hidden Studies', {
                  hidden: !showInaccessibleStudies,
                })
                setShowInaccessibleStudies(!showInaccessibleStudies)
              }}
            >
              Show
            </button>
          </h2>
          {showInaccessibleStudies ? (
            <ul className={styles.studyCards}>
              {inaccessibleStudies.map(study => (
                <Card
                  key={study.studyNumber}
                  preheader={study.studyDate}
                  header={study.name}
                  isLocked
                />
              ))}
            </ul>
          ) : (
            <p>Restricted studies are not accessible to you</p>
          )}
        </React.Fragment>
      )}
    </React.Fragment>
  )
}

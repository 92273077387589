import React, { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import cs from 'classnames'

import { Icon } from '@knowledgehound/laika'

import HighlightedText from 'components/HighlightedText'

import { changeUserPreference } from 'data/personalization/actions'
import * as styles from './PreferenceTag.module.scss'

const propTypes = {
  field: PropTypes.string.isRequired,
  value: PropTypes.object.isRequired,
  preselected: PropTypes.bool,
  searchValue: PropTypes.string,
  userHasNotViewedModal: PropTypes.bool,
  fetchAction: PropTypes.func,
}

function PreferenceTag({
  field,
  value,
  preselected,
  searchValue,
  userHasNotViewedModal,
  fetchAction,
}) {
  const dispatch = useDispatch()

  const handleUpdatePreference = useCallback(async () => {
    await dispatch(changeUserPreference(field, value.label, !value.selected, value.hasChildren))
    if (typeof fetchAction === 'function') {
      await dispatch(fetchAction(true))
    }
  }, [dispatch, field, value.label, value.selected, value.hasChildren, fetchAction])

  return (
    <button
      className={cs(styles.tagLabel, { [styles.userSelected]: value.selected })}
      onClick={handleUpdatePreference}
    >
      {preselected && <Icon icon="profile" size="small" />}
      {preselected ? (
        value.label
      ) : (
        <HighlightedText text={String(value.label)} match={searchValue} />
      )}
      {preselected && (
        <div className={styles.removePreference}>
          <Icon icon="close" size="small" color="charcoal" />
        </div>
      )}
    </button>
  )
}

PreferenceTag.propTypes = propTypes
export default PreferenceTag

import { createStore, applyMiddleware, compose } from 'redux'
import { createReduxEnhancer as createSentryReduxEnhancer } from '@sentry/react'
import thunked, { getDecoratedFetch } from '@knowledgehound/thunked'

import rootReducer from './reducers'

export default function configureReduxStore(jwt, history) {
  const composeEnhancers =
    (typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose

  const decoratedFetch = getDecoratedFetch(jwt)
  const fetch = (url, options) =>
    decoratedFetch(url, options).then(response => {
      if (
        !options?.skipLoginRedirect &&
        (response.status === 401 || response.status === 403) &&
        /^https?:\/\/.*\.knowledgehound.com?\b/gi.test(response.url)
      ) {
        window.location.assign(
          `/login?next=${encodeURIComponent(window.location.pathname)}${encodeURIComponent(
            window.location.search
          )}`
        )
      }
      return response
    })

  return createStore(
    rootReducer,
    {},
    composeEnhancers(applyMiddleware(thunked({ fetch, history })), createSentryReduxEnhancer())
  )
}

import moment from 'moment'

import * as actions from './actions'

const initialState = {
  isFetching: false,
  hasError: false,
  data: {
    count: 0,
    results: [],
  },
  availableSuppliers: [],
  sorting: {
    direction: 'DESCENDING',
    field: 'study_year',
    boostPreferences: true,
  },
  createForm: {
    responseErrors: {
      name: '',
      number: '',
    },
    errorMessageKey: null,
    name: '',
    number: '',
    month: moment().month(),
    year: moment().year(),
    suppliers: [],
  },
  pagination: {
    currentPage: 1,
    totalPages: 1,
    pageSize: 2500,
  },
}

export default function StudyHomeReducer(state = initialState, action = {}) {
  switch (action.type) {
    case actions.SET_FETCH_STUDIES_LOADING:
      return {
        ...state,
        hasError: false,
        isFetching: action.isFetching,
      }
    case actions.SET_FETCH_STUDIES_ERROR:
      return {
        ...state,
        isFetching: false,
        hasError: action.hasError,
      }
    case actions.SET_FETCH_STUDIES_SUCCESS:
      return {
        ...state,
        isFetching: false,
        hasError: false,
        data: {
          ...action.data,
          results: action.data.results.map(item => ({
            metadata: item.metadata,
            sample: item.sample,
            name: item.name,
            studyDate: moment(item.study_date).format('MMMM YYYY'),
            studyNumber: item.study_number,
            summaryHits: item.summary_hits,
            datapointHits: item.datapoint_hits,
            permissions: item.permissions,
          })),
        },
        pagination: {
          ...state.pagination,
          currentPage: action.resetCurrentPage ? 1 : state.pagination.currentPage,
          totalPages: Math.ceil(action.data.count / state.pagination.pageSize),
        },
      }
    case actions.SET_CURRENT_PAGE:
      if (action.currentPage < 1 || action.currentPage > state.pagination.totalPages) {
        return state
      }

      return {
        ...state,
        pagination: {
          ...state.pagination,
          currentPage: action.currentPage,
        },
      }
    case actions.SET_STUDIES_SORTING:
      return {
        ...state,
        sorting: {
          ...state.sorting,
          field: action.field,
          direction: action.direction,
        },
      }
    case actions.TOGGLE_PREFERENCES_STUDY_HOME_BOOST:
      return {
        ...state,
        sorting: {
          ...state.sorting,
          boostPreferences: action.boostPreferences ?? !state.sorting.boostPreferences,
        },
      }
    case actions.MUTATE_CREATE_FORM:
      return {
        ...state,
        createForm: {
          ...state.createForm,
          responseErrors: initialState.createForm.responseErrors,
          [action.key]: action.value,
        },
      }
    case actions.RESET_CREATE_FORM:
      return {
        ...state,
        createForm: initialState.createForm,
        availableSuppliers: initialState.availableSuppliers,
      }
    case actions.SET_SUPPLIER_LIST:
      return {
        ...state,
        availableSuppliers: action.availableSuppliers,
      }
    default:
      return state
  }
}

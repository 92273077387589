// @flow
import React from 'react'
import './ErrorPage.scss'

type PropsT = {
  leadText?: string,
  text: string,
  className?: string,
  actionItem?: React$Element<any>,
}

const ErrorPage = ({ leadText, text, className, actionItem }: PropsT) => (
  <section className={`error-page column-container ${className ? className : ''}`}>
    <div className="error-page-content">
      <div className="column-container align-center fill-x">
        <p className="lead-text">
          <strong>{leadText}</strong>
        </p>
        <p className="body-text">{text}</p>
        {actionItem}
      </div>
    </div>
  </section>
)

export default ErrorPage

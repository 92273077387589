import * as actions from './actions'

const initialReducerState = {
  keyInsights: {
    isFetching: false,
    error: null,
    data: [],
    lastFetched: null,
  },
  newKeyInsight: {
    isFetching: false,
    error: null,
    data: null,
    lastFetched: null,
  },
  updateKeyInsight: {
    isFetching: false,
    error: null,
    data: null,
    lastFetched: null,
  },
  deleteKeyInsight: {
    isFetching: false,
    error: null,
    data: null,
    lastFetched: null,
  },
  updatePositionKeyInsight: {
    isFetching: false,
    error: null,
    data: null,
    lastFetched: null,
  },
}

export default function keyInsightsReducer(state = initialReducerState, action = {}) {
  switch (action.type) {
    case actions.FETCH_KEY_INSIGHTS_LOADING: {
      return {
        ...state,
        keyInsights: {
          ...state.keyInsights,
          isFetching: true,
          error: null,
          data: [],
        },
      }
    }
    case actions.FETCH_KEY_INSIGHTS_SUCCESS: {
      const lastFetched = Date.now()
      return {
        ...state,
        keyInsights: {
          ...state.keyInsights,
          isFetching: false,
          lastFetched,
          data: action.payload,
          error: null,
        },
      }
    }
    case actions.FETCH_KEY_INSIGHTS_ERROR: {
      const lastFetched = Date.now()
      return {
        ...state,
        keyInsights: {
          ...state.keyInsights,
          isFetching: false,
          lastFetched,
          error: action.payload,
        },
      }
    }

    case actions.CREATE_KEY_INSIGHT_LOADING: {
      return {
        ...state,
        newKeyInsight: {
          ...state.newKeyInsight,
          isFetching: true,
          error: null,
          data: null,
        },
      }
    }
    case actions.CREATE_KEY_INSIGHT_SUCCESS: {
      const lastFetched = Date.now()
      return {
        ...state,
        keyInsights: {
          ...state.keyInsights,
          lastFetched,
          data: [...(state.keyInsights.data || []), action.payload],
        },
        newKeyInsight: {
          ...state.newKeyInsight,
          isFetching: false,
          lastFetched,
          data: action.payload,
          error: null,
        },
      }
    }
    case actions.CREATE_KEY_INSIGHT_ERROR: {
      const lastFetched = Date.now()
      return {
        ...state,
        newKeyInsight: {
          ...state.newKeyInsight,
          isFetching: false,
          lastFetched,
          error: action.payload,
        },
      }
    }
    case actions.UPDATE_KEY_INSIGHT_LOADING: {
      return {
        ...state,
        updateKeyInsight: {
          ...state.updateKeyInsight,
          isFetching: true,
          error: null,
          data: null,
        },
      }
    }
    case actions.UPDATE_KEY_INSIGHT_SUCCESS: {
      const lastFetched = Date.now()

      let updatedKeyInsights = state.keyInsights.data
      const updatedInsight = action.payload

      if (state.keyInsights.data && updatedInsight && updatedInsight.id) {
        updatedKeyInsights = state.keyInsights.data.map(keyInsight => {
          if (keyInsight.id === updatedInsight.id) {
            return updatedInsight
          } else {
            return keyInsight
          }
        })
      }

      return {
        ...state,
        updateKeyInsight: {
          ...state.updateKeyInsight,
          isFetching: false,
          lastFetched,
          data: action.payload,
          error: null,
        },
        keyInsights: {
          ...state.keyInsights,
          lastFetched,
          data: updatedKeyInsights || state.keyInsights.data,
        },
      }
    }
    case actions.UPDATE_KEY_INSIGHT_ERROR: {
      const lastFetched = Date.now()
      return {
        ...state,
        updateKeyInsight: {
          ...state.updateKeyInsight,
          isFetching: false,
          lastFetched,
          error: action.payload,
        },
      }
    }
    case actions.DELETE_KEY_INSIGHT_LOADING: {
      return {
        ...state,
        deleteKeyInsight: {
          ...state.deleteKeyInsight,
          isFetching: true,
          error: null,
          data: action.payload,
        },
      }
    }
    case actions.DELETE_KEY_INSIGHT_SUCCESS: {
      const lastFetched = Date.now()

      let updatedKeyInsights
      if (state.keyInsights.data && state.deleteKeyInsight.data && state.deleteKeyInsight.data.id) {
        updatedKeyInsights = state.keyInsights.data.filter(keyInsight => {
          if (state.deleteKeyInsight.data && state.deleteKeyInsight.data.id) {
            return keyInsight.id !== state.deleteKeyInsight.data.id
          }
          return true
        })
      }
      return {
        ...state,
        keyInsights: {
          ...state.keyInsights,
          lastFetched,
          data: updatedKeyInsights || state.keyInsights.data,
        },
        deleteKeyInsight: {
          ...state.deleteKeyInsight,
          isFetching: false,
          lastFetched,
          data: null,
          error: null,
        },
      }
    }
    case actions.DELETE_KEY_INSIGHT_ERROR: {
      const lastFetched = Date.now()
      return {
        ...state,
        deleteKeyInsight: {
          ...state.deleteKeyInsight,
          isFetching: false,
          lastFetched,
          error: action.payload,
        },
      }
    }

    // NOTE: WE DON'T UPDATE THE `position` key within the cards here!
    // The position is updated on the backend, but just the ordering of the array
    // is updated on the frontend in order to give immediate response to the user.
    // Then if the backend call fails, the original positions (which are still there)
    // are then used to revert the array back to its original state.
    case actions.UPDATE_KEY_INSIGHT_CARD_POSITION: {
      const lastFetched = Date.now()
      const newOrdering = state.keyInsights.data.slice()
      newOrdering.splice(action.payload.newPosition, 1)
      newOrdering.splice(action.payload.atPosition, 0, action.payload.card)
      return {
        ...state,
        keyInsights: {
          ...state.keyInsights,
          data: newOrdering,
          isFetching: false,
          lastFetched,
          error: null,
        },
      }
    }

    default:
      return state
  }
}

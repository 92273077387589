// @flow
import * as actions from './ShareActions'
import type { ActionT } from '@knowledgehound/data/Action.type'
// import type { ErrorT } from '@knowledgehound/data/Error.type'
import type { ShareSuccessT } from './ShareActions'

export type NewShareT = {
  isFetching: boolean,
  lastFetched: null | number,
  error: any,
  data: null | ShareSuccessT,
}

export type ShareStateT = {
  newShare: NewShareT,
}

const initialReducerState = {
  newShare: {
    isFetching: false,
    error: null,
    data: null,
    lastFetched: null,
  },
}

export const shareReducer = (
  state: ShareStateT = initialReducerState,
  action: ActionT
): ShareStateT => {
  switch (action.type) {
    case actions.CREATE_SHARE_LOADING: {
      return {
        ...state,
        newShare: {
          ...state.newShare,
          isFetching: true,
          error: null,
          data: null,
        },
      }
    }
    case actions.CREATE_SHARE_SUCCESS: {
      const lastFetched = Date.now()
      return {
        ...state,
        newShare: {
          ...state.newShare,
          isFetching: false,
          lastFetched,
          data: action.payload,
          error: null,
        },
      }
    }
    case actions.CREATE_SHARE_ERROR: {
      const lastFetched = Date.now()
      return {
        ...state,
        newShare: {
          ...state.newShare,
          isFetching: false,
          lastFetched,
          error: action.payload,
        },
      }
    }
    case actions.CREATE_SHARE_RESET: {
      return {
        ...state,
        newShare: {
          ...state.newShare,
          ...initialReducerState.newShare,
        },
      }
    }
    default:
      return state
  }
}

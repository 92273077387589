// @flow
import { handleData, handleError, handleResponse } from '@knowledgehound/data/fetchUtils'
import type { ActionT } from '@knowledgehound/data/Action.type'
import type { ErrorT } from '@knowledgehound/data/Error.type'
import urlParse from 'url-parse'

import * as requests from './AnalysisRequests'
import type { AnalysisQuestionT } from './AnalysisQuestion.type'
import type { ParentStudyT } from './ParentStudy.type'
import type { OpenEndResultsT } from './AnalysisState.type'
import { assembleSampleObject, extractQuestionId } from './AnalysisUtils'
import { Analysis2Store } from '@knowledgehound/analysis'

// CONSTANTS
export const FETCH_ANALYSISQUESTION_LOADING = 'FETCH_ANALYSISQUESTION_LOADING'
export const FETCH_ANALYSISQUESTION_SUCCESS = 'FETCH_ANALYSISQUESTION_SUCCESS'
export const FETCH_ANALYSISQUESTION_ERROR = 'FETCH_ANALYSISQUESTION_ERROR'
export const FETCH_PARENTSTUDY_LOADING = 'FETCH_PARENTSTUDY_LOADING '
export const FETCH_PARENTSTUDY_SUCCESS = 'FETCH_PARENTSTUDY_SUCCESS'
export const FETCH_PARENTSTUDY_ERROR = 'FETCH_PARENTSTUDY_ERROR'
export const FETCH_SIMILARSAMPLES_LOADING = 'FETCH_SIMILARSAMPLES_LOADING '
export const FETCH_SIMILARSAMPLES_SUCCESS = 'FETCH_SIMILARSAMPLES_SUCCESS'
export const FETCH_SIMILARSAMPLES_ERROR = 'FETCH_SIMILARSAMPLES_ERROR'

export const FETCH_OPEN_END_RESPONSES_LOADING = 'FETCH_OPEN_END_RESPONSES_LOADING'
export const FETCH_OPEN_END_RESPONSES_SUCCESS = 'FETCH_OPEN_END_RESPONSES_SUCCESS'
export const FETCH_OPEN_END_RESPONSES_ERROR = 'FETCH_OPEN_END_RESPONSES_ERROR'
export const RESET_OPEN_END_RESPONSES = 'RESET_OPEN_END_RESPONSES'

export const FETCH_SENTIMENT_RANGES_LOADING = 'FETCH_SENTIMENT_RANGES_LOADING'
export const FETCH_SENTIMENT_RANGES_SUCCESS = 'FETCH_SENTIMENT_RANGES_SUCCESS'
export const FETCH_SENTIMENT_RANGES_ERROR = 'FETCH_SENTIMENT_RANGES_ERROR'

export function fetchAnalysisQuestionLoading(): ActionT {
  return {
    type: FETCH_ANALYSISQUESTION_LOADING,
  }
}

export function fetchAnalysisQuestionSuccess(
  analysisQuestion: AnalysisQuestionT | string
): ActionT {
  return {
    type: FETCH_ANALYSISQUESTION_SUCCESS,
    payload: analysisQuestion,
  }
}

export function fetchAnalysisQuestionError(err: ErrorT): ActionT {
  return {
    type: FETCH_ANALYSISQUESTION_ERROR,
    error: true,
    payload: err,
  }
}

export function fetchAnalysisQuestion(naturalKey: string, params: Object = {}): Function {
  return (dispatch: Function) => {
    dispatch(fetchAnalysisQuestionLoading())

    return new Promise((resolve: Function, reject: Function) => {
      Analysis2Store.requests
        .getQuestion(naturalKey, params)
        .then(handleData(resolve, dispatch, fetchAnalysisQuestionSuccess))
        .catch(handleError(reject, dispatch, fetchAnalysisQuestionError))
    })
  }
}

export function fetchParentStudyLoading(): ActionT {
  return {
    type: FETCH_PARENTSTUDY_LOADING,
  }
}

export function fetchParentStudySuccess(parentStudy: ParentStudyT | string): ActionT {
  return {
    type: FETCH_PARENTSTUDY_SUCCESS,
    payload: parentStudy,
  }
}

export function fetchParentStudyError(err: ErrorT): ActionT {
  return {
    type: FETCH_PARENTSTUDY_ERROR,
    error: true,
    payload: err,
  }
}

export function fetchParentStudy(parentStudy: string, params?: Object): Function {
  return (dispatch: Function, getState: Function) => {
    dispatch(fetchParentStudyLoading())

    const parentStudyPath = urlParse(parentStudy).pathname

    return new Promise((resolve: Function, reject: Function) => {
      requests
        .getParentStudy(parentStudyPath, params)
        .then(handleData(resolve, dispatch, fetchParentStudySuccess))
        .catch(handleError(reject, dispatch, fetchParentStudyError))
    })
  }
}

export function fetchSimilarSamplesLoading(): ActionT {
  return {
    type: FETCH_SIMILARSAMPLES_LOADING,
  }
}

export function fetchSimilarSamplesSuccess(similarSamples: Array<any> | string): ActionT {
  return {
    type: FETCH_SIMILARSAMPLES_SUCCESS,
    payload: similarSamples,
  }
}

export function fetchSimilarSamplesError(err: ErrorT): ActionT {
  return {
    type: FETCH_SIMILARSAMPLES_ERROR,
    error: true,
    payload: err,
  }
}

export function fetchSimilarSamples(question: AnalysisQuestionT): Function {
  function fetchDetectedSample(id: string) {
    return new Promise((resolve, reject) => {
      fetch(`/proxy/spss/questions/${id}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'same-origin',
      })
        .then(handleResponse)
        .then(resolve)
        .catch(reject)
    })
  }

  /*
    Examines the criteria of the detected sample to determine whether
    the sample is ambiguous, representative of all users answering the question,
    or a valid sample in the chain

    @sampleContainer - An object containing:
      sample_objects: An array of sample objects that will be used to construct the question sample
        string
      detected_samples: An array containing either 1 or more question sample objects, an empty
      object or nothing at all
      sample_size: An integer representing the number of respondents that answered this question
    used to maintain persistence throughout the recursive building of the final human readable
    question sample
    deferred - The deferred object that will be resolved with the final sample to display to the user

    returns either undefined (when we have a promise resolution) or true indicating we want to continue
  */
  function validateSampleChain(sampleContainer) {
    // There was an ambiguous sample detected: either multiple questions were returned,
    // or no questions were returned
    if (
      (sampleContainer.detected_samples.length === 0 && sampleContainer.sample_size >= 0) ||
      sampleContainer.detected_samples.length > 1
    ) {
      return []
    } else if (Object.keys(sampleContainer.detected_samples[0]).length === 0) {
      // The first element is an empty object, meaning all respondents answered
      // We received a an empty object as the first detected_sample, so reflect
      // that all users answered
      if (sampleContainer.sample_objects.length === 0) {
        return []
      } else if (sampleContainer.sample_objects.length > 0) {
        // We already have a sample generated, so this means we've reached the end of the
        // sample chain
        return sampleContainer.sample_objects
      }
    } else if (Object.keys(sampleContainer.detected_samples).length === 1) {
      // We're dealing with a single sample, so return null to continue
      return null
    }
  }

  /*
  Recursively follows a chain of question samples returned from the analytics engine
  until an ambiguous sample is reached, or a sample that was answered by all users of the survey.

  @sampleContainer - An object containing:
  	sample_objects: An array of sample objects that will be used to construct the question sample
  		string
  	detected_samples: An array containing either 1 or more question sample objects, an empty
  	object or nothing at all
  	sample_size: An integer representing the number of respondents that answered this question
  used to maintain persistence throughout the recursive building of the final human readable
  question sample
  deferred - The deferred object that will be resolved with the final sample to display to the user

  returns nothing
  */
  const getSimilarSamples = (sampleContainer: Object) => {
    const result = validateSampleChain(sampleContainer)
    // If result has something, lets return and bail out
    if (result) {
      return result
    }
    return fetchDetectedSample(extractQuestionId(sampleContainer.detected_samples[0]))
      .then(detailQuestion => {
        // Generate sample object and store for display in view
        const newSampleContainer = {
          ...sampleContainer,
        }
        newSampleContainer.sample_objects.unshift(
          assembleSampleObject(detailQuestion, sampleContainer.detected_samples[0])
        )
        newSampleContainer.detected_samples = detailQuestion.detected_samples
        newSampleContainer.sample_size = detailQuestion.sample_size

        return getSimilarSamples(newSampleContainer)
      })
      .catch(error => Promise.reject(error))
  }

  return (dispatch: Function, getState: Function) => {
    dispatch(fetchSimilarSamplesLoading())

    return new Promise((resolve: Function, reject: Function) => {
      Promise.resolve({
        sample_objects: [],
        detected_samples: question.detected_samples,
        sample_size: question.sample_size,
      })
        .then(getSimilarSamples)
        .then(handleData(resolve, dispatch, fetchSimilarSamplesSuccess))
        .catch(handleError(reject, dispatch, fetchSimilarSamplesError))
    })
  }
}

export function resetOpenEndResponses(): ActionT {
  return {
    type: RESET_OPEN_END_RESPONSES,
  }
}

export function fetchOpenEndResponsesLoading(): ActionT {
  return {
    type: FETCH_OPEN_END_RESPONSES_LOADING,
  }
}

export function fetchOpenEndResponsesSuccess(results: OpenEndResultsT | string): ActionT {
  return {
    type: FETCH_OPEN_END_RESPONSES_SUCCESS,
    payload: results,
  }
}

export function fetchOpenEndResponsesError(err: ErrorT): ActionT {
  return {
    type: FETCH_OPEN_END_RESPONSES_ERROR,
    error: true,
    payload: err,
  }
}

export function fetchOpenEndResponses(naturalKey: string, params?: Object): Function {
  return (dispatch: Function, getState: Function): Promise<any> => {
    dispatch(fetchOpenEndResponsesLoading())

    return new Promise((resolve: Function, reject: Function) => {
      requests
        .getOpenEndedResponses(naturalKey, params)
        .then(handleData(resolve, dispatch, fetchOpenEndResponsesSuccess))
        .catch(handleError(reject, dispatch, fetchOpenEndResponsesError))
    })
  }
}

export function fetchSentimentRangesLoading(): ActionT {
  return {
    type: FETCH_SENTIMENT_RANGES_LOADING,
  }
}

export function fetchSentimentRangesSuccess(results: OpenEndResultsT | string): ActionT {
  return {
    type: FETCH_SENTIMENT_RANGES_SUCCESS,
    payload: results,
  }
}

export function fetchSentimentRangesError(err: ErrorT): ActionT {
  return {
    type: FETCH_SENTIMENT_RANGES_ERROR,
    error: true,
    payload: err,
  }
}

export function fetchSentimentRanges(): Function {
  return (dispatch: Function, getState: Function): Promise<any> => {
    dispatch(fetchSentimentRangesLoading())

    return new Promise((resolve: Function, reject: Function) => {
      requests
        .getSentimentRanges()
        .then(handleData(resolve, dispatch, fetchSentimentRangesSuccess))
        .catch(handleError(reject, dispatch, fetchSentimentRangesError))
    })
  }
}

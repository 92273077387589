import * as actions from './actions'

const initialState = {
  stories: {
    isFetching: false,
    hasFetched: false,
    error: null,
    data: [],
  },
  studies: {
    isFetching: false,
    hasFetched: false,
    error: null,
    data: [],
  },
  favorites: {
    isFetching: false,
    hasFetched: false,
    error: null,
    data: [],
  },
}

export default function HomeReducer(state = initialState, action = {}) {
  switch (action.type) {
    case actions.HOME_FETCH_STUDIES_START: {
      return {
        ...state,
        studies: {
          isFetching: true,
          hasFetched: false,
          error: null,
          data: [],
        },
      }
    }
    case actions.HOME_FETCH_STUDIES_SUCCESS: {
      return {
        ...state,
        studies: {
          isFetching: false,
          hasFetched: true,
          error: null,
          data: action.data,
        },
      }
    }
    case actions.HOME_FETCH_STUDIES_ERROR: {
      return {
        ...state,
        studies: {
          isFetching: false,
          hasFetched: true,
          error: action.error,
          data: [],
        },
      }
    }
    case actions.HOME_FETCH_STORIES_START: {
      return {
        ...state,
        stories: {
          isFetching: true,
          hasFetched: false,
          error: null,
          data: [],
        },
      }
    }
    case actions.HOME_FETCH_STORIES_SUCCESS: {
      return {
        ...state,
        stories: {
          isFetching: false,
          hasFetched: true,
          error: null,
          data: action.data,
        },
      }
    }
    case actions.HOME_FETCH_STORIES_ERROR: {
      return {
        ...state,
        stories: {
          isFetching: false,
          hasFetched: true,
          error: action.error,
          data: [],
        },
      }
    }
    case actions.HOME_FETCH_RECENT_FAVORITES_START: {
      return {
        ...state,
        favorites: {
          isFetching: true,
          hasFetched: false,
          error: null,
          data: [],
        },
      }
    }
    case actions.HOME_FETCH_RECENT_FAVORITES_SUCCESS: {
      return {
        ...state,
        favorites: {
          isFetching: false,
          hasFetched: true,
          error: null,
          data: action.data,
        },
      }
    }
    case actions.HOME_FETCH_RECENT_FAVORITES_ERROR: {
      return {
        ...state,
        favorites: {
          isFetching: false,
          hasFetched: true,
          error: action.error,
          data: [],
        },
      }
    }
    default:
      return state
  }
}

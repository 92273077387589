import React, { useRef, useState, useEffect } from 'react'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import moment from 'moment'
import { Modal, Button, Tooltip } from '@knowledgehound/laika'
import { currentUserIsManager } from 'data/session/SessionSelectors'

import { mutateCreateForm, startCreatingStudy, createStudy } from 'data/studyHome/actions'
import { getCreateFormState, getSuppliers } from 'data/studyHome/selectors'

import * as styles from './StudyCreateModal.module.scss'

const monthOptions = new Array(12).fill(0).map((_, value) => ({
  value,
  label: `${moment().month(value).format('MM')} - ${moment().month(value).format('MMMM')}`,
}))

const formValidator = state => {
  const { name, year, suppliers, number, errorMessageKey, responseErrors } =
    state.studyHome.createForm

  if (errorMessageKey) return true
  if (responseErrors.name || responseErrors.number) return true
  if (number.length > 0 && !number.match(/^[A-Za-z0-9_-]+$/)) return 'number-content'
  if (number.length > 200) return 'number'
  if (!name.length || name.length > 250) return 'name'
  if (year > moment().year() || year < 1990) return 'year'
  if (!suppliers.length) return 'suppliers'
  return false
}

function StudyCreateModal({ history, text = 'Add Study' }) {
  const dispatch = useDispatch()
  const nameInputRef = useRef()
  const numberInputRef = useRef()
  const { responseErrors = {}, ...formState } = useSelector(getCreateFormState, shallowEqual)
  const suppliers = useSelector(getSuppliers, shallowEqual)
  const formIsInvalid = useSelector(formValidator, shallowEqual)
  const [modalOpen, setModalOpen] = useState(false)
  const isManager = useSelector(currentUserIsManager, shallowEqual)

  useEffect(() => {
    if (nameInputRef && nameInputRef.current) {
      nameInputRef.current.setCustomValidity(responseErrors.name)
    }
    if (numberInputRef && numberInputRef.current) {
      numberInputRef.current.setCustomValidity(responseErrors.number)
    }
  }, [responseErrors, nameInputRef, numberInputRef])

  return (
    <React.Fragment>
      <Tooltip placement="bottom" content="Only managers can add new studies.">
        {({ hostRef, openTooltip, closeTooltip, attributes }) => (
          <div
            onMouseOver={isManager ? undefined : openTooltip}
            onMouseOut={closeTooltip}
            {...attributes}
          >
            <Button
              ref={hostRef}
              variant="primary"
              onClick={() => {
                dispatch(startCreatingStudy())
                setModalOpen(true)
              }}
              disabled={!isManager}
            >
              {text}
            </Button>
          </div>
        )}
      </Tooltip>
      <Modal
        title="Add a New Study"
        isOpen={modalOpen}
        onClose={() => setModalOpen(false)}
        submitDisabled={Boolean(formIsInvalid) || Boolean(formState.errorMessageKey)}
        submitText="Create Study"
        onSubmit={async () => {
          const number = await dispatch(createStudy())
          if (number) {
            window.analytics.track('New Study Created', {
              'Study Number': number,
            })
            setModalOpen(false)
            history.push(`/studies/${number}`)
          }
        }}
      >
        <form className={styles.createForm}>
          <label htmlFor="study_name">Study Name</label>
          <input
            ref={nameInputRef}
            id="study_name"
            name="study_name"
            type="text"
            value={formState.name}
            onChange={e => dispatch(mutateCreateForm('name', e.target.value))}
          />
          {responseErrors.name && <div className={styles.fieldError}>{responseErrors.name}</div>}
          <label htmlFor="study_number">Study Number (Optional)</label>
          <input
            ref={numberInputRef}
            id="study_number"
            name="study_number"
            type="text"
            value={formState.number}
            onChange={e => dispatch(mutateCreateForm('number', e.target.value))}
            pattern="^[A-Za-z0-9_\-]+$"
          />
          {responseErrors.number && (
            <div className={styles.fieldError}>{responseErrors.number}</div>
          )}
          {formIsInvalid === 'number-content' && (
            <p className={styles.fieldError}>
              Study Number can only contain letters, numbers, hyphens, and underscores.
            </p>
          )}
          <fieldset>
            <legend>Date</legend>
            <select
              placeholder="Month"
              name="study_month"
              required
              value={formState.month}
              onChange={e => dispatch(mutateCreateForm('month', e.target.value))}
            >
              {monthOptions.map(({ label, value }) => (
                <option key={value} value={value}>
                  {label}
                </option>
              ))}
            </select>
            <input
              type="number"
              required
              name="study_year"
              placeholder="Year"
              min="1990"
              max={new Date().getFullYear()}
              value={formState.year}
              onChange={e => dispatch(mutateCreateForm('year', e.target.value))}
            />
          </fieldset>
          <label htmlFor="suppliers">Suppliers</label>
          <select
            multiple
            id="suppliers"
            name="suppliers"
            disabled={!suppliers.length}
            onChange={e =>
              dispatch(
                mutateCreateForm(
                  'suppliers',
                  Array.from(e.target.selectedOptions, o => o.value)
                )
              )
            }
            value={formState.suppliers}
          >
            {suppliers.map(({ id, value }) => (
              <option key={id} value={id}>
                {value}
              </option>
            ))}
          </select>
        </form>
        {formState.errorMessageKey ? (
          <p className={styles.errorPanel}>
            <FormattedMessage
              id={formState.errorMessageKey}
              defaultMessage="An unknown error occurred."
            />
          </p>
        ) : (
          <p>It may take several minutes for this new study to appear.</p>
        )}
      </Modal>
    </React.Fragment>
  )
}

export default withRouter(StudyCreateModal)

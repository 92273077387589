import moment from 'moment'
import * as Sentry from '@sentry/react'

import * as filterActions from 'data/filters/unified/actions'
import { getSerializableFilterState } from 'data/filters/unified/selectors'
import { getArePreferencesBoosted } from './selectors'

export const SET_FETCH_STUDIES_LOADING = 'SET_FETCH_STUDIES_LOADING'
const __setStudiesLoading = isFetching => ({
  type: SET_FETCH_STUDIES_LOADING,
  isFetching,
})

export const SET_FETCH_STUDIES_ERROR = 'SET_FETCH_STUDIES_ERROR'
const __setFetchStudiesError = hasError => ({
  type: SET_FETCH_STUDIES_ERROR,
  hasError,
})

export const SET_FETCH_STUDIES_SUCCESS = 'SET_FETCH_STUDIES_SUCCESS'
const __setFetchStudiesSuccess = (data, resetCurrentPage) => ({
  type: SET_FETCH_STUDIES_SUCCESS,
  data,
  resetCurrentPage,
})

export const SET_STUDIES_SORTING = 'SET_STUDIES_SORTING'
const __setStudiesSorting = (field, direction) => {
  if (!['ASCENDING', 'DESCENDING'].includes(direction)) {
    throw new Error('Illegal sort direction')
  }

  return {
    type: SET_STUDIES_SORTING,
    field,
    direction,
  }
}

export const TOGGLE_PREFERENCES_STUDY_HOME_BOOST = 'TOGGLE_PREFERENCES_STUDY_HOME_BOOST'
const __togglePreferencesBoost = boostPreferences => ({
  type: TOGGLE_PREFERENCES_STUDY_HOME_BOOST,
  boostPreferences,
})

export const togglePreferencesBoost = boostPreferences => (dispatch, getState) => {
  dispatch(__togglePreferencesBoost(boostPreferences))

  const prefsBoosted = getArePreferencesBoosted(getState())
  localStorage.setItem('preferredStudiesAreBoosted', prefsBoosted)

  window.analytics.track('Toggle Study Home Preferences Boost', { boosted: prefsBoosted })
}

export const setFilterSelection = (fieldKey, key, value) => dispatch => {
  dispatch(filterActions.setFilterSelection(fieldKey, key, value))
  dispatch(fetchStudies(true))
}

export const clearFilters = () => dispatch => {
  dispatch(filterActions.clearUnifiedFilters())
  dispatch(fetchStudies(true))
}

const getSortingQueryParam = state => {
  const { field, direction } = state.studyHome.sorting
  return `${direction === 'DESCENDING' ? '-' : '+'}${field}`
}

export const fetchStudies =
  resetCurrentPage =>
  async (dispatch, getState, { fetch }) => {
    const storedIsBoosted = localStorage.getItem('preferredStudiesAreBoosted')
    if (storedIsBoosted) {
      // Storage values are strings
      dispatch(__togglePreferencesBoost(storedIsBoosted === 'true'))
    }

    if (resetCurrentPage) {
      dispatch(__setStudiesLoading(true))
      await dispatch(filterActions.initializeFilters())
    }

    const sort = getSortingQueryParam(getState())
    const filters = getSerializableFilterState(getState())
    const { currentPage, pageSize } = getState().studyHome.pagination

    const params = new URLSearchParams({
      page: currentPage,
      page_size: pageSize,
      sort: sort,
    })

    if (filters) {
      params.set('filters', JSON.stringify(filters))
    }

    const res = await fetch(`/proxy/husky/studies/?${params.toString()}`)

    if (!res.ok) {
      dispatch(__setFetchStudiesError(true))
      return
    }

    const data = await res.json()
    dispatch(__setFetchStudiesSuccess(data, resetCurrentPage))

    if (!resetCurrentPage) {
      dispatch(filterActions.updateAvailableFilters(data.aggregations))
    }
  }

export const SET_CURRENT_PAGE = 'SET_CURRENT_PAGE'
const __setCurrentPage = currentPage => ({
  type: SET_CURRENT_PAGE,
  currentPage,
})

export const changePage = pageNumber => (dispatch, getState) => {
  if (pageNumber === getState().studyHome.pagination.currentPage) return
  dispatch(__setCurrentPage(pageNumber))
  dispatch(fetchStudies(false))
}

export const changeSorting = (field, direction) => (dispatch, getState) => {
  const { field: currentField, direction: currentDirection } = getState().studyHome.sorting
  if (field === currentField && direction === currentDirection) return

  dispatch(__setStudiesSorting(field, direction))
  dispatch(fetchStudies(false))
}

export const MUTATE_CREATE_FORM = 'MUTATE_CREATE_FORM'
export const mutateCreateForm = (key, value) => ({
  type: MUTATE_CREATE_FORM,
  key,
  value,
})

export const RESET_CREATE_FORM = 'RESET_CREATE_FORM'
export const resetCreateForm = () => ({
  type: RESET_CREATE_FORM,
})

export const SET_SUPPLIER_LIST = 'SET_SUPPLIER_LIST'
const __setSupplierList = availableSuppliers => ({
  type: SET_SUPPLIER_LIST,
  availableSuppliers,
})

export const startCreatingStudy =
  () =>
  async (dispatch, getState, { fetch }) => {
    dispatch(resetCreateForm())

    try {
      const fieldRes = await fetch('/proxy/greyhound/fields/?name=Supplier', {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'same-origin',
      })
      const supplierField = await fieldRes.json()

      const supplierRes = await fetch(`/proxy/greyhound/fields/${supplierField[0].id}/`, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'same-origin',
      })
      const suppliers = await supplierRes.json()
      dispatch(__setSupplierList(suppliers.values))

      if (suppliers.values.length === 1) {
        dispatch(mutateCreateForm('suppliers', [suppliers.values[0].id]))
      }
    } catch (error) {
      Sentry.captureException(
        new Error('Error fetching suppliers in study create modal', { cause: error })
      )
      dispatch(mutateCreateForm('errorMessageKey', 'studyHome.errors.createStudyFetchingSuppliers'))
    }
  }

export const createStudy =
  () =>
  async (dispatch, getState, { fetch }) => {
    const { availableSuppliers } = getState().studyHome
    const { name, number, month, year, suppliers } = getState().studyHome.createForm

    const monthString = moment().month(month).format('MM')

    const res = await fetch('/proxy/greyhound/studies/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      credentials: 'same-origin',
      body: JSON.stringify({
        client_values: suppliers,
        Supplier: availableSuppliers.filter(({ id }) => suppliers.includes(id)),
        study_name: name,
        study_date: `${year}-${monthString}-01`,
        study_month: monthString,
        study_year: year,
        study_number: number,
      }),
    })

    const data = await res.json()

    if (res.ok) {
      return data.study_number
    }

    if (Object.keys(data)) {
      const errorData = {
        name: data.study_name ? data.study_name[0] : '',
        number: data.study_number ? data.study_number[0] : '',
      }
      dispatch(mutateCreateForm('responseErrors', errorData))
    } else {
      dispatch(mutateCreateForm('errorMessageKey', 'studyHome.errors.createStudyDispatch'))
    }
    return false
  }

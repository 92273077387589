import React, { useCallback, useState } from 'react'
import { createSelector } from 'reselect'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { DropdownButton, IconButton, ToggleSwitch, Tooltip } from '@knowledgehound/laika'

import {
  getStudySorting,
  getStudyCount,
  getPagination,
  isFetchingStudies,
  getArePreferencesBoosted,
} from 'data/studyHome/selectors'
import { fetchStudies, changeSorting, togglePreferencesBoost } from 'data/studyHome/actions'
import { FilterBar, TokenList } from 'components/unifiedFilters'
import { UserPreferenceModal } from 'components/UserPreferenceSelection'
import PageControls from '../PageControls'
import * as styles from './Controls.module.scss'

const sortItems = [
  { field: 'study_year', direction: 'DESCENDING', label: 'Date (Newest-Oldest)' },
  { field: 'study_year', direction: 'ASCENDING', label: 'Date (Oldest-Newest)' },
  { field: 'study_name', direction: 'ASCENDING', label: 'Study Name (A-Z)' },
  { field: 'study_name', direction: 'DESCENDING', label: 'Study Name (Z-A)' },
]

const getStudyCountMessage = createSelector(
  isFetchingStudies,
  getStudyCount,
  getPagination,
  (isFetching, count, { currentPage, totalPages, pageSize }) => {
    if (isFetching) return 'Fetching studies'
    if (totalPages > 1) {
      const start = 1 + (currentPage > 1 ? pageSize * (currentPage - 1) : 0)
      const end = pageSize + (currentPage > 1 ? pageSize * (currentPage - 1) : 0)
      return `Showing ${start} - ${count < end ? count : end} of ${count} studies`
    }
    return `Showing ${count} ${count === 1 ? 'study' : 'studies'}`
  }
)

const getSortLabel = sort =>
  sortItems.find(item => item.field === sort.field && item.direction === sort.direction).label

export default function Controls() {
  const dispatch = useDispatch()
  const sorting = useSelector(getStudySorting, shallowEqual)
  const studyCountMessage = useSelector(getStudyCountMessage, shallowEqual)
  const arePreferencesBoosted = useSelector(getArePreferencesBoosted, shallowEqual)

  const [preferencesModalOpen, setPreferencesModalOpen] = useState(false)
  const openPreferencesModal = useCallback(
    () => setPreferencesModalOpen(true),
    [setPreferencesModalOpen]
  )
  const closePreferencesModal = useCallback(
    () => setPreferencesModalOpen(false),
    [setPreferencesModalOpen]
  )
  const togglePreferencesBoosting = useCallback(
    () => dispatch(togglePreferencesBoost()),
    [dispatch]
  )

  return (
    <React.Fragment>
      <FilterBar fetchAction={fetchStudies} />
      <TokenList fetchAction={fetchStudies} />
      <div className={styles.controls}>
        <div className={styles.leftSide}>
          <span className={styles.studyCount}>{studyCountMessage}</span>
          <div className={styles.buttonGroup}>
            <DropdownButton
              size="large"
              type="secondary"
              text={`Sort by: ${getSortLabel(sorting)}`}
            >
              {sortItems.map(({ field, direction, label }) => (
                <DropdownButton.MenuItem
                  key={label}
                  onClick={() => dispatch(changeSorting(field, direction))}
                  active={field === sorting.field && direction === sorting.direction}
                >
                  {label}
                </DropdownButton.MenuItem>
              ))}
            </DropdownButton>
            <Tooltip content="Edit your preferences" placement="top">
              <IconButton icon="profile" variant="outlinedKhBlue" callback={openPreferencesModal} />
            </Tooltip>
            <UserPreferenceModal
              isOpen={preferencesModalOpen}
              onClose={closePreferencesModal}
              fetchAction={fetchStudies}
            />
          </div>
          <div className={styles.prefsToggleContainer}>
            <ToggleSwitch
              id="prefs-toggle-switch"
              isToggled={arePreferencesBoosted}
              onToggle={togglePreferencesBoosting}
            />
            <label htmlFor="prefs-toggle-switch">Preferences first</label>
          </div>
        </div>
        <div className={styles.rightSide}>
          <PageControls />
        </div>
      </div>
    </React.Fragment>
  )
}

// @flow
import queryString from 'query-string'

import type { ActionT } from 'data/ActionT'
import type { ErrorT } from 'data/ErrorT'
import type { MetadataFieldT } from './MetadataFieldT'
import { handleResponse, handleError } from '@knowledgehound/data/fetchUtils'

// CONSTANTS for reqeusts
export const SEARCH_METADATAFIELD_LOADING = 'SEARCH_METADATAFIELD_LOADING'
export const SEARCH_METADATAFIELD_SUCCESS = 'SEARCH_METADATAFIELD_SUCCESS'
export const SEARCH_METADATAFIELD_ERROR = 'SEARCH_METADATAFIELD_ERROR'
export const FETCH_METADATAFIELDS_LOADING = 'FETCH_METADATAFIELDS_LOADING'
export const FETCH_METADATAFIELDS_SUCCESS = 'FETCH_METADATAFIELDS_SUCCESS'
export const FETCH_METADATAFIELDS_ERROR = 'FETCH_METADATAFIELDS_ERROR'
export const UPDATE_METADATAFIELDS_LOADING = 'UPDATE_METADATAFIELDS_LOADING'
export const UPDATE_METADATAFIELDS_SUCCESS = 'UPDATE_METADATAFIELDS_SUCCESS'
export const UPDATE_METADATAFIELDS_ERROR = 'UPDATE_METADATAFIELDS_ERROR'

// RELATED to DND
export const MOVE_METADATAFIELD_ITEM = 'MOVE_METADATAFIELD_ITEM'
export const ACTIVATE_METADATAFIELD = 'ACTIVATE_METADATAFIELD'
export const REMOVE_METADATAFIELD_ITEM = 'REMOVE_METADATAFIELD_ITEM'
export const TOGGLE_METADATAFIELD_ITEM_FILTERABLE = 'TOGGLE_METADATAFIELD_ITEM_FILTERABLE'
export const TOGGLE_METADATAFIELD_ITEM_PERSONALIZABLE = 'TOGGLE_METADATAFIELD_ITEM_PERSONALIZABLE'
export const ADD_METADATAFIELD_ITEM = 'ADD_METADATAFIELD_ITEM'
export const TOGGLE_METADATAFIELD_INPUT = 'TOGGLE_METADATAFIELD_INPUT'
export const TOGGLE_METADATAITEM_INPUT = 'TOGGLE_METADATAITEM_INPUT'
export const TOGGLE_METADATA_ITEM = 'TOGGLE_METADATA_ITEM'
export const REMOVE_METADATA_ITEM = 'REMOVE_METADATA_ITEM'
export const MOVE_METADATA_ITEM = 'MOVE_METADATA_ITEM'
export const ADD_METADATA_ITEM = 'ADD_METADATA_ITEM'
export const RESET_FIELDS_CHANGED = 'RESET_FIELDS_CHANGED'
export const SET_FIELDS_CHANGED = 'SET_FIELDS_CHANGED'
export const TOGGLE_EDIT_METADATA_ITEM = 'TOGGLE_EDIT_METADATA_ITEM'
export const UPDATE_METADATA_ITEM = 'UPDATE_METADATA_ITEM'
export const TOGGLE_EDIT_METADATA_FIELD = 'TOGGLE_EDIT_METADATA_FIELD'
export const UPDATE_METADATA_FIELD = 'UPDATE_METADATA_FIELD'

export function searchMetadataFieldLoading(): ActionT {
  return {
    type: SEARCH_METADATAFIELD_LOADING,
  }
}

export function searchMetadataFieldSuccess(
  metadataFields: Array<MetadataFieldT> | string
): ActionT {
  return {
    type: SEARCH_METADATAFIELD_SUCCESS,
    payload: metadataFields,
  }
}

export function searchMetadataFieldError(err: ErrorT): ActionT {
  return {
    type: SEARCH_METADATAFIELD_ERROR,
    error: true,
    payload: err,
  }
}

export function searchMetadataField(name: string): Function {
  return (dispatch: Function, getState: Function, { fetch }) => {
    dispatch(searchMetadataFieldLoading())

    return new Promise((resolve: Function, reject: Function) => {
      fetch(`/proxy/greyhound/fields/?name=${name}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'same-origin',
      })
        .then(handleResponse)
        .then(function (metadataFields: Array<MetadataFieldT> | string) {
          resolve(metadataFields)
          dispatch(searchMetadataFieldSuccess(metadataFields))
        })
        .catch(handleError(reject, dispatch, searchMetadataFieldError))
    })
  }
}

export function fetchMetadataFieldsLoading(): ActionT {
  return {
    type: FETCH_METADATAFIELDS_LOADING,
  }
}

export function fetchMetadataFieldsSuccess(fields: Array<MetadataFieldT> | string): ActionT {
  return {
    type: FETCH_METADATAFIELDS_SUCCESS,
    payload: fields,
  }
}

export function fetchMetadataFieldsError(err: ErrorT): ActionT {
  return {
    type: FETCH_METADATAFIELDS_ERROR,
    error: true,
    payload: err,
  }
}

export function fetchMetadataFields(params: Object): Function {
  return (dispatch: Function, getState: Function, { fetch }) => {
    dispatch(fetchMetadataFieldsLoading())

    return new Promise((resolve: Function, reject: Function) => {
      fetch(`/proxy/greyhound/fields/${params ? '?' + queryString.stringify(params) : ''}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'same-origin',
      })
        .then(handleResponse)
        .then(function (fields: Array<MetadataFieldT> | string) {
          resolve(fields)
          dispatch(fetchMetadataFieldsSuccess(fields))
        })
        .catch(handleError(reject, dispatch, fetchMetadataFieldsError))
    })
  }
}

export function updateMetadataFieldsLoading(): ActionT {
  return {
    type: UPDATE_METADATAFIELDS_LOADING,
  }
}

export function updateMetadataFieldsSuccess(fields: Array<MetadataFieldT> | string): ActionT {
  return {
    type: UPDATE_METADATAFIELDS_SUCCESS,
    payload: fields,
  }
}

export function updateMetadataFieldsError(err: ErrorT): ActionT {
  return {
    type: UPDATE_METADATAFIELDS_ERROR,
    error: true,
    payload: err,
  }
}

export function updateMetadataFields(fields: Array<MetadataFieldT>): Function {
  return (dispatch: Function, getState: Function, { fetch }) => {
    dispatch(updateMetadataFieldsLoading())

    return new Promise((resolve: Function, reject: Function) => {
      fetch('/proxy/greyhound/fields/bulk/', {
        method: 'POST',
        body: JSON.stringify(fields),
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'same-origin',
      })
        .then(handleResponse)
        .then(function (fields: Array<MetadataFieldT> | string) {
          resolve(fields)
          dispatch(updateMetadataFieldsSuccess(fields))
        })
        .catch(handleError(reject, dispatch, updateMetadataFieldsError))
    })
  }
}

export function moveMetadataField(fromId: number, toId: number): ActionT {
  return {
    type: MOVE_METADATAFIELD_ITEM,
    payload: {
      fromId,
      toId,
    },
  }
}

export function activateMetadataField(id: number): ActionT {
  return {
    type: ACTIVATE_METADATAFIELD,
    payload: {
      id,
    },
  }
}

export function removeMetadataField(id: number): ActionT {
  return {
    type: REMOVE_METADATAFIELD_ITEM,
    payload: {
      id,
    },
  }
}

export function toggleMetadataFieldFilterable(id: number): ActionT {
  return {
    type: TOGGLE_METADATAFIELD_ITEM_FILTERABLE,
    payload: {
      id,
    },
  }
}

export function toggleMetadataFieldPersonalizable(id: number): ActionT {
  return {
    type: TOGGLE_METADATAFIELD_ITEM_PERSONALIZABLE,
    payload: {
      id,
    },
  }
}

export function addMetadataField(value: string, type: string): ActionT {
  return {
    type: ADD_METADATAFIELD_ITEM,
    payload: {
      value,
      type,
    },
  }
}

export function toggleMetadataFieldInput(type: string | void): ActionT {
  return {
    type: TOGGLE_METADATAFIELD_INPUT,
    payload: {
      type: type || null,
    },
  }
}

export function toggleMetadataItemInput(parentId: number | string | void): ActionT {
  return {
    type: TOGGLE_METADATAITEM_INPUT,
    payload: {
      parentId: parentId || null,
    },
  }
}

export function toggleMetadataItem(id: number): ActionT {
  return {
    type: TOGGLE_METADATA_ITEM,
    payload: {
      id,
    },
  }
}

export function removeMetadataItem(fieldId: number, id: number): ActionT {
  return {
    type: REMOVE_METADATA_ITEM,
    payload: {
      fieldId,
      id,
    },
  }
}

export function moveMetadataItem(
  fieldId: number,
  fromId: number,
  toId: number,
  isOffset: boolean
): ActionT {
  return {
    type: MOVE_METADATA_ITEM,
    payload: {
      fieldId,
      fromId,
      toId,
      isOffset,
    },
  }
}

export function addMetadataItem(fieldId: number, value: string, parent: number | null): ActionT {
  return {
    type: ADD_METADATA_ITEM,
    payload: {
      fieldId,
      value,
      parent: parent || null,
    },
  }
}

export function toggleEditMetadataItem(id: string | void): ActionT {
  return {
    type: TOGGLE_EDIT_METADATA_ITEM,
    payload: {
      id: id || null,
    },
  }
}

export function updateMetadataItem(fieldId: string, itemId: string, value: string): ActionT {
  return {
    type: UPDATE_METADATA_ITEM,
    payload: {
      fieldId,
      itemId,
      value,
    },
  }
}

export function toggleEditMetadataField(id: number | void): ActionT {
  return {
    type: TOGGLE_EDIT_METADATA_FIELD,
    payload: {
      id: id || null,
    },
  }
}

export function updateMetadataField(fieldId: number, value: string): ActionT {
  return {
    type: UPDATE_METADATA_FIELD,
    payload: {
      fieldId,
      value,
    },
  }
}

export function setFieldsHasChanged(): ActionT {
  return {
    type: SET_FIELDS_CHANGED,
  }
}

export function resetFieldsHasChanged(): ActionT {
  return {
    type: RESET_FIELDS_CHANGED,
  }
}

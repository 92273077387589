import { createSelector } from 'reselect'

import { getUserPreferences, getIsFetchingUserPreferences } from 'data/personalization/selectors'
import { getUnifiedFilterTokens } from 'data/filters/unified/selectors'

export const hasErrorFetchingStudies = state => state.studyHome.hasError

const __isFetchingStudies = state => state.studyHome.isFetching
export const isFetchingStudies = createSelector(
  __isFetchingStudies,
  getIsFetchingUserPreferences,
  getUserPreferences,
  (fetchingStudies, fetchingPrefs, userPrefs) =>
    fetchingStudies || (!userPrefs.length && fetchingPrefs)
)

export const getStudyCount = state => state.studyHome?.data?.count ?? 0
export const getStudySorting = state => state.studyHome.sorting
export const getCreateFormState = state => state.studyHome.createForm
export const getSuppliers = state => state.studyHome.availableSuppliers
export const getPagination = state => state.studyHome.pagination
export const getFilters = state => state.studyHome.filters
export const getSearchQuery = state => state.studyHome.search.query
export const isSearchLoading = state => state.studyHome.search.isLoading
export const hasNoCreatedStudies = createSelector(
  getUnifiedFilterTokens,
  getStudyCount,
  (filters, studyCount) => studyCount === 0 && filters.length === 0
)

export const getArePreferencesBoosted = state => Boolean(state.studyHome.sorting.boostPreferences)

const getStudies = state => state.studyHome?.data?.results ?? []

const getPreferencesByCategory = createSelector(getUserPreferences, preferences =>
  preferences.reduce((acc, category) => {
    acc[category.name] = category.values
    return acc
  }, {})
)

const studyMatchesPreferences = (study, preferences) =>
  study.metadata.some(category =>
    category.values.some(value =>
      (preferences[category.name] ?? []).includes(value[value.length - 1])
    )
  )

export const getACLPartitionedStudies = createSelector(
  getStudies,
  getPreferencesByCategory,
  getArePreferencesBoosted,
  (studies, preferences, shouldBoost) =>
    studies.reduce(
      (acc, study) => {
        if (study.permissions.view) {
          if (shouldBoost && studyMatchesPreferences(study, preferences)) {
            acc.preferredStudies.push(study)
          } else {
            acc.accessibleStudies.push(study)
          }
        } else {
          acc.inaccessibleStudies.push(study)
        }
        return acc
      },
      { preferredStudies: [], accessibleStudies: [], inaccessibleStudies: [] }
    )
)

import React, { useState, useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { Modal } from '@knowledgehound/laika'
import { Analysis2Store } from '@knowledgehound/analysis'

import {
  removeAllPreferences,
  setUserHasViewedModal,
  setShowToolTip,
} from 'data/personalization/actions'
import { getUserHasPreferences } from 'data/personalization/selectors'

import bannerImage from './banner.svg'
import UserPreferenceSelection from './UserPreferenceSelection'
import * as styles from './UserPreferenceModal.module.scss'

const propTypes = {
  /** Redux action to call to refetch data behind the modal. */
  fetchAction: PropTypes.func.isRequired,
  /** Override whether the modal is open or not. Normally opens when user is not onboarded. */
  isOpen: PropTypes.bool,
  /** Callback triggered when user attempts to close modal. Use with `isOpen` prop. */
  onClose: PropTypes.func,
}

function UserPreferenceModal({ fetchAction, isOpen, onClose }) {
  const dispatch = useDispatch()
  const userHasPreferences = useSelector(getUserHasPreferences, shallowEqual)
  const userHasNotViewedModal = useSelector(
    Analysis2Store.config.getUserHasNotViewedModal,
    shallowEqual
  )
  const [internalModalOpen, setInternalModalOpen] = useState(true)

  useEffect(() => {
    dispatch(setShowToolTip(false)) //workaround, as tooltip otherwise may display above modal
  }, [dispatch])

  const handleCloseModal = useCallback(() => {
    setInternalModalOpen(false)
    onClose()
  }, [onClose])

  const handleCancelModal = useCallback(() => {
    window.analytics.track(`preferences dismissed`)
    if (userHasPreferences) {
      dispatch(setUserHasViewedModal(true))
      dispatch(setShowToolTip(true))
    }
    handleCloseModal()
  }, [dispatch, handleCloseModal, userHasPreferences])

  const handleDontPersonalize = useCallback(() => {
    window.analytics.track(`dont personalize selected`)
    dispatch(removeAllPreferences())
    handleCloseModal()
    dispatch(setShowToolTip(true))
  }, [dispatch, handleCloseModal])

  const handleUserPreferenceSubmit = useCallback(() => {
    window.analytics.track(`preferences saved`)
    dispatch(setUserHasViewedModal(true))
    handleCloseModal()
    dispatch(setShowToolTip(true))
  }, [dispatch, handleCloseModal])

  return (
    <Modal
      isOpen={(userHasNotViewedModal && internalModalOpen) || isOpen}
      onClose={handleCancelModal}
      secondaryActionText="Don't personalize my experience"
      onSecondaryAction={userHasNotViewedModal ? handleDontPersonalize : null}
      onSubmit={handleUserPreferenceSubmit}
      submitText="Finish and save preferences"
      title="Personalize KnowledgeHound"
      variant="slideRight"
    >
      <img
        className={styles.banner}
        src={bannerImage}
        alt="Grid of selected and deselected preferences"
      />
      <h1 className={styles.header}>
        Find answers faster. Stay up-to-date on the latest insights.
      </h1>
      <UserPreferenceSelection
        fetchAction={fetchAction}
        userHasNotViewedModal={userHasNotViewedModal}
      />
    </Modal>
  )
}

UserPreferenceModal.propTypes = propTypes

export default UserPreferenceModal

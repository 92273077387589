// extracted by mini-css-extract-plugin
export var active = "MainNavbar-module__active--omhFm";
export var baseTiming = "125ms";
export var baseTimingInt = "125";
export var borderRadius = "3px";
export var charcoal = "#2d2e2e";
export var cloudGrey = "#c8cdcf";
export var doveGrey = "#f1f3f5";
export var hoverPath = "MainNavbar-module__hover-path--yA8fH";
export var infoMsg = "MainNavbar-module__info-msg--Lopz5";
export var khBlue = "#04b1e0";
export var khBlueDark = "#0376ae";
export var khBlueLight = "#7de1f4";
export var lemon = "#fae436";
export var lemonDark = "#e3cd02";
export var lemonLight = "#fff284";
export var lime = "#05c150";
export var limeDark = "#068230";
export var limeLight = "#65ec83";
export var logoSearchBanner = "MainNavbar-module__logo-search-banner--Lpmlj";
export var logoSmall = "MainNavbar-module__logo-small--h7pGd";
export var mainNavbar = "MainNavbar-module__main-navbar--rxMqv";
export var menuIcon = "MainNavbar-module__menu-icon--GvIGS";
export var menuItem = "MainNavbar-module__menu-item--NijBe";
export var menuItems = "MainNavbar-module__menu-items--BKmWO";
export var menuItemsSmall = "MainNavbar-module__menu-items-small--qXoFi";
export var navLogoImg = "MainNavbar-module__nav-logo-img--qdSuY";
export var navy = "#052976";
export var navyDark = "#04173f";
export var navyLight = "#82b1f6";
export var rightCondensed = "MainNavbar-module__right-condensed--e+n3X";
export var scarlet = "#e9062b";
export var scarletDark = "#9d050e";
export var scarletLight = "#f5726d";
export var stormGrey = "#969b9c";
export var userDropDownMenuItem = "MainNavbar-module__user-drop-down-menu-item--KHuDM";
export var violet = "#d401f8";
export var violetDark = "#9003a8";
export var violetLight = "#e77ef9";
export var white = "#fff";
/*
  Takes a sample object and extracts the question id to retrieve the question detail object for
  the next question in the chain

  @sample - A question sample object formatted as {id : {operator : value}}
  returns an integer representing the question detail id
*/
export function extractQuestionId(sample) {
  const keys = Object.keys(sample)
  if (!isNaN(parseInt(keys[0], 10))) {
    // return question key
    return parseInt(keys[0], 10)
  }
  return null
}

/*
  Assembles an object that represents one part (or the entirety) of the
  final question sample string
  @detailQuestion - An object containing the detail information about a question
  @detectedSample - The detected sample object returned from the analytics engine
  currently in the form of {pk : {$operator : value}}

  returns a sample object
*/
export function assembleSampleObject(detailQuestion, detectedSample) {
  const operator = Object.keys(detectedSample[detailQuestion.pk])[0]
  const value = detectedSample[detailQuestion.pk][operator]

  return {
    pk: detailQuestion.pk,
    nk: detailQuestion.nk,
    question_text: detailQuestion.question_text,
    operator,
    value,
    question_detail: detailQuestion,
  }
}

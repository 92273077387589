import React from 'react'
import { useSelector, shallowEqual } from 'react-redux'

import Card from 'components/Card'
import {
  getStudies,
  getHasFetchedStudies,
  getIsFetchingStudies,
  getStudiesHasError,
} from 'data/home/selectors'
import { monthWithYear } from 'util/dateHelpers'
import imageUri from 'thumbnails/no_studies.png'
import EmptyRow from './EmptyRow'
import CardRow from './CardRow'
import LoadingCardSkeleton from './LoadingCardSkeleton'

export default function HomeStudiesCards() {
  const studies = useSelector(getStudies, shallowEqual)
  const hasFetched = useSelector(getHasFetchedStudies, shallowEqual)
  const isFetching = useSelector(getIsFetchingStudies, shallowEqual)
  const hasError = useSelector(getStudiesHasError, shallowEqual)
  const isEmpty = !studies.length

  return (
    <CardRow
      linkTo="/studies"
      showViewAll={!isEmpty}
      sectionMessageId="homepage.sections.addedStudies"
      viewAllMessageId="homepage.sections.viewAllStudies"
    >
      {hasError && (
        <EmptyRow imgUri={imageUri} altText="Problem fetching studies">
          There was a problem loading your studies. Please refresh and try again.
        </EmptyRow>
      )}
      {isFetching && <LoadingCardSkeleton />}
      {hasFetched && isEmpty ? (
        <EmptyRow imgUri={imageUri} altText="No studies loaded">
          There are no studies loaded, but you can load one from the top navigation bar!
        </EmptyRow>
      ) : (
        <React.Fragment>
          {studies.map(study => (
            <Card
              key={study.id}
              preheader={study.date ? monthWithYear(study.date) : undefined}
              header={study.name}
              footer={study.id}
              linkTo={`/studies/${study.id}`}
            />
          ))}
        </React.Fragment>
      )}
    </CardRow>
  )
}

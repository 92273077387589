import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import { setQueryString as setReduxQuery } from 'data/results/actions'

import homepageImage from './kh-homepage-bg.png'

import * as styles from './HomeSearchHeader.module.scss'

export default function HomeSearchHeader() {
  const [queryString, setQueryString] = useState('')
  const dispatch = useDispatch()
  const history = useHistory()

  return (
    <div
      id="quick-answers"
      className={styles.homepageHeader}
      style={{
        backgroundImage: `url('${homepageImage}'), linear-gradient(168deg, #3ac5e9, #04b4e4)`,
      }}
    >
      <form
        role="search"
        className={styles.searchContainer}
        onSubmit={event => {
          event.preventDefault()
          dispatch(setReduxQuery(queryString))
          history.push(`/results?query=${encodeURIComponent(queryString)}`)
        }}
      >
        <input
          type="search"
          className={styles.searchInput}
          aria-label="search-text"
          value={queryString}
          placeholder="Search and discover insights"
          onChange={event => setQueryString(event.target.value)}
        />
      </form>
    </div>
  )
}

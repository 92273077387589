// @flow
import moment from 'moment'

export function monthWithYear(date: string): string {
  return moment(date).format('MMMM YYYY')
}

export function formatReadableDate(date: string): string {
  return moment(date).format('YYYY-MM-DD')
}

import { createSelector } from 'reselect'
import { selectors as filesSelectors } from '@knowledgehound/files'

import { getCurrentSessionData, currentUserIsDIYAdmin } from 'data/session/SessionSelectors'
import { getAvailableGroups } from 'data/domains/selectors'

export const getDatasets = state => state.studyDatasets.datasets
export const getEditMode = state => state.studyDatasets.editMode

export const getDatasetsLoading = createSelector(getDatasets, datasets => datasets.isFetching)

export const getDatasetList = createSelector(getDatasets, datasets => datasets.data?.results ?? [])

export const getDatasetListResult = createSelector(
  getDatasets,
  datasets => datasets.data?.results?.[0] ?? {}
)

export const getUserHasAccessToDataset = createSelector(
  getDatasetListResult,
  dataset => dataset?.permissions?.view ?? true // Pass-through when no dataset present
)

export const getIsDIYStudy = createSelector(getDatasetListResult, dataset =>
  Boolean(dataset.external_type)
)

export const getIsFirstDatasetProcessing = createSelector(
  getDatasetListResult,
  dataset => dataset?.version_number === 0 && dataset?.ready === false
)

export const areDatasetQuestionLabelsEnabled = createSelector(
  getDatasetListResult,
  dataset => dataset.question_labels_enabled ?? false
)

export const getDefaultAnalysisFilters = createSelector(getDatasetListResult, dataset =>
  dataset.default_filters ? JSON.parse(dataset.default_filters) : []
)

export const getQuestionSearchQuery = state => state.studyDatasets.questions.searchQuery
export const areQuestionNamesShowing = state => state.studyDatasets.questions.showingQuestionNames
export const getAvailableQuestionTags = state => state.studyDatasets.questions.tags

export const getDatasetStatusCancelCallback = state =>
  state.studyDatasets.datasets.statusCancelCallback

export const getIsDatasetSelfLoaded = createSelector(
  getDatasetListResult,
  filesSelectors.getFilesList,
  (dataset, files) => {
    const dataFile = files.find(
      file =>
        file.name.includes(dataset.dataset_name) && // No full file name in Collie...
        file.versionNumber === dataset.version_number
    )

    if (!dataFile) return false
    return dataFile.fileType === 'user_data'
  }
)

/** Applies for DIY and manager self-loaded studies */
export const getDatasetPublishState = createSelector(getDatasets, datasets => {
  const [readyDataset] = datasets.data?.results ?? []
  if (!readyDataset) return ''

  const state = readyDataset.current_state
  if (!state) return 'Restricted'
  if (state.startsWith('Awaiting')) return 'Publishing'

  const stateMap = {
    Ready: 'Published',
    'Needs Annotation': 'Unpublished',
    'Parsing Failed': 'Import Error',
    ERROR: 'Import Error',
  }
  return stateMap[state] || 'Importing'
})

export const getIsPublishingStudy = state => state.studyDatasets.datasets.isPublishingStudy

export const getHasErrorPublishingStudy = state =>
  Boolean(state.studyDatasets.datasets.hasErrorPublishingStudy)

export const getDatasetQuestions = createSelector([getDatasetListResult], datasetResult =>
  datasetResult &&
  datasetResult.permissions &&
  datasetResult.permissions.view &&
  datasetResult.questions
    ? datasetResult.questions
    : []
)

export const getFilterDatasetQuestions = createSelector([getDatasetListResult], datasetResult => {
  if (!datasetResult || !datasetResult.questions) return []
  if (datasetResult.permissions && datasetResult.permissions.view) {
    return datasetResult.questions
  }
  return datasetResult.questions.filter(q => q.unrestricted_filter)
})

const getVisibleQuestions = createSelector(
  getDatasets,
  getDatasetQuestions,
  getCurrentSessionData,
  currentUserIsDIYAdmin,
  (datasets, datasetQuestions, session, isDIYAdmin) => {
    const datasetsList = datasets.data
    const isManager = session && session.manager

    let externalType = null
    let annotated = false
    let selfLoadedDataset = false
    if (datasetsList) {
      datasetsList.results.forEach(result => {
        annotated ||= result.annotated
        if (result.external_type) {
          externalType = result.external_type
        }
        selfLoadedDataset = result.previewable
      })
    }
    const diyDataset = externalType && externalType !== 'Data API'
    let questions = []
    if ((isDIYAdmin && diyDataset) || (isManager && selfLoadedDataset) || annotated) {
      questions = datasetQuestions.filter(
        q => q.main_var_list || q.suggested_xtab || (isDIYAdmin && diyDataset)
      )
    }
    return questions
  }
)

export const getVisibleQuestionsSuggestedBottom = createSelector(getVisibleQuestions, questions => {
  const { main, suggested } = questions.reduce(
    (acc, question) => {
      if (question.suggested_xtab) {
        acc.suggested.push(question)
      } else {
        acc.main.push(question)
      }
      return acc
    },
    { suggested: [], main: [] }
  )

  return [...main, ...suggested]
})

export const getQuestionsWithSearchSuggested = createSelector(
  getVisibleQuestions,
  getVisibleQuestionsSuggestedBottom,
  getAvailableQuestionTags,
  getQuestionSearchQuery,
  areDatasetQuestionLabelsEnabled,
  areQuestionNamesShowing,
  (questions, questionsSuggestedBottom, selectedTags, searchQuery, labelsEnabled, namesShowing) => {
    const query = searchQuery.toLowerCase()
    const hasAppliedTagFilter = Object.values(selectedTags).some(category => category.selected)

    if (!searchQuery && !hasAppliedTagFilter) return questionsSuggestedBottom

    const sortedResults = questions.flatMap(question => {
      const responseMatches = question.categorical_responses.filter(response =>
        response.toLowerCase().includes(query)
      )
      const breakoutMatches = question.breakouts.filter(breakout =>
        breakout.toLowerCase().includes(query)
      )
      const questionTextMatches = question.question_text.toLowerCase().includes(query)
      const tagCategoryMatches = Object.keys(question.tags).filter(
        category => selectedTags[category].selected
      )
      let questionNameMatches
      if (namesShowing) {
        questionNameMatches = question.question_name.toLowerCase().includes(query)
      }
      if (labelsEnabled) {
        questionNameMatches = question.question_label?.toLowerCase().includes(query)
      }
      if (
        (!searchQuery.length ||
          questionTextMatches ||
          questionNameMatches ||
          responseMatches.length ||
          breakoutMatches.length) &&
        (!hasAppliedTagFilter || tagCategoryMatches.length)
      ) {
        return {
          ...question,
          // questionNameMatches,
          innerHits: responseMatches.length + breakoutMatches.length - 3,
          matches: [...responseMatches, ...breakoutMatches].slice(0, 3),
        }
      }
      return [] // omit from results
    })
    return sortedResults
  }
)

export const getQuestionsPresent = createSelector(getVisibleQuestions, visibleQuestions =>
  Boolean(visibleQuestions && visibleQuestions.length)
)

export const getAreQuestionsPatching = state => getDatasets(state).isPatchingQuestion

export const getQuestionPatchingFailed = state => getDatasets(state).error

export const getFailedDatasetImports = createSelector(getDatasets, datasets =>
  (datasets?.data?.results ?? []).reduce((acc, dataset) => {
    if (dataset.external_type) return acc.concat(dataset.failed_imports)
    return acc
  }, [])
)

export const getIsSavingNewACLs = state => getDatasets(state).isSavingNewACLs
export const getStagedACLsChanges = state => getDatasets(state).stagedACLsChanges

const getCurrentGroupMapping = createSelector(getDatasetListResult, dataset =>
  (dataset.group_restrictions ?? []).reduce((acc, group) => {
    acc[group] = true
    return acc
  }, {})
)

export const getHasStagedACLsChanged = createSelector(
  getAvailableGroups,
  getStagedACLsChanges,
  getCurrentGroupMapping,
  (allGroups, stagedChanges, currentGroups) => {
    for (const group of allGroups) {
      if ((stagedChanges[group] ?? 0) ^ (currentGroups[group] ?? 0)) {
        return true
      }
    }
    return false
  }
)

const getGridStagingArea = state => state.studyDatasets.questions.gridEditing

export const getIsCreatingGridStagingArea = state =>
  getGridStagingArea(state).isFetchingPotentialOptions
export const getHasErrorCreatingGridStagingArea = state =>
  Boolean(getGridStagingArea(state).errorFetchingPotentialOptions)

export const getStagedGridQuestionName = state => getGridStagingArea(state).targetQuestionName
export const getStagedGridText = state => getGridStagingArea(state).targetQuestionText
export const getStagedGridOptions = state => getGridStagingArea(state).options

export const getIsCommittingGridChanges = state => getGridStagingArea(state).isCommittingGridChanges
export const getHasErrorCommittingGridChanges = state =>
  Boolean(getGridStagingArea(state).errorCommittingGridChanges)

export const getIsUngroupingGrid = state => getGridStagingArea(state).isUngroupingGridQuestion
export const getHasErrorUngroupingGrid = state =>
  Boolean(getGridStagingArea(state).errorUngroupingGridQuestion)

export const getHandleTrack = createSelector(
  getDatasetListResult,
  dataset => (eventName, properties) =>
    window.analytics.track(eventName, {
      study_id: dataset.studyId,
      study_name: dataset.studyName,
      client: dataset.client,
      dataset_nk: dataset.datasetNk,
      dataset: dataset.dataset_name,
      ...properties,
    })
)

export const getSomeOptionsSelected = createSelector(getStagedGridOptions, gridOptions =>
  gridOptions.some(option => option.active)
)

export const getActiveGridOptions = createSelector(getStagedGridOptions, gridOptions =>
  gridOptions.filter(option => option.active)
)

export const getCompletedQuestionCount = createSelector(
  getDatasetListResult,
  result => result.current_question_count
)

export const getTotalQuestionCount = createSelector(
  getDatasetListResult,
  result => result.total_questions_count ?? 0
)

export const getPercentageQuestionsComplete = createSelector(
  getTotalQuestionCount,
  getCompletedQuestionCount,
  (totalQuestions, completedQuestions) => {
    if (completedQuestions === null) return
    const percent = Math.trunc((completedQuestions / totalQuestions) * 100)
    return percent
  }
)

import _uniqueId from 'lodash/uniqueId'

import type { MetadataFieldT } from 'data/metadata/metadataField/MetadataFieldT'

export default function addMetadataField(
  metadataFields: Array<MetadataValueT>,
  value: string,
  field_type: string
): Array<MetadataFieldT> {
  return [
    ...metadataFields,
    {
      name: value,
      field_type,
      filterable: false,
      id: _uniqueId('metadataField_'),
      position: metadataFields[metadataFields.length - 1].position + 1,
      values: [],
    },
  ]
}

import { setIntervalBackOff } from '@knowledgehound/thunked'
import * as Sentry from '@sentry/react'

import {
  getItems,
  getPollCancelCallback,
  getReportId,
  getStudyId,
  getGlobalFilters,
  isPolling,
} from './selectors'

const BASE_URL = `${process.env.REACT_APP_DALMATIAN_API2_URL || ''}/reports`

export const CREATE_REPORT_SUCCESS = 'SR_CREATE_REPORT_SUCCESS'
const __createReportSuccess = (studyId, reportId, items) => ({
  type: CREATE_REPORT_SUCCESS,
  studyId,
  reportId,
  items,
})

export const CREATE_REPORT_ERROR = 'SR_CREATE_REPORT_ERROR'
const __createReportError = error => ({
  type: CREATE_REPORT_ERROR,
  error,
})

export const CLEAR_REPORT = 'SR_CLEAR_REPORT'
const __clearReport = () => ({
  type: CLEAR_REPORT,
})

export const clearReport = () => dispatch => {
  dispatch(stopPolling())
  dispatch(__clearReport())
}

export const SET_GLOBAL_FILTERS = 'SR_SET_GLOBAL_FILTERS'
export const setGlobalFilters = filters => ({
  type: SET_GLOBAL_FILTERS,
  filters,
})

export const CLEAR_GLOBAL_FILTERS = 'SR_CLEAR_GLOBAL_FILTERS'
export const clearGlobalFilters = () => ({
  type: CLEAR_GLOBAL_FILTERS,
})

export const SET_POLL_CANCEL_CALLBACK = 'SR_SET_POLL_INTERVAL_ID'
const __setItemPollCancelCallback = pollCancelCallback => ({
  type: SET_POLL_CANCEL_CALLBACK,
  pollCancelCallback,
})

export const CLEAR_POLL_CANCEL_CALLBACK = 'SR_CLEAR_POLL_INTERVAL_ID'
const __clearItemPollCancelCallback = () => ({
  type: CLEAR_POLL_CANCEL_CALLBACK,
})

export const stopPolling = () => (dispatch, getState) => {
  getPollCancelCallback(getState())?.()
  dispatch(__clearItemPollCancelCallback())
}

export const UPDATE_ITEMS_STATUS = 'SR_UPDATE_ITEMS_STATUS'
const __updateItemsStatus = (items, pptxUrl) => ({
  type: UPDATE_ITEMS_STATUS,
  items,
  pptxUrl,
})

export const UPDATE_ITEM = 'SR_SET_ITEM_CONTENT'
const __updateItem = (itemId, content) => ({
  type: UPDATE_ITEM,
  itemId,
  content,
})

const __fetchSummaryItem =
  itemId =>
  async (dispatch, getState, { fetch }) => {
    const reportId = getReportId(getState())
    const studyId = getStudyId(getState())
    try {
      const itemRes = await fetch(`${BASE_URL}/${studyId}/${reportId}/items/${itemId}/`)
      const {
        analysis_data: analysisData,
        analysis_state: analysisState,
        stats_data: statsData,
        error_data: errorData,
        state_id: stateId,
      } = await itemRes.json()
      dispatch(__updateItem(itemId, { analysisData, analysisState, statsData, errorData, stateId }))
    } catch (error) {
      Sentry.captureException(new Error('Failed to download summary report item', { cause: error }))
      dispatch(__updateItem(itemId, { errorData: { code: 'generic', error } }))
    }
  }

const __pollReportStatus =
  () =>
  async (dispatch, getState, { fetch }) => {
    try {
      const reportId = getReportId(getState())
      const studyId = getStudyId(getState())
      const currentItems = getItems(getState())
      const statusRes = await fetch(`${BASE_URL}/${studyId}/${reportId}/items/`)

      if (!statusRes.ok) {
        dispatch(__createReportError(new Error('Failed to fetch item status')))
        dispatch(stopPolling())
        return
      }

      const {
        complete: completeItemCount,
        total: totalItems,
        items: nextItems,
        pptx_url: pptxUrl,
      } = await statusRes.json()

      dispatch(__updateItemsStatus(nextItems, pptxUrl))

      if (completeItemCount === totalItems && Boolean(pptxUrl)) {
        dispatch(stopPolling())
      }

      await Promise.all(
        nextItems.map(nextItem => {
          if (nextItem.state === 'complete' && currentItems[nextItem.id].state !== 'complete') {
            return dispatch(__fetchSummaryItem(nextItem.id))
          }
          return Promise.resolve()
        })
      )
    } catch (error) {
      Sentry.captureException(new Error('Failed to check summary report status', { cause: error }))
      dispatch(__createReportError(error))
      dispatch(stopPolling())
    }
  }

export const createSummaryReport =
  studyId =>
  async (dispatch, getState, { fetch }) => {
    if (isPolling(getState())) return

    try {
      const filters = getGlobalFilters(getState())
      const creationRes = await fetch(`${BASE_URL}/${studyId}/summary/`, {
        method: 'POST',
        jsonBody: { filters },
      })

      if (!creationRes.ok) {
        dispatch(__createReportError('TODO'))
        return
      }

      const { id: reportId, items } = await creationRes.json()
      dispatch(__createReportSuccess(studyId, reportId, items))
    } catch (error) {
      Sentry.captureException(new Error('Failed to create summary report', { cause: error }))
      dispatch(__createReportError(error))
      return
    }

    const { cancel: cancelCallback } = setIntervalBackOff(
      () => {
        dispatch(__pollReportStatus())
      },
      { growthRate: 1.5, linearScalar: 2000 }
    )

    dispatch(__setItemPollCancelCallback(cancelCallback))
  }

export const SET_SEARCH_VALUE = 'SET_SEARCH_VALUE'
export const setSearchValue = searchValue => ({
  type: SET_SEARCH_VALUE,
  searchValue: searchValue,
})

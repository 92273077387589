import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Helmet } from 'react-helmet'
import { withRouter } from 'react-router-dom'
import { NotificationStack } from 'react-notification'
import { renderRoutes } from 'react-router-config'
import * as Sentry from '@sentry/react'
import queryString from 'query-string'
import _omit from 'lodash/omit'
import NProgress from 'nprogress'
import type { Match, RouterHistory, Location } from 'react-router-dom'
import { LoadingSpinner } from '@knowledgehound/laika'
import ScrollToTop from '@knowledgehound/ui-theme/navigation/ScrollToTop'
import ErrorPage from '@knowledgehound/ui-theme/layout/error/ErrorPage'
import notificationTypes from '@knowledgehound/ui-theme/notifications/notificationTypes'

import * as session from 'data/session'
import { setQueryString } from 'data/results/actions'
import { fetchSession } from 'data/session/SessionActions'
import ViewerFrame from 'components/viewer/ViewerFrame'
import ViewFrame from 'components/viewer/ViewFrame'
import type { CurrentSessionT } from 'data/session/SessionStateT'
import { getError } from 'data/ErrorSelector'
import type { ErrorSelectorT } from 'data/ErrorSelector'
import { fetchClientBranding } from 'data/clientBranding/actions'
import routes from './routeConfig'
import MainNavbar from './MainNavbar'
import NotificationContext from 'NotificationContext'
import ViewerContext from 'ViewerContext'

// Import to ensure it is copied
import qual_placeholder from 'thumbnails/kh-placeholder-open-ends.svg'
import quant_placeholder from 'thumbnails/kh-placeholder-quant.svg'

import './App.scss'

type PropsT = {
  session: CurrentSessionT,
  error: ErrorSelectorT,
  match: Match,
  history: RouterHistory,
  location: Location,
  route: any,
}

type StateT = {
  notifications: Array<Object>,
  viewerUrl: null | string,
  viewUrl: null | string,
  viewPage: null | number,
  searchQuery: string,
  appFetchingError: null | Error,
}

NProgress.configure({ showSpinner: false })
class App extends Component<PropsT, StateT> {
  state: StateT

  static childContextTypes = {
    sendNotification: PropTypes.func,
    showViewer: PropTypes.func,
    showNewViewer: PropTypes.func,
    viewerDocPath: PropTypes.func,
    newViewerDocPath: PropTypes.func,
    setSearchQuery: PropTypes.func,
  }

  getChildContext() {
    return {
      sendNotification: this.sendNotification,
      showViewer: this.showViewer,
      showNewViewer: this.showNewViewer,
      viewerDocPath: this.viewerDocPath,
      newViewerDocPath: this.newViewerDocPath,
      setSearchQuery: this.setSearchQuery,
    }
  }

  constructor(props) {
    super(props)

    const params = queryString.parse(props.location.search)

    this.quant_placeholder = quant_placeholder
    this.qual_placeholder = qual_placeholder

    this.state = {
      notifications: [],
      viewerUrl: params.viewer || null,
      viewUrl: params.view || null,
      viewPage: params.page || 1,
      searchQuery: props.location.pathname.includes('/results') ? params.query || '' : '',
      appFetchingError: null,
    }
  }

  componentDidMount() {
    this.identifyUser(this.props.session)
    this.props.dispatch(fetchClientBranding())

    // TODO: Remove this redirect if no clients trigger it
    const angularRouteMatch = /^#(\/.+)$/g.exec(this.props.location.hash)
    if (angularRouteMatch) {
      this.props.history.push(angularRouteMatch[1])
      window.analytics.track('Angular Route Redirect', { angularPath: angularRouteMatch[1] })
      return
    }

    // Transition user to client homepage URL if set
    const homepageUrl = this.props.session.data.homepage_url
    if (['/', '/home'].includes(this.props.location.pathname) && homepageUrl) {
      const path = new URL(homepageUrl).pathname
      this.props.history.push(path)
    }
  }

  redirectToLogin() {
    window.location.assign(
      `/login?next=${encodeURIComponent(window.location.pathname)}${encodeURIComponent(
        window.location.search
      )}`
    )
  }

  componentWillReceiveProps(nextProps) {
    const { session: nextSession } = nextProps

    // check for errors on any action that result in an unauthorized response
    if (nextProps.error && nextProps.error.error && nextProps.error.error.response) {
      const { status } = nextProps.error.error.response
      if (status === 401 || status === 403) return this.redirectToLogin()
    }

    if (!nextSession.isFetching && (!nextSession.data || !nextSession.data.valid_login)) {
      return this.redirectToLogin()
    }

    // see if the route has changed
    const current = this.props.location.pathname
    const next = nextProps.location.pathname
    if (current === next) {
      return
    }

    //the url has changed, update intercom and fetch the new route data
    window.Intercom('update')

    //if a url comes in with a viewer
    const params = queryString.parse(nextProps.location.search)

    if (params.viewer && params.viewer.length > 0) {
      this.setState({
        viewerUrl: params.viewer,
      })
    }

    if (params.view && params.view.length > 0) {
      this.setState({
        viewUrl: params.view,
        viewPage: params.page || 1,
      })
    }

    if (params.query && params.query.length > 0) {
      this.setState({
        searchQuery: nextProps.location.pathname.includes('/results') ? params.query || '' : '',
      })
    }
    this.props.dispatch(fetchSession())
  }

  shouldComponentUpdate(nextProps, nextState) {
    // same check is needed here to that there is not a flash of rendering
    if (nextProps.error && nextProps.error.error && nextProps.error.error.response) {
      const { status } = nextProps.error.error.response
      if (status === 401 || status === 403) return false
    }
    return true
  }

  setSearchQuery = searchQuery => {
    this.setState({
      searchQuery,
    })
  }

  getUserId(userSession) {
    return `${userSession.client_id}:${userSession.email}`
  }

  userTrackingRole(user) {
    return user.admin ? 'admin' : user.manager ? 'manager' : 'user'
  }

  analyticsIdentify(userSession) {
    const permissions = Object.entries(userSession).reduce((acc, [key, value]) => {
      const result = key.match(/^(.+)_enabled$/)
      if (result) {
        acc[result[1]] = Boolean(value)
      }
      return acc
    }, {})

    window.analytics.identify(
      this.getUserId(userSession),
      {
        name: `${userSession.first_name} ${userSession.last_name}`,
        email: userSession.email,
        company: userSession.client_id,
        role: this.userTrackingRole(userSession),
        created_at: userSession.created,
        organization: {
          name: userSession.client_id, // Required for ClientSuccess integration
        },
        permissions,
      },
      {
        /**
         * Note: Segment and Intercom's documentation conflicts. Using Intercom's method may
         * prevent other destinations from receiving identify calls
         * https://segment.com/docs/connections/destinations/catalog/intercom/#identity-verification
         * https://segment.com/docs/connections/destinations/catalog/intercom/#identity-verification-plus-filtering-via-destinations-object
         * https://app.intercom.io/a/apps/qkcvgcqo/settings/identity-verification/web
         */
        Intercom: {
          user_hash: userSession.intercom_hash,
        },
      }
    )
  }

  analyticsGroup(userSession) {
    window.analytics.group(userSession.client_id, {
      name: userSession.client_id,
      company: userSession.client_id,
    })
  }

  identifyUser(session: CurrentSessionT) {
    const userSession = session.data

    if (userSession) {
      const role = this.userTrackingRole(userSession)
      this.analyticsGroup(userSession)
      this.analyticsIdentify(userSession)

      Sentry.setUser({ email: userSession.email })

      if (window.Intercom) {
        window.Intercom('boot', {
          app_id: process.env.REACT_APP_INTERCOM_APP_ID,
          name: `${userSession.first_name} ${userSession.last_name}`,
          email: userSession.email,
          created_at: userSession.created,
          user_hash: userSession.intercom_hash,
          user_id: this.getUserId(userSession),
          role: role,
          client: userSession.client_id,
          company: {
            id: userSession.client_id,
            name: userSession.client_id,
          },
        })
      }
    }
  }

  removeNotification = notification => {
    this.setState({
      notifications: this.state.notifications.filter(
        activeNotification => activeNotification.key !== notification.key
      ),
    })
  }

  sendNotification = (key: string, type: string, message: string, options = {}) => {
    this.setState({
      notifications: this.state.notifications.concat({
        message,
        key,
        action: options.action ?? 'Dismiss',
        dismissAfter: options.dismissAfter ?? 6000,
        barStyle: notificationTypes[type].barStyle,
        actionStyle: notificationTypes[type].actionStyle,
        centered: Boolean(options.centered),
        onClick: notification => {
          options.onClick?.()
          this.removeNotification(notification)
        },
      }),
    })
  }

  showViewer = (url: string, target: string = '_self') => {
    const { history, location } = this.props
    if (target !== '_self') {
      window.open(window.location.pathname + this.mergedQueryString({ viewerUrl: url }), target)
      return
    }
    this.setState(
      {
        viewerUrl: url,
      },
      () => {
        history.replace(`${location.pathname}?viewer=${encodeURIComponent(url)}`)
      }
    )
  }

  mergeQueryParams(params) {
    const { location } = this.props

    const search = queryString.parse(location.search)

    return {
      ...search,
      ...params,
    }
  }

  mergedQueryString(params) {
    return `?${queryString.stringify(params)}`
  }

  viewerDocPath = (url: string) => {
    const { location } = this.props
    const mergedParams = this.mergeQueryParams({ viewer: url })
    return location.pathname + this.mergedQueryString(mergedParams)
  }

  newViewerDocPath = (url: string) => {
    const { location } = this.props
    const mergedParams = this.mergeQueryParams({ view: url })
    return location.pathname + this.mergedQueryString(mergedParams)
  }

  showNewViewer = (url: string, target: string = '_self', page: number = 1) => {
    const { history, location } = this.props
    if (target !== '_self') {
      window.open(window.location.pathname + this.mergedQueryString({ view: url, page }), target)
      return
    }
    this.setState(
      {
        viewUrl: url,
        viewPage: page,
      },
      () => {
        const newSearch = this.mergeQueryParams({ view: url, page })

        history.replace({
          pathname: location.pathname,
          search: this.mergedQueryString(newSearch),
        })
      }
    )
  }

  hideViewer = () => {
    const { history, location } = this.props
    this.setState(
      {
        viewerUrl: null,
      },
      () => {
        const search = queryString.parse(location.search)
        const newSearch = _omit(search, ['viewer'])

        history.replace({
          pathname: location.pathname,
          search: this.mergedQueryString(newSearch),
        })
      }
    )
  }

  hideNewViewer = () => {
    const { history, location } = this.props
    this.setState(
      {
        viewUrl: null,
        viewPage: null,
      },
      () => {
        const search = queryString.parse(location.search)
        const newSearch = _omit(search, ['page', 'view'])

        history.replace({
          pathname: location.pathname,
          search: this.mergedQueryString(newSearch),
        })
      }
    )
  }

  updateViewerSlug = url => {
    const { history, location } = this.props
    history.replace(`${location.pathname}?viewer=${encodeURIComponent(url)}`)
    history.replace({
      search: queryString.stringify(
        this.mergeQueryParams({
          viewer: url,
        })
      ),
    })
  }

  updateViewerPage = pageNumber => {
    const { history } = this.props
    history.replace({
      search: queryString.stringify(
        this.mergeQueryParams({
          view: this.state.viewUrl,
          page: pageNumber,
        })
      ),
    })
  }

  handleClickSupport = (evt: Event) => {
    window.analytics.track('Support')
  }

  handleChangeSearch = (evt: SyntheticEvent<HTMLInputElement>) => {
    this.setState({
      searchQuery: evt.currentTarget.value,
    })
  }

  handleSubmitSearch = (evt: SyntheticEvent<>) => {
    evt.preventDefault()
    this.props.dispatch(setQueryString(this.state.searchQuery))
    this.props.history.push(`/results?query=${encodeURIComponent(this.state.searchQuery)}`)
  }

  renderAppRoutes() {
    const { appFetchingError } = this.state

    if (appFetchingError) {
      Sentry.captureException(new Error('Top-level error', { cause: appFetchingError }))

      return (
        <ErrorPage
          leadText="Oops!"
          text="There was a problem with this page."
          actionItem={
            <button className="button primary" onClick={this.handleClickSupport}>
              Contact us
            </button>
          }
        />
      )
    }

    return renderRoutes(routes)
  }

  handleClickLogout = evt => {
    window.Intercom('shutdown')
  }

  render() {
    const { session } = this.props
    const user =
      session && session.data && session.data.valid_login
        ? {
            first_name: session.data.first_name,
            last_name: session.data.last_name,
            email: session.data.email,
            role: session.data.role,
            admin: session.data.admin,
            manager: session.data.manager,
            client_banner: session.data.client_banner,
          }
        : null

    const homepageUrl: string =
      session.data && session.data.homepage_url ? session.data.homepage_url : '/home'

    return (
      <NotificationContext.Provider value={{ sendNotification: this.sendNotification }}>
        <ViewerContext.Provider
          value={{ showNewViewer: this.showNewViewer, newViewerDocPath: this.newViewerDocPath }}
        >
          <ScrollToTop>
            <section className="App">
              <Helmet titleTemplate="%s | Knowledgehound" />
              <ViewerFrame
                viewerUrl={this.state.viewerUrl}
                onClose={this.hideViewer}
                updateViewerSlug={this.updateViewerSlug}
              />
              <ViewFrame
                viewUrl={this.state.viewUrl}
                viewPage={this.state.viewPage}
                onClose={this.hideNewViewer}
                onPageChanged={this.updateViewerPage}
              />
              <section className="App__content">
                <MainNavbar
                  user={user}
                  homepageUrl={homepageUrl}
                  onClickLogout={this.handleClickLogout}
                />
                <React.Suspense fallback={<LoadingSpinner centered text="One Moment..." />}>
                  {this.renderAppRoutes()}
                </React.Suspense>
              </section>
              <NotificationStack
                notifications={this.state.notifications}
                onDismiss={this.removeNotification}
                barStyleFactory={(index, styles, notification) =>
                  notification.centered
                    ? {
                        ...styles,
                        left: '50%',
                        bottom: '-100%',
                        transform: 'translateX(-50%)',
                        zIndex: 9999,
                      }
                    : {
                        ...styles,
                        bottom: `${2 + index * 4}rem`,
                        zIndex: 9999,
                      }
                }
                activeBarStyleFactory={(index, styles, notification) =>
                  notification.centered
                    ? {
                        ...styles,
                        left: '50%',
                        bottom: `${4 + index * 4}rem`,
                        transform: 'translateX(-50%)',
                        zIndex: 9999,
                      }
                    : { ...styles, zIndex: 9999 }
                }
              />
            </section>
          </ScrollToTop>
        </ViewerContext.Provider>
      </NotificationContext.Provider>
    )
  }
}

const mapStateToProps = state => ({
  session: session.selectors.getCurrentSession(state),
  error: getError(state),
})

const connectedApp = connect(mapStateToProps)(App)

export default withRouter(connectedApp)

const UPDATE_STORY_CARD_POSITION = 'UPDATE_STORY_CARD_POSITION'

export const updateStoryCardPosition = (newPosition, atPosition, card) => ({
  type: UPDATE_STORY_CARD_POSITION,
  payload: {
    newPosition,
    atPosition,
    card,
  },
})

// This should eventually be migrated into the @knowledgehound npm library.
// For now the cases are just added on the outside using a HOC
const higherReducer = reducer => (state, action) => {
  const { payload, type } = action
  switch (type) {
    // NOTE: WE DON'T UPDATE THE `position` key within the cards here!
    // The position is updated on the backend, but just the ordering of the array
    // is updated on the frontend in order to give immediate response to the user.
    // Then if the backend call fails, the original positions (which are still there)
    // are then used to revert the array back to its original state.
    case UPDATE_STORY_CARD_POSITION: {
      const newOrdering = state.currentStoryCards.data.slice()
      newOrdering.splice(payload.newPosition, 1)
      newOrdering.splice(payload.atPosition, 0, payload.card)
      return {
        ...state,
        currentStoryCards: {
          ...state.currentStoryCards,
          data: newOrdering,
        },
      }
    }
    case 'UPDATE_STORY_CARD_ERROR': {
      const reordered = state.currentStoryCards.data.sort((a, b) => a.position - b.position)
      return reducer(
        {
          ...state,
          currentStoryCards: {
            ...state.currentStoryCards,
            data: reordered,
          },
        },
        action
      )
    }
    default:
      return reducer(state, action)
  }
}

export default higherReducer

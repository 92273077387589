import moment from 'moment'
import { set, get } from 'lodash'

const buildFilterState = (aggregations, stateKey) =>
  aggregations.reduce(
    (filters, field) => ({
      ...filters,
      [field.name]: field.values.reduce((map, { name, full_name, email, count }) => {
        if (Array.isArray(name)) {
          let _count = count
          for (let i = name.length; i > 0; i -= 1) {
            const path = name.slice(0, i).join(':::')
            _count += get(map, `${path}.count`, 0)
            map[path] = {
              label: name[i - 1],
              count: _count,
              hasChildren: i !== name.length,
              selected: false,
            }
          }
        } else if (field.name === 'study_year') {
          const label = moment.utc(name).year()
          map[label] = {
            label,
            count,
            hasChildren: false,
            selected: false,
          }
        } else {
          map[name] = {
            label:
              stateKey === 'storyFilters' && field.name === 'author' ? full_name || email : name,
            count,
            hasChildren: false,
            selected: false,
          }
        }
        return map
      }, {}),
    }),
    {}
  )

export const initialState = { initialState: true }

/**
 * This is not a standalone store reducer, rather it's a module designed to fit
 * inside a reducer and handle and maintain filter state based on ElasticSearch
 * aggregations from the backend.
 *
 * @param {Object} actionBindings - Action type mapping
 * @param {Object} state - Redux state object
 * @param {Object} action - Redux action object
 * @returns {(Object|boolean)} New state object if this module handled the action, otherwise
 * returns false if the host reducer should process the action.
 */
export default function SharedFilterModule(
  { initAction, updateAction, setSelectionAction, clearAction },
  state,
  action
) {
  switch (action.type) {
    case initAction:
      return {
        ...state,
        ...set({}, action.stateKey, buildFilterState(action.aggregations, action.stateKey)),
        initialState: false,
      }
    case updateAction:
      const incomingState = buildFilterState(action.aggregations, action.stateKey)
      return {
        ...state,
        ...set(
          {},
          action.stateKey,
          Object.entries(get(state, action.stateKey)).reduce((filters, [fieldKey, fieldValues]) => {
            return {
              ...filters,
              [fieldKey]: Object.entries(fieldValues).reduce((map, [key, value]) => {
                if (!incomingState[fieldKey][key]) {
                  map[key] = {
                    label: value.label,
                    count: 0,
                    hasChildren: value.hasChildren,
                    selected: value.selected,
                  }
                } else {
                  map[key] = {
                    label: value.label,
                    count: incomingState[fieldKey][key].count,
                    hasChildren: value.hasChildren,
                    selected: value.selected,
                  }
                }
                return map
              }, {}),
            }
          }, {})
        ),
      }
    case setSelectionAction:
      return {
        ...state,
        [action.stateKey]: {
          ...state[action.stateKey],
          [action.fieldKey]: {
            ...state[action.stateKey][action.fieldKey],
            [action.key]: {
              ...state[action.stateKey][action.fieldKey][action.key],
              selected:
                action.value === undefined
                  ? !state[action.stateKey][action.fieldKey][action.key].selected
                  : action.value,
            },
          },
        },
      }
    case clearAction:
      return {
        ...state,
        [action.stateKey]: Object.entries(get(state, action.stateKey)).reduce(
          (categoryAcc, [category, values]) => {
            categoryAcc[category] = Object.entries(values).reduce(
              (valuesAcc, [valueName, data]) => {
                valuesAcc[valueName] = {
                  ...data,
                  selected: false,
                }
                return valuesAcc
              },
              {}
            )
            return categoryAcc
          },
          {}
        ),
      }
    default:
      return false // Fall through to host reducer
  }
}

import * as actions from './FavoriteActions'

export const initialReducerState = {
  newFavorite: {
    isFetching: false,
    error: null,
    data: null,
    lastFetched: null,
  },
  favorites: {
    isFetching: false,
    error: null,
    data: [],
    details: {},
    lastFetched: null,
  },
}

export const favoriteReducer = (state = initialReducerState, action = {}) => {
  switch (action.type) {
    case actions.CREATE_FAVORITE_LOADING: {
      return {
        ...state,
        newFavorite: {
          ...state.newFavorite,
          isFetching: true,
          error: null,
          data: null,
        },
      }
    }
    case actions.CREATE_FAVORITE_SUCCESS: {
      const lastFetched = Date.now()
      return {
        ...state,
        newFavorite: {
          ...state.newFavorite,
          isFetching: false,
          lastFetched,
          data: action.payload,
          error: null,
        },
        favorites: {
          ...state.favorites,
          lastFetched,
          data: [...state.favorites.data, action.payload],
        },
      }
    }
    case actions.CREATE_FAVORITE_ERROR: {
      const lastFetched = Date.now()
      return {
        ...state,
        newFavorite: {
          ...state.newFavorite,
          isFetching: false,
          lastFetched,
          error: action.payload,
        },
      }
    }
    case actions.DELETE_FAVORITE_LOADING: {
      return {
        ...state,
        favorites: {
          ...state.favorites,
          isFetching: true,
          error: null,
        },
      }
    }
    case actions.DELETE_FAVORITE_SUCCESS: {
      const lastFetched = Date.now()
      const { favoriteId } = action.payload
      const { details: updatedDetails, data } = { ...state.favorites }
      delete updatedDetails[favoriteId]
      return {
        ...state,
        favorites: {
          ...state.favorites,
          isFetching: false,
          lastFetched,
          data: data.filter(f => f.id !== favoriteId),
          details: updatedDetails,
          error: null,
        },
      }
    }
    case actions.DELETE_FAVORITE_ERROR: {
      const lastFetched = Date.now()
      return {
        ...state,
        favorites: {
          ...state.favorites,
          isFetching: false,
          lastFetched,
          error: action.payload,
        },
      }
    }
    case actions.FETCH_FAVORITES_LOADING: {
      return {
        ...state,
        favorites: {
          ...state.favorites,
          isFetching: true,
          error: null,
          data: [],
          details: {},
        },
      }
    }
    case actions.FETCH_FAVORITES_SUCCESS: {
      const lastFetched = Date.now()
      const favorites = action.payload
      const favDetails = favorites.reduce((acc, f) => {
        acc[f.id] = {
          loading: true,
          data: null,
          error: false,
        }
        return acc
      }, {})
      return {
        ...state,
        favorites: {
          ...state.favorites,
          isFetching: false,
          lastFetched,
          data: action.payload,
          details: favDetails,
          error: null,
        },
      }
    }
    case actions.FETCH_FAVORITES_ERROR: {
      const lastFetched = Date.now()
      return {
        ...state,
        favorites: {
          ...state.favorites,
          isFetching: false,
          lastFetched,
          error: action.payload,
        },
      }
    }
    case actions.SET_FAVORITES_DETAILS: {
      const lastFetched = Date.now()
      const { fId, favDetails } = action.payload
      return {
        ...state,
        favorites: {
          ...state.favorites,
          lastFetched,
          details: {
            ...state.favorites.details,
            [fId]: favDetails,
          },
        },
      }
    }
    default:
      return state
  }
}

const COLLIE_URL = process.env.REACT_APP_COLLIE_URL || ''

export const publishSurvey =
  (integrationName, surveyId) =>
  async (dispatch, getState, { fetch }) => {
    return fetch(`${COLLIE_URL}/${integrationName}/surveys/${surveyId}/publish/`, {
      method: 'POST',
    })
  }

export const FETCH_INTEGRATION_STATUS_LOADING = 'FETCH_INTEGRATION_STATUS_LOADING'
const __fetchIntegrationStatusLoading = () => ({
  type: FETCH_INTEGRATION_STATUS_LOADING,
})

export const FETCH_INTEGRATION_STATUS_SUCCESS = 'FETCH_INTEGRATION_STATUS_SUCCESS'
const __fetchIntegrationStatusSuccess = response => ({
  type: FETCH_INTEGRATION_STATUS_SUCCESS,
  payload: response,
})

export const FETCH_INTEGRATION_STATUS_ERROR = 'FETCH_INTEGRATION_STATUS_ERROR'
const __fetchIntegrationStatusError = err => ({
  type: FETCH_INTEGRATION_STATUS_ERROR,
  payload: err,
})

export const fetchIntegrationStatus =
  () =>
  async (dispatch, getState, { fetch }) => {
    dispatch(__fetchIntegrationStatusLoading())

    const response = await fetch(`${COLLIE_URL}/survey_integrations/status/`)

    if (!response.ok) {
      dispatch(__fetchIntegrationStatusError(response.statusText))
      // TODO: Use selector to detect errors in caller, don't throw
      throw new Error(response)
    }

    const data = await response.json()
    dispatch(__fetchIntegrationStatusSuccess(data))
  }

export const UPDATE_INTEGRATION_OPTION_LOADING = 'UPDATE_INTEGRATION_OPTION_LOADING'
const updateIntegrationOptionLoading = integration => ({
  type: UPDATE_INTEGRATION_OPTION_LOADING,
  integration,
})

export const UPDATE_INTEGRATION_OPTION_SUCCESS = 'UPDATE_INTEGRATION_OPTION_SUCCESS'
const updateIntegrationOptionSuccess = (response: IntegrationTokenT | string) => ({
  type: UPDATE_INTEGRATION_OPTION_SUCCESS,
  payload: response,
})

export const UPDATE_INTEGRATION_OPTION_ERROR = 'UPDATE_INTEGRATION_OPTION_ERROR'
const updateIntegrationOptionError = err => ({
  type: UPDATE_INTEGRATION_OPTION_ERROR,
  error: true,
  payload: err,
})

/**
 * @param {String} integration - Name of the integration being modified?
 * @param {String} token - API token of the integration
 * @param {String} option - ???
 * @param {Boolean} value - ???
 */
export const updateIntegrationOption =
  (integration, token, option, value) =>
  async (dispatch, getState, { fetch }) => {
    dispatch(updateIntegrationOptionLoading(integration))

    const response = await fetch(`${COLLIE_URL}/survey_integrations/${integration}/token/update/`, {
      method: 'PATCH',
      jsonBody: {
        api_key: token,
        [option]: value,
      },
    })

    if (!response.ok) {
      dispatch(updateIntegrationOptionError(response.statusText))
      throw new Error(response)
    }

    const data = await response.json()
    dispatch(updateIntegrationOptionSuccess(data))
  }

export const FETCH_INTEGRATION_TOKEN_LOADING = 'FETCH_INTEGRATION_TOKEN_LOADING'
const fetchIntegrationTokenLoading = integration => ({
  type: FETCH_INTEGRATION_TOKEN_LOADING,
  payload: integration,
})

export const FETCH_INTEGRATION_TOKEN_SUCCESS = 'FETCH_INTEGRATION_TOKEN_SUCCESS'
const fetchIntegrationTokenSuccess = (response: IntegrationTokenT | string) => ({
  type: FETCH_INTEGRATION_TOKEN_SUCCESS,
  payload: response,
})

export const FETCH_INTEGRATION_TOKEN_ERROR = 'FETCH_INTEGRATION_TOKEN_ERROR'
const fetchIntegrationTokenError = (integration, error) => ({
  type: FETCH_INTEGRATION_TOKEN_ERROR,
  integration,
  payload: error,
})

/**
 * @param {String} integration - Name of the integration
 */
export const fetchIntegrationToken =
  integration =>
  async (dispatch, getState, { fetch }) => {
    dispatch(fetchIntegrationTokenLoading(integration))

    const response = await fetch(`${COLLIE_URL}/survey_integrations/${integration}/token/`)

    if (!response.ok) {
      dispatch(fetchIntegrationTokenError(integration, response.statusText))
      if (integration !== 'decipher' || response.status !== 404) {
        throw new Error(response)
      }
    }

    const data = await response.json()
    dispatch(fetchIntegrationTokenSuccess(data))
    return data
  }

export const SET_SURVEY_SEARCH_QUERY = 'SET_SURVEY_SEARCH_QUERY'
export const setSurveySearchQuery = searchQuery => ({
  type: SET_SURVEY_SEARCH_QUERY,
  searchQuery,
})

// @flow
import { createSelector } from 'reselect'

import listToTree from 'data/metadata/metadataField/listToTree'

const metadataFieldSearch = state => state.metadataField.metadataFieldSearch

export const getMetadataFieldSearch = createSelector(
  [metadataFieldSearch],
  metadataFieldSearch => metadataFieldSearch
)

const metadataFields = state => state.metadataField.metadataFields

export const getMetadataFields = createSelector([metadataFields], metadataFields => metadataFields)

const updateMetadataFields = state => state.metadataField.updateMetadataFields

export const getUpdateMetadataFields = createSelector(
  [updateMetadataFields],
  updateMetadataFields => updateMetadataFields
)

const activeMetadataFieldId = state => state.metadataField.activeMetadataFieldId

export const getActiveMetadataFieldId = createSelector(
  [activeMetadataFieldId],
  activeMetadataFieldId => activeMetadataFieldId
)

const activeMetadataFieldInput = state => state.metadataField.activeMetadataFieldInput

export const getActiveMetadataFieldInput = createSelector(
  [activeMetadataFieldInput],
  activeMetadataFieldInput => activeMetadataFieldInput
)

const collapsedMetadataItems = state => state.metadataField.collapsedMetadataItems

export const getCollapsedMetadataItems = createSelector(
  [collapsedMetadataItems],
  collapsedMetadataItems => collapsedMetadataItems
)

const activeMetadataItemInput = state => state.metadataField.activeMetadataItemInput

export const getActiveMetadataItemInput = createSelector(
  [activeMetadataItemInput],
  activeMetadataItemInput => activeMetadataItemInput
)

const activeEditMetadataItem = state => state.metadataField.activeEditMetadataItem

export const getActiveEditMetadataItem = createSelector(
  [activeEditMetadataItem],
  activeEditMetadataItem => activeEditMetadataItem
)

const activeEditMetadataField = state => state.metadataField.activeEditMetadataField

export const getActiveEditMetadataField = createSelector(
  [activeEditMetadataField],
  activeEditMetadataField => activeEditMetadataField
)

const fieldsHasChanged = state => state.metadataField.fieldsHasChanged

export const getFieldsHasChanged = createSelector(
  [fieldsHasChanged],
  fieldsHasChanged => fieldsHasChanged
)

export const getMetadataNodesTree = createSelector(
  [metadataFields, activeMetadataFieldId],
  (metadataFields, activeMetadataFieldId) => {
    if (!activeMetadataFieldId) return []
    const activeMetadataField = metadataFields.data.find(
      field => field.id === activeMetadataFieldId
    )
    if (
      activeMetadataField &&
      activeMetadataField.values &&
      activeMetadataField.values.length > 0
    ) {
      return listToTree(activeMetadataField.values)
    } else {
      return []
    }
  }
)

import { getStoriesEnabled, getUserEmail } from 'data/session/SessionSelectors'

import { getIsFetchingStudies, getIsFetchingStories, getIsFetchingFavorites } from './selectors'

const DALMATIAN = process.env.REACT_APP_DALMATIAN_API2_URL || ''
const GREYHOUND = process.env.REACT_APP_GREYHOUND_URL || ''

export const HOME_FETCH_STUDIES_START = 'HOME_FETCH_STUDIES_START'
const __fetchStudiesStart = () => ({
  type: HOME_FETCH_STUDIES_START,
})

export const HOME_FETCH_STUDIES_SUCCESS = 'HOME_FETCH_STUDIES_SUCCESS'
const __fetchStudiesSuccess = data => ({
  type: HOME_FETCH_STUDIES_SUCCESS,
  data,
})

export const HOME_FETCH_STUDIES_ERROR = 'HOME_FETCH_STUDIES_ERROR'
const __fetchStudiesError = error => ({
  type: HOME_FETCH_STUDIES_ERROR,
  error,
})

export const fetchStudies =
  () =>
  async (dispatch, getState, { fetch }) => {
    if (getIsFetchingStudies(getState())) return
    dispatch(__fetchStudiesStart())

    const params = new URLSearchParams({
      page: 1,
      page_size: 5,
      sort: '-study_year',
      restrict: 1,
    })
    const response = await fetch(`/proxy/husky/studies/?${params.toString()}`)

    if (!response.ok) {
      dispatch(__fetchStudiesError(response.statusText))
      return
    }

    const data = await response.json()

    dispatch(
      __fetchStudiesSuccess(
        data.results.map(study => ({
          name: study.name,
          date: study.study_date,
          id: study.study_number,
        }))
      )
    )
  }

export const HOME_FETCH_STORIES_START = 'HOME_FETCH_STORIES_START'
const __fetchStoriesStart = () => ({
  type: HOME_FETCH_STORIES_START,
})

export const HOME_FETCH_STORIES_SUCCESS = 'HOME_FETCH_STORIES_SUCCESS'
const __fetchStoriesSuccess = data => ({
  type: HOME_FETCH_STORIES_SUCCESS,
  data,
})

export const HOME_FETCH_STORIES_ERROR = 'HOME_FETCH_STORIES_ERROR'
const __fetchStoriesError = error => ({
  type: HOME_FETCH_STORIES_ERROR,
  error,
})

export const fetchStories =
  () =>
  async (dispatch, getState, { fetch }) => {
    if (getIsFetchingStories(getState()) || !getStoriesEnabled(getState())) {
      return
    }
    dispatch(__fetchStoriesStart())

    const response = await fetch(`${DALMATIAN}/stories/?restrict=1`)

    if (!response.ok) {
      dispatch(__fetchStoriesError(response.statusText))
      return
    }

    const data = await response.json()

    dispatch(
      __fetchStoriesSuccess(
        data
          .sort((a, b) => new Date(b.modified) - new Date(a.modified))
          .slice(0, 5)
          .map(story => ({
            id: story.id,
            title: story.title,
            author: story.author,
            modified: story.modified,
          }))
      )
    )
  }

export const HOME_FETCH_RECENT_FAVORITES_START = 'HOME_FETCH_RECENT_FAVORITES_START'
const __fetchRecentFavoritesStart = () => ({
  type: HOME_FETCH_RECENT_FAVORITES_START,
})

export const HOME_FETCH_RECENT_FAVORITES_SUCCESS = 'HOME_FETCH_RECENT_FAVORITES_SUCCESS'
const __fetchRecentFavoritesSuccess = data => ({
  type: HOME_FETCH_RECENT_FAVORITES_SUCCESS,
  data,
})

export const HOME_FETCH_RECENT_FAVORITES_ERROR = 'HOME_FETCH_RECENT_FAVORITES_ERROR'
const __fetchRecentFavoritesError = error => ({
  type: HOME_FETCH_RECENT_FAVORITES_ERROR,
  error,
})

export const fetchRecentFavorites =
  () =>
  async (dispatch, getState, { fetch }) => {
    if (getIsFetchingFavorites(getState())) return
    dispatch(__fetchRecentFavoritesStart())

    const params = new URLSearchParams({ user: getUserEmail(getState()) })
    const response = await fetch(`${DALMATIAN}/favorites/?${params.toString()}`)

    if (!response.ok) {
      dispatch(__fetchRecentFavoritesError(response.statusText))
      return
    }

    const rawData = await response.json()

    let data = rawData.map(fave => ({
      itemType: fave.item_type,
      id: fave.id,
      itemId: fave.item_id,
      created: fave.created,
      modified: fave.modified,
    }))

    if (!getStoriesEnabled(getState())) {
      data = data.filter(fave => fave.itemType !== 'story')
    }

    data = data.sort((a, b) => new Date(b.modified) - new Date(a.modified)).slice(0, 5)

    const completeData = await Promise.all(
      data.map(async resource => {
        if (resource.itemType === 'story') {
          const storyResponse = await fetch(`${DALMATIAN}/stories/${resource.itemId}/`)
          if (!storyResponse.ok) return {} // favorite was deleted

          const story = await storyResponse.json()
          return { ...resource, title: story.title }
        }
        if (resource.itemType === 'study') {
          const studyResponse = await fetch(`${GREYHOUND}/studies/${resource.itemId}/`)
          if (!studyResponse.ok) return {} // favorite was deleted

          const study = await studyResponse.json()
          return { ...resource, title: study.study_name }
        }
      })
    )

    dispatch(__fetchRecentFavoritesSuccess(completeData.filter(fav => fav.title)))
  }

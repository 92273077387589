// @flow
import * as actions from './MetadataFieldActions'
import type { MetadataFieldStateT } from './MetadataFieldStateT'
import type { MetadataFieldT } from 'data/metadata/metadataField/MetadataFieldT'
import moveMetadataField from 'data/metadata/metadataField/moveMetadataField'
import removeMetadata from 'data/metadata/metadataField/removeMetadataField'
import toggleMetadataFieldFilterable from 'data/metadata/metadataField/toggleMetadataFieldFilterable'
import toggleMetadataFieldPersonalizable from 'data/metadata/metadataField/toggleMetadataFieldPersonalizable'
import addMetadataField from 'data/metadata/metadataField/addMetadataField'
import removeMetadataItem from 'data/metadata/metadataField/removeMetadataItem'
import moveMetadataItem from 'data/metadata/metadataField/moveMetadataItem'
import addMetadataItem from 'data/metadata/metadataField/addMetadataItem'
import updateMetadataItem from 'data/metadata/metadataField/updateMetadataItem'
import updateMetadataField from 'data/metadata/metadataField/updateMetadataField'

type ActionT = {
  type: string,
  payload: any,
}

export const metadataFieldReducer = (
  state: MetadataFieldStateT = {
    metadataField: {
      isFetching: true,
      error: null,
      data: null,
      lastFetched: null,
    },
    metadataFieldSearch: {
      isFetching: true,
      error: null,
      data: null,
      lastFetched: null,
    },
    metadataFields: {
      isFetching: true,
      error: null,
      data: null,
      lastFetched: null,
    },
    updateMetadataFields: {
      isFetching: false,
      error: null,
      data: null,
      lastFetched: null,
    },
    activeMetadataFieldId: null,
    activeMetadataFieldInput: null,
    collapsedMetadataItems: [],
    activeMetadataItemInput: null,
    activeEditMetadataItem: null,
    activeEditMetadataField: null,
    fieldsHasChanged: false,
  },
  action: ActionT
): MetadataFieldStateT => {
  switch (action.type) {
    case actions.SEARCH_METADATAFIELD_LOADING: {
      return {
        ...state,
        metadataFieldSearch: {
          ...state.metadataFieldSearch,
          isFetching: true,
          error: null,
        },
      }
    }
    case actions.SEARCH_METADATAFIELD_SUCCESS: {
      const lastFetched = Date.now()
      return {
        ...state,
        metadataFieldSearch: {
          ...state.metadataFieldSearch,
          isFetching: false,
          lastFetched,
          data: action.payload,
          error: null,
        },
      }
    }
    case actions.SEARCH_METADATAFIELD_ERROR: {
      const lastFetched = Date.now()
      return {
        ...state,
        metadataFieldSearch: {
          ...state.metadataFieldSearch,
          isFetching: false,
          lastFetched,
          error: action.payload,
        },
      }
    }
    case actions.FETCH_METADATAFIELDS_LOADING: {
      return {
        ...state,
        metadataFields: {
          ...state.metadataFields,
          isFetching: true,
          error: null,
        },
      }
    }
    case actions.FETCH_METADATAFIELDS_SUCCESS: {
      const lastFetched = Date.now()
      return {
        ...state,
        metadataFields: {
          ...state.metadataFields,
          isFetching: false,
          lastFetched,
          data: action.payload,
          error: null,
        },
      }
    }
    case actions.FETCH_METADATAFIELDS_ERROR: {
      const lastFetched = Date.now()
      return {
        ...state,
        metadataFields: {
          ...state.metadataFields,
          isFetching: false,
          lastFetched,
          error: action.payload,
        },
      }
    }
    case actions.UPDATE_METADATAFIELDS_LOADING: {
      return {
        ...state,
        updateMetadataFields: {
          ...state.updateMetadataFields,
          isFetching: true,
          error: null,
        },
      }
    }
    case actions.UPDATE_METADATAFIELDS_SUCCESS: {
      const lastFetched = Date.now()
      return {
        ...state,
        updateMetadataFields: {
          ...state.updateMetadataFields,
          isFetching: false,
          lastFetched,
          data: action.payload,
          error: null,
        },
      }
    }
    case actions.UPDATE_METADATAFIELDS_ERROR: {
      const lastFetched = Date.now()
      return {
        ...state,
        updateMetadataFields: {
          ...state.updateMetadataFields,
          isFetching: false,
          lastFetched,
          error: action.payload,
        },
      }
    }
    case actions.MOVE_METADATAFIELD_ITEM: {
      if (state.metadataFields.data) {
        return {
          ...state,
          metadataFields: {
            ...state.metadataFields,
            data: moveMetadataField(state.metadataFields.data, {
              fromId: action.payload.fromId,
              toId: action.payload.toId,
            }),
          },
        }
      }
      return state
    }
    case actions.ACTIVATE_METADATAFIELD: {
      return {
        ...state,
        activeMetadataFieldId: action.payload.id,
      }
    }
    case actions.REMOVE_METADATAFIELD_ITEM: {
      if (state.metadataFields.data) {
        return {
          ...state,
          fieldsHasChanged: true,
          metadataFields: {
            ...state.metadataFields,
            data: removeMetadata(state.metadataFields.data, action.payload.id),
          },
        }
      }
      return state
    }
    case actions.TOGGLE_METADATAFIELD_ITEM_FILTERABLE: {
      if (state.metadataFields.data) {
        return {
          ...state,
          fieldsHasChanged: true,
          metadataFields: {
            ...state.metadataFields,
            data: toggleMetadataFieldFilterable(state.metadataFields.data, action.payload.id),
          },
        }
      }
      return state
    }
    case actions.TOGGLE_METADATAFIELD_ITEM_PERSONALIZABLE: {
      if (state.metadataFields.data) {
        return {
          ...state,
          fieldsHasChanged: true,
          metadataFields: {
            ...state.metadataFields,
            data: toggleMetadataFieldPersonalizable(state.metadataFields.data, action.payload.id),
          },
        }
      }
      return state
    }
    case actions.ADD_METADATAFIELD_ITEM: {
      const { value, type } = action.payload
      return {
        ...state,
        fieldsHasChanged: true,
        metadataFields: {
          ...state.metadataFields,
          data: addMetadataField(state.metadataFields.data, value, type),
        },
      }
    }
    case actions.TOGGLE_METADATAFIELD_INPUT: {
      const { type } = action.payload
      return {
        ...state,
        activeMetadataFieldInput: type,
      }
    }
    case actions.TOGGLE_METADATA_ITEM: {
      const id = action.payload.id
      const collapsed = [].concat(state.collapsedMetadataItems)

      if (id && collapsed.includes(id)) {
        collapsed.splice(
          collapsed.findIndex(collapseId => collapseId === id),
          1
        )
      } else {
        collapsed.push(id)
      }

      return {
        ...state,
        collapsedMetadataItems: collapsed,
      }
    }
    case actions.REMOVE_METADATA_ITEM: {
      if (state.metadataFields.data) {
        const fieldIndex = state.metadataFields.data.findIndex(field => {
          return field.id === action.payload.fieldId
        })

        if (fieldIndex !== -1 && state.metadataFields.data) {
          const newField = removeMetadataItem(
            state.metadataFields.data[fieldIndex],
            action.payload.id
          )
          const newFieldData: Array<MetadataFieldT> | Array<any> = [].concat(
            state.metadataFields.data
          )
          newFieldData[fieldIndex] = newField

          return {
            ...state,
            fieldsHasChanged: true,
            metadataFields: {
              ...state.metadataFields,
              data: newFieldData,
            },
          }
        } else {
          return state
        }
      } else {
        return state
      }
    }
    case actions.ADD_METADATA_ITEM: {
      if (state.metadataFields.data) {
        const fieldIndex = state.metadataFields.data.findIndex(field => {
          return field.id === action.payload.fieldId
        })

        if (fieldIndex !== -1 && state.metadataFields.data) {
          const newField = addMetadataItem(
            state.metadataFields.data[fieldIndex],
            action.payload.value,
            action.payload.parent
          )
          const newFieldData: Array<MetadataFieldT> | Array<any> = [].concat(
            state.metadataFields.data
          )
          newFieldData[fieldIndex] = newField

          return {
            ...state,
            fieldsHasChanged: true,
            metadataFields: {
              ...state.metadataFields,
              data: newFieldData,
            },
          }
        } else {
          return state
        }
      } else {
        return state
      }
    }
    case actions.UPDATE_METADATA_ITEM: {
      if (state.metadataFields.data) {
        const fieldIndex = state.metadataFields.data.findIndex(field => {
          return field.id === action.payload.fieldId
        })

        if (fieldIndex !== -1 && state.metadataFields.data) {
          const newField = updateMetadataItem(
            state.metadataFields.data[fieldIndex],
            action.payload.itemId,
            action.payload.value
          )
          const newFieldData: Array<MetadataFieldT> | Array<any> = [].concat(
            state.metadataFields.data
          )
          newFieldData[fieldIndex] = newField

          return {
            ...state,
            fieldsHasChanged: true,
            metadataFields: {
              ...state.metadataFields,
              data: newFieldData,
            },
          }
        } else {
          return state
        }
      } else {
        return state
      }
    }
    case actions.UPDATE_METADATA_FIELD: {
      if (state.metadataFields.data) {
        const fieldIndex = state.metadataFields.data.findIndex(field => {
          return field.id === action.payload.fieldId
        })

        if (fieldIndex !== -1 && state.metadataFields.data) {
          const newField = updateMetadataField(
            state.metadataFields.data[fieldIndex],
            action.payload.value
          )
          const newFieldData: Array<MetadataFieldT> | Array<any> = [].concat(
            state.metadataFields.data
          )
          newFieldData[fieldIndex] = newField

          return {
            ...state,
            fieldsHasChanged: true,
            metadataFields: {
              ...state.metadataFields,
              data: newFieldData,
            },
          }
        } else {
          return state
        }
      } else {
        return state
      }
    }
    case actions.MOVE_METADATA_ITEM: {
      if (state.metadataFields.data) {
        const fieldIndex = state.metadataFields.data.findIndex(field => {
          return field.id === action.payload.fieldId
        })

        if (fieldIndex !== -1 && state.metadataFields.data) {
          const newField = moveMetadataItem(
            state.metadataFields.data[fieldIndex],
            action.payload.fromId,
            action.payload.toId,
            action.payload.isOffset
          )
          const newFieldData: Array<MetadataFieldT> | Array<any> = [].concat(
            state.metadataFields.data
          )
          newFieldData[fieldIndex] = newField
          return {
            ...state,
            metadataFields: {
              ...state.metadataFields,
              data: newFieldData,
            },
          }
        } else {
          return state
        }
      } else {
        return state
      }
    }
    case actions.TOGGLE_METADATAITEM_INPUT: {
      const { parentId } = action.payload
      return {
        ...state,
        activeMetadataItemInput: parentId || null,
      }
    }
    case actions.TOGGLE_EDIT_METADATA_ITEM: {
      const { id } = action.payload
      return {
        ...state,
        activeEditMetadataItem: id || null,
      }
    }
    case actions.TOGGLE_EDIT_METADATA_FIELD: {
      const { id } = action.payload
      return {
        ...state,
        activeEditMetadataField: id || null,
      }
    }
    case actions.RESET_FIELDS_CHANGED: {
      return {
        ...state,
        fieldsHasChanged: false,
      }
    }
    case actions.SET_FIELDS_CHANGED: {
      return {
        ...state,
        fieldsHasChanged: true,
      }
    }
    default:
      return state
  }
}

import React from 'react'
import { useSelector, shallowEqual } from 'react-redux'
import { Link } from 'react-router-dom'

import Card from 'components/Card'
import { getStoriesEnabled } from 'data/session/SessionSelectors'
import {
  getStories,
  getHasFetchedStories,
  getIsFetchingStories,
  getStoriesHasError,
} from 'data/home/selectors'
import { formatReadableDate } from 'util/dateHelpers'
import imageUri from 'thumbnails/no_stories.png'
import EmptyRow from './EmptyRow'
import CardRow from './CardRow'
import LoadingCardSkeleton from './LoadingCardSkeleton'

export default function HomeStoriesCards() {
  const storiesEnabled = useSelector(getStoriesEnabled, shallowEqual)
  const stories = useSelector(getStories, shallowEqual)
  const isFetching = useSelector(getIsFetchingStories, shallowEqual)
  const hasFetched = useSelector(getHasFetchedStories, shallowEqual)
  const hasError = useSelector(getStoriesHasError, shallowEqual)
  const isEmpty = !stories.length

  if (!storiesEnabled) return null

  return (
    <CardRow
      linkTo="/stories"
      showViewAll={!isEmpty}
      sectionMessageId="homepage.sections.updatedStories"
      viewAllMessageId="homepage.sections.viewAllStories"
    >
      {hasError && (
        <EmptyRow imgUri={imageUri} altText="Problem fetching Stories">
          There was a problem loading your stories. Please refresh and try again.
        </EmptyRow>
      )}
      {isFetching && <LoadingCardSkeleton />}

      {hasFetched && isEmpty ? (
        <EmptyRow imgUri={imageUri} altText="no public stories">
          No public stories yet. Go&nbsp;
          <Link to="/stories/new">create one</Link>
          &nbsp;now.
        </EmptyRow>
      ) : (
        <React.Fragment>
          {stories.map(story => (
            <Card
              key={`story-${story.id}`}
              preheader={story.author}
              header={story.title}
              footer={story.modified ? `Last updated ${formatReadableDate(story.modified)}` : ''}
              linkTo={`/stories/${story.id}`}
            />
          ))}
        </React.Fragment>
      )}
    </CardRow>
  )
}

import type { MetadataFieldT } from 'data/metadata/metadataField/MetadataFieldT'

export default function updateMetadataItem(
  metadataField: MetadataField,
  itemId: string,
  value: string
): MetadataFieldT {
  const newItems = [...metadataField.values]

  // find the existing value index
  const itemIndex = newItems.findIndex(item => item.id === itemId)

  const newItem = {
    ...newItems[itemIndex],
    value,
  }

  newItems[itemIndex] = newItem

  return {
    ...metadataField,
    values: newItems,
  }
}

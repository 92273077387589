import * as actions from './actions'

const initialReducerState = {
  datasets: {
    isFetching: false,
    error: null,
    data: null,
    lastFetched: null,
    statusCancelCallback: null,
    isPatchingQuestion: false,
    isEditingDefaultAnalysisFilters: false,
    stagedACLsChanges: {},
    isSavingNewACLs: false,
    isPublishingStudy: false,
    hasErrorPublishingStudy: null,
  },
  questions: {
    searchQuery: '',
    tags: {},
    showingQuestionNames: false,
    gridEditing: {
      targetQuestionName: null,
      targetQuestionText: '',
      options: [],
      isFetchingPotentialOptions: false,
      errorFetchingPotentialOptions: null,
      isCommittingGridChanges: false,
      errorCommittingGridChanges: null,
      isUngroupingGridQuestion: false,
      errorUngroupingGridQuestion: null,
    },
  },
  editMode: false,
}

export default function datasetsReducer(state = initialReducerState, action = {}) {
  switch (action.type) {
    case actions.RESET_STUDY_DATASETS_REDUCER: {
      return initialReducerState
    }
    case actions.FETCH_INTEGRATION_DATASETS_LOADING: {
      return {
        ...state,
        datasets: {
          ...state.datasets,
          isFetching: true,
          error: null,
          data: null,
        },
        questions: { ...initialReducerState.questions },
      }
    }
    case actions.FETCH_INTEGRATION_DATASETS_SUCCESS: {
      const lastFetched = Date.now()
      return {
        ...state,
        datasets: {
          ...state.datasets,
          isFetching: false,
          lastFetched,
          data: action.payload,
          error: null,
        },
        questions: {
          ...state.questions,
          tags: action.tags,
        },
      }
    }
    case actions.FETCH_INTEGRATION_DATASETS_ERROR: {
      const lastFetched = Date.now()
      return {
        ...state,
        datasets: {
          ...state.datasets,
          isFetching: false,
          lastFetched,
          error: action.payload,
        },
        questions: { ...initialReducerState.questions },
      }
    }
    case actions.UPDATE_DATASET_STATUS: {
      if (!state.datasets.data.results.length) return state

      const existingDatasets = state.datasets.data.results.reduce((acc, dataset) => {
        acc[dataset.version_number] = dataset
        return acc
      }, {})

      const lastFetched = Date.now()
      const results = action.data.results.map(dataset => ({
        ...(existingDatasets[dataset.version_number] ?? {}),
        ...dataset,
      }))

      return {
        ...state,
        datasets: {
          ...state.datasets,
          isFetching: false,
          lastFetched,
          data: {
            ...state.datasets.data,
            count: action.data.count,
            results,
          },
          error: null,
        },
      }
    }
    case actions.UPDATE_DATASET_EDITMODE: {
      return {
        ...state,
        editMode: action.payload,
      }
    }
    case actions.CHANGE_DATASET_ACLS_SELECTION: {
      return {
        ...state,
        datasets: {
          ...state.datasets,
          stagedACLsChanges: {
            ...state.datasets.stagedACLsChanges,
            [action.groupName]: action.isActive,
          },
        },
      }
    }
    case actions.RESET_DATASET_ACLS_SELECTIONS: {
      return {
        ...state,
        datasets: {
          ...state.datasets,
          stagedACLsChanges: action.groupSelections,
        },
      }
    }
    case actions.UPDATE_DATASET_ACLS_LOADING: {
      return {
        ...state,
        datasets: {
          ...state.datasets,
          isSavingNewACLs: true,
        },
      }
    }
    case actions.UPDATE_DATASET_ACLS_SUCCESS: {
      const lastFetched = Date.now()
      return {
        ...state,
        datasets: {
          ...state.datasets,
          lastFetched,
          isSavingNewACLs: false,
          data: {
            ...state.datasets.data,
            results: [action.payload],
          },
        },
      }
    }
    case actions.UPDATE_DATASET_ACLS_ERROR: {
      const lastFetched = Date.now()
      return {
        ...state,
        datasets: {
          ...state.datasets,
          isSavingNewACLs: false,
          lastFetched,
          error: action.payload,
        },
      }
    }
    case actions.PATCH_QUESTION_TEXT_LOADING: {
      return {
        ...state,
        datasets: {
          ...state.datasets,
          isPatchingQuestion: true,
          error: null,
        },
      }
    }
    case actions.PATCH_QUESTION_TEXT_SUCCESS: {
      const lastFetched = Date.now()

      const questionsShallowCopy = state.datasets.data.results[0].questions.slice()
      const found = questionsShallowCopy.find(q => q.nk === action.payload.nk)
      found.question_text = action.payload.question_text
      found.raw_question_text = action.payload.raw_question_text || action.payload.question_text
      found.original_question_text = action.payload.original_question_text

      // If we ever return more than 1 dataset result, must change code to accomodate
      return {
        ...state,
        datasets: {
          ...state.datasets,
          lastFetched,
          isPatchingQuestion: false,
          data: {
            ...state.datasets.data,
            results: [
              {
                ...state.datasets.data.results[0],
                questions: questionsShallowCopy,
              },
            ],
          },
          error: null,
        },
      }
    }
    case actions.PATCH_QUESTION_TEXT_ERROR: {
      const lastFetched = Date.now()
      return {
        ...state,
        datasets: {
          ...state.datasets,
          isFetching: false,
          isPatchingQuestion: false,
          lastFetched,
          error: action.payload,
        },
      }
    }
    case actions.DASHBOARD_SET_QUESTION_SEARCH_QUERY: {
      return {
        ...state,
        questions: {
          ...state.questions,
          searchQuery: action.searchQuery,
        },
      }
    }
    case actions.DASHBOARD_SET_QUESTION_TAG_FILTER: {
      return {
        ...state,
        questions: {
          ...state.questions,
          tags: {
            ...state.questions.tags,
            [action.category]: {
              ...state.questions.tags[action.category],
              selected: action.selected ?? !state.questions.tags[action.category].selected,
            },
          },
        },
      }
    }
    case actions.SET_STUDY_DATASET_POLL_CANCEL_CALLBACK: {
      return {
        ...state,
        datasets: {
          ...state.datasets,
          statusCancelCallback: action.cancelCallback,
        },
      }
    }
    case actions.CLEAR_STUDY_DATASET_POLL_CANCEL_CALLBACK: {
      return {
        ...state,
        datasets: {
          ...state.datasets,
          statusCancelCallback: null,
        },
      }
    }
    case actions.SET_DEFAULT_ANALYSIS_FILTERS_LOADING: {
      return {
        ...state,
        datasets: {
          ...state.datasets,
          isEditingDefaultAnalysisFilters: true,
        },
      }
    }
    case actions.SET_DEFAULT_ANALYSIS_FILTERS_SUCCESS: {
      return {
        ...state,
        datasets: {
          ...state.datasets,
          isEditingDefaultAnalysisFilters: false,
          data: {
            ...state.datasets.data,
            results: state.datasets.data.results.map(dataset => ({
              ...dataset,
              default_filters: action.newFiltersString,
            })),
          },
        },
      }
    }
    case actions.SET_DEFAULT_ANALYSIS_FILTERS_ERROR: {
      return {
        ...state,
        datasets: {
          ...state.datasets,
          isEditingDefaultAnalysisFilters: false,
        },
      }
    }
    case actions.PUBLISH_SELF_LOADED_STUDY_LOADING: {
      return {
        ...state,
        datasets: {
          ...state.datasets,
          isPublishingStudy: true,
          hasErrorPublishingStudy: null,
        },
      }
    }
    case actions.PUBLISH_SELF_LOADED_STUDY_SUCCESS: {
      return {
        ...state,
        datasets: {
          ...state.datasets,
          isPublishingStudy: false,
          hasErrorPublishingStudy: null,
        },
      }
    }
    case actions.PUBLISH_SELF_LOADED_STUDY_ERROR: {
      return {
        ...state,
        datasets: {
          ...state.datasets,
          isPublishingStudy: false,
          hasErrorPublishingStudy: action.error,
        },
      }
    }
    case actions.FETCH_POTENTIAL_GRID_OPTIONS_LOADING: {
      return {
        ...state,
        questions: {
          ...state.questions,
          gridEditing: {
            ...state.questions.gridEditing,
            targetQuestionName: action.questionName,
            targetQuestionText: '',
            isFetchingPotentialOptions: true,
            errorFetchingPotentialOptions: null,
            isCommittingGridChanges: false,
            errorCommittingGridChanges: null,
            options: [],
          },
        },
      }
    }
    case actions.FETCH_POTENTIAL_GRID_OPTIONS_SUCCESS: {
      return {
        ...state,
        questions: {
          ...state.questions,
          gridEditing: {
            ...state.questions.gridEditing,
            targetQuestionName: action.questionName,
            targetQuestionText: action.questionText,
            isFetchingPotentialOptions: false,
            errorFetchingPotentialOptions: null,
            options: action.options,
          },
        },
      }
    }
    case actions.FETCH_POTENTIAL_GRID_OPTIONS_ERROR: {
      return {
        ...state,
        questions: {
          ...state.questions,
          gridEditing: {
            ...state.questions.gridEditing,
            targetQuestionName: action.questionName,
            targetQuestionText: '',
            isFetchingPotentialOptions: false,
            errorFetchingPotentialOptions: action.error,
            options: [],
          },
        },
      }
    }
    case actions.EDIT_STAGED_GRID_QUESTION_TEXT: {
      return {
        ...state,
        questions: {
          ...state.questions,
          gridEditing: {
            ...state.questions.gridEditing,
            targetQuestionText: action.stagedText,
          },
        },
      }
    }
    case actions.EDIT_STAGED_GRID_OPTION_TEXT: {
      return {
        ...state,
        questions: {
          ...state.questions,
          gridEditing: {
            ...state.questions.gridEditing,
            options: state.questions.gridEditing.options.map((option, index) => {
              if (option.index === action.index) {
                return {
                  ...option,
                  stagedText: action.stagedText,
                }
              }
              return option
            }),
          },
        },
      }
    }
    case actions.TOGGLE_STAGED_GRID_OPTION_ACTIVE: {
      return {
        ...state,
        questions: {
          ...state.questions,
          gridEditing: {
            ...state.questions.gridEditing,
            options: state.questions.gridEditing.options.map((option, index) => {
              if (option.index === action.index) {
                return {
                  ...option,
                  active: action.active,
                }
              }
              return option
            }),
          },
        },
      }
    }
    case actions.COMMIT_GRID_EDIT_CHANGES_LOADING: {
      return {
        ...state,
        questions: {
          ...state.questions,
          gridEditing: {
            ...state.questions.gridEditing,
            isCommittingGridChanges: true,
            errorCommittingGridChanges: null,
          },
        },
      }
    }
    case actions.COMMIT_GRID_EDIT_CHANGES_SUCCESS: {
      return {
        ...state,
        questions: {
          ...state.questions,
          gridEditing: {
            ...state.questions.gridEditing,
            isCommittingGridChanges: false,
            errorCommittingGridChanges: null,
            targetQuestionName: null,
            targetQuestionText: '',
            options: [],
          },
        },
      }
    }
    case actions.COMMIT_GRID_EDIT_CHANGES_ERROR: {
      return {
        ...state,
        questions: {
          ...state.questions,
          gridEditing: {
            ...state.questions.gridEditing,
            isCommittingGridChanges: false,
            errorCommittingGridChanges: action.error,
          },
        },
      }
    }
    case actions.UNGROUP_GRID_QUESTION_LOADING: {
      return {
        ...state,
        questions: {
          ...state.questions,
          gridEditing: {
            ...state.questions.gridEditing,
            isUngroupingGridQuestion: true,
            errorUngroupingGridQuestion: null,
          },
        },
      }
    }
    case actions.UNGROUP_GRID_QUESTION_SUCCESS: {
      return {
        ...state,
        questions: {
          ...state.questions,
          gridEditing: {
            ...state.questions.gridEditing,
            isUngroupingGridQuestion: false,
            errorUngroupingGridQuestion: null,
          },
        },
      }
    }
    case actions.UNGROUP_GRID_QUESTION_ERROR: {
      return {
        ...state,
        questions: {
          ...state.questions,
          gridEditing: {
            ...state.questions.gridEditing,
            isUngroupingGridQuestion: false,
            errorUngroupingGridQuestion: action.error,
          },
        },
      }
    }
    case actions.SET_IS_SHOWING_QUESTION_NAME: {
      return {
        ...state,
        questions: {
          ...state.questions,
          showingQuestionNames: action.isShowing,
        },
      }
    }
    default:
      return state
  }
}

import React from 'react'
import PropTypes from 'prop-types'
import { useSelector, shallowEqual } from 'react-redux'

import { getFilterCategories } from 'data/filters/unified/selectors'
import FilterDropdown from './FilterDropdown'
import * as styles from './FilterBar.module.scss'

const propTypes = {
  /** Action to dispatch after applying filter changes. */
  fetchAction: PropTypes.func.isRequired,
}

function FilterBar({ fetchAction }) {
  const categories = useSelector(getFilterCategories, shallowEqual)

  return (
    <div className={styles.filters}>
      {categories.map(name => (
        <FilterDropdown key={name} name={name} fetchAction={fetchAction} />
      ))}
    </div>
  )
}

FilterBar.propTypes = propTypes

export default FilterBar

import _uniqueId from 'lodash/uniqueId'

import type { MetadataFieldT } from 'data/metadata/metadataField/MetadataFieldT'

/** parent can be a string of 'root' */
export default function addMetadataItem(
  metadataField: MetadataField,
  value: string,
  parent?: number | string
): MetadataFieldT {
  const newItems = [...metadataField.values]

  if (parent) {
    const parentItems = newItems.filter(item => item.id === parent || item.parent === parent)
    const lastParentIndex = newItems.findIndex(
      item => item.id === parentItems[parentItems.length - 1].id
    )
    const groupItems = newItems.filter(newItem => newItem.parent === parent)
    //add it to the array
    newItems.splice(lastParentIndex + 1, 0, {
      value,
      parent,
      id: _uniqueId('metadataItem_'),
      group_position:
        groupItems && groupItems.length > 0
          ? groupItems[groupItems.length - 1].group_position + 1
          : groupItems.length + 1,
    })
    return {
      ...metadataField,
      values: newItems,
    }
  } else {
    const rootItems = newItems.filter(newItem => !newItem.parent)
    return {
      ...metadataField,
      values: [
        ...newItems,
        {
          value,
          id: _uniqueId('metadataItem_'),
          parent: null,
          group_position:
            rootItems && rootItems.length > 0
              ? rootItems[rootItems.length - 1].group_position + 1
              : rootItems.length + 1,
        },
      ],
    }
  }
}

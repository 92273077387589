//@flow
import { combineReducers } from 'redux'
import { siteUsersReducer } from './siteUser'
import { emailWhitelistReducer } from './whitelist/email'
import { domainWhitelistReducer } from './whitelist/domain'

export type SiteDomainT = {
  domain: string,
}

// This reducer will combine everything.
export const reducer = combineReducers({
  siteUsers: siteUsersReducer,
  emailWhitelist: emailWhitelistReducer,
  domainWhitelist: domainWhitelistReducer,
})

import React from 'react'

import Card from 'components/Card'

import * as styles from './LoadingCardSkeleton.module.scss'

const LoadingCardSkeleton = () => (
  <Card
    header={<div className={styles.bigRow} />}
    preheader={<div className={styles.smallRow} />}
  />
)

export default LoadingCardSkeleton

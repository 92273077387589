import { v1 as uuidv1 } from 'uuid'
import { Analysis2Store } from '@knowledgehound/analysis'
import { setIntervalBackOff } from '@knowledgehound/thunked'
import { selectors as filesSelectors } from '@knowledgehound/files'

import { getAvailableGroups } from 'data/domains/selectors'
import {
  getDatasets,
  getDatasetListResult,
  getAreQuestionsPatching,
  getDatasetQuestions,
  getDatasetStatusCancelCallback,
  getStagedACLsChanges,
  getStagedGridQuestionName,
  getStagedGridText,
  getStagedGridOptions,
  getIsPublishingStudy,
} from './selectors'

const COLLIE_URL = process.env.REACT_APP_COLLIE_URL || ''

export const SET_IS_SHOWING_QUESTION_NAME = 'SET_IS_SHOWING_QUESTION_NAME'
export const setIsShowingQuestionName = isShowing => ({
  type: SET_IS_SHOWING_QUESTION_NAME,
  isShowing,
})

export const RESET_STUDY_DATASETS_REDUCER = 'RESET_STUDY_DATASETS_REDUCER'
export const resetStudyDatasetsReducer = () => ({
  type: RESET_STUDY_DATASETS_REDUCER,
})

export const FETCH_INTEGRATION_DATASETS_LOADING = 'FETCH_INTEGRATION_DATASETS_LOADING'
const __fetchIntegrationDatasetsLoading = () => ({
  type: FETCH_INTEGRATION_DATASETS_LOADING,
})

export const FETCH_INTEGRATION_DATASETS_SUCCESS = 'FETCH_INTEGRATION_DATASETS_SUCCESS'
const __fetchIntegrationDatasetsSuccess = response => {
  const tagState = (response.results?.[0]?.questions ?? []).reduce((tags, question) => {
    question.tags.forEach(tagPair => {
      const [category, label] = tagPair.split(':')

      if (tags[category]) {
        tags[category].labels = tags[category].labels.concat(label)
      } else {
        tags[category] = {
          selected: false,
          labels: [label],
        }
      }
    })
    return tags
  }, {})

  response.results.forEach(dataset => {
    dataset.questions = dataset.questions.map(question => ({
      ...question,
      tags: question.tags.reduce((tags, tag) => {
        const [category, label] = tag.split(':')
        tags[category] = (tags[category] ?? []).concat(label)
        return tags
      }, {}),
    }))
  })

  return {
    type: FETCH_INTEGRATION_DATASETS_SUCCESS,
    payload: response,
    tags: tagState,
  }
}

export const FETCH_INTEGRATION_DATASETS_ERROR = 'FETCH_INTEGRATION_DATASETS_ERROR'
const __fetchIntegrationDatasetsError = err => ({
  type: FETCH_INTEGRATION_DATASETS_ERROR,
  error: true,
  payload: err,
})

/**
 * Fetch dataset for the given study.
 *
 * This does not trigger polling for the dataset, you would only need to call this
 * to sync local dataset edits with backend state.
 *
 * @param {String} studyId - ID of the study, (i.e. FRE0123)
 * @param {Boolean} quietReload - Silently fetch and replace new data, no loading state
 */
export const fetchIntegrationDatasets = (studyId, quietReload) => async (dispatch, getState) => {
  if (!quietReload) {
    dispatch(__fetchIntegrationDatasetsLoading())
  }
  try {
    const data = await Analysis2Store.requests.getCollieDataset(studyId, '', {
      richQuestionMetadata: true,
    })
    dispatch(__fetchIntegrationDatasetsSuccess(data))
  } catch (error) {
    dispatch(__fetchIntegrationDatasetsError(error))
  }
}

/**
 * Fetch dataset for given study and start polling if dataset is not ready
 *
 * @param {String} studyId - ID of the study, (i.e. FRE0123)
 */
export const fetchDatasetsThunk = studyId => async dispatch => {
  try {
    const { dataset, defaultFilters, allDatasets } = await Analysis2Store.requests.getDatasetData(
      studyId,
      { richQuestionMetadata: true }
    )
    dispatch(__fetchIntegrationDatasetsSuccess(allDatasets))
    dispatch(Analysis2Store.actions.fetchDatasetAction.success({ dataset, defaultFilters }))
    dispatch(startDatasetStatusPolling(studyId))
    return allDatasets
  } catch (error) {
    dispatch(__fetchIntegrationDatasetsError(error))
    dispatch(Analysis2Store.actions.fetchDatasetAction.error(error))
  }
}

export const UPDATE_DATASET_STATUS = 'UPDATE_DATASET_STATUS'
const __updateDatasetStatus = data => ({
  type: UPDATE_DATASET_STATUS,
  data,
})

export const SET_STUDY_DATASET_POLL_CANCEL_CALLBACK = 'SET_STUDY_DATASET_POLL_CANCEL_CALLBACK'
const __setPollCancelCallback = cancelCallback => ({
  type: SET_STUDY_DATASET_POLL_CANCEL_CALLBACK,
  cancelCallback,
})

export const CLEAR_STUDY_DATASET_POLL_CANCEL_CALLBACK = 'CLEAR_STUDY_DATASET_POLL_CANCEL_CALLBACK'
const __clearPollCancelCallback = () => ({
  type: CLEAR_STUDY_DATASET_POLL_CANCEL_CALLBACK,
})

export const stopDatasetStatusPolling = () => (dispatch, getState) => {
  getDatasetStatusCancelCallback(getState())?.()
  dispatch(__clearPollCancelCallback())
}

const shouldNotStatusPoll = data => {
  return (
    data?.results?.length > 0 && // We may need to wait for Greyhound to sync with Collie
    data.results.every(ds => ['Ready', 'ERROR'].includes(ds.current_state))
  )
}

const fetchDatasetStatus = async studyId => {
  try {
    const data = await Analysis2Store.requests.getCollieDataset(studyId, '', {
      richQuestionMetadata: true,
    })

    return {
      data,
      stopPolling: shouldNotStatusPoll(data),
    }
  } catch (error) {
    return { data: null, stopPolling: true }
  }
}

export const startDatasetStatusPolling =
  studyId =>
  async (dispatch, getState, { fetch }) => {
    const isRunning = Boolean(getDatasetStatusCancelCallback(getState()))
    const isUserAdmin = Analysis2Store.config.getIsUserAdmin(getState())
    const isUserManager = Analysis2Store.config.getIsUserManager(getState())
    const datasetResult = getDatasetListResult(getState())
    const files = filesSelectors.getFilesList(getState())

    // We only want to poll on studies that have datasets loaded from SAVs,
    // so we exclude studies without SAVs and studies loaded from DIY partners
    if (
      isRunning ||
      !(isUserAdmin || isUserManager) ||
      (!files.find(file => ['user_data', 'data'].includes(file.fileType)) &&
        !datasetResult.external_type) ||
      shouldNotStatusPoll(getDatasets(getState()).data)
    ) {
      return
    }

    const { cancel: cancelCallback, resetIterationCount } = setIntervalBackOff(
      async () => {
        const { data, stopPolling } = await fetchDatasetStatus(studyId)
        const currentDataset = getDatasetListResult(getState())

        if (currentDataset.study_id !== data.results?.[0]?.study_id) {
          // Prevents race condition with lingering interval callback for previous study
          dispatch(stopDatasetStatusPolling())
          return
        }

        const stateChanged = currentDataset.current_state !== data?.results?.[0]?.current_state

        dispatch(__updateDatasetStatus(data))

        if (!data || stopPolling) {
          dispatch(stopDatasetStatusPolling())

          if (!datasetResult.rdm_complete) {
            dispatch(fetchDatasetsThunk(studyId))
          }
        } else if (stateChanged) {
          resetIterationCount()
        }
      },
      { delayInitialExecution: true, growthRate: 1.5, linearScalar: 2500 }
    )

    dispatch(__setPollCancelCallback(cancelCallback))
  }

export const UPDATE_DATASET_EDITMODE = 'UPDATE_DATASET_EDITMODE'
export function setEditMode(userInEditMode) {
  return {
    type: UPDATE_DATASET_EDITMODE,
    payload: userInEditMode,
  }
}

export const CHANGE_DATASET_ACLS_SELECTION = 'CHANGE_DATASET_ACLS_SELECTION'
export const changeDatasetACLsSelection = (groupName, isActive) => ({
  type: CHANGE_DATASET_ACLS_SELECTION,
  groupName,
  isActive,
})

export const RESET_DATASET_ACLS_SELECTIONS = 'RESET_DATASET_ACLS_SELECTIONS'
const __resetDatasetACLsSelections = groupSelections => ({
  type: RESET_DATASET_ACLS_SELECTIONS,
  groupSelections,
})

export const resetDatasetACLsSelections = () => (dispatch, getState) => {
  const availableGroups = getAvailableGroups(getState())
  const { group_restrictions: currentGroups } = getDatasetListResult(getState())

  const currentGroupMap = currentGroups.reduce((acc, group) => {
    acc[group] = true
    return acc
  }, {})

  const groupSelections = availableGroups.reduce((acc, group) => {
    acc[group] = currentGroupMap[group] ?? false
    return acc
  }, {})

  dispatch(__resetDatasetACLsSelections(groupSelections))
}

export const UPDATE_DATASET_ACLS_LOADING = 'UPDATE_DATASET_ACLS_LOADING'
const __updateDatasetACLsLoading = () => ({
  type: UPDATE_DATASET_ACLS_LOADING,
})

export const UPDATE_DATASET_ACLS_SUCCESS = 'UPDATE_DATASET_ACLS_SUCCESS'
const __updateDatasetACLsSuccess = response => ({
  type: UPDATE_DATASET_ACLS_SUCCESS,
  payload: response,
})

export const UPDATE_DATASET_ACLS_ERROR = 'UPDATE_DATASET_ACLS_ERROR'
const __updateDatasetACLsError = err => ({
  type: UPDATE_DATASET_ACLS_ERROR,
  payload: err,
})

export const updateDatasetACLs =
  () =>
  async (dispatch, getState, { fetch }) => {
    dispatch(__updateDatasetACLsLoading())

    const { dataset_name: datasetName, study_id: studyId } = getDatasetListResult(getState())
    const stagedChanges = getStagedACLsChanges(getState())

    const newACLsGroups = Object.keys(stagedChanges).reduce((acc, group) => {
      if (!stagedChanges[group]) return acc
      return acc.concat(group)
    }, [])

    const response = await fetch(`${COLLIE_URL}/datasets/${studyId}/${datasetName}/`, {
      method: 'PATCH',
      jsonBody: { group_restrictions: newACLsGroups },
    })

    if (!response.ok) {
      dispatch(__updateDatasetACLsError(response.statusText))
      // Caught by component to send error to notification context
      // TODO Move toast state to Redux, not App-level state with React Context
      throw new Error('Failed to update dataset ACLs', { cause: response })
    }

    const data = await response.json()
    dispatch(__updateDatasetACLsSuccess(data))

    window.analytics.track('Save ACLs', {
      source: 'Study Metadata',
      dataset: datasetName,
      group_restrictions: newACLsGroups,
    })
  }

export const SET_DEFAULT_ANALYSIS_FILTERS_LOADING = 'SET_DEFAULT_ANALYSIS_FILTERS_LOADING'
const __setDefaultAnalysisFiltersLoading = () => ({
  type: SET_DEFAULT_ANALYSIS_FILTERS_LOADING,
})

export const SET_DEFAULT_ANALYSIS_FILTERS_SUCCESS = 'SET_DEFAULT_ANALYSIS_FILTERS_SUCCESS'
const __setDefaultAnalysisFiltersSuccess = newFiltersString => ({
  type: SET_DEFAULT_ANALYSIS_FILTERS_SUCCESS,
  newFiltersString,
})

export const SET_DEFAULT_ANALYSIS_FILTERS_ERROR = 'SET_DEFAULT_ANALYSIS_FILTERS_ERROR'
const __setDefaultAnalysisFiltersError = () => ({
  type: SET_DEFAULT_ANALYSIS_FILTERS_ERROR,
})

export const setDefaultAnalysisFilters =
  filters =>
  async (dispatch, getState, { fetch }) => {
    dispatch(__setDefaultAnalysisFiltersLoading())
    const { dataset_name: datasetName, study_id: studyId } = getDatasetListResult(getState())

    const response = await fetch(`${COLLIE_URL}/datasets/${studyId}/${datasetName}/`, {
      method: 'PATCH',
      jsonBody: { default_filters: JSON.stringify(filters) },
    })

    if (!response.ok) {
      dispatch(__setDefaultAnalysisFiltersError())
      // Caught by component to send error to notification context
      // TODO Move toast state to Redux, not App-level state with React Context
      throw new Error('Failed to edit default analysis filters', { cause: response })
    }

    dispatch(__setDefaultAnalysisFiltersSuccess(JSON.stringify(filters)))
  }

export const toggleQuestionLabels =
  () =>
  async (dispatch, getState, { fetch }) => {
    const {
      study_id: studyId,
      dataset_name: datasetName,
      question_labels_enabled: questionLabelsEnabled,
    } = getDatasetListResult(getState())

    const response = await fetch(`${COLLIE_URL}/datasets/${studyId}/${datasetName}/`, {
      method: 'PATCH',
      jsonBody: {
        question_labels_enabled: !questionLabelsEnabled,
      },
    })

    if (!response.ok) {
      // Caught by component to send error to notification context
      // TODO Move toast state to Redux, not App-level state with React Context
      throw new Error('Failed to toggle question labels', { cause: response })
    }

    await dispatch(fetchIntegrationDatasets(studyId, true))
  }

export const PATCH_QUESTION_TEXT_LOADING = 'PATCH_QUESTION_TEXT_LOADING'
const __patchQuestionTextLoading = () => ({
  type: PATCH_QUESTION_TEXT_LOADING,
})

export const PATCH_QUESTION_TEXT_SUCCESS = 'PATCH_QUESTION_TEXT_SUCCESS'
const __patchQuestionTextSuccess = response => ({
  type: PATCH_QUESTION_TEXT_SUCCESS,
  payload: response,
})

export const PATCH_QUESTION_TEXT_ERROR = 'PATCH_QUESTION_TEXT_ERROR'
const __patchQuestionTextError = error => ({
  type: PATCH_QUESTION_TEXT_ERROR,
  payload: error,
})

/**
 * @param {string} nkUri
 * @param {string} newQuestionText
 */
export const patchQuestionText =
  (questionNkUri, questionText) =>
  async (dispatch, getState, { fetch }) => {
    if (!questionText || getAreQuestionsPatching(getState())) return

    const questions = getDatasetQuestions(getState())
    const stagedQuestion = questions.find(question => question.nk_uri === questionNkUri)
    const newQuestionText = questionText.trim()

    if (!stagedQuestion || stagedQuestion.question_text === newQuestionText) return

    dispatch(__patchQuestionTextLoading())

    const response = await fetch(`${COLLIE_URL}/v2/questions/${questionNkUri}/`, {
      method: 'PATCH',
      jsonBody: { question_text: newQuestionText },
    })

    if (!response.ok) {
      dispatch(__patchQuestionTextError(response.statusText))
      return
    }

    const data = await response.json()
    dispatch(__patchQuestionTextSuccess(data))

    const dataset = getDatasetListResult(getState())
    window.analytics.track('Question text edit success', {
      question_id: questionNkUri,
      question_text: newQuestionText,
      study_id: dataset.study_id,
      study_name: dataset.studyName,
      source: 'Study Dashboard',
    })
  }

export const PUBLISH_SELF_LOADED_STUDY_LOADING = 'PUBLISH_SELF_LOADED_STUDY_LOADING'
const __publishSelfLoadedStudyLoading = () => ({
  type: PUBLISH_SELF_LOADED_STUDY_LOADING,
})

export const PUBLISH_SELF_LOADED_STUDY_SUCCESS = 'PUBLISH_SELF_LOADED_STUDY_SUCCESS'
const __publishSelfLoadedStudySuccess = () => ({
  type: PUBLISH_SELF_LOADED_STUDY_SUCCESS,
})

export const PUBLISH_SELF_LOADED_STUDY_ERROR = 'PUBLISH_SELF_LOADED_STUDY_ERROR'
const __publishSelfLoadedStudyError = error => ({
  type: PUBLISH_SELF_LOADED_STUDY_ERROR,
  error,
})

export const publishStudy =
  () =>
  async (dispatch, getState, { fetch }) => {
    const dataset = getDatasetListResult(getState())
    if (!dataset.previewable || getIsPublishingStudy(getState())) return

    dispatch(__publishSelfLoadedStudyLoading())

    const studyID = encodeURIComponent(dataset.study_id)
    const datasetName = encodeURIComponent(dataset.dataset_name)

    const response = await fetch(`${COLLIE_URL}/datasets/${studyID}/${datasetName}/publish/`, {
      method: 'POST',
    })

    if (!response.ok) {
      dispatch(__publishSelfLoadedStudyError(response.statusText))
      // TODO Move toast state to Redux, not App-level state with React Context
      throw new Error('Failed to publish self-loaded study', { cause: response })
    }

    dispatch(__publishSelfLoadedStudySuccess())
  }

export const DASHBOARD_SET_QUESTION_SEARCH_QUERY = 'DASHBOARD_SET_QUESTION_SEARCH_QUERY'
export const setQuestionSearchQuery = searchQuery => ({
  type: DASHBOARD_SET_QUESTION_SEARCH_QUERY,
  searchQuery,
})

export const DASHBOARD_SET_QUESTION_TAG_FILTER = 'DASHBOARD_SET_QUESTION_TAG_FILTER'
const __setQuestionTagFilter = (category, selected) => ({
  type: DASHBOARD_SET_QUESTION_TAG_FILTER,
  category,
  selected,
})

export const setQuestionTagFilter = (category, selected) => (dispatch, getState) => {
  const { studyName, study_id } = getDatasetListResult(getState())
  window.analytics.track('Study Questions Filtered by Question Tag', {
    category,
    selected,
    studyName,
    studyId: study_id,
    source: 'Study Dashboard',
  })

  dispatch(__setQuestionTagFilter(category, selected))
}

/*-----------------------------------------------------------------------------
 * Grid Editing
 *---------------------------------------------------------------------------*/

export const FETCH_POTENTIAL_GRID_OPTIONS_LOADING = 'FETCH_POTENTIAL_GRID_OPTIONS_LOADING'
const __fetchPotentialGridOptionsLoading = questionName => ({
  type: FETCH_POTENTIAL_GRID_OPTIONS_LOADING,
  questionName,
})

export const FETCH_POTENTIAL_GRID_OPTIONS_SUCCESS = 'FETCH_POTENTIAL_GRID_OPTIONS_SUCCESS'
const __fetchPotentialGridOptionsSuccess = (questionName, questionText, options) => ({
  type: FETCH_POTENTIAL_GRID_OPTIONS_SUCCESS,
  questionName,
  questionText,
  options,
})

export const FETCH_POTENTIAL_GRID_OPTIONS_ERROR = 'FETCH_POTENTIAL_GRID_OPTIONS_ERROR'
const __fetchPotentialGridOptionsError = (questionName, error) => ({
  type: FETCH_POTENTIAL_GRID_OPTIONS_ERROR,
  questionName,
  error,
})

/**
 * Fetch the potential options that could form or be added to a grid from the
 * provided base question.  Assumed that the dataset is fetched with rich
 * question metadata to pull the original breakout text.
 *
 * @param {String} questionName - Name of the base question to create a grid from
 */
export const startGridEditing =
  questionName =>
  async (dispatch, getState, { fetch }) => {
    dispatch(__fetchPotentialGridOptionsLoading(questionName))

    const { study_id, dataset_name, questions } = getDatasetListResult(getState())
    const questionsByName = questions.reduce((acc, question) => {
      acc[question.question_name] = question
      return acc
    }, {})

    const studyID = encodeURIComponent(study_id)
    const datasetName = encodeURIComponent(dataset_name)
    const { raw_question_text: targetQuestionText, breakout_metadata: originalBreakouts = [] } =
      questionsByName[questionName]

    const params = new URLSearchParams({ question: questionName }).toString()

    const potentialsResponse = await fetch(
      `${COLLIE_URL}/datasets/${studyID}/${datasetName}/grids/?${params}`
    )

    if (!potentialsResponse.ok) {
      dispatch(__fetchPotentialGridOptionsError(questionName, potentialsResponse.statusText))
      return
    }

    const potentialQuestionsData = await potentialsResponse.json()
    const currentOptions = originalBreakouts.map(breakout => ({
      active: true,
      questionName: breakout.question_name,
      stagedText: breakout.breakout_text,
      questionText: breakout.breakout_text,
      originalText: breakout.original_question_text ?? breakout.breakout_text,
    }))
    const potentialQuestions = (potentialQuestionsData[questionName] ?? []).filter(
      name =>
        questionsByName[name] &&
        !currentOptions.some(currentOpt => currentOpt.questionName === name)
    )
    const options = [
      ...currentOptions,
      ...potentialQuestions.map(name => {
        const question = questionsByName[name]
        return {
          active: questionName === name,
          stagedText: question.raw_question_text,
          questionText: question.raw_question_text,
          originalText: question.original_question_text ?? question.raw_question_text,
          questionName: name,
        }
      }),
    ].map((option, index) => ({
      ...option,
      index,
    }))

    dispatch(__fetchPotentialGridOptionsSuccess(questionName, targetQuestionText, options))
  }

export const EDIT_STAGED_GRID_QUESTION_TEXT = 'EDIT_STAGED_GRID_QUESTION_TEXT'
export const editStagedGridQuestionText = stagedText => ({
  type: EDIT_STAGED_GRID_QUESTION_TEXT,
  stagedText,
})

export const EDIT_STAGED_GRID_OPTION_TEXT = 'EDIT_STAGED_GRID_OPTION_TEXT'
export const editStagedGridOptionText = (index, stagedText) => ({
  type: EDIT_STAGED_GRID_OPTION_TEXT,
  index,
  stagedText,
})

export const TOGGLE_STAGED_GRID_OPTION_ACTIVE = 'TOGGLE_STAGED_GRID_OPTION_ACTIVE'
export const toggleStagedGridOptionActive = (index, active) => ({
  type: TOGGLE_STAGED_GRID_OPTION_ACTIVE,
  index,
  active,
})

export const COMMIT_GRID_EDIT_CHANGES_LOADING = 'COMMIT_GRID_EDIT_CHANGES_LOADING'
const __commitGridEditChangesLoading = () => ({
  type: COMMIT_GRID_EDIT_CHANGES_LOADING,
})

export const COMMIT_GRID_EDIT_CHANGES_SUCCESS = 'COMMIT_GRID_EDIT_CHANGES_SUCCESS'
const __commitGridEditChangesSuccess = () => ({
  type: COMMIT_GRID_EDIT_CHANGES_SUCCESS,
})

export const COMMIT_GRID_EDIT_CHANGES_ERROR = 'COMMIT_GRID_EDIT_CHANGES_ERROR'
const __commitGridEditChangesError = error => ({
  type: COMMIT_GRID_EDIT_CHANGES_ERROR,
  error,
})

export const commitGridEditChanges =
  () =>
  async (dispatch, getState, { fetch }) => {
    dispatch(__commitGridEditChangesLoading())
    const targetQuestionName = getStagedGridQuestionName(getState())
    const stagedGridQuestionText = getStagedGridText(getState()).trim()
    const stagedOptions = getStagedGridOptions(getState())

    const { study_id, dataset_name, questions } = getDatasetListResult(getState())
    const questionsByName = questions.reduce((acc, question) => {
      acc[question.question_name] = question
      return acc
    }, {})
    const action = questionsByName[targetQuestionName].is_grid ? 'update' : 'create'
    const targetQuestionText = questionsByName[targetQuestionName].raw_question_text

    const studyID = encodeURIComponent(study_id)
    const datasetName = encodeURIComponent(dataset_name)
    const contributors = stagedOptions.reduce((acc, option) => {
      if (option.active) {
        return acc.concat(option.questionName)
      }
      return acc
    }, [])

    const changesBody = {
      action,
      contributors,
    }

    if (action === 'create') {
      changesBody.grid_text = stagedGridQuestionText
    }
    const id = questionsByName[targetQuestionName].is_grid ? targetQuestionName : uuidv1()

    const editResponse = await fetch(`${COLLIE_URL}/datasets/${studyID}/${datasetName}/grids/`, {
      method: 'POST',
      jsonBody: { [id]: changesBody },
    })

    if (!editResponse.ok) {
      // TODO Should flesh out error handling so 409s are alerted to user differently
      const errorBody = await editResponse.json()
      dispatch(__commitGridEditChangesError(errorBody))
      return
    }

    if (action === 'update' && targetQuestionText !== stagedGridQuestionText) {
      await dispatch(
        patchQuestionText(questionsByName[targetQuestionName].nk_uri, stagedGridQuestionText)
      )
    }

    let hadErrorPatchingBreakouts = false

    for (const stagedOption of stagedOptions) {
      const stagedText = stagedOption.stagedText.trim()
      if (stagedText !== stagedOption.questionText) {
        const response = await fetch(
          `${COLLIE_URL}/v2/questions/${studyID}/${datasetName}/${stagedOption.questionName}/`,
          {
            method: 'PATCH',
            jsonBody: { question_text: stagedText },
          }
        )

        if (!response.ok) {
          hadErrorPatchingBreakouts = true
        }
      }
    }

    const gridName =
      targetQuestionText !== stagedGridQuestionText ? stagedGridQuestionText : targetQuestionName

    if (action === 'create') {
      window.analytics.track(`Create Grid`, {
        gridName: gridName,
        studyId: studyID,
        datasetName: dataset_name,
      })
    }

    if (action === 'update') {
      window.analytics.track(`Update Grid`, {
        gridName: gridName,
        studyId: studyID,
        datasetName: dataset_name,
      })
    }

    dispatch(__commitGridEditChangesSuccess())
    await dispatch(fetchIntegrationDatasets(study_id, true))

    // Used to allow caller to decide to show error toast notification
    // TODO Move toast state to Redux, not App-level state with React Context
    return {
      hadErrorPatchingBreakouts,
    }
  }

export const UNGROUP_GRID_QUESTION_LOADING = 'UNGROUP_GRID_QUESTION_LOADING'
const __ungroupGridQuestionLoading = questionName => ({
  type: UNGROUP_GRID_QUESTION_LOADING,
  questionName,
})

export const UNGROUP_GRID_QUESTION_SUCCESS = 'UNGROUP_GRID_QUESTION_SUCCESS'
const __ungroupGridQuestionSuccess = () => ({
  type: UNGROUP_GRID_QUESTION_SUCCESS,
})

export const UNGROUP_GRID_QUESTION_ERROR = 'UNGROUP_GRID_QUESTION_ERROR'
const __ungroupGridQuestionError = error => ({
  type: UNGROUP_GRID_QUESTION_ERROR,
  error,
})

export const ungroupGridQuestion =
  questionName =>
  async (dispatch, getState, { fetch }) => {
    dispatch(__ungroupGridQuestionLoading(questionName))

    const { study_id, dataset_name } = getDatasetListResult(getState())

    const studyID = encodeURIComponent(study_id)
    const datasetName = encodeURIComponent(dataset_name)

    const response = await fetch(`${COLLIE_URL}/datasets/${studyID}/${datasetName}/grids/`, {
      method: 'POST',
      jsonBody: {
        [questionName]: {
          action: 'delete',
        },
      },
    })

    if (!response.ok) {
      dispatch(__ungroupGridQuestionError(response.statusText))
      return
    }

    window.analytics.track('Ungroup grid', {
      gridName: questionName,
      studyId: studyID,
      datasetName: dataset_name,
    })

    dispatch(__ungroupGridQuestionSuccess())
    await dispatch(fetchIntegrationDatasets(study_id, true))
  }

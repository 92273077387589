import { createSelector } from 'reselect'
import { startCase, isEmpty } from 'lodash'

import { getSerializableFilterState as sharedSerializer } from 'data/filters/shared/selectors'

export const getUnifiedFilters = state => state.unifiedFilters.categories

export const getFilterCategories = state => Object.keys(state.unifiedFilters.categories ?? {})
export const getFiltersNotInitialized = createSelector(getFilterCategories, isEmpty)

export const getSerializableFilterState = createSelector(getUnifiedFilters, sharedSerializer)

export const getFilteringQueryParam = createSelector(getSerializableFilterState, filterState =>
  filterState === null ? '' : encodeURIComponent(JSON.stringify(filterState))
)

export const getUnifiedFilterTokens = createSelector(getUnifiedFilters, filters =>
  Object.entries(filters).reduce((acc, [fieldKey, fieldValues]) => {
    Object.entries(fieldValues).forEach(([key, value]) => {
      if (value.selected) {
        acc.push({
          fieldKey,
          key,
          fieldLabel: startCase(fieldKey),
          label: key.split(':::').join(' – '),
          personalized: false,
        })
      }
    })
    return acc
  }, [])
)

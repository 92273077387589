// @flow
import * as actions from './MetadataValuesActions'
import type { MetadataValuesStateT } from './MetadataValuesT'
import type { ActionT } from '@knowledgehound/data/Action.type'

export const metadataValuesReducer = (
  state: MetadataValuesStateT = {
    metadataValues: {
      isFetching: true,
      error: null,
      data: null,
      lastFetched: null,
    },
  },
  action: ActionT
): MetadataValuesStateT => {
  switch (action.type) {
    case actions.METADATAVALUES_LOADING: {
      return {
        ...state,
        metadataValues: {
          ...state.metadataValues,
          isFetching: true,
          error: null,
        },
      }
    }
    case actions.METADATAVALUES_SUCCESS: {
      const lastFetched = Date.now()
      return {
        ...state,
        metadataValues: {
          ...state.metadataValues,
          isFetching: false,
          lastFetched,
          data: action.payload,
          error: null,
        },
      }
    }
    case actions.METADATAVALUES_ERROR: {
      const lastFetched = Date.now()
      return {
        ...state,
        metadataValues: {
          ...state.metadataValues,
          isFetching: false,
          lastFetched,
          error: action.payload,
        },
      }
    }
    default:
      return state
  }
}

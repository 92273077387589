export const getSerializableFilterState = filterState => {
  let filterIsEffective = 0

  const filters = Object.entries(filterState).reduce((acc, [fieldKey, selections]) => {
    const selectedOpts = Object.entries(selections).filter(([key, item]) => item.selected)
    if (!selectedOpts.length) return acc

    acc[fieldKey] =
      fieldKey === 'study_year'
        ? selectedOpts.map(([key]) => Number(key))
        : selectedOpts.map(([key]) => key.split(':::'))

    filterIsEffective += acc[fieldKey].length

    return acc
  }, {})

  if (!filterIsEffective) return null
  return filters
}

export const getFilteringQueryParam = filterState => {
  const state = getSerializableFilterState(filterState)

  if (state === null) return ''
  return encodeURIComponent(JSON.stringify(state))
}

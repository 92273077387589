import type { MetadataFieldT } from 'data/metadata/metadataField/MetadataFieldT'

export default function updateMetadataField(
  metadataField: MetadataField,
  value: string
): MetadataFieldT {
  return {
    ...metadataField,
    name: value,
  }
}

// @flow
import type { MetadataFieldT } from 'data/metadata/metadataField/MetadataFieldT'

export default function toggleMetadataField(
  metadataFields: Array<MetadataFieldT>,
  id: number
): Array<MetadataFieldT> {
  const newFields = [...metadataFields]
  const itemToUpdateIndex = metadataFields.findIndex(item => item.id === id)

  const newField = {
    ...metadataFields[itemToUpdateIndex],
    filterable: !metadataFields[itemToUpdateIndex].filterable,
  }

  newFields.splice(itemToUpdateIndex, 1, newField)
  return newFields
}

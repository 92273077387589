// @flow
import type { MetadataFieldT } from './MetadataFieldT'

export default function moveMetadataField(
  metadataFields: Array<MetadataFieldT>,
  { fromId, toId }: { fromId: number, toId: number }
): Array<MetadataFieldT> {
  const newItems = [...metadataFields]

  // position is index + 1
  const fromIndex = newItems.findIndex(item => item.id === fromId)
  const from = newItems[fromIndex]
  const toIndex = newItems.findIndex(item => item.id === toId)

  newItems.splice(fromIndex, 1) // remove the old one from its index
  newItems.splice(toIndex, 0, from) // move it to its new index

  // re-sync positions
  newItems.forEach((newItem, idx) => (newItem.position = idx + 1))

  return newItems
}

import { pick } from 'lodash'
import { Fetcher, Fetching, makeGetReducer } from 'util/fetcher'
import { parseField, parseString, parseBoolean } from 'util/parseUtils'

// Represents a single user within the set of users for a particular client.
export type SiteUserT = {
  profile_id: string,
  first_name: string,
  last_name: string,
  email: string,
  integrations: string,
  activated: boolean,
  admin: boolean,
  manager: boolean,
}

// Parse/validate a site user object as returned via JSON.
export const parseSiteUser = (object: any): SiteUserT => {
  return {
    profile_id: parseField(object, 'profile_id', parseString),
    first_name: parseField(object, 'first_name', parseString),
    last_name: parseField(object, 'last_name', parseString),
    integrations: parseField(object, 'integrations', parseString),
    email: parseField(object, 'email', parseString),
    admin: parseField(object, 'admin', parseBoolean),
    manager: parseField(object, 'manager', parseBoolean),
    active: parseField(object, 'active', parseBoolean),
  }
}

// Render the user's name as a string
export const userName = (user: { first_name: string, last_name: string }): string => {
  return [user.first_name, user.last_name].filter(s => s).join(' ')
}

// Three different user roles
type Role = 'Admin' | 'Manager' | 'User'

// Get the role of a user as one of the three options.
export function getRole(user: SiteUserT): Role {
  return user.admin ? 'Admin' : user.manager ? 'Manager' : 'User'
}

export function roleAtLeast(role: Role, compareTo: Role): boolean {
  switch (role) {
    case 'Admin':
      return true
    case 'Manager':
      return compareTo !== 'Admin'
    default:
      return compareTo === 'User'
  }
}

// Determine whether a particular user can change another's activation state.
export function canSetActivation(editorRole: Role, editeeRole: Role) {
  if (editorRole === 'Admin') return true
  else if (editorRole === 'Manager') return editeeRole !== 'Admin'
  else return false
}

// Determine whether a particular user can change another user's role.
export function canSetRole(editorRole: Role, editeeCurrentRole: Role, newRole: Role) {
  if (editorRole === 'Admin') {
    // Admins can do anything
    return true
  } else if (editorRole === 'Manager' && editeeCurrentRole !== 'Admin') {
    // A manager can set manager or user roles, but not admin,
    // Additionally it can't change an admin's role.
    return ['Manager', 'User'].includes(newRole)
  } else {
    // A user can't set anything
    return false
  }
}

// Fetches a list of users
export const siteUsersFetcher: Fetcher<void, Array<SiteUserT>> = new Fetcher({
  prefix: 'SITE_USERS',
  buildUrl: () => '/proxy/rottweiler/users',
  decoder: obj => obj.results.map(parseSiteUser),
})

const siteUsersFetchReducer = makeGetReducer(siteUsersFetcher.prefix)

// Information needed to update a site user via rottweiler.
export type SiteUserUpdateT = {
  profile_id: string,
  admin?: boolean,
  manager?: boolean,
  active?: boolean,
}

export type IntegrationAdminUpdateT = {
  integration: string,
  email: string,
}

export type IntegrationAdminResponseT = {
  permission: string,
  id: number,
  domain: number,
  email: string,
}

export const siteUsersUpdater: Fetcher<SiteUserUpdateT, SiteUserT> = new Fetcher({
  prefix: 'UPDATE_SITE_USER',
  buildUrl: (u: SiteUserUpdateT) => `/proxy/rottweiler/users/${u.profile_id}/`,
  buildOptions: (u: SiteUserUpdateT) =>
    Fetcher.defaultOptions({
      method: 'PATCH',
      body: JSON.stringify(pick(u, ['admin', 'manager', 'active'])),
    }),
})

const siteUsersUpdateReducer = siteUsersUpdater.makeReducer({
  onSuccess: (state, payload) => {
    const update = users =>
      (users || []).map(u => (u.profile_id === payload.profile_id ? payload : u))
    const ret = state.handle({
      whenFetching: items => Fetching.fetched(update(items)),
      whenErrored: _ => Fetching.fetched([payload]),
      whenFetched: items => Fetching.fetched(update(items)),
    })
    return ret
  },
})

export const integrationAdminUpdater: Fetcher<IntegrationAdminUpdateT, IntegrationAdminResponseT> =
  new Fetcher({
    prefix: 'UPDATE_INTEGRATION_ADMIN',
    buildUrl: (u: IntegrationAdminUpdateT) => `/proxy/rottweiler/integration_admins/`,
    buildOptions: (u: IntegrationAdminUpdateT) =>
      Fetcher.defaultOptions({
        method: 'POST',
        body: JSON.stringify(pick(u, ['integration', 'email'])),
      }),
  })

export const integrationAdminRemover: Fetcher<IntegrationAdminUpdateT, IntegrationAdminResponseT> =
  new Fetcher({
    prefix: 'DELETE_INTEGRATION_ADMIN',
    buildUrl: (u: IntegrationAdminUpdateT) =>
      `/proxy/rottweiler/integration_admins/?email=${encodeURIComponent(
        u.email
      )}&integration=${encodeURIComponent(u.integration)}`,
    buildOptions: (u: IntegrationAdminUpdateT) =>
      Fetcher.defaultOptions({
        method: 'DELETE',
      }),
  })

export const integrationAdminUpdateReducer = (state, action) => {
  let update = state.data

  if (action.type === 'UPDATE_INTEGRATION_ADMIN_SUCCESS') {
    update = (state.data || []).map(user => {
      if (user.email === action.payload.email) {
        const newIntegrationState = user.integrations.length
          ? `${user.integrations}, ${action.payload.permission}`
          : action.payload.permission

        return { ...user, integrations: newIntegrationState }
      }

      return user
    })
  } else if (action.type === 'DELETE_INTEGRATION_ADMIN_SUCCESS') {
    update = (state.data || []).map(user => {
      if (user.email === action.payload.email) {
        const replaceRegex = new RegExp(action.payload.permission, 'g')
        const newIntegrationState = user.integrations.replace(replaceRegex, '').replace(/, /g, '')
        return { ...user, integrations: newIntegrationState }
      }

      return user
    })
  }
  return state.handle({
    whenFetching: items => Fetching.fetched(update),
    whenErrored: _ => Fetching.errored(state, [action.payload]),
    whenFetched: items => Fetching.fetched(update),
  })
}

export const siteUsersReducer = (state, action) => {
  state = siteUsersFetchReducer(state, action)
  state = siteUsersUpdateReducer(state, action)
  state = integrationAdminUpdateReducer(state, action)

  return state
}

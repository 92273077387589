// @flow
import { createSelector } from 'reselect'

const currentAnalysisQuestion = state => state.analysis.currentAnalysisQuestion

export const getCurrentAnalysisQuestion = createSelector(
  [currentAnalysisQuestion],
  currentAnalysisQuestion => currentAnalysisQuestion
)

const currentParentStudy = state => state.analysis.currentParentStudy

export const getCurrentParentStudy = createSelector(
  [currentParentStudy],
  currentParentStudy => currentParentStudy
)

export const getMainQuestionList = createSelector(
  [getCurrentParentStudy],
  currentParentStudy =>
    currentParentStudy &&
    currentParentStudy.data &&
    currentParentStudy.data.questions &&
    currentParentStudy.data.questions.filter(question => question.main_var_list)
)

const currentSimilarSamples = state => state.analysis.currentSimilarSamples

export const getCurrentSimilarSamples = createSelector(
  [currentSimilarSamples],
  currentSimilarSamples => currentSimilarSamples
)

export const getLowbaseFromStudy = (state: any) =>
  (state.study &&
    state.study.currentStudy &&
    state.study.currentStudy.data &&
    state.study.currentStudy.data.low_base_threshold) ||
  100

const currentOpenEndResults = state => state.analysis.currentOpenEndResults

export const getCurrentOpenEndResults = createSelector(
  [currentOpenEndResults],
  currentOpenEndResults => currentOpenEndResults
)

const sentimentRanges = state => state.analysis.sentimentRanges

export const getSentimentRanges = createSelector(
  [sentimentRanges],
  sentimentRanges => sentimentRanges
)

import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'

import * as styles from './CardRow.module.scss'

const propTypes = {
  children: PropTypes.node.isRequired,
  linkTo: PropTypes.string.isRequired,
  sectionMessageId: PropTypes.string.isRequired,
  showViewAll: PropTypes.bool,
  viewAllMessageId: PropTypes.string.isRequired,
}

const CardRow = ({ children, linkTo, sectionMessageId, showViewAll = false, viewAllMessageId }) => (
  <section className={styles.cardRow}>
    <header className={styles.heading}>
      <h4 className={styles.headingText}>
        <FormattedMessage id={sectionMessageId} />
        {showViewAll && (
          <Link className={styles.link} to={linkTo}>
            <FormattedMessage id={viewAllMessageId} />
          </Link>
        )}
      </h4>
    </header>
    <div className={styles.cards}>{children}</div>
  </section>
)

CardRow.propTypes = propTypes

export default CardRow

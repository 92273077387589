// @flow
import type { MetadataFieldT } from 'data/metadata/metadataField/MetadataFieldT'

export default function removeMetadataField(
  metadataFields: Array<MetadataFieldT>,
  id: number
): Array<MetadataFieldT> {
  const newFields = [...metadataFields]

  const index = metadataFields.findIndex(item => item.id === id)
  newFields.splice(index, 1)

  return newFields
}

import React from 'react'
import PropTypes from 'prop-types'

import * as styles from './EmptyRow.module.scss'

const propTypes = {
  children: PropTypes.node.isRequired,
  altText: PropTypes.string.isRequired,
  imgUri: PropTypes.string.isRequired,
}

const EmptyRow = ({ children, altText, imgUri }) => (
  <div className={styles.emptyContainer}>
    <img className={styles.emptyImg} src={imgUri} alt={altText} />
    <p className={styles.emptyPrompt}>{children}</p>
  </div>
)

EmptyRow.propTypes = propTypes

export default EmptyRow

import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import BodyLayout from 'components/BodyLayout'
import { fetchStudies } from 'data/studyHome/actions'
import { fetchUserPreferences } from 'data/personalization/actions'
import StudyList from './StudyList'
import Controls from './Controls'
import PageControls from './PageControls'
import * as styles from './Home.module.scss'

export default function StudyHome() {
  const dispatch = useDispatch()

  useEffect(() => {
    ;(async () => {
      await dispatch(fetchUserPreferences())
      dispatch(fetchStudies(true))
    })()
  }, [dispatch])

  return (
    <BodyLayout title="Studies">
      <Controls />
      <StudyList />
      <div className={styles.footer}>
        <PageControls />
      </div>
    </BodyLayout>
  )
}

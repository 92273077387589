// @flow
import { handleResponse, handleError, handleData } from '@knowledgehound/data/fetchUtils'
import type { ActionT } from '@knowledgehound/data/Action.type'
import type { ErrorT } from '@knowledgehound/data/Error.type'

export type ShareT = {
  share_description: string,
  share_link: string,
  share_type: string,
  text: string,
  to: string,
}

export type ShareSuccessT = {
  error: ErrorT,
  message: string,
  status: string,
}

export const CREATE_SHARE_LOADING = 'CREATE_SHARE_LOADING'
export const CREATE_SHARE_SUCCESS = 'CREATE_SHARE_SUCCESS'
export const CREATE_SHARE_ERROR = 'CREATE_SHARE_ERROR'
export const CREATE_SHARE_RESET = 'CREATE_SHARE_RESET'

export function createShareLoading(): ActionT {
  return {
    type: CREATE_SHARE_LOADING,
  }
}

export function createShareSuccess(response: ShareSuccessT | string): ActionT {
  return {
    type: CREATE_SHARE_SUCCESS,
    payload: response,
  }
}

export function createShareError(err: ErrorT): ActionT {
  return {
    type: CREATE_SHARE_ERROR,
    error: true,
    payload: err,
  }
}

export function createShareReset(): ActionT {
  return {
    type: CREATE_SHARE_RESET,
  }
}

export function createShare(share: ShareT): Function {
  return (dispatch: Function, getState: Function, { fetch }): Promise<*> => {
    dispatch(createShareLoading())

    return new Promise((resolve: Function, reject: Function) => {
      fetch('/share/email', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(share),
        credentials: 'same-origin',
      })
        .then(handleResponse)
        .then(handleData(resolve, dispatch, createShareSuccess))
        .catch(handleError(reject, dispatch, createShareError))
    })
  }
}

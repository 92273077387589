import { batch } from 'react-redux'

import {
  createInitializeFiltersAction,
  createSetFilterSelectionAction,
  createUpdateAvailableFiltersAction,
} from '../shared/actions'
import { getSerializableFilterState, getFilteringQueryParam } from '../shared/selectors'
import { getUnifiedFilters } from './selectors'

const STATE_KEY = 'categories'
const SESSION_FILTER_STORAGE_KEY = 'unifiedStudyFilters'

const __commitFiltersToURL =
  () =>
  (dispatch, getState, { history }) => {
    const unifiedFilters = getUnifiedFilters(getState())
    localStorage.setItem(
      SESSION_FILTER_STORAGE_KEY,
      JSON.stringify(getSerializableFilterState(unifiedFilters))
    )

    const nextParams = new URLSearchParams(history.location.search)
    const filterParam = getFilteringQueryParam(unifiedFilters)

    if (filterParam) {
      nextParams.set('studyFilters', filterParam)
    } else {
      nextParams.delete('studyFilters')
    }
    history.replace(`?${nextParams.toString()}`)
  }

export const INITIALIZE_UNIFIED_FILTERS = 'INITIALIZE_UNIFIED_FILTERS'
const __initializeFilters = createInitializeFiltersAction(INITIALIZE_UNIFIED_FILTERS, STATE_KEY)

export const initializeFilters =
  () =>
  async (dispatch, getState, { fetch, history }) => {
    // Fetch full aggregations w/o filters+query to get all applied metadata
    const response = await fetch('/proxy/husky/studies/?page=1&page_size=1&sort=-study_year')
    if (!response.ok) {
      return
    }

    const { aggregations } = await response.json()
    dispatch(__initializeFilters(aggregations))

    // Session storage gets precedence over query params
    // We have to distinguish between a fresh session from a saved/shared
    // link vs. an existing session
    const queryParams = new URLSearchParams(history.location.search)
    const sessionFilters = localStorage.getItem(SESSION_FILTER_STORAGE_KEY)
    if (sessionFilters !== null && sessionFilters !== 'null') {
      const storedFilters = JSON.parse(sessionFilters)
      batch(() => {
        Object.entries(storedFilters).forEach(([category, values]) => {
          values.forEach(value => {
            dispatch(
              setFilterSelection(category, Array.isArray(value) ? value.join(':::') : value, true)
            )
          })
        })
      })
    } else if (queryParams.get('studyFilters') !== null) {
      // TODO: Alert user if URL params conflict with preferences, disable preferences
      const queryFilters = JSON.parse(decodeURIComponent(queryParams.get('studyFilters')))
      batch(() => {
        Object.entries(queryFilters).forEach(([category, values]) => {
          values.forEach(value => {
            dispatch(
              setFilterSelection(category, Array.isArray(value) ? value.join(':::') : value, true)
            )
          })
        })
      })
    }

    dispatch(__commitFiltersToURL())
  }

export const SET_UNIFIED_FILTER_SELECTION = 'SET_UNIFIED_FILTER_SELECTION'
const __setFilterSelection = createSetFilterSelectionAction(SET_UNIFIED_FILTER_SELECTION, STATE_KEY)

export const setFilterSelection = (fieldKey, key, value) => dispatch => {
  dispatch(__setFilterSelection(fieldKey, key, value))
  dispatch(__commitFiltersToURL())
  window.analytics.track('Change Unified Filter', {
    category: fieldKey,
    value: key,
    selected: value,
  })
}

export const UPDATE_AVAILABLE_UNIFIED_FILTERS = 'UPDATE_AVAILABLE_UNIFIED_FILTERS'
const __updateAvailableFilters = createUpdateAvailableFiltersAction(
  UPDATE_AVAILABLE_UNIFIED_FILTERS,
  STATE_KEY
)

export const updateAvailableFilters =
  (aggregations = []) =>
  (dispatch, getState) => {
    dispatch(__updateAvailableFilters(aggregations))
  }

export const CLEAR_UNIFIED_FILTERS = 'CLEAR_UNIFIED_FILTERS'
const __clearUnifiedFilters = () => ({
  type: CLEAR_UNIFIED_FILTERS,
})

export const clearUnifiedFilters =
  () =>
  (dispatch, getState, { history }) => {
    localStorage.removeItem(SESSION_FILTER_STORAGE_KEY)

    const nextParams = new URLSearchParams(history.location.search)
    nextParams.delete('studyFilters')
    history.replace(`?${nextParams.toString()}`)

    dispatch(__clearUnifiedFilters())

    window.analytics.track('Clear Unified Filters')
  }

import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { Icon } from '@knowledgehound/laika'

import { getUnifiedFilterTokens } from 'data/filters/unified/selectors'
import { clearUnifiedFilters, setFilterSelection } from 'data/filters/unified/actions'
import * as styles from './TokenList.module.scss'

const propTypes = {
  fetchAction: PropTypes.func.isRequired,
}

function TokenList({ fetchAction }) {
  const dispatch = useDispatch()
  const activeFilters = useSelector(getUnifiedFilterTokens, shallowEqual)

  const clearFilters = useCallback(() => {
    dispatch(clearUnifiedFilters())
    dispatch(fetchAction(true))
  }, [dispatch, fetchAction])

  return (
    <div className={styles.tokenList}>
      {Boolean(activeFilters.length) && (
        <button className={styles.clearButton} onClick={clearFilters}>
          <Icon icon="close" size="small" color="charcoal" />
          <span>Clear filters</span>
        </button>
      )}
      {activeFilters.map(filter => (
        <div key={`${filter.fieldKey}-${filter.key}`} className={styles.token}>
          <span className={styles.value}>{filter.label}</span>
          <button
            className={styles.removeToken}
            onClick={() => {
              dispatch(setFilterSelection(filter.fieldKey, filter.key, false))
              dispatch(fetchAction(true))
            }}
          >
            <Icon icon="close" size="small" color="charcoal" />
          </button>
        </div>
      ))}
    </div>
  )
}

TokenList.propTypes = propTypes

export default TokenList

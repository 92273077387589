//@flow
import { handleResponse, handleError, handleData } from '@knowledgehound/data/fetchUtils'

type HandleArgs<T, U> = {
  whenFetching: (null | T) => U,
  whenErrored: Error => U,
  whenFetched: T => U,
}

type HandleSomeArgs<T, U> = {
  whenFetching?: (null | T) => U,
  whenErrored?: Error => U,
  whenFetched?: T => U,
}

// Represents an object being fetched.
export class Fetching<T> {
  isFetching: boolean
  error: null | Error
  data: null | T

  constructor(isFetching: boolean, error: null | Error, data: null | T) {
    this.isFetching = isFetching
    this.error = error
    this.data = data
  }

  dataOrDefault = (default_: T): T => this.data || default_

  static fromObject(obj: null | Object): Fetching<T> {
    if (!obj) return (Fetching.initial(): any) // come on flow
    const data = (obj.data: T) || null
    return new Fetching(Boolean(obj.isFetching), obj.error, data)
  }

  // Initial state of a fetched object: no data yet, not fetching yet.
  static initial = (): Fetching<T> => new Fetching(false, null, null)

  // Object currently being fetched. Previously fetched data can be passed in.
  static fetching = (prev: null | Fetching<T>): Fetching<T> =>
    new Fetching(true, null, prev && prev.data)

  // Object successfully fetched.
  static fetched = (data: T): Fetching<T> => new Fetching(false, null, data)

  // An error occurred when fetching the object.
  // If desired, this could be modified to retain previous data rather than nullifying it.
  static errored = (prev: null | Fetching<T>, error: Error): Fetching<T> =>
    new Fetching(false, error, prev && prev.data)

  // Handle the fetching, error and fetched states. In general this
  // should be preferred over directly accessing '.data' or
  // '.isFetching'
  handle<U>({ whenFetching, whenErrored, whenFetched }: HandleArgs<T, U>): U {
    if (!this.isFetching && this.data) return whenFetched(this.data)
    else if (!this.isFetching && this.error) return whenErrored(this.error)
    else return whenFetching(this.data)
  }

  // Same as `handle` but can return null
  handleSome<U>({ whenFetching, whenErrored, whenFetched }: HandleSomeArgs<T, U>): null | U {
    if (!this.isFetching && this.data && whenFetched) return whenFetched(this.data)
    else if (!this.isFetching && this.error && whenErrored) return whenErrored(this.error)
    else if (whenFetching) return whenFetching(this.data)
    else return null
  }

  mapData<U>(f: T => U): null | U {
    return this.data != null ? f(this.data) : null
  }
}

// Arguments to the Fetcher class constructor. Unfortunately we can't
// define them inline due to flow restrictions (see
// https://github.com/facebook/flow/issues/307)
type FetcherArgs<Args, T> = {
  prefix: string,
  buildUrl: Args => string,
  buildOptions?: Args => Object,
  decoder?: (any, Args) => T,
}

type MakeReducerArgs<State, T> = {
  onSuccess?: (State, T) => State,
  onError?: (State, Error) => State,
  onFetching?: State => State,
}

// Handles a lot of boilerplate. Fires events for fetching, success and error states.
export class Fetcher<Args, T> {
  // Prefix to apply to the 'type' key in dispatched redux actions.
  // Suffix will be _FETCHING, _SUCCESS or _ERROR as the case may be.
  prefix: string
  // Builds a URL from parameters
  buildUrl: Args => string
  // Options to apply to fetch call (e.g. method, headers, etc). A default is defined below.
  buildOptions: Args => Object
  // How to decode the API response into a T object, after JSON
  // parsing. The result of this call will be put into the `payload`
  // of the success action. The first argument is the API response
  // JSON object. The second argument is arguments to the `fetch`
  // call, in case they're needed (e.g. if the response doesn't
  // contain all information needed).
  decoder: (any, Args) => T

  static defaultOptions = (overrides: Object) =>
    Object.assign(
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'same-origin',
      },
      overrides
    )

  constructor({ prefix, buildUrl, buildOptions, decoder }: FetcherArgs<Args, T>) {
    this.prefix = prefix
    this.buildUrl = buildUrl
    this.buildOptions = buildOptions || (() => Fetcher.defaultOptions({}))
    this.decoder = decoder || (x => x)
  }

  fetchingEvent = () => ({ type: `${this.prefix}_FETCHING` })
  successEvent = (result: T) => ({ type: `${this.prefix}_SUCCESS`, payload: result })
  errorEvent = (err: Error) => ({ type: `${this.prefix}_ERROR`, error: true, payload: err })

  // Perform a request, firing off fetching/success/error events along the way.
  fetch = (args: Args) => {
    return (dispatch: Function): Promise<T> => {
      dispatch(this.fetchingEvent())

      return new Promise((resolve: Function, reject: Function) => {
        const url = this.buildUrl(args)
        const options = this.buildOptions(args)
        fetch(url, options)
          .then(handleResponse)
          .then(r => this.decoder(r, args))
          .then(handleData(resolve, dispatch, this.successEvent))
          .catch(handleError(reject, dispatch, this.errorEvent))
      })
    }
  }

  makeReducer<State>({
    onSuccess = (s, _) => s,
    onError = (s, _) => s,
    onFetching = s => s,
  }: MakeReducerArgs<State, T>) {
    return (state: State, action: any): State => {
      switch (action.type) {
        case `${this.prefix}_SUCCESS`:
          return onSuccess(state, action.payload)
        case `${this.prefix}_ERROR`:
          return onError(state, action.payload)
        case `${this.prefix}_FETCHING`:
          return onFetching(state)
        default:
          return state
      }
    }
  }
}

// Use this reducer for fetchers which just fetches a single object
// and sets it on the state.
export function makeGetReducer<T>(actionTypePrefix: string) {
  return (state: Fetching<T> = Fetching.initial(), action: any): Fetching<T> => {
    if (!action) return state
    const { fetched, errored, fetching } = Fetching
    switch (action.type) {
      case `${actionTypePrefix}_SUCCESS`:
        return fetched(action.payload)
      case `${actionTypePrefix}_ERROR`:
        return errored(state, action.payload)
      case `${actionTypePrefix}_FETCHING`:
        return fetching(state)
      default:
        return state
    }
  }
}

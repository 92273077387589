// extracted by mini-css-extract-plugin
export var baseTiming = "125ms";
export var baseTimingInt = "125";
export var borderRadius = "3px";
export var buttonGroup = "Controls-module__button-group--7mbY-";
export var charcoal = "#2d2e2e";
export var cloudGrey = "#c8cdcf";
export var controls = "Controls-module__controls--Unvpy";
export var doveGrey = "#f1f3f5";
export var khBlue = "#04b1e0";
export var khBlueDark = "#0376ae";
export var khBlueLight = "#7de1f4";
export var leftSide = "Controls-module__left-side--JHCmz";
export var lemon = "#fae436";
export var lemonDark = "#e3cd02";
export var lemonLight = "#fff284";
export var lime = "#05c150";
export var limeDark = "#068230";
export var limeLight = "#65ec83";
export var navy = "#052976";
export var navyDark = "#04173f";
export var navyLight = "#82b1f6";
export var prefsToggleContainer = "Controls-module__prefs-toggle-container--MxcE2";
export var rightSide = "Controls-module__right-side--p1kql";
export var scarlet = "#e9062b";
export var scarletDark = "#9d050e";
export var scarletLight = "#f5726d";
export var stormGrey = "#969b9c";
export var studyCount = "Controls-module__study-count--7QYEL";
export var violet = "#d401f8";
export var violetDark = "#9003a8";
export var violetLight = "#e77ef9";
export var white = "#fff";
// @flow
import type { MetadataFieldT } from 'data/metadata/metadataField/MetadataFieldT'

export default function moveMetadataItem(
  metadataField: MetadataFieldT,
  fromId: number,
  toId: number,
  isOffset: boolean
): MetadataFieldT {
  const newItems = [...metadataField.values]

  const fromIndex = newItems.findIndex(item => item.id === fromId)
  const from = newItems[fromIndex]
  const toIndex = newItems.findIndex(item => item.id === toId)
  const to = newItems[toIndex]

  if (from.parent !== to.parent) {
    if (isOffset) {
      //this means the parents are different, and the user is dragging it offset from the target
      // and that the dragged item has a parent of the to item, which means it is not a really legit move
      if (from.parent === to.id) return metadataField
    } else {
      // both have parents
      if (from.parent && to.parent) {
        from.parent = to.parent // make the parents the same
      }
      // it is a root node and is moving down
      if (!from.parent && to.parent) {
        from.parent = to.parent
      }
      // to does not have a parent
      if (!to.parent) {
        from.parent = null
      }

      newItems.splice(fromIndex, 1) // remove the old one from its index
      newItems.splice(toIndex, 0, from) // move it to its new index
    }
  } else {
    // move it under it only if isOffset
    if (isOffset) {
      from.parent = to.id
    }
    if (!isOffset) {
      //if moving between same level, move it in the array as well
      newItems.splice(fromIndex, 1) // remove the old one from its index
      newItems.splice(toIndex, 0, from) // move it to its new index
    }
  }

  // resync group_position
  newItems
    .filter(newItem => {
      return newItem.parent === from.parent // get the group for this item
    })
    .forEach((newItem, idx) => {
      newItem.group_position = idx + 1
    })

  return {
    ...metadataField,
    values: newItems,
  }
}

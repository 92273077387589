import getSession from './getSession'

export const FETCH_SESSION_LOADING = 'FETCH_SESSION_LOADING'
export function fetchSessionLoading() {
  return {
    type: FETCH_SESSION_LOADING,
  }
}

export const FETCH_SESSION_SUCCESS = 'FETCH_SESSION_SUCCESS'
export function fetchSessionSuccess(session) {
  return {
    type: FETCH_SESSION_SUCCESS,
    payload: session,
  }
}

export const FETCH_SESSION_ERROR = 'FETCH_SESSION_ERROR'
export function fetchSessionError(err) {
  return {
    type: FETCH_SESSION_ERROR,
    error: true,
    payload: err,
  }
}

export const fetchSession = () => async dispatch => {
  dispatch(fetchSessionLoading())

  try {
    const session = await getSession()
    dispatch(fetchSessionSuccess(session))
  } catch (error) {
    dispatch(fetchSessionError(error))
  }
}

import type { MetadataFieldT } from 'data/metadata/metadataField/MetadataFieldT'

export default function removeMetadataItem(
  metadataField: MetadataFieldT,
  id: number
): MetadataFieldT {
  const newItems = [...metadataField.values]

  function hasChildren(value) {
    const child = newItems.find(newItem => {
      return newItem.parent === value.id
    })
    return child
  }

  function removeChildren(parent) {
    if (hasChildren(parent)) {
      // see if it has children
      const children = newItems.filter(item => {
        return item.parent === parent.id
      })

      //delete children if found
      children.forEach(child => {
        if (hasChildren(child)) {
          removeChildren(child)
        }
        const childIndex = newItems.findIndex(newItem => {
          return newItem.id === child.id
        })
        newItems.splice(childIndex, 1)
      })
    }
  }

  const index = newItems.findIndex(item => item.id === id)
  const itemToDelete = newItems[index]
  // delete it
  newItems.splice(index, 1)
  // see if it has children
  removeChildren(itemToDelete)

  return {
    ...metadataField,
    values: newItems,
  }
}

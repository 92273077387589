// extracted by mini-css-extract-plugin
export var baseTiming = "125ms";
export var baseTimingInt = "125";
export var borderRadius = "3px";
export var charcoal = "#2d2e2e";
export var clearButton = "TokenList-module__clear-button--HO27y";
export var cloudGrey = "#c8cdcf";
export var doveGrey = "#f1f3f5";
export var khBlue = "#04b1e0";
export var khBlueDark = "#0376ae";
export var khBlueLight = "#7de1f4";
export var lemon = "#fae436";
export var lemonDark = "#e3cd02";
export var lemonLight = "#fff284";
export var lime = "#05c150";
export var limeDark = "#068230";
export var limeLight = "#65ec83";
export var navy = "#052976";
export var navyDark = "#04173f";
export var navyLight = "#82b1f6";
export var removeToken = "TokenList-module__remove-token--35N9r";
export var scarlet = "#e9062b";
export var scarletDark = "#9d050e";
export var scarletLight = "#f5726d";
export var stormGrey = "#969b9c";
export var token = "TokenList-module__token--UdAzj";
export var tokenList = "TokenList-module__token-list--Xofzd";
export var value = "TokenList-module__value--seDhC";
export var violet = "#d401f8";
export var violetDark = "#9003a8";
export var violetLight = "#e77ef9";
export var white = "#fff";
import { batch } from 'react-redux'
import { Analysis2Store } from '@knowledgehound/analysis'

import { getAvailablePreferences, getLocalFilters, getUserPreferences } from './selectors'

const GREYHOUND_URL = process.env.REACT_APP_GREYHOUND_API2_URL || ''

const formatUserPreferences = preferences => {
  if (preferences.length > 0)
    return {
      name: preferences[0].metadata_field,
      values: preferences.map(preference => preference.metadata_value),
    }
}

export const SET_SEARCH_QUERY = 'SET_SEARCH_QUERY'
export const setSearchQuery = searchQuery => ({
  type: SET_SEARCH_QUERY,
  searchQuery,
})

export const FETCH_USER_PREFERENCES_LOADING = 'FETCH_USER_PREFERENCES_LOADING'
const __fetchUserPreferencesLoading = () => ({ type: FETCH_USER_PREFERENCES_LOADING })

export const FETCH_USER_PREFERENCES_ERROR = 'FETCH_USER_PREFERENCES_ERROR'
const __fetchUserPreferencesError = error => ({
  type: FETCH_USER_PREFERENCES_ERROR,
  error,
})

export const FETCH_USER_PREFERENCES_SUCCESS = 'FETCH_USER_PREFERENCES_SUCCESS'
const __fetchUserPreferencesSuccess = results => ({
  type: FETCH_USER_PREFERENCES_SUCCESS,
  results,
})

export const INITIALIZE_AVAILABLE_PREFERENCES = 'INITIALIZE_AVAILABLE_PREFERENCES'
const __initializeAvailablePreferences = aggregations => ({
  type: INITIALIZE_AVAILABLE_PREFERENCES,
  stateKey: 'availablePreferences',
  aggregations,
})

export const UPDATE_AVAILABLE_PREFERENCES = 'UPDATE_AVAILABLE_PREFERENCES'

export const SET_AVAILABLE_PREFERENCES = 'SET_AVAILABLE_PREFERENCES'
export const __setAvailablePreferences = (metadataField, metadataValue, userPreferred) => ({
  type: SET_AVAILABLE_PREFERENCES,
  fieldKey: metadataField,
  key: metadataValue,
  value: userPreferred,
  stateKey: 'availablePreferences',
})

export const FETCH_LOCAL_FILTERS = 'FETCH_LOCAL_FILTERS'
const __fetchLocalFilters = localFilters => ({
  type: FETCH_LOCAL_FILTERS,
  localFilters,
})

export const SET_SHOW_TOOLTIP = 'SET_SHOW_TOOLTIP'
export const setShowToolTip = show => ({
  type: SET_SHOW_TOOLTIP,
  show,
})

export const fetchUserPreferences =
  () =>
  async (dispatch, getState, { fetch }) => {
    dispatch(__fetchUserPreferencesLoading())

    const response = await fetch(`${GREYHOUND_URL}/usermetadata/`)
    if (!response.ok) {
      dispatch(__fetchUserPreferencesError(response.statusText))
      return
    }

    const result = await response.json()
    dispatch(__fetchUserPreferencesSuccess(result))
  }

export const REMOVED_USER_PREFERENCES_SUCCESS = 'REMOVED_USER_PREFERENCES_SUCCESS'
const __removedUserPreferencesSuccess = updatedPreferences => ({
  type: REMOVED_USER_PREFERENCES_SUCCESS,
  updatedPreferences,
})

export const ADDED_USER_PREFERENCES_SUCCESS = 'ADDED_USER_PREFERENCES_SUCCESS'
const __addedUserPreferencesSuccess = updatedPreferences => ({
  type: ADDED_USER_PREFERENCES_SUCCESS,
  updatedPreferences,
})

export const UPDATE_USER_PREFERENCES_ERROR = 'UPDATE_USER_PREFERENCES_ERROR'
const __updateUserPreferencesError = error => ({
  type: UPDATE_USER_PREFERENCES_ERROR,
  error,
})

export const setInitialPreferences =
  userHasNotViewedFilters =>
  async (dispatch, getState, { fetch }) => {
    const userPreferences = getUserPreferences(getState())
    const availablePreferences = getAvailablePreferences(getState())

    if (userHasNotViewedFilters) {
      const localFilters = getLocalFilters(getState())
      await Promise.all(
        localFilters.map(filter =>
          dispatch(changeUserPreference(filter.fieldKey, filter.key, true))
        )
      )
    }

    batch(() => {
      userPreferences.forEach(field => {
        if (availablePreferences[field.name]) {
          for (let value in availablePreferences[field.name]) {
            if (field.values.includes(availablePreferences[field.name][value].label)) {
              dispatch(__setAvailablePreferences(field.name, value, true))
            }
          }
        }
      })
    })
  }

export const setAvailablePreferences =
  (formattedUserPreferences, userPreferred) =>
  async (dispatch, getState, { fetch }) => {
    const availablePreferences = getAvailablePreferences(getState())
    const metadataField = formattedUserPreferences.name
    const metadataValues = formattedUserPreferences.values
    for (let value in availablePreferences[metadataField]) {
      const metadataValue = availablePreferences[metadataField][value].label
      if (metadataValues.includes(metadataValue)) {
        const userAction = userPreferred ? 'Added' : 'Removed'
        window.analytics.track(`${userAction} user preference`, { metadataField, metadataValue })
        dispatch(__setAvailablePreferences(metadataField, value, userPreferred))
      }
    }
  }

export const fetchAvailablePreferences =
  () =>
  async (dispatch, getState, { fetch }) => {
    const response = await fetch(`/proxy/husky/studies/?page=1&page_size=1&sort=-study_year`, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
      credentials: 'same-origin',
    })

    const result = await response.json()
    dispatch(__initializeAvailablePreferences(result.aggregations))
    const userPreferences = getUserPreferences(getState())
    const userHasNotViewedFilters = Analysis2Store.config.getUserHasNotViewedLocalFilters(
      getState()
    )
    const localFilters = getLocalFilters(getState())

    if (userPreferences.length > 0 || (userHasNotViewedFilters && localFilters.length > 0)) {
      dispatch(setInitialPreferences(userHasNotViewedFilters))
    }
  }

export const changeUserPreference =
  (metadataField, metadataValue, userPreferred, hasChildren) =>
  async (dispatch, getState, { fetch }) => {
    const greyhoundResponse = await fetch(`${GREYHOUND_URL}/usermetadata/`, {
      method: 'PATCH',
      jsonBody: {
        metadata_value: metadataValue,
        metadata_field: metadataField,
        user_selected: userPreferred,
        include_children: hasChildren,
      },
    })

    if (!greyhoundResponse.ok) {
      const error = await greyhoundResponse.json()
      dispatch(__updateUserPreferencesError(error ?? greyhoundResponse.statusText))
      return
    }

    const updatedPreferences = await greyhoundResponse.json()
    const formattedUserPreferences = formatUserPreferences(updatedPreferences)

    if (updatedPreferences.length > 0) {
      if (userPreferred) {
        dispatch(__addedUserPreferencesSuccess(formattedUserPreferences))
        dispatch(setAvailablePreferences(formattedUserPreferences, userPreferred))
        return
      }
      dispatch(__removedUserPreferencesSuccess(formattedUserPreferences))
      dispatch(setAvailablePreferences(formattedUserPreferences, userPreferred))
    }
  }

export const removeAllPreferences =
  () =>
  async (dispatch, getState, { fetch }) => {
    const preferences = getUserPreferences(getState())
    dispatch(setUserHasViewedModal(false))
    await preferences.forEach(field => {
      field.values.forEach(value => {
        dispatch(changeUserPreference(field.name, value, false))
      })
    })
  }

export const setUserViewedLocalFilters =
  () =>
  async (dispatch, getState, { fetch }) => {
    const currentPersonalization = Analysis2Store.config.getUserPersonalization(getState())
    const profileId = Analysis2Store.config.getUserProfileId(getState())
    const currentDate = new Date()
    const year = currentDate.getFullYear()
    const month = currentDate.getMonth() + 1
    const day = currentDate.getDate()
    const updatedPersonalization = {
      ...currentPersonalization,
      viewedLocalFilters: `${year}-${month}-${day}`,
    }
    const response = await fetch(`/proxy/rottweiler/users/${profileId}/`, {
      method: 'PATCH',
      jsonBody: {
        personalization: updatedPersonalization,
      },
    })

    if (!response.ok) {
      const error = await response.json()
      console.log(`rottweiler patch personalization error ${error}`)
      return
    }
    const data = await response.json()
    dispatch(Analysis2Store.config.patchUserPersonlization(data))
  }

export const setUserHasViewedModal =
  setPreferences =>
  async (dispatch, getState, { fetch }) => {
    const currentPersonalization = Analysis2Store.config.getUserPersonalization(getState())
    const profileId = Analysis2Store.config.getUserProfileId(getState())
    const currentDate = new Date()
    const year = currentDate.getFullYear()
    const month = currentDate.getMonth() + 1
    const day = currentDate.getDate()
    let updatedPersonalization = {
      ...currentPersonalization,
      viewedModal: `${year}-${month}-${day}`,
      setPreferences,
    }
    const localFilters = getLocalFilters(getState())
    if (localFilters) {
      updatedPersonalization = {
        ...updatedPersonalization,
        viewedLocalFilters: `${year}-${month}-${day}`,
      }
    }
    const response = await fetch(`/proxy/rottweiler/users/${profileId}/`, {
      method: 'PATCH',
      jsonBody: {
        personalization: updatedPersonalization,
      },
    })

    if (!response.ok) {
      const error = await response.json()
      console.log(`rottweiler patch personalization error ${error}`)
      return
    }
    const data = await response.json()
    dispatch(Analysis2Store.config.patchUserPersonlization(data))
  }

export const fetchLocalFilters =
  () =>
  (dispatch, getState, { fetch }) => {
    if (localStorage.getItem('studyFilters')) {
      const localFilters = JSON.parse(localStorage.getItem('studyFilters'))
      dispatch(__fetchLocalFilters(localFilters))
    }
  }

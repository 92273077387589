import React, { useEffect, useState, useCallback } from 'react'
import { isEqual } from 'lodash'
import PropTypes from 'prop-types'
import { useDispatch, shallowEqual, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

import { LoadingSpinner, SearchInput, useDebounce } from '@knowledgehound/laika'

import {
  setSearchQuery,
  fetchAvailablePreferences,
  fetchUserPreferences,
  fetchLocalFilters,
} from 'data/personalization/actions'
import {
  getFilteredPreferences,
  getSelectedPreferences,
  getIsFetchingUserPreferences,
} from 'data/personalization/selectors'
import UserPreferenceTags from './UserPreferenceTags/UserPreferenceTags'
import PreferenceTag from './PreferenceTag'

import * as styles from './UserPreferenceSelection.module.scss'

const propTypes = {
  fetchAction: PropTypes.func,
  userHasNotViewedModal: PropTypes.bool,
}

function UserPreferenceSelection({ fetchAction, userHasNotViewedModal }) {
  const dispatch = useDispatch()
  const [searchValue, setSearchValue] = useState('')
  const location = useLocation()
  const inUserSettings = location.pathname === '/settings/info'
  const preferences = useSelector(getFilteredPreferences, isEqual)
  const debouncedSearchQuery = useDebounce(searchValue, 125)
  const selectedPreferences = useSelector(getSelectedPreferences, shallowEqual)
  const userHasPreferences = selectedPreferences ? selectedPreferences.length > 0 : false
  const isFetchingPreferences = useSelector(getIsFetchingUserPreferences, shallowEqual)

  useEffect(() => {
    dispatch(fetchUserPreferences())
    dispatch(fetchAvailablePreferences())
    dispatch(fetchLocalFilters())
  }, [dispatch])

  useEffect(() => {
    dispatch(setSearchQuery(debouncedSearchQuery))
    window.analytics.track('User preferences filtered', {
      value: debouncedSearchQuery,
    })
  }, [debouncedSearchQuery, dispatch])

  const handleSearch = useCallback(event => setSearchValue(event.currentTarget.value), [])

  if (isFetchingPreferences) {
    return <LoadingSpinner text="Fetching preferences" />
  }

  return (
    <div>
      {inUserSettings && <div className={styles.preferenceSelectionTitle}>Preferences</div>}
      <section className={styles.preferenceSelectionContainer}>
        <span>
          <span>Select tags that interest you.</span>{' '}
          <button
            className={styles.openHelp}
            onClick={() => window.Intercom('showArticle', 8677414)}
          >
            Learn more.
          </button>
        </span>

        <SearchInput
          onChange={handleSearch}
          id="user preference search"
          placeholder={inUserSettings ? 'Search categories and content tags' : 'Find relevant tags'}
          size="large"
          value={searchValue}
        />
        {!inUserSettings && (
          <div>
            {userHasPreferences && (
              <div className={styles.preselectedPreferenceText}>
                {userHasNotViewedModal ? 'Pre-selected for you' : 'Your preferences'}
              </div>
            )}
            <div className={styles.preselectedPreferences}>
              {selectedPreferences &&
                selectedPreferences.map(preference => (
                  <PreferenceTag
                    key={`${preference.field}-${preference.label}`}
                    value={preference}
                    field={preference.field}
                    preselected={true}
                    userHasNotViewedModal={userHasNotViewedModal}
                    fetchAction={fetchAction}
                  />
                ))}
            </div>
          </div>
        )}
        {searchValue &&
          Object.entries(preferences).length === 0 &&
          `Sorry, we were unable to find any results for '${searchValue}'`}
        {preferences &&
          Object.entries(preferences).map(([field, values], index) => (
            <UserPreferenceTags
              key={`${field}`}
              field={field}
              values={values}
              inUserSettings={inUserSettings}
              searchValue={searchValue}
              userHasNotViewedModal={userHasNotViewedModal}
              fetchAction={fetchAction}
            />
          ))}
      </section>
    </div>
  )
}

UserPreferenceSelection.propTypes = propTypes
export default UserPreferenceSelection

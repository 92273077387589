import React from 'react'
import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'
import { useHistory } from 'react-router-dom'
import { Button } from '@knowledgehound/laika'

import * as styles from './CalloutSection.module.scss'

const propTypes = {
  context: PropTypes.string.isRequired,
  linkTo: PropTypes.string,
}

const CalloutSection = ({ context, linkTo }) => {
  const history = useHistory()
  const intl = useIntl()

  return (
    <div className={styles.section}>
      <h3 className={styles.subheading}>
        {intl.formatMessage({ id: `homepage.callout.${context}SubHeading` })}
      </h3>

      <p className={styles.text}>{intl.formatMessage({ id: `homepage.callout.${context}Text` })}</p>
      {linkTo && (
        <Button variant="primary" onClick={() => history.push(linkTo)}>
          {intl.formatMessage({ id: `homepage.callout.${context}Button` })}
        </Button>
      )}
    </div>
  )
}

CalloutSection.propTypes = propTypes

export default CalloutSection

// @flow
import * as actions from './AnalysisActions'
import type { AnalysisQuestionT } from './AnalysisQuestion.type'
import type { AnalysisStateT } from './AnalysisState.type'
import type { ActionT as ChartActionT } from '@knowledgehound/data/Action.type'

type ActionT =
  | {
      type: 'FETCH_ANALYSISQUESTION_SUCCESS',
      payload: AnalysisQuestionT,
    }
  | {
      type: 'FETCH_ANALYSISQUESTION_ERROR',
      payload: Error,
    }
  | {
      type: 'FETCH_ANALYSISQUESTION_LOADING',
    }
  | ChartActionT

export const defaultState: AnalysisStateT = {
  currentAnalysisQuestion: {
    isFetching: true,
    error: null,
    data: null,
    lastFetched: null,
  },
  currentParentStudy: {
    isFetching: true,
    error: null,
    data: null,
    lastFetched: null,
  },
  currentSimilarSamples: {
    isFetching: true,
    error: null,
    data: null,
    lastFetched: null,
  },
  currentOpenEndResults: {
    isFetching: true,
    error: null,
    data: null,
    lastFetched: null,
  },
  sentimentRanges: {
    isFetching: true,
    error: null,
    data: null,
    lastFetched: null,
  },
}

export const analysisReducer = (
  state: AnalysisStateT = defaultState,
  action: ActionT
): AnalysisStateT => {
  switch (action.type) {
    case actions.FETCH_ANALYSISQUESTION_LOADING: {
      return {
        ...state,
        currentAnalysisQuestion: {
          ...state.currentAnalysisQuestion,
          isFetching: true,
          error: null,
        },
      }
    }
    case actions.FETCH_ANALYSISQUESTION_SUCCESS: {
      const lastFetched = Date.now()
      return {
        ...state,
        currentAnalysisQuestion: {
          ...state.currentAnalysisQuestion,
          isFetching: false,
          lastFetched,
          data: action.payload,
          error: null,
        },
      }
    }
    case actions.FETCH_ANALYSISQUESTION_ERROR: {
      const lastFetched = Date.now()
      return {
        ...state,
        currentAnalysisQuestion: {
          ...state.currentAnalysisQuestion,
          isFetching: false,
          lastFetched,
          error: action.payload,
        },
      }
    }
    case actions.FETCH_PARENTSTUDY_LOADING: {
      return {
        ...state,
        currentParentStudy: {
          ...state.currentParentStudy,
          isFetching: true,
          error: null,
        },
      }
    }
    case actions.FETCH_PARENTSTUDY_SUCCESS: {
      const lastFetched = Date.now()
      return {
        ...state,
        currentParentStudy: {
          ...state.currentParentStudy,
          isFetching: false,
          lastFetched,
          data: action.payload,
          error: null,
        },
      }
    }
    case actions.FETCH_PARENTSTUDY_ERROR: {
      const lastFetched = Date.now()
      return {
        ...state,
        currentParentStudy: {
          ...state.currentParentStudy,
          isFetching: false,
          lastFetched,
          error: action.payload,
        },
      }
    }
    case actions.FETCH_SIMILARSAMPLES_LOADING: {
      return {
        ...state,
        currentSimilarSamples: {
          ...state.currentSimilarSamples,
          isFetching: true,
          error: null,
        },
      }
    }
    case actions.FETCH_SIMILARSAMPLES_SUCCESS: {
      const lastFetched = Date.now()
      return {
        ...state,
        currentSimilarSamples: {
          ...state.currentSimilarSamples,
          isFetching: false,
          lastFetched,
          data: action.payload,
          error: null,
        },
      }
    }
    case actions.FETCH_SIMILARSAMPLES_ERROR: {
      const lastFetched = Date.now()
      return {
        ...state,
        currentSimilarSamples: {
          ...state.currentSimilarSamples,
          isFetching: false,
          lastFetched,
          error: action.payload,
        },
      }
    }
    case actions.RESET_OPEN_END_RESPONSES: {
      return {
        ...state,
        currentOpenEndResults: {
          isFetching: true,
          error: null,
          data: null,
          lastFetched: null,
        },
      }
    }
    case actions.FETCH_OPEN_END_RESPONSES_LOADING: {
      return {
        ...state,
        currentOpenEndResults: {
          ...state.currentOpenEndResults,
          isFetching: true,
          error: null,
        },
      }
    }
    case actions.FETCH_OPEN_END_RESPONSES_SUCCESS: {
      const lastFetched = Date.now()
      return {
        ...state,
        currentOpenEndResults: {
          ...state.currentOpenEndResults,
          isFetching: false,
          lastFetched,
          data: action.payload,
          error: null,
        },
      }
    }
    case actions.FETCH_OPEN_END_RESPONSES_ERROR: {
      const lastFetched = Date.now()
      return {
        ...state,
        currentOpenEndResults: {
          ...state.currentOpenEndResults,
          isFetching: false,
          lastFetched,
          error: action.payload,
        },
      }
    }
    case actions.FETCH_SENTIMENT_RANGES_LOADING: {
      return {
        ...state,
        sentimentRanges: {
          ...state.sentimentRanges,
          isFetching: true,
          error: null,
        },
      }
    }
    case actions.FETCH_SENTIMENT_RANGES_SUCCESS: {
      const lastFetched = Date.now()
      return {
        ...state,
        sentimentRanges: {
          ...state.sentimentRanges,
          isFetching: false,
          lastFetched,
          data: action.payload,
          error: null,
        },
      }
    }
    case actions.FETCH_SENTIMENT_RANGES_ERROR: {
      const lastFetched = Date.now()
      return {
        ...state,
        sentimentRanges: {
          ...state.sentimentRanges,
          isFetching: false,
          lastFetched,
          error: action.payload,
        },
      }
    }
    default:
      return state
  }
}

import React from 'react'
import { useSelector, shallowEqual } from 'react-redux'
import { FormattedMessage } from 'react-intl'
import { Icon } from '@knowledgehound/laika'

import { getHideStudyLibrary, getStoriesEnabled } from 'data/session/SessionSelectors'
import CalloutSection from './CalloutSection'
import * as styles from './ExploreCallout.module.scss'

export default function ExploreCallout() {
  const storiesEnabled = useSelector(getStoriesEnabled, shallowEqual)
  const hideStudyLibrary = useSelector(getHideStudyLibrary, shallowEqual)

  return (
    <section className={styles.exploreCallout}>
      <h2 className={styles.heading}>
        <Icon icon="compass" size="extraLarge" />
        <FormattedMessage id="homepage.callout.heading" />
      </h2>
      <div className={styles.items}>
        {!hideStudyLibrary && <CalloutSection context="studies" linkTo="/studies" />}
        {storiesEnabled && <CalloutSection context="stories" linkTo="/stories" />}
      </div>
    </section>
  )
}

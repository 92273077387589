import React from 'react'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { Icon } from '@knowledgehound/laika'

import { changePage } from 'data/studyHome/actions'
import { getPagination } from 'data/studyHome/selectors'

import * as styles from './PageControls.module.scss'

export default function PageControls() {
  const dispatch = useDispatch()
  const { currentPage, totalPages } = useSelector(getPagination, shallowEqual)

  const pages = new Array(totalPages).fill(0).map((_, idx) => idx + 1)

  if (totalPages <= 1) return null

  const handleNav = (page, action) => () => {
    dispatch(changePage(page))

    let eventName = 'Go Directly to'
    if (action === 'next') eventName = 'Next'
    if (action === 'prev') eventName = 'Previous'
    window.analytics.track(`Study Home ${eventName} Page Clicked`, {
      source: 'Study Index',
      page,
    })
  }

  return (
    <div className={styles.pageControls}>
      <button
        className={styles.previous}
        disabled={currentPage <= 1}
        onClick={handleNav(currentPage - 1, 'prev')}
      >
        <Icon icon="arrowDown" color="inherit" size="small" />
      </button>
      {pages.slice(0, currentPage - 1).map(page => (
        <button key={page} onClick={handleNav(page)}>
          {page}
        </button>
      ))}
      <button className={styles.active}>{currentPage}</button>
      {pages.slice(currentPage).map(page => (
        <button key={page} onClick={handleNav(page)}>
          {page}
        </button>
      ))}
      <button
        className={styles.next}
        disabled={currentPage >= totalPages}
        onClick={handleNav(currentPage + 1, 'next')}
      >
        <Icon icon="arrowDown" color="inherit" size="small" />
      </button>
    </div>
  )
}

import React, { useCallback, useState, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { startCase } from 'lodash'
import { DropdownButton } from '@knowledgehound/laika'

import { setFilterSelection } from 'data/filters/unified/actions'
import * as styles from './FilterDropdown.module.scss'

const getVisibleItems = (name, filterBy) => state => {
  if (filterBy) {
    const depth = filterBy.split(':::').length + 1
    return Object.keys(state.unifiedFilters.categories[name] || {}).filter(
      key => key !== filterBy && key.startsWith(filterBy) && key.split(':::').length === depth
    )
  }
  if (name === 'study_year') {
    return Object.keys(state.unifiedFilters.categories[name] || {})
      .filter(key => !key.includes(':::'))
      .sort((a, b) => Number(b) - Number(a))
  }
  return Object.keys(state.unifiedFilters.categories[name] || {}).filter(
    key => !key.includes(':::')
  )
}

const getFilterState = name => state => state.unifiedFilters.categories[name] || {}

// Allow union within active filter categories, intersection between filter categories
const hasAnyOptionSelected = name => state =>
  Object.values(getFilterState(name)(state)).some(({ selected }) => selected)

const propTypes = {
  name: PropTypes.string.isRequired,
  fetchAction: PropTypes.func.isRequired,
}

function FilterDropdown({ name, fetchAction }) {
  const dispatch = useDispatch()
  const [parentFilterItem, setParentFilterItem] = useState('')
  const visibleItems = useSelector(getVisibleItems(name, parentFilterItem), shallowEqual)
  const filterState = useSelector(getFilterState(name), shallowEqual)
  const anyOptionSelected = useSelector(hasAnyOptionSelected(name), shallowEqual)

  const dropdownText = useMemo(() => startCase(name), [name])
  const handleParentClick = useCallback(
    () => setParentFilterItem(item => item.split(':::').slice(0, -1).join(':::')),
    [setParentFilterItem]
  )

  return (
    <div className={styles.dropdownContainer}>
      <DropdownButton
        disabled={!visibleItems.length}
        size="large"
        type="secondaryDark"
        text={dropdownText}
      >
        {parentFilterItem && (
          <DropdownButton.MenuItem isParent onClick={handleParentClick}>
            <span>{parentFilterItem.split(':::').join(' – ')}</span>
          </DropdownButton.MenuItem>
        )}
        {visibleItems.map((item, index) => (
          <DropdownButton.MenuItem
            key={index}
            onNextChild={filterState[item].hasChildren ? () => setParentFilterItem(item) : null}
          >
            <div className={styles.filterItem}>
              <input
                type="checkbox"
                id={item}
                checked={filterState[item].selected}
                disabled={anyOptionSelected ? false : !filterState[item].count}
                onChange={() => {
                  dispatch(setFilterSelection(name, item, !filterState[item].selected))
                  dispatch(fetchAction())
                }}
              />
              <label htmlFor={item}>
                <span className={styles.label}>{filterState[item].label}</span>
              </label>
            </div>
          </DropdownButton.MenuItem>
        ))}
      </DropdownButton>
    </div>
  )
}

FilterDropdown.propTypes = propTypes

export default FilterDropdown

import merge from 'lodash/merge'
import { messages as analysisMessages } from '@knowledgehound/analysis'
import storyComponentMessages from '@knowledgehound/story-components/messages'

const customerMessages = {
  'en-US': {
    global: {
      cancel: 'Cancel',
      study: 'Study',
      hide: 'Hide',
      to: 'to',
      goHome: 'Head safely home',
      contactUs: 'Contact Us',
      delete: 'Delete',
      save: 'Save',
      saveAndClose: 'Save and Close',
      saveChanges: 'Save Changes',
      upload: 'Upload',
      resetToDefault: 'Reset to Default',
      homepageBackgroundMessage: 'What can we help you discover today?',
    },
    accessRestricted: {
      title: 'Access restricted',
      content:
        'Our records show you do not have access to view this content. If you have questions please reach out to our customer success team.',
      chat: 'Chat with customer success',
      homepage: 'Head to the homepage',
    },
    notFound: {
      title: 'Not found',
      content:
        'What you are looking for either does not exist or was deleted. If you have questions please reach out to our customer success team.',
      chat: 'Chat with customer success',
      homepage: 'Head to the homepage',
    },
    studyHome: {
      errors: {
        createStudyFetchingSuppliers: 'Something went wrong, please refresh and try again.',
        createStudyDispatch:
          'Something went wrong creating this study. Please refresh and try again.',
      },
    },
    homepage: {
      callout: {
        heading: 'Looking for other ways to explore and activate insights?',
        storiesButton: 'Create and view stories',
        studiesButton: 'Browse your study library',
        studiesSubHeading: 'Find a study',
        storiesSubHeading: 'Create & view stories',
        storiesText: 'Create storyboards and share them with team members',
        studiesText: 'All your studies in one place along with their study questions and files',
      },
      sections: {
        addedStudies: 'Most recently added studies',
        updatedStories: 'Most recently updated public stories',
        viewAllStudies: 'View all studies',
        viewAllStories: 'View all public stories',
        favorites: 'Your favorites',
        viewAllFavorites: 'View all favorites',
      },
    },
    studyDetail: {
      accessControls: {
        title: 'Access to {entity}',
        datasetTitle: 'Dataset access for {entity}',
        tooltip: {
          loaded: 'Configure study access controls',
          loading: 'Loading groups...',
        },
        aclsWarning:
          'Caution: It is possible to lock yourself out if you are not a member of the groups you select.',
        error: "Couldn't update access controls for {studyName}",
      },
      addFilterData: 'Add',
      filterData: 'Filter data',
      rapidReports: {
        actionBar: {
          displayOptions:
            'Customize with additional display options, including data labels, auto update base, and hide/show low base',
          statTesting:
            'Turn on/off stat testing and set confidence level. Note, stats do not show in preview.',
        },
        previewingOldReport:
          'You’re viewing an old report. You can make a copy of this report and customize it to your liking.',
      },
      studyMetadata: 'Study Metadata',
      studyQuestions: 'Study Questions',
      studyDocuments: 'Study Documents',
      search: 'Filter all {count} {object}s by keyword',
      empty: {
        summary: {
          manager: 'Create a Summary of this Study. It helps showcase the key findings.',
          user: 'No Study Summary currently created.',
        },
        files: {
          manager: 'Upload some files related to this Study.',
          user: 'No files have been uploaded to this Study.',
        },
      },
      downloadWillBegin: 'Your download will begin momentarily',
      deleteFileConfirmation:
        'Are you sure you want to delete this study file? It will no longer show up on Stories and Dashboards.',
      deleteStudy: {
        error: 'An error occurred while deleting study "{studyName}"',
      },
      // These intl keys should match keys in the Analysis package
      dashboardErrors: {
        notAvailable: {
          title: 'This dataset is still generating raw data',
          body: 'Please try again in a few minutes. If the problem persists, contact client success.',
        },
        timeOut: {
          title: 'We were unable to load your data because there were too many cross tabulations',
          body: 'Please try again later.',
        },
        noVariableOptionsSelected: {
          title: 'No data available',
          body: 'At least one variable does not have any data because of applied filters.',
        },
        deletedOption: {
          title:
            'A response, option or net has been deleted from this dataset and cannot be visualized.',
          body: 'Managers can click on "View Original" in the dropdown to the right and recreate the desired chart.',
        },
        everythingLowBase: {
          title: 'All series are hidden due to their low base sizes',
          body: 'Managers can see the hidden series by viewing the original pinned chart.',
        },
        everythingNullSuppressed: {
          title: 'All series are hidden because they only contain null data',
          body: 'Managers can see the hidden series by viewing the original pinned chart.',
        },
        unknown: {
          title: 'An unknown error has occurred',
          body: 'Please try again in a few minutes.',
        },
      },
    },
    stories: {
      home: {
        tabs: {
          publicStories: {
            tabName: 'Public Stories',
            count: '{count} of {total, plural, one {# Public Story} other {# Public Stories}}',
            total: '{total, plural, one {# Public Story} other {# Public Stories}}',
          },
          myStories: {
            tabName: 'My Stories',
            count: '{count} of {total, plural, one {# Story} other {# Stories}}',
            total: '{total, plural, one {# Story} other {# Stories}}',
          },
          favorites: {
            tabName: 'Favorites',
            count: '{count} of {total, plural, one {# Favorite} other {# Favorites}}',
            total: '{total, plural, one {# Favorite} other {# Favorites}}',
          },
        },
      },
      noCards: 'No cards have been added to this story',
      emptyState1: 'Nice new story! Now its time to add some insights.',
      emptyState2: 'You can add study questions, documents or studies.',
      emptyDescription:
        "Click here to write a summary of your story or note the key outcomes you've found.",
      updatedPublicStory:
        'Your story was successfully updated. It may take a few minutes before your changes will appear in search.',
      lastUpdated: 'Last updated: {lastUpdated}',
      deleteStoryTitle: 'Delete Story',
      deleteStoryConfirmation: 'Are you sure you want to delete this story?',
      deleteKeyInsightTitle: 'Delete Key Insight',
      deleteKeyInsightConfirmation: 'Are you sure you want to delete this Key Insight?',
      public: 'Public',
      publicExplanation: 'Allow users to find your Story in search and in the library',
      private: 'Private',
      privateExplanation: 'Your story is only viewable to those with the URL.',
      privacySettingsTitle: 'Privacy settings',
      editCardTitle: 'Edit story card',
      confirmDeleteCard: 'Delete story card',
      confirmDeletion: 'Are you sure you want to delete this story card?',
      viewOriginal: 'View Original',
      viewAnalysis: 'Analyze',
      displayNets: 'Response Nets',
      displayXtabs: 'Sub Groups',
      displayFilters: 'Filters',
      displayOverallSentiment: 'Overall sentiment',
      displayFilteredSentiment: 'Filtered sentiment',
      displayThemes: 'Themes',
      noThemes: 'No themes to display',
      deleteCard: 'Yes, remove this card',
      keepCard: "Don't remove this card",
      errorCard: 'There was an issue in updating the story!',
      exportStoryError: 'There was an error exporting the story!',
    },
    openAnalysis: {
      filterByKeyword: 'Keyword',
      filterByKeywordWide: 'Filter by keyword',
      collapse: 'Collapse',
      export: 'Export responses to CSV',
      share: 'Share this analysis via email',
      needsAnnotation: 'Open Analysis is unavailable until the Survey is published.',
      responses:
        '{itemCount, plural, =0 {# responses found} one {# response found} other {# responses found}}',
    },
    files: {
      associated_document: 'Associated Document',
      research_document: 'Research Document',
      data: 'Data File',
      download: 'Download {name}',
      view: 'View {name}',
      copied: 'Document viewer url copied to clipboard!',
      file: 'File',
      downloadError: 'There was a problem downloading the file',
      downloadNotFound: 'The requested file could not be found',
      deleteFileConfirmation:
        'Are you sure you want to delete this study file? (deletion may take up to one minute)',
    },
    share: {
      share: 'Share: {share_description}',
      to: 'To',
      toPlaceholder: 'Add recipient',
      message: 'Message',
      messagePlaceholder: 'Add your message',
      shareButton: 'Share',
      error: 'There was an error sharing this page',
    },
    addToFavorite: {
      error: 'There was a problem adding {typeLabel} to favorites',
    },
    removeFromFavorite: {
      error: 'There was a problem removing {typeLabel} from favorites',
    },
    actionItems: {
      addToStory: 'Add {object} to Story',
      copied: 'Copied!',
      copyToClipboard: 'Copy {object} to clipboard',
      manage: 'Manage',
      share: 'Share {object}',
      favorite: 'Favorite {object}',
      removeFavorite: 'Remove {object} from favorites',
      addRemoveFavorite: 'Add/Remove {object} from favorites',
      manageStudy: 'Manage Study',
    },
    addToStory: {
      titlePlaceholder: 'Title',
      descriptionPlaceholder: 'Description',
    },
    results: {
      allStudies: 'All Studies',
      topResults: 'Top Results',
      studies: 'Studies',
      stories: 'Stories',
      questions: 'Study Questions',
      documents: 'Documents',
      studySummary: '{study_name} is from {formattedStudyDate} and was conducted by {suppliers}.',
      studySummaryNoSupplier: '{study_name} is from {formattedStudyDate}.',
      studySampleSummary: 'The study sample was {study_sample}.',
      noStudySampleSummary: 'The study sample was not set.',
      duplicates:
        'This question was asked {itemCount, plural, one {# other time} other {# other times}}.',
      loadMoreResults: 'Load more results',
      viewDashboard: 'View study dashboard',
      questionsHeader: 'Questions matching your search',
      documentsHeader: 'Documents matching your search',
      noResultsQuestions: 'No questions found matching {queryTerm}',
      noResultsDocuments: 'No documents found matching {queryTerm}',
      studyInnerHits: {
        questions:
          '{datapoint_hits, plural, =0 {} =1 {1 question} other {{datapoint_hits} questions}}',
        documents: '{summary_hits, plural, =0 {} =1 {1 document} other {{summary_hits} documents}}',
      },
      storyInnerHits: {
        cards: '{cards, plural, =0 {} =1 {1 card} other {{cards} cards}} match',
      },
    },
    updateFeaturedItem: {
      newTitle: 'Title was updated successfully.',
      error: 'Could not update item.',
    },
    integration: {
      qualtrics: {
        confirmRemoveIntegration:
          'Are you sure you want to remove this integration? Qualtrics survey data will no longer be synced. Knowledgehound dashboards created from Qualtrics surveys will remain unchanged.',
      },
      surveymonkey: {
        confirmRemoveIntegration:
          'Are you sure you want to remove this integration? SurveyMonkey survey data will no longer be synced. Knowledgehound dashboards created from SurveyMonkey surveys will remain unchanged.',
      },
      decipher: {
        surveyListTitle: 'Decipher Surveys',
        surveyListDescription:
          'All of your available Decipher surveys are listed here. You can sync studies to ensure your KnowledgeHound studies are always updated with the newest data. Larger surveys could take more than an hour to sync.',
        fetchingSurveys: 'Fetching your Decipher surveys',
      },
    },
    userManagement: {
      setUserActivation: {
        error: 'User {name} could not be {operation}',
      },
      setUserRole: {
        error: 'Could not set role of {name} to {newRole}',
      },
      removeWhitelistExplanation:
        'Please note that revoking an authorization will prevent users from logging in but will not end their current session or unregister existing users. Similarly, deleting or deactivating a user will not deauthorize their address.',
      removeWhitelistDomain: {
        confirm: 'Are you sure you want to remove domain {domain}?',
        success: 'Removed {domain} from domain whitelist',
        error: 'Could not remove {domain} from domain whitelist',
      },
      removeWhitelistEmail: {
        confirm: 'Are you sure you want to remove user {email}?',
        success: 'Removed {email} from email whitelist',
        error: 'Could not remove {email} from email whitelist',
      },
      addWhitelistEmails: {
        success: 'Added {value} to the email whitelist',
        warning: 'Could not add "{badEmail}": invalid email address',
        error: 'There was an error adding to the email whitelist',
      },
      addWhitelistDomains: {
        success: 'Added {value} to the domain whitelist',
        warning: 'Could not add "{badDomain}": invalid domain',
        error: 'There was an error adding to the domain whitelist',
      },
      registerConfirmation: {
        confirm: 'Are you sure you want to register user {email}?',
      },
      registerExplanation:
        'Your account has Single Sign On (SSO) enabled and only users logging in with their email and ' +
        'password should be registered. SSO auto-registers users as they login. ' +
        "Click 'No' if this email address is an SSO user.",
    },
    customize: {
      upload: 'Upload new',
      loadingBranding: 'Loading customization options...',
      uploaderInfo: 'Uploaded {uploadDate} by {uploaderName}',
      downloadStarterTemplate: 'Download starter template',
      logo: {
        title: 'Company logo',
        guidelines: 'Image must be PNG, JPEG or GIF. Maximum file size is {maxFileSizeMb}MB.',
      },
      homepageBackground: {
        title: 'Homepage background',
        guidelines:
          'Image must be PNG, JPEG or GIF. Maximum file size is {maxFileSizeMb}MB. We recommend a minimum resolution of {minResX}px by {minResY}px.',
      },
      defaultCoverPhoto: {
        title: 'Default study image',
        guidelines:
          'Image must be PNG, JPEG or GIF. Maximum file size is {maxFileSizeMb}MB. We recommend a minimum resolution of {minResX}px by {minResY}px.  If your cover photo includes text leave plenty of space from the edges to the account for cropping.',
      },
      loginBackground: {
        title: 'Login page',
        guidelines:
          'Image must be PNG, JPEG or GIF. Maximum file size is {maxFileSizeMb}MB. We recommend a minimum resolution of {minResX}px by {minResY}px.',
      },
      pptxTemplate: {
        title: 'Custom PowerPoint Template',
        guidelines: 'File must be PPTX and contain 2 master slides.',
      },
    },
  },
}

const messages = merge(customerMessages, analysisMessages, storyComponentMessages)

export default messages

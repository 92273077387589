import { createSelector } from 'reselect'
import { isEmpty } from 'lodash'
import search from 'simple-text-search'

import { Analysis2Store } from '@knowledgehound/analysis'

import { getDatasetListResult, getDatasetQuestions } from 'data/studyDatasets/selectors'
import { gridPlaceholderText } from './constants'

const MAX_SEG_COUNT = 2

export const getBulkAnalysisList = state => state.bulkAnalysis.bulkAnalysisList

export const getBulkAnalysisListData = state => state.bulkAnalysis.bulkAnalysisList.data || []

export const getBulkAnalysisListFetching = state => state.bulkAnalysis.bulkAnalysisList.isFetching

export const getBulkAnalysisListError = state => state.bulkAnalysis.bulkAnalysisList.error

export const getBulkAnalysisFilter = state => state.bulkAnalysis.bulkAnalysisList.filterText || ''

export const getFilteredBulkAnalysisList = createSelector(
  [getBulkAnalysisListData, getBulkAnalysisFilter],
  (bulkAnalysisList, filterText) => {
    const get = search(bulkAnalysisList, 'title')
    return get(filterText).map(report => ({
      ...report,
      name: `${report.last_name}:::${report.first_name}`,
    }))
  }
)

export const getIsExporting = state => state.bulkAnalysis.bulkAnalysisList.isExporting

export const getCreateBulkAnalysis = state => state.bulkAnalysis.createBulkAnalysis

export const getReportBuilderActive = state =>
  state.bulkAnalysis.createBulkAnalysis.reportBuilderActive

export const getFilterModalOpen = state => state.bulkAnalysis.createBulkAnalysis.isFilterModalOpen

export const getNetModalOpen = state => state.bulkAnalysis.createBulkAnalysis.isNetsModalOpen

export const getReportName = state => state.bulkAnalysis.createBulkAnalysis.reportName || ''

export const isReportAutoName = state => state.bulkAnalysis.createBulkAnalysis.autoName

export const getPreviewVariable = state => state.bulkAnalysis.createBulkAnalysis.previewVariable

export const showPreviewModal = state => state.bulkAnalysis.createBulkAnalysis.previewModalOpen

export const getIsChartLoading = state => state.bulkAnalysis.createBulkAnalysis.chartLoadingOverride

export const getReportMissingData = state => state.bulkAnalysis.createBulkAnalysis.reportMissingData

export const getVariableFilter = state =>
  state.bulkAnalysis.createBulkAnalysis.variableFilterText || ''

export const getDragCount = state => state.bulkAnalysis.createBulkAnalysis.dragCount

export const getDragAxis = state => state.bulkAnalysis.createBulkAnalysis.dragAxis

export const getDragVariable = state => state.bulkAnalysis.createBulkAnalysis.dragVariable

export const getSelectedVariables = state =>
  state.bulkAnalysis.createBulkAnalysis.selectedVariables || []

export const getLastSelectedIdx = state => state.bulkAnalysis.createBulkAnalysis.lastSelectedIdx

export const getMainVariables = state => state.bulkAnalysis.createBulkAnalysis.mainVariables || []

export const getSegmentationVariables = state =>
  state.bulkAnalysis.createBulkAnalysis.segmentationVariables || []

export const getShowGridPlaceholder = createSelector(
  [getMainVariables, getPreviewVariable],
  (mainVars, previewVariable) => {
    const preview = mainVars.find(v => v.variableNk === previewVariable)
    const hasGrid = mainVars.some(v => v.isGrid && v.variableNk !== gridPlaceholderText)
    return Boolean(hasGrid && (!preview || !preview.isGrid))
  }
)

export const getMaxSegCount = createSelector([Analysis2Store.selectors.getChartType], chartType => {
  return chartType === 'line' ? 1 : MAX_SEG_COUNT
})

export const getVisibleSegVars = createSelector(
  [
    getSegmentationVariables,
    getPreviewVariable,
    getShowGridPlaceholder,
    getSelectedVariables,
    getDragCount,
    getDragVariable,
    getDragAxis,
    getMaxSegCount,
  ],
  (
    segmentationVariables,
    previewVariable,
    showGridPlaceholder,
    selectedVariables,
    dragCount,
    dragVar,
    dragAxis,
    maxSegCount
  ) => {
    const dragAdjustment = dragAxis === 'COLUMN' ? 1 : 0
    const visibleSegVars = []
    let gridCounted = false
    let currentVarCount = 0
    segmentationVariables.forEach(v => {
      if (!v.isGrid) {
        currentVarCount++
        visibleSegVars.push(v)
      } else if (v.variableNk !== gridPlaceholderText) {
        if (v.variableNk === previewVariable) visibleSegVars.push(v)
        if (
          dragVar &&
          dragVar.variableNk === v.variableNk &&
          dragVar.resourceType !== v.resourceType
        ) {
          currentVarCount++
        } else {
          const gridVars = segmentationVariables.filter(sv => sv.variableNk === v.variableNk)
          if (gridVars.length > 1) {
            currentVarCount++
            visibleSegVars.push(v)
          } else if (!gridCounted) {
            gridCounted = true
            currentVarCount++
          }
        }
      } else if (showGridPlaceholder) {
        visibleSegVars.push(v)
      }
    })
    const segDropDisabled = !!(
      currentVarCount + Math.max(dragCount, selectedVariables.length) - dragAdjustment >
      maxSegCount
    )
    return { visibleSegVars, segDropDisabled }
  }
)

export const getCanLineChart = createSelector([getSegmentationVariables], segVars => {
  return segVars.length === 1 && segVars[0].represents === 'interval'
})

export const getAxisVariables = state => state.bulkAnalysis.createBulkAnalysis.axisVariables || []

export const getVariableLists = createSelector(
  [
    getDatasetQuestions,
    getVariableFilter,
    getSelectedVariables,
    getMainVariables,
    getSegmentationVariables,
  ],
  (allQuestions, variableFilter, selectedVariables, mainVars, segVars) => {
    const get = search(allQuestions, 'question_text')
    const filteredQuestions = get(variableFilter)
    const suggested = []
    const main = []
    filteredQuestions.forEach(q => {
      const isVarAdded = [...mainVars, ...segVars].find(v => v.variableNk === q.nk)
      if (!q.is_quantitative || isVarAdded) return
      const selectionVar = {
        questionName: q.question_name,
        label: q.question_text,
        variableNk: q.nk,
        suggested: q.suggested_xtab,
        isSelected: selectedVariables.some(v => v.questionName === q.question_name),
        isGrid: q.is_grid,
        funQuestionType: q.fundamental_question_type,
        represents: q.represents,
        resourceType: 'options',
        positionOverride: false,
      }
      if (q.suggested_xtab) {
        suggested.push({ ...selectionVar, idx: suggested.length })
      } else if (q.main_var_list) {
        main.push(selectionVar)
      }
    })
    return {
      suggestedVariables: suggested,
      mainVariables: main.map((v, i) => ({
        ...v,
        idx: suggested.length + i,
      })),
    }
  }
)

export const getReportOptions = state => state.bulkAnalysis.createBulkAnalysis.options

export const getShowStats = state =>
  state.bulkAnalysis.createBulkAnalysis.options.viewSettings.showStatTesting

export const getShowStatsConfidence = state =>
  state.bulkAnalysis.createBulkAnalysis.options.viewSettings.statsConfidence

export const getCreateBAReportFetching = state => state.bulkAnalysis.createBulkAnalysis.isFetching

export const getCreateBAReportError = state => state.bulkAnalysis.createBulkAnalysis.error

export const getGenerateDisabledMessage = createSelector(
  [getReportName, getMainVariables, getSegmentationVariables, getAxisVariables],
  (reportName, mainVars, segVars, axisVars) => {
    if (!reportName) return 'A report name is required to generate a report'
    if (!mainVars.filter(v => v.variableNk !== gridPlaceholderText).length) {
      return 'At least one main report variable is required to generate a report'
    }
    const noOptionsVar = [...mainVars, ...segVars].find(v => {
      const axisVar = axisVars.find(
        av => v.variableNk === av.variableNk && v.resourceType === av.resourceType
      )
      if (!axisVar || axisVar.fromOldReport) return false
      return axisVar.selectedOptions.every(o => !o.selected)
    })
    if (noOptionsVar) {
      return `At least one option must be selected for each variable. Please select an option for "${noOptionsVar.label}"`
    }
    return ''
  }
)

/** Has user not acknowledged that they're viewing an old report they can not edit but can make a copy of? */
export const getIsPreviewingOldReport = state =>
  state.bulkAnalysis.createBulkAnalysis.previewingOldReport

/** Get name of old report that user started a fresh copy of. */
export const getOldReportName = state => state.bulkAnalysis.createBulkAnalysis.previewingOldReport

const getOldReportVars = state => state.bulkAnalysis.fetchExistingReport.reportVars
const listToLookupTable = (opts = []) =>
  opts.reduce((acc, opt) => {
    acc[opt] = true
    return acc
  }, {})

/**
 * Get lookup table of variable option selections from existing report.
 * Keyed by question ID/name, not variable natural key.
 */
export const getOldReportVarSelectionMap = createSelector(
  getOldReportVars,
  ({ main = [], segmentation = [] }) =>
    [...main, ...segmentation].reduce((acc, variable) => {
      const id = Object.keys(variable)[0]
      if (variable[id].type === 'breakouts') {
        acc[id] = {
          ...acc[id],
          calculatedBreakouts: listToLookupTable(variable[id].calculated),
          deselectedBreakouts:
            variable[id].deselected_breakouts === undefined
              ? 'ALL_DESELECTED'
              : listToLookupTable(variable[id].deselected_breakouts),
          nets: !isEmpty(acc[id]?.nets)
            ? { ...acc[id].nets, ...listToLookupTable(variable[id].nets) }
            : listToLookupTable(variable[id].nets),
        }
      } else {
        acc[id] = {
          ...acc[id],
          calculated: listToLookupTable(variable[id].calculated),
          deselectedOptions:
            variable[id].deselected_options === undefined
              ? 'ALL_DESELECTED'
              : listToLookupTable(variable[id].deselected_options),
          nets: !isEmpty(acc[id]?.nets)
            ? { ...acc[id].nets, ...listToLookupTable(variable[id].nets) }
            : listToLookupTable(variable[id].nets),
        }
      }
      return acc
    }, {})
)

/**
  Sends analytics data to kissmetrics / segment
  Default data sent are as listed below
    - Dataset name
    - study ID
    - user email
    - client

  @param eventName {string} - name of the event that will be logged
  @param valuesToAdd {Object} - Object which the properties will be spread to
  the data that is sent to the back end
*/
export const getHandleTrack = createSelector(
  getDatasetListResult,
  Analysis2Store.config.getUserInfo,
  getIsPreviewingOldReport,
  getOldReportName,
  (dataset, userInfo, isPreviewingOldReport, oldReportName) => (eventName, valuesToAdd) => {
    if (userInfo && dataset) {
      const standardProperties = {
        source: 'Bulk Analysis Reports',
        study_id: dataset.study_id,
        dataset_name: dataset.dataset_name,
        user_email: userInfo.email,
        first_name: userInfo.first_name,
        last_name: userInfo.last_name,
        admin: userInfo.admin,
        manager: userInfo.manager,
        client: window.location.host.split('.')[0],
      }

      const mergedEventProperties = {
        ...standardProperties,
        ...valuesToAdd,
        isPreviewingOldReport,
        oldReportName,
      }

      window.analytics.track(eventName, mergedEventProperties)
    }
  }
)

import {
  getIsFetchingDecipherSurveys,
  getIsCreatingDecipherKey,
  getHasErrorCreatingDecipherKey,
  getIsUpdatingDecipherKey,
  getIsDeletingDecipherKey,
  getHasErrorDeletingDecipherKey,
  getDecipherPublicKey,
  getDecipherPrivateKey,
  getDecipherToken,
} from '../selectors'

const COLLIE_URL = process.env.REACT_APP_COLLIE_URL || ''

export const EDIT_DECIPHER_API_KEY = 'EDIT_DECIPHER_API_KEY'
/**
 * Stage a change to Decipher API key. Used for creation and
 * updating an existing key.
 *
 * Decipher API keys consist of a 32-character public key
 * concatenated with a 32-character private key.
 *
 * @param {String} partType - Either 'publicKey' or 'privateKey'
 * @param {String} value - Staged value for the API key part
 */
export const editDecipherApiKeyPart = (partType, value) => ({
  type: EDIT_DECIPHER_API_KEY,
  partType,
  value,
})

export const CREATE_DECIPHER_KEY_LOADING = 'CREATE_DECIPHER_KEY_LOADING'
const __createDecipherKeyLoading = () => ({
  type: CREATE_DECIPHER_KEY_LOADING,
})

export const CREATE_DECIPHER_KEY_SUCCESS = 'CREATE_DECIPHER_KEY_SUCCESS'
const __createDecipherKeySuccess = data => ({
  type: CREATE_DECIPHER_KEY_SUCCESS,
  data,
})

export const CREATE_DECIPHER_KEY_ERROR = 'CREATE_DECIPHER_KEY_ERROR'
const __createDecipherKeyError = error => ({
  type: CREATE_DECIPHER_KEY_ERROR,
  error,
})

export const createDecipherKey =
  () =>
  async (dispatch, getState, { fetch }) => {
    if (getIsCreatingDecipherKey(getState())) return

    dispatch(__createDecipherKeyLoading())

    const publicKey = getDecipherPublicKey(getState()).trim()
    const privateKey = getDecipherPrivateKey(getState()).trim()
    const formData = new FormData()
    formData.append('api_key', `${publicKey}${privateKey}`)

    const response = await fetch(`${COLLIE_URL}/survey_integrations/decipher/token/create/`, {
      method: 'POST',
      body: formData,
    })

    if (!response.ok) {
      dispatch(__createDecipherKeyError(response.statusText))
      return
    }

    const data = await response.json()
    dispatch(__createDecipherKeySuccess(data))
  }

export const DELETE_DECIPHER_KEY_LOADING = 'DELETE_DECIPHER_KEY_LOADING'
const __deleteDecipherKeyLoading = () => ({
  type: DELETE_DECIPHER_KEY_LOADING,
})

export const DELETE_DECIPHER_KEY_SUCCESS = 'DELETE_DECIPHER_KEY_SUCCESS'
const __deleteDecipherKeySuccess = isUpdatingKey => ({
  type: DELETE_DECIPHER_KEY_SUCCESS,
  isUpdatingKey,
})

export const DELETE_DECIPHER_KEY_ERROR = 'DELETE_DECIPHER_KEY_ERROR'
const __deleteDecipherKeyError = error => ({
  type: DELETE_DECIPHER_KEY_ERROR,
  error,
})

export const deleteDecipherKey =
  options =>
  async (dispatch, getState, { fetch }) => {
    if (getIsDeletingDecipherKey(getState())) return

    dispatch(__deleteDecipherKeyLoading())

    const formData = new FormData()
    formData.append('api_key', getDecipherToken(getState()))

    const response = await fetch(`${COLLIE_URL}/survey_integrations/decipher/token/delete/`, {
      method: 'POST',
      body: formData,
    })

    if (!response.ok) {
      dispatch(__deleteDecipherKeyError(response.statusText))
      return
    }

    dispatch(__deleteDecipherKeySuccess(options?.isUpdatingKey ?? false))
  }

export const UPDATE_DECIPHER_KEY_LOADING = 'UPDATE_DECIPHER_KEY_LOADING'
const __updateDecipherKeyLoading = () => ({ type: UPDATE_DECIPHER_KEY_LOADING })

export const UPDATE_DECIPHER_KEY_SUCCESS = 'UPDATE_DECIPHER_KEY_SUCCESS'
const __updateDecipherKeySuccess = () => ({ type: UPDATE_DECIPHER_KEY_SUCCESS })

export const UPDATE_DECIPHER_KEY_ERROR = 'UPDATE_DECIPHER_KEY_ERROR'
const __updateDecipherKeyError = error => ({
  type: UPDATE_DECIPHER_KEY_ERROR,
  error,
})

export const updateDecipherKey =
  () =>
  async (dispatch, getState, { fetch }) => {
    if (getIsUpdatingDecipherKey(getState())) return
    dispatch(__updateDecipherKeyLoading())

    await dispatch(deleteDecipherKey({ isUpdatingKey: true }))

    if (getHasErrorDeletingDecipherKey(getState())) {
      dispatch(__updateDecipherKeyError(true))
      return false
    }

    await dispatch(createDecipherKey())

    if (getHasErrorCreatingDecipherKey(getState())) {
      dispatch(__updateDecipherKeyError(true))
      return false
    }

    dispatch(__updateDecipherKeySuccess())

    await dispatch(fetchDecipherSurveys())
    return true
  }

export const FETCH_DECIPHER_SURVEYS_LOADING = 'FETCH_DECIPHER_SURVEYS_LOADING'
const __fetchDecipherSurveysLoading = () => ({
  type: FETCH_DECIPHER_SURVEYS_LOADING,
})

export const FETCH_DECIPHER_SURVEYS_SUCCESS = 'FETCH_DECIPHER_SURVEYS_SUCCESS'
const __fetchDecipherSurveysSuccess = surveys => ({
  type: FETCH_DECIPHER_SURVEYS_SUCCESS,
  surveys,
})

export const FETCH_DECIPHER_SURVEYS_ERROR = 'FETCH_DECIPHER_SURVEYS_ERROR'
const __fetchDecipherSurveysError = error => ({
  type: FETCH_DECIPHER_SURVEYS_ERROR,
  error,
})

export const fetchDecipherSurveys =
  () =>
  async (dispatch, getState, { fetch }) => {
    if (getIsFetchingDecipherSurveys(getState()) || !getDecipherToken(getState())) return

    dispatch(__fetchDecipherSurveysLoading())

    const response = await fetch(`${COLLIE_URL}/decipher/surveys/`, { skipLoginRedirect: true })

    if (!response.ok) {
      dispatch(__fetchDecipherSurveysError(response.status))
      return
    }

    const { results } = await response.json()
    dispatch(__fetchDecipherSurveysSuccess(results))
  }

export const IMPORT_DECIPHER_SURVEY_LOADING = 'IMPORT_DECIPHER_SURVEY_LOADING'
const __importDecipherSurveyLoading = surveyId => ({
  type: IMPORT_DECIPHER_SURVEY_LOADING,
  surveyId,
})

export const IMPORT_DECIPHER_SURVEY_SUCCESS = 'IMPORT_DECIPHER_SURVEY_SUCCESS'
const __importDecipherSurveySuccess = (surveyId, data) => ({
  type: IMPORT_DECIPHER_SURVEY_SUCCESS,
  surveyId,
  data,
})

export const IMPORT_DECIPHER_SURVEY_ERROR = 'IMPORT_DECIPHER_SURVEY_ERROR'
const __importDecipherSurveyError = (surveyId, error) => ({
  type: IMPORT_DECIPHER_SURVEY_ERROR,
  surveyId,
  error,
})

export const importDecipherSurvey =
  ({ survey_id: surveyId, name, created_date: createdDate }) =>
  async (dispatch, getState, { fetch }) => {
    dispatch(__importDecipherSurveyLoading(surveyId))

    window.analytics.track('Decipher Survey Import Start', {
      survey: name,
    })

    const formData = new FormData()
    formData.append('study_name', name)
    formData.append('created_date', createdDate)

    const response = await fetch(`${COLLIE_URL}/decipher/surveys/${surveyId}/`, {
      method: 'POST',
      body: formData,
    })

    if (!response.ok) {
      dispatch(__importDecipherSurveyError(surveyId, response.statusText))
      return
    }

    const data = await response.json()
    dispatch(__importDecipherSurveySuccess(surveyId, data))
  }

export const UPDATE_DECIPHER_SURVEY_STATUS_SUCCESS = 'UPDATE_DECIPHER_SURVEY_STATUS_SUCCESS'
const __updateDecipherSurveyStatusSuccess = data => ({
  type: UPDATE_DECIPHER_SURVEY_STATUS_SUCCESS,
  data,
})

export const UPDATE_DECIPHER_SURVEY_STATUS_ERROR = 'UPDATE_DECIPHER_SURVEY_STATUS_ERROR'
const __updateDecipherSurveyStatusError = error => ({
  type: UPDATE_DECIPHER_SURVEY_STATUS_ERROR,
  error,
})

export const updateDecipherSurveyStatus =
  surveys =>
  async (dispatch, getState, { fetch }) => {
    const params = surveys.map(id => `survey_id=${id}`).join('&')
    const response = await fetch(`${COLLIE_URL}/decipher/surveys/?${params}`)

    if (!response.ok) {
      const error = await response.json()
      dispatch(__updateDecipherSurveyStatusError(error))
      throw new Error(response.statusText)
    }

    const { results } = await response.json()
    dispatch(__updateDecipherSurveyStatusSuccess(results))
  }

export const PUBLISH_DECIPHER_SURVEY_LOADING = 'PUBLISH_DECIPHER_SURVEY_LOADING'
const __publishDecipherSurveyLoading = surveyId => ({
  type: PUBLISH_DECIPHER_SURVEY_LOADING,
  surveyId,
})

export const PUBLISH_DECIPHER_SURVEY_SUCCESS = 'PUBLISH_DECIPHER_SURVEY_SUCCESS'
const __publishDecipherSurveySuccess = surveyId => ({
  type: PUBLISH_DECIPHER_SURVEY_SUCCESS,
  surveyId,
})

export const PUBLISH_DECIPHER_SURVEY_ERROR = 'PUBLISH_DECIPHER_SURVEY_ERROR'
const __publishDecipherSurveyError = surveyId => ({
  type: PUBLISH_DECIPHER_SURVEY_ERROR,
  surveyId,
})

export const publishDecipherSurvey =
  ({ survey_id: surveyId }) =>
  async (dispatch, getState, { fetch }) => {
    dispatch(__publishDecipherSurveyLoading(surveyId))

    const response = await fetch(`${COLLIE_URL}/decipher/surveys/${surveyId}/publish/`, {
      method: 'POST',
    })

    if (!response.ok) {
      dispatch(__publishDecipherSurveyError(surveyId))
      throw new Error('Failed to publish Decipher survey', { cause: response })
    }

    dispatch(__publishDecipherSurveySuccess(surveyId))
  }

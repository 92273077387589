export const createInitializeFiltersAction = (actionType, stateKey) => aggregations => ({
  type: actionType,
  stateKey,
  aggregations,
})

export const createUpdateAvailableFiltersAction = (actionType, stateKey) => aggregations => ({
  type: actionType,
  stateKey,
  aggregations,
})

export const createSetFilterSelectionAction = (actionType, stateKey) => (fieldKey, key, value) => ({
  type: actionType,
  stateKey,
  fieldKey,
  key,
  value,
})

export const createClearFiltersAction = (actionType, stateKey) => () => ({
  type: actionType,
  stateKey,
})

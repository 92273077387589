import { createSelector } from 'reselect'
import { getFilteringQueryParam } from 'data/filters/shared/selectors'
import { getFiltersNotInitialized } from 'data/filters/unified/selectors'
import { get } from 'lodash'

// Query Selector -------------------------------------------
export const getSearchQuery = state => state.results.query
export const getStoryQueryFilter = state => state.results.storyFilterSettings.queryFilter

// Filter Selectors -----------------------------------------

export const getStoryFilters = state => state.results.storyFilters
export const getStoryFilterSettings = state => state.results.storyFilterSettings

export const queryFilterHasError = createSelector(
  getStoryFilterSettings,
  storyFilterSettings => storyFilterSettings.hasError
)

export const isFiltersInitialized = createSelector(
  getFiltersNotInitialized,
  getStoryFilterSettings,
  (unifiedFiltersNotInitialized, storyFilterSettings) =>
    !unifiedFiltersNotInitialized &&
    !storyFilterSettings.isFetching &&
    !storyFilterSettings.initialState
)

export const getStoryFilterQueryParams = createSelector([getStoryFilters], filters =>
  getFilteringQueryParam(filters)
)

export const getInitialStoryFilterState = state =>
  state.results.storyFilterSettings.initialStoryFilterAggregations

// Study Selectors ------------------------------------------

export const hasErrorFetchingStudies = state => state.results.studies.hasError
export const isFetchingStudies = state => state.results.studies.isFetching
export const getStudyCount = state => get(state, 'results.studies.count')
export const getStudyPagination = state => state.results.studies.pagination

const __getStudies = state => get(state, 'results.studies.results', [])

export const getStudies = createSelector(__getStudies, studies => {
  return studies && studies.length ? studies.filter(s => s.permissions.view) : studies
})

// Story Selectors ------------------------------------------

export const hasErrorFetchingStories = state => state.results.stories.hasError
export const isFetchingStories = state => state.results.stories.isFetching
export const getStoryCount = state => get(state, 'results.stories.count')
export const getStoryPagination = state => state.results.stories.pagination
// dev note: husky filters out the stories for you
export const getStories = state => get(state, 'results.stories.results', [])

// Question Selectors ------------------------------------------

export const hasErrorFetchingQuestions = state => state.results.questions.hasError
export const isFetchingQuestions = state => state.results.questions.isFetching
export const getQuestionCount = state => get(state, 'results.questions.count')
export const getQuestionPagination = state => state.results.questions.pagination

const __getQuestions = state => get(state, 'results.questions.results', [])

export const getQuestions = createSelector(__getQuestions, questions => {
  return questions && questions.length ? questions.filter(s => s.permissions.view) : questions
})

// Document Selectors ------------------------------------------

export const hasErrorFetchingDocuments = state => state.results.documents.hasError
export const isFetchingDocuments = state => state.results.documents.isFetching
export const getDocumentCount = state => get(state, 'results.documents.count')
export const getDocumentPagination = state => state.results.documents.pagination

const __getDocuments = state => get(state, 'results.documents.results', [])

export const getDocuments = createSelector(__getDocuments, documents => {
  return documents && documents.length ? documents.filter(s => s.permissions.view) : documents
})

// Meta Selectors
export const isFetchingStudyResults = createSelector(
  [isFetchingStudies, isFetchingQuestions, isFetchingDocuments],
  (isFetchingStudies, isFetchingQuestions, isFetchingDocuments) => {
    return isFetchingStudies || isFetchingDocuments || isFetchingQuestions
  }
)
export const getStudyResults = createSelector(
  [getStudies, getDocuments, getQuestions],
  (studies, documents, questions) => {
    const resultsMap = {}
    studies.forEach(study => {
      const currStudy = study.study_number
      resultsMap[currStudy] = {
        ...study,
        documents: [],
        questions: [],
        resultCount: 1,
      }
    })

    questions.forEach(q => {
      if (!q.permissions.view) return

      const currStudy = q.study.study_number
      if (!resultsMap[currStudy]) {
        resultsMap[currStudy] = {
          ...q.study,
          documents: [],
          questions: [q],
          resultCount: 2,
        }
      } else {
        resultsMap[currStudy].questions.push(q)
        resultsMap[currStudy].resultCount += 1
      }
    })
    documents.forEach(doc => {
      if (!doc.permissions.view) return

      const currStudy = doc.study.study_number
      if (!resultsMap[currStudy]) {
        resultsMap[currStudy] = {
          ...doc.study,
          documents: [doc],
          questions: [],
          resultCount: 2,
        }
      } else {
        resultsMap[currStudy].documents.push(doc)
        resultsMap[currStudy].resultCount += 1
      }
    })
    const resultsArr = Object.values(resultsMap)
    // Rank based on number of query match results
    resultsArr.sort((a, b) => b.resultCount - a.resultCount)
    return resultsArr
  }
)

const hasNotFetched = state => state.results.initialState

export const isFetchingResults = createSelector(
  [isFetchingStudies, isFetchingStories, isFetchingDocuments, isFetchingQuestions],
  (isFetchingStudies, isFetchingStories, isFetchingDocuments, isFetchingQuestions) => {
    return isFetchingStudies || isFetchingStories || isFetchingDocuments || isFetchingQuestions
  }
)

export const isInitialState = createSelector(
  hasNotFetched,
  isFetchingResults,
  (hasNotFetched, isFetching) => hasNotFetched && !isFetching
)

export const getAIVendors = state => state.results.vectorResults.vendors

export const getVectorResults = state => state.results.vectorResults

export const getOpenAiCount = state => state.results.vectorResults.openai?.topMatches.length ?? 0

export const getSentenceCount = state =>
  state.results.vectorResults.sentence_transformer?.topMatches.length ?? 0

export const getVectorTopMatches = createSelector(
  [state => state.results.vectorResults, (state, vendor) => vendor],
  (vectorResults, vendor) => vectorResults[vendor]?.topMatches ?? []
)

export const getVectorQuestions = createSelector(
  [state => state.results.vectorResults, (state, vendor) => vendor],
  (vectorResults, vendor) => vectorResults[vendor]?.questions ?? []
)

export const getVectorSuggested = createSelector(
  [state => state.results.vectorResults, (state, vendor) => vendor],
  (vectorResults, vendor) => vectorResults[vendor]?.suggested ?? []
)

import { createSelector } from 'reselect'

export const getIsFetchingUserPreferences = state =>
  state.personalization.userPreferences.isFetching
export const getHasErrorFetchingUserPreferences = state =>
  Boolean(state.personalization.userPreferences.fetchingError)
export const getAvailablePreferences = state => state.personalization?.availablePreferences ?? null
export const getUserPreferences = state => state.personalization.userPreferences.results ?? []
export const getLocalFilters = state => state.personalization.localFilters ?? []
export const getShowPersonalizationToolTip = state => state.personalization?.showToolTip ?? false

export const getPreferencesExtraneousRemoved = createSelector(
  getAvailablePreferences,
  preferences => {
    let preferencesUnique = {}
    for (let field in preferences) {
      const uniqueValues = []
      if (field !== 'study_year' && Object.values(preferences[field]).length > 0) {
        for (let value in preferences[field]) {
          const valueLabel = preferences[field][value].label
          if (!uniqueValues.includes(valueLabel)) {
            if (!preferencesUnique[field]) {
              preferencesUnique[field] = {}
            }
            uniqueValues.push(valueLabel)
            preferencesUnique[field][value] = { ...preferences[field][value] }
          }
        }
      }
    }
    return preferencesUnique
  }
)

export const getSearchQuery = state => state.personalization.searchQuery
export const getFilteredPreferences = createSelector(
  getSearchQuery,
  getPreferencesExtraneousRemoved,
  (searchQuery, preferences) => {
    if (!searchQuery) return preferences
    const searchTerm = searchQuery.toLowerCase()

    let searchedPreferences = {}

    for (let field in preferences) {
      if (field.toLowerCase().includes(searchTerm)) {
        searchedPreferences[field] = { ...preferences[field] }
      }
      for (let value in preferences[field]) {
        const valueLabel = String(preferences[field][value].label).toLowerCase()
        if (valueLabel.includes(searchTerm)) {
          if (!searchedPreferences[field]) {
            searchedPreferences[field] = {}
          }
          searchedPreferences[field][value] = { ...preferences[field][value] }
        }
      }
    }
    return searchedPreferences
  }
)

export const uniqueValues = values => {
  const uniquePreferences = []
  const uniqueValues = []
  for (let value in values) {
    const label = value.label
    if (!uniqueValues.includes(label)) {
      const unique = { ...value }
      uniqueValues.push(label)
      uniquePreferences.push(unique)
    }
  }
  return uniquePreferences
}

export const getSelectedPreferences = createSelector(getFilteredPreferences, preferences => {
  const selectedPreferences = []
  for (let field in preferences) {
    for (let value in preferences[field]) {
      if (preferences[field][value].selected) {
        const selected = { ...preferences[field][value] }
        selected.field = field
        selectedPreferences.push(selected)
      }
    }
  }
  return selectedPreferences
})

export const getUserHasPreferences = createSelector(getSelectedPreferences, preferences => {
  return Object.keys(preferences).length > 0
})

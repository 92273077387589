import * as actions from './actions'

export const initialReducerState = {
  searchQuery: '',
  integrationStatus: {
    isFetching: false,
    error: null,
    data: null,
    lastFetched: null,
  },
  deleteSmIntegration: {
    isFetching: false,
    error: null,
    data: null,
    lastFetched: null,
  },
  integrationTokens: {}, // Set of IntegrationTokenStateT's
  qualtricsSurveys: {
    isFetching: false,
    error: null,
    data: null,
    lastFetched: null,
  },
  surveyMonkeySurveys: {
    isFetching: false,
    error: null,
    data: null,
    lastFetched: null,
  },
  decipher: {
    apiKey: {
      isCreating: false,
      errorCreating: null,
      isUpdating: false,
      errorUpdating: null,
      isDeleting: false,
      errorDeleting: null,
      publicKey: '',
      privateKey: '',
    },
    isFetchingSurveys: false,
    errorFetchingSurveys: null,
    surveys: null,
  },
}

function newIntegrationTokenState(state, integration, newState) {
  // This really requires a deep copy but cloneDeep from lodash was causing side effects
  const oldTokenState = state.integrationTokens[integration] || {}
  const currentIntegrationTokens = state.integrationTokens
  const newIntegrationTokens = {
    ...currentIntegrationTokens,
  }
  if (!newState) {
    delete newIntegrationTokens[integration]
  } else {
    newIntegrationTokens[integration] = {
      ...oldTokenState,
      ...newState,
    }
  }
  return {
    ...state,
    integrationTokens: newIntegrationTokens,
  }
}

export default function integrationsReducer(state = initialReducerState, action = {}) {
  switch (action.type) {
    case actions.FETCH_INTEGRATION_STATUS_LOADING: {
      return {
        ...state,
        integrationStatus: {
          ...state.integrationStatus,
          isFetching: true,
          error: null,
        },
      }
    }
    case actions.FETCH_INTEGRATION_STATUS_SUCCESS: {
      const lastFetched = Date.now()
      return {
        ...state,
        integrationStatus: {
          ...state.integrationStatus,
          isFetching: false,
          lastFetched,
          data: action.payload,
          error: null,
        },
      }
    }
    case actions.FETCH_INTEGRATION_STATUS_ERROR: {
      const lastFetched = Date.now()
      return {
        ...state,
        integrationStatus: {
          ...state.integrationStatus,
          isFetching: false,
          lastFetched,
          error: action.payload,
        },
      }
    }
    case actions.FETCH_INTEGRATION_TOKEN_LOADING: {
      return newIntegrationTokenState(state, action.payload, {
        isFetching: true,
        error: null,
        data: null,
      })
    }
    case actions.FETCH_INTEGRATION_TOKEN_SUCCESS: {
      const newState = newIntegrationTokenState(state, action.payload.integration, {
        isFetching: false,
        error: null,
        data: action.payload,
      })

      if (action.payload.integration === 'decipher') {
        const [, publicKey, privateKey] = action.payload.token.match('^(.{32})(.{32})$') ?? [
          '',
          '',
          '',
        ]
        return {
          ...newState,
          decipher: {
            ...newState.decipher,
            apiKey: {
              ...newState.decipher.apiKey,
              publicKey,
              privateKey,
            },
          },
        }
      }

      return newState
    }
    case actions.FETCH_INTEGRATION_TOKEN_ERROR: {
      return newIntegrationTokenState(state, action.integration, {
        isFetching: false,
        error: action.payload,
        data: null,
      })
    }
    case actions.CREATE_QUALTRICS_KEY_LOADING: {
      return {
        ...state,
      }
    }
    case actions.CREATE_QUALTRICS_KEY_SUCCESS: {
      let newState = {
        ...state,
        integrationStatus: {
          ...state.integrationStatus,
          data: {
            ...state.integrationStatus.data,
            qualtrics: 'connected',
          },
        },
      }
      const oldTokenState = newState.integrationTokens[action.payload.integration] || {}
      newState.integrationTokens[action.payload.integration] = {
        ...oldTokenState,
        isFetching: false,
        error: null,
        data: action.payload,
      }
      return newState
    }
    case actions.CREATE_QUALTRICS_KEY_ERROR: {
      return {
        ...state,
      }
    }
    case actions.DELETE_QUALTRICS_TOKEN_SUCCESS: {
      const lastFetched = Date.now()
      let status = {
        ...state.integrationStatus,
      }
      status.data[action.payload.integration] = 'disconnected'
      let baseState = {
        ...state,
        integrationStatus: status,
      }
      if (action.payload.integration === 'qualtrics') {
        baseState['qualtricsSurveys'] = {
          ...state.qualtricsSurveys,
          lastFetched,
          data: null,
        }
      }
      return newIntegrationTokenState(baseState, action.payload.integration, null)
    }
    case actions.DELETE_QUALTRICS_TOKEN_ERROR: {
      return {
        ...state,
      }
    }
    case actions.FETCH_QUALTRICS_SURVEYS_LOADING: {
      return {
        ...state,
        qualtricsSurveys: {
          ...state.qualtricsSurveys,
          isFetching: true,
          error: null,
          data: null,
        },
      }
    }
    case actions.FETCH_QUALTRICS_SURVEYS_SUCCESS: {
      const lastFetched = Date.now()
      return {
        ...state,
        qualtricsSurveys: {
          ...state.qualtricsSurveys,
          isFetching: false,
          lastFetched,
          data: action.payload,
          error: null,
        },
      }
    }
    case actions.FETCH_QUALTRICS_SURVEYS_ERROR: {
      const lastFetched = Date.now()
      return {
        ...state,
        qualtricsSurveys: {
          ...state.qualtricsSurveys,
          isFetching: false,
          lastFetched,
          error: action.payload,
        },
      }
    }
    case actions.FETCH_QUALTRICS_SURVEY_STATUS_LOADING: {
      return {
        ...state,
        qualtricsSurveys: {
          ...state.qualtricsSurveys,
          error: null,
        },
      }
    }
    case actions.FETCH_QUALTRICS_SURVEY_STATUS_SUCCESS: {
      const lastFetched = Date.now()
      const stateMap = {}
      action.payload.results.forEach(res => {
        stateMap[res.survey_id] = res
      })
      return {
        ...state,
        qualtricsSurveys: {
          ...state.qualtricsSurveys,
          lastFetched,
          data: {
            results: state.qualtricsSurveys.data.results.map((survey, index) => {
              return stateMap[survey.survey_id]
                ? {
                    ...survey,
                    state:
                      stateMap[survey.survey_id].state === 'not_imported'
                        ? 'queued'
                        : stateMap[survey.survey_id].state,
                    study_number: stateMap[survey.survey_id].study_number,
                  }
                : survey
            }),
          },
        },
      }
    }
    case actions.FETCH_QUALTRICS_SURVEY_STATUS_ERROR: {
      const lastFetched = Date.now()
      return {
        ...state,
        qualtricsSurveys: {
          ...state.qualtricsSurveys,
          lastFetched,
          isFetching: false,
          error: action.type,
          data: {
            results: state.qualtricsSurveys.data.results.map((survey, index) => {
              return action.body.indexOf(survey.survey_id) > -1
                ? {
                    ...survey,
                    state: 'error',
                    errorType: action.type,
                  }
                : survey
            }),
          },
        },
      }
    }
    case actions.IMPORT_QUALTRICS_SURVEY_LOADING: {
      return {
        ...state,
        qualtricsSurveys: {
          ...state.qualtricsSurveys,
          data: {
            results: state.qualtricsSurveys.data.results.map((survey, index) => {
              return survey.survey_id === action.survey_id ? { ...survey, state: 'queued' } : survey
            }),
          },
        },
      }
    }
    case actions.IMPORT_QUALTRICS_SURVEY_SUCCESS: {
      const lastFetched = Date.now()
      return {
        ...state,
        qualtricsSurveys: {
          ...state.qualtricsSurveys,
          lastFetched,
          data: {
            results: state.qualtricsSurveys.data.results.map((survey, index) => {
              return survey.survey_id === action.survey_id
                ? { ...survey, study_number: action.payload.study_id }
                : survey
            }),
          },
        },
      }
    }
    case actions.IMPORT_QUALTRICS_SURVEY_ERROR: {
      const lastFetched = Date.now()
      return {
        ...state,
        qualtricsSurveys: {
          ...state.qualtricsSurveys,
          lastFetched,
          data: {
            results: state.qualtricsSurveys.data.results.map((survey, index) => {
              return survey.survey_id === action.survey_id
                ? { ...survey, state: 'error', errorType: action.type }
                : survey
            }),
          },
          error: action.type,
        },
      }
    }
    case actions.PUBLISH_QUALTRICS_SURVEY_LOADING: {
      return {
        ...state,
        qualtricsSurveys: {
          ...state.qualtricsSurveys,
          data: {
            results: state.qualtricsSurveys.data.results.map((survey, index) => {
              return survey.survey_id === action.survey_id
                ? { ...survey, state: 'publishing' }
                : survey
            }),
          },
        },
      }
    }
    case actions.PUBLISH_QUALTRICS_SURVEY_SUCCESS: {
      const lastFetched = Date.now()
      return {
        ...state,
        qualtricsSurveys: {
          ...state.qualtricsSurveys,
          lastFetched,
          data: {
            results: state.qualtricsSurveys.data.results.map((survey, index) => {
              return survey.survey_id === action.survey_id ? { ...survey, state: 'live' } : survey
            }),
          },
        },
      }
    }
    case actions.PUBLISH_QUALTRICS_SURVEY_ERROR: {
      const lastFetched = Date.now()
      return {
        ...state,
        qualtricsSurveys: {
          ...state.qualtricsSurveys,
          lastFetched,
          data: {
            results: state.qualtricsSurveys.data.results.map((survey, index) => {
              return survey.survey_id === action.survey_id
                ? { ...survey, state: 'error', errorType: action.type }
                : survey
            }),
          },
          error: action.type,
        },
      }
    }
    // surveymonkey actions
    case actions.FETCH_SM_SURVEYS_LOADING: {
      return {
        ...state,
        surveyMonkeySurveys: {
          ...state.surveyMonkeySurveys,
          isFetching: true,
          error: null,
          data: null,
        },
      }
    }
    case actions.FETCH_SM_SURVEYS_SUCCESS: {
      const lastFetched = Date.now()
      return {
        ...state,
        surveyMonkeySurveys: {
          ...state.surveyMonkeySurveys,
          isFetching: false,
          lastFetched,
          data: action.payload,
          error: null,
        },
      }
    }
    case actions.FETCH_SM_SURVEYS_ERROR: {
      const lastFetched = Date.now()
      return {
        ...state,
        surveyMonkeySurveys: {
          ...state.surveyMonkeySurveys,
          isFetching: false,
          lastFetched,
          error: action.payload,
        },
      }
    }
    case actions.FETCH_SM_SURVEY_STATUS_LOADING: {
      return {
        ...state,
        surveyMonkeySurveys: {
          ...state.surveyMonkeySurveys,
          error: null,
        },
      }
    }
    case actions.FETCH_SM_SURVEY_STATUS_SUCCESS: {
      const lastFetched = Date.now()
      const stateMap = {}
      action.payload.results.forEach(res => {
        stateMap[res.survey_id] = res
      })
      return {
        ...state,
        surveyMonkeySurveys: {
          ...state.surveyMonkeySurveys,
          lastFetched,
          data: {
            results: state.surveyMonkeySurveys.data.results.map((survey, index) => {
              return stateMap[survey.survey_id]
                ? {
                    ...survey,
                    state:
                      stateMap[survey.survey_id].state === 'not_imported'
                        ? 'queued' // if we successfully requested, it should be not be not_imported state visually
                        : stateMap[survey.survey_id].state,
                    study_number: stateMap[survey.survey_id].study_number,
                  }
                : survey
            }),
          },
        },
      }
    }
    case actions.FETCH_SM_SURVEY_STATUS_ERROR: {
      const lastFetched = Date.now()
      return {
        ...state,
        surveyMonkeySurveys: {
          ...state.surveyMonkeySurveys,
          lastFetched,
          isFetching: false,
          error: action.type,
          data: {
            results: state.surveyMonkeySurveys.data.results.map((survey, index) => {
              return action.body.indexOf(survey.survey_id) > -1
                ? {
                    ...survey,
                    state: 'error',
                    errorType: action.type,
                  }
                : survey
            }),
          },
        },
      }
    }
    case actions.IMPORT_SM_SURVEY_LOADING: {
      return {
        ...state,
        surveyMonkeySurveys: {
          ...state.surveyMonkeySurveys,
          data: {
            results: state.surveyMonkeySurveys.data.results.map((survey, index) => {
              return survey.survey_id === action.survey_id ? { ...survey, state: 'queued' } : survey
            }),
          },
        },
      }
    }
    case actions.IMPORT_SM_SURVEY_SUCCESS: {
      const lastFetched = Date.now()
      return {
        ...state,
        surveyMonkeySurveys: {
          ...state.surveyMonkeySurveys,
          lastFetched,
          data: {
            results: state.surveyMonkeySurveys.data.results.map((survey, index) => {
              return survey.survey_id === action.survey_id
                ? { ...survey, study_number: action.payload.study_id }
                : survey
            }),
          },
        },
      }
    }
    case actions.IMPORT_SM_SURVEY_ERROR: {
      const lastFetched = Date.now()
      return {
        ...state,
        surveyMonkeySurveys: {
          ...state.surveyMonkeySurveys,
          lastFetched,
          data: {
            results: state.surveyMonkeySurveys.data.results.map((survey, index) => {
              return survey.survey_id === action.survey_id
                ? { ...survey, state: 'error', errorType: action.type }
                : survey
            }),
          },
          error: action.type,
        },
      }
    }
    case actions.PUBLISH_SM_SURVEY_LOADING: {
      return {
        ...state,
        surveyMonkeySurveys: {
          ...state.surveyMonkeySurveys,
          data: {
            results: state.surveyMonkeySurveys.data.results.map((survey, index) => {
              return survey.survey_id === action.survey_id
                ? { ...survey, state: 'publishing' }
                : survey
            }),
          },
        },
      }
    }
    case actions.PUBLISH_SM_SURVEY_SUCCESS: {
      const lastFetched = Date.now()
      return {
        ...state,
        surveyMonkeySurveys: {
          ...state.surveyMonkeySurveys,
          lastFetched,
          data: {
            results: state.surveyMonkeySurveys.data.results.map((survey, index) => {
              return survey.survey_id === action.survey_id ? { ...survey, state: 'live' } : survey
            }),
          },
        },
      }
    }
    case actions.PUBLISH_SM_SURVEY_ERROR: {
      const lastFetched = Date.now()
      return {
        ...state,
        surveyMonkeySurveys: {
          ...state.surveyMonkeySurveys,
          lastFetched,
          data: {
            results: state.surveyMonkeySurveys.data.results.map((survey, index) => {
              return survey.survey_id === action.survey_id
                ? { ...survey, state: 'error', errorType: action.type }
                : survey
            }),
          },
          error: action.type,
        },
      }
    }
    case actions.DELETE_SM_INTEGRATION_LOADING: {
      return {
        ...state,
        deleteSmIntegration: {
          ...state.deleteSmIntegration,
          isFetching: true,
          error: null,
          data: null,
        },
      }
    }
    case actions.DELETE_SM_INTEGRATION_SUCCESS: {
      const lastFetched = Date.now()
      return {
        ...state,
        deleteSmIntegration: {
          ...state.deleteSmIntegration,
          isFetching: false,
          lastFetched,
          data: action.payload,
          error: null,
        },
      }
    }
    case actions.DELETE_SM_INTEGRATION_ERROR: {
      const lastFetched = Date.now()
      return {
        ...state,
        deleteSmIntegration: {
          ...state.deleteSmIntegration,
          isFetching: false,
          lastFetched,
          error: action.payload,
        },
      }
    }
    case actions.UPDATE_INTEGRATION_OPTION_LOADING: {
      const newState = { ...state }
      const oldTokenState = newState.integrationTokens[action.integration] || {}
      newState.integrationTokens[action.integration] = {
        ...oldTokenState,
        isFetching: true,
        error: null,
      }
      return newState
    }
    case actions.UPDATE_INTEGRATION_OPTION_SUCCESS: {
      const newState = { ...state }
      const oldTokenState = newState.integrationTokens[action.payload.integration] || {}
      newState.integrationTokens[action.payload.integration] = {
        ...oldTokenState,
        isFetching: false,
        error: null,
        data: action.payload,
      }
      return newState
    }
    case actions.UPDATE_INTEGRATION_OPTION_ERROR: {
      const newState = { ...state }
      const oldTokenState = newState.integrationTokens[action.payload.integration] || {}
      newState.integrationTokens[action.payload.integration] = {
        ...oldTokenState,
        isFetching: false,
        error: action.payload,
        data: null,
      }
      return newState
    }
    case actions.SET_SURVEY_SEARCH_QUERY: {
      return {
        ...state,
        searchQuery: action.searchQuery,
      }
    }

    case actions.EDIT_DECIPHER_API_KEY: {
      return {
        ...state,
        decipher: {
          ...state.decipher,
          apiKey: {
            ...state.decipher.apiKey,
            [action.partType]: action.value,
          },
        },
      }
    }
    case actions.CREATE_DECIPHER_KEY_LOADING: {
      return {
        ...state,
        decipher: {
          ...state.decipher,
          apiKey: {
            ...state.decipher.apiKey,
            isCreating: true,
            errorCreating: null,
          },
        },
      }
    }
    case actions.CREATE_DECIPHER_KEY_SUCCESS: {
      return {
        ...state,
        integrationTokens: {
          ...state.integrationTokens,
          decipher: {
            isFetching: false,
            error: null,
            data: action.data,
          },
        },
        decipher: {
          ...state.decipher,
          apiKey: {
            ...state.decipher.apiKey,
            isCreating: false,
          },
        },
      }
    }
    case actions.CREATE_DECIPHER_KEY_ERROR: {
      return {
        ...state,
        decipher: {
          ...state.decipher,
          apiKey: {
            ...state.decipher.apiKey,
            isCreating: false,
            errorCreating: action.error,
          },
        },
      }
    }
    case actions.UPDATE_DECIPHER_KEY_LOADING: {
      return {
        ...state,
        decipher: {
          ...state.decipher,
          apiKey: {
            ...state.decipher.apiKey,
            isUpdating: true,
            errorUpdating: null,
          },
        },
      }
    }
    case actions.UPDATE_DECIPHER_KEY_SUCCESS: {
      return {
        ...state,
        decipher: {
          ...state.decipher,
          apiKey: {
            ...state.decipher.apiKey,
            isUpdating: false,
          },
        },
      }
    }
    case actions.UPDATE_DECIPHER_KEY_ERROR: {
      return {
        ...state,
        decipher: {
          ...state.decipher,
          apiKey: {
            ...state.decipher.apiKey,
            isUpdating: false,
            errorUpdating: action.error,
          },
        },
      }
    }
    case actions.DELETE_DECIPHER_KEY_LOADING: {
      return {
        ...state,
        decipher: {
          ...state.decipher,
          apiKey: {
            ...state.decipher.apiKey,
            isDeleting: true,
            errorDeleting: null,
          },
        },
      }
    }
    case actions.DELETE_DECIPHER_KEY_SUCCESS: {
      return {
        ...state,
        integrationTokens: {
          ...state.integrationTokens,
          decipher: null,
        },
        decipher: {
          ...state.decipher,
          apiKey: {
            ...state.decipher.apiKey,
            isDeleting: false,
            publicKey: action.isUpdatingKey ? state.decipher.apiKey.publicKey : '',
            privateKey: action.isUpdatingKey ? state.decipher.apiKey.privateKey : '',
          },
          surveys: [],
        },
      }
    }
    case actions.DELETE_DECIPHER_KEY_ERROR: {
      return {
        ...state,
        decipher: {
          ...state.decipher,
          apiKey: {
            ...state.decipher.apiKey,
            isDeleting: false,
            errorDeleting: action.error,
          },
        },
      }
    }
    case actions.FETCH_DECIPHER_SURVEYS_LOADING: {
      return {
        ...state,
        decipher: {
          ...state.decipher,
          isFetchingSurveys: true,
          errorFetchingSurveys: null,
        },
      }
    }
    case actions.FETCH_DECIPHER_SURVEYS_SUCCESS: {
      return {
        ...state,
        decipher: {
          ...state.decipher,
          isFetchingSurveys: false,
          surveys: action.surveys,
        },
      }
    }
    case actions.FETCH_DECIPHER_SURVEYS_ERROR: {
      return {
        ...state,
        decipher: {
          ...state.decipher,
          isFetchingSurveys: false,
          errorFetchingSurveys: action.error,
        },
      }
    }
    case actions.IMPORT_DECIPHER_SURVEY_LOADING: {
      return {
        ...state,
        decipher: {
          ...state.decipher,
          surveys: state.decipher.surveys.map(survey => ({
            ...survey,
            state: survey.survey_id === action.surveyId ? 'queued' : survey.state,
          })),
        },
      }
    }
    case actions.IMPORT_DECIPHER_SURVEY_SUCCESS: {
      return {
        ...state,
        decipher: {
          ...state.decipher,
          surveys: state.decipher.surveys.map(survey =>
            survey.survey_id === action.surveyId
              ? {
                  ...survey,
                  study_number: action.data.study_number,
                }
              : survey
          ),
        },
      }
    }
    case actions.IMPORT_DECIPHER_SURVEY_ERROR: {
      return {
        ...state,
        decipher: {
          ...state.decipher,
          surveys: state.decipher.surveys.map(survey => ({
            ...survey,
            errorType: survey.survey_id === action.surveyId ? action.type : null,
            state: survey.survey_id === action.surveyId ? 'error' : survey.state,
          })),
        },
      }
    }

    case actions.UPDATE_DECIPHER_SURVEY_STATUS_SUCCESS: {
      const statusMapById = action.data.reduce((acc, survey) => {
        acc[survey.survey_id] = survey
        return acc
      }, {})

      return {
        ...state,
        decipher: {
          ...state.decipher,
          surveys: state.decipher.surveys.map(survey => {
            if (statusMapById[survey.survey_id]) {
              return {
                ...survey,
                ...statusMapById[survey.survey_id],
              }
            }
            return survey
          }),
        },
      }
    }
    case actions.UPDATE_DECIPHER_SURVEY_STATUS_ERROR: {
      const statusMapById = action.data.reduce((acc, survey) => {
        acc[survey.survey_id] = survey
        return acc
      }, {})

      return {
        ...state,
        decipher: {
          ...state.decipher,
          surveys: state.decipher.surveys.map(survey => {
            if (statusMapById[survey.survey_id]) {
              return {
                ...survey,
                errorType: action.type,
                state: 'error',
              }
            }
            return survey
          }),
        },
      }
    }

    case actions.PUBLISH_DECIPHER_SURVEY_LOADING: {
      return {
        ...state,
        decipher: {
          ...state.decipher,
          surveys: state.decipher.surveys.map(survey => ({
            ...survey,
            state: survey.survey_id === action.surveyId ? 'publishing' : survey.state,
          })),
        },
      }
    }
    case actions.PUBLISH_DECIPHER_SURVEY_SUCCESS: {
      return {
        ...state,
        decipher: {
          ...state.decipher,
          surveys: state.decipher.surveys.map(survey => ({
            ...survey,
            state: survey.survey_id === action.surveyId ? 'live' : survey.state,
          })),
        },
      }
    }
    case actions.PUBLISH_DECIPHER_SURVEY_ERROR: {
      return {
        ...state,
        decipher: {
          ...state.decipher,
          surveys: state.decipher.surveys.map(survey => ({
            ...survey,
            errorType: survey.survey_id === action.surveyId ? action.type : null,
            state: survey.survey_id === action.surveyId ? 'error' : survey.state,
          })),
        },
      }
    }

    default:
      return state
  }
}

import React, { forwardRef, useMemo } from 'react'
import PropTypes from 'prop-types'
import cs from 'classnames'
import { createSelector } from 'reselect'
import { useSelector, shallowEqual } from 'react-redux'
import { Tooltip } from '@knowledgehound/laika'

import { getUserPreferences } from 'data/personalization/selectors'
import * as styles from './MetadataList.module.scss'

const AVERAGE_SOURCE_SANS_CHAR_WIDTH = 6.73
const MAX_BADGE_WIDTH = 120
const MAX_CHARACTER_COUNT = 15
const COLLAPSED_BADGE_WIDTH = 30

const getPrefsByCategory = createSelector(getUserPreferences, preferences =>
  preferences.reduce((acc, category) => {
    acc[category.name] = category.values
    return acc
  }, {})
)

const sortSupplierToEnd = (a, b) => {
  if (a.category !== 'Supplier' && b.category === 'Supplier') {
    return -1
  }
  if (a.category === 'Supplier' && b.category !== 'Supplier') {
    return 1
  }
  return 0
}

const propTypes = {
  interiorCardWidth: PropTypes.number,
  metadata: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      values: PropTypes.any, // Arrays of arrays of strings,
    })
  ).isRequired,
}

const MetadataList = forwardRef(({ interiorCardWidth, metadata }, ref) => {
  const preferences = useSelector(getPrefsByCategory, shallowEqual)

  const { tokens, hasMoreTooltip, hasMorePreferred } = useMemo(() => {
    const { preferredTokens, nonPreferredTokens } = metadata.reduce(
      (acc, category) => {
        category.values.forEach(value => {
          const fullName = value[value.length - 1]
          const fullTokenWidth = fullName.length * AVERAGE_SOURCE_SANS_CHAR_WIDTH + 20
          const name =
            fullTokenWidth >= MAX_BADGE_WIDTH
              ? `${fullName.slice(0, MAX_CHARACTER_COUNT)}...`
              : fullName

          const token = {
            name,
            fullName,
            category: category.name,
            preferred: (preferences[category.name] ?? []).includes(fullName),
          }

          if (token.preferred) {
            acc.preferredTokens.push(token)
          } else {
            acc.nonPreferredTokens.push(token)
          }
        })

        return acc
      },
      { preferredTokens: [], nonPreferredTokens: [] }
    )

    preferredTokens.sort(sortSupplierToEnd)
    nonPreferredTokens.sort(sortSupplierToEnd)

    const allTokens = [...preferredTokens, ...nonPreferredTokens]
    const thresholdWidth = interiorCardWidth - COLLAPSED_BADGE_WIDTH
    let cumulativeWidth = 0
    let index = 0
    for (const token of allTokens) {
      cumulativeWidth += token.name.length * AVERAGE_SOURCE_SANS_CHAR_WIDTH + 20
      if (cumulativeWidth >= thresholdWidth) {
        break
      }
      index += 1
    }

    const restTokens = allTokens.slice(index)

    return {
      tokens: allTokens.slice(0, index),
      hasMoreTooltip: restTokens.map(({ fullName }) => fullName).join(', '),
      hasMorePreferred: restTokens.some(({ preferred }) => preferred),
    }
  }, [interiorCardWidth, metadata, preferences])

  return (
    <div ref={ref} className={styles.fmdList}>
      {tokens.map((tag, idx) => (
        <div
          key={`${tag.name}-${idx}`}
          className={cs(styles.badge, { [styles.preferred]: tag.preferred })}
        >
          {tag.name}
        </div>
      ))}
      {hasMoreTooltip && (
        <Tooltip content={hasMoreTooltip} placement="top">
          <div className={cs(styles.badge, { [styles.preferred]: hasMorePreferred })}>•••</div>
        </Tooltip>
      )}
    </div>
  )
})

MetadataList.displayName = 'MetadataList'
MetadataList.propTypes = propTypes

export default MetadataList

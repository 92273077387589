export const getHasFetchedStudies = state => state.home.studies.hasFetched
export const getIsFetchingStudies = state => state.home.studies.isFetching
export const getStudiesHasError = state => Boolean(state.home.studies.error)
export const getStudies = state => state.home.studies.data || []

export const getHasFetchedStories = state => state.home.stories.hasFetched
export const getIsFetchingStories = state => state.home.stories.isFetching
export const getStoriesHasError = state => Boolean(state.home.stories.error)
export const getStories = state => state.home.stories.data || []

export const getHasFetchedFavorites = state => state.home.favorites.hasFetched
export const getIsFetchingFavorites = state => state.home.favorites.isFetching
export const getFavoritesHasError = state => Boolean(state.home.favorites.error)
export const getFavorites = state => state.home.favorites.data || []

import { captureMessage } from '@sentry/react'

import { getFavorites } from './FavoriteSelectors'

const DALMATIAN = process.env.REACT_APP_DALMATIAN_API2_URL || ''
const GREYHOUND = process.env.REACT_APP_GREYHOUND_URL || ''

export const CREATE_FAVORITE_LOADING = 'CREATE_FAVORITE_LOADING'
const createFavoriteLoading = () => ({
  type: CREATE_FAVORITE_LOADING,
})

export const CREATE_FAVORITE_SUCCESS = 'CREATE_FAVORITE_SUCCESS'
const createFavoriteSuccess = response => ({
  type: CREATE_FAVORITE_SUCCESS,
  payload: response,
})

export const CREATE_FAVORITE_ERROR = 'CREATE_FAVORITE_ERROR'
const createFavoriteError = err => ({
  type: CREATE_FAVORITE_ERROR,
  error: true,
  payload: err,
})

/**
 * Save new favorite for user
 *
 * @param {Object} favorite - New favorite
 * @param {string} favorite.item_id - ID of the favorite
 * @param {string} favorite.item_type - Type of the favorite
 * @param {string} favorite.user - Email of the favorite author
 * @returns {Object|null} Returns new favorite, null otherwise
 */
export const createFavorite =
  favorite =>
  async (dispatch, getState, { fetch }) => {
    dispatch(createFavoriteLoading())

    const response = await fetch(`${DALMATIAN}/favorites/`, {
      method: 'POST',
      jsonBody: favorite,
    })

    if (!response.ok) {
      dispatch(createFavoriteError(response.statusText))
      return null
    }

    const data = await response.json()
    return dispatch(createFavoriteSuccess(data))
  }

export const DELETE_FAVORITE_LOADING = 'DELETE_FAVORITE_LOADING'
const deleteFavoriteLoading = () => ({
  type: DELETE_FAVORITE_LOADING,
})

export const DELETE_FAVORITE_SUCCESS = 'DELETE_FAVORITE_SUCCESS'
const deleteFavoriteSuccess = favoriteId => ({
  type: DELETE_FAVORITE_SUCCESS,
  payload: { favoriteId },
})

export const DELETE_FAVORITE_ERROR = 'DELETE_FAVORITE_ERROR'
const deleteFavoriteError = err => ({
  type: DELETE_FAVORITE_ERROR,
  error: true,
  payload: err,
})

/**
 * Delete a favorite
 * @param {String} favoriteId - UUID of the favorite
 * @returns {boolean} True if successful
 */
export const deleteFavorite =
  favoriteId =>
  async (dispatch, getState, { fetch }) => {
    dispatch(deleteFavoriteLoading())

    const response = await fetch(`${DALMATIAN}/favorites/${favoriteId}/`, {
      method: 'DELETE',
    })

    if (!response.ok) {
      dispatch(deleteFavoriteError(response.statusText))
      return false
    }

    dispatch(deleteFavoriteSuccess(favoriteId))
    return true
  }

export const FETCH_FAVORITES_LOADING = 'FETCH_FAVORITES_LOADING'
const fetchFavoritesLoading = () => ({
  type: FETCH_FAVORITES_LOADING,
})

export const FETCH_FAVORITES_SUCCESS = 'FETCH_FAVORITES_SUCCESS'
const fetchFavoritesSuccess = response => ({
  type: FETCH_FAVORITES_SUCCESS,
  payload: response,
})

export const FETCH_FAVORITES_ERROR = 'FETCH_FAVORITES_ERROR'
const fetchFavoritesError = err => ({
  type: FETCH_FAVORITES_ERROR,
  error: true,
  payload: err,
})

/**
 * Fetch all favorites for a user by their email
 * @param {String} type - Favorite type(s)
 * @param {String} email - User's email address
 */
export const fetchFavorites =
  (type, email) =>
  async (dispatch, getState, { fetch }) => {
    dispatch(fetchFavoritesLoading())

    const params = new URLSearchParams({ type, user: email })

    const response = await fetch(`${DALMATIAN}/favorites/?${params.toString()}`)

    if (!response.ok) {
      dispatch(fetchFavoritesError(response.statusText))
      return
    }

    const data = await response.json()
    dispatch(fetchFavoritesSuccess(data))
  }

export const SET_FAVORITES_DETAILS = 'SET_FAVORITES_DETAILS'
const setFavoriteDetails = (fId, favDetails) => ({
  type: SET_FAVORITES_DETAILS,
  payload: { fId, favDetails },
})

export const fetchFavoritesWithDetails =
  (type, email) =>
  async (dispatch, getState, { fetch }) => {
    await dispatch(fetchFavorites(type, email))

    const { data = [] } = getFavorites(getState())

    data.forEach(async f => {
      if (f.item_type === 'story') {
        const response = await fetch(`${DALMATIAN}/stories/${f.item_id}/`)
        if (!response.ok) {
          dispatch(
            setFavoriteDetails(f.id, {
              loading: false,
              data: null,
              error: response.statusText,
            })
          )
        }

        const story = await response.json()
        dispatch(
          setFavoriteDetails(f.id, {
            loading: false,
            data: story,
            error: false,
          })
        )
      } else if (f.item_type === 'study') {
        const response = await fetch(`${GREYHOUND}/studies/${f.item_id}/`)

        if (!response.ok) {
          dispatch(
            setFavoriteDetails(f.id, {
              loading: false,
              data: null,
              error: response.statusText,
            })
          )
        }

        const study = await response.json()
        dispatch(
          setFavoriteDetails(f.id, {
            loading: false,
            data: study,
            error: false,
          })
        )
      } else {
        captureMessage(`Unknown favorite entity type ${f.item_type}`)
      }
    })
  }
